import { Badge, Button, Modal, Select, Spin, Steps, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import './HelperComponent.css'
import { Col, Label, Row } from 'reactstrap'
import AwsServerService from '../../../common/util/AwsServerService'
import { OutlinedFlag } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
import { CustomeCollapse } from '../../../tabs/Task/AddTaskTabs/CustomeCollapse'
import { Node } from 'mathjs'
export const DeleteNodeConformPopup = ({ open, setOpen, onClose, onOk, onCancel, Nodes, Edges, taskFlowId, bluePrintData }) => {
    const [loading, setLoading] = useState(false);
    const [dependancies, setDependancies] = useState();
    const [statusdependancies, setStatusdependancies] = useState();
    const [outgoingNodesToCurrent, setOutgoingNodesToCurrent] = useState([]);
    const [incommingNodesToCurrent, setIncommingNodesToCurrent] = useState([]);

    const [connectedNodes, setConnectedNodes] = useState([]);

    const [errors, setErrors] = useState([]);

    const [incommingSteps, setIncommingSteps] = useState([])
    const [outgoingSteps, setOutgoingSteps] = useState([])

    const [approvedIds, setApprovedIds] = useState([]);
    const [targetStatus, setTargetStatus] = useState();
    useEffect(e => {
        if (open?.open && open?.node?.id && open?.flowId) {
              
            setLoading(true);
            AwsServerService.fetchNodeDependancies(open?.flowId, open.node.id).then(res => {
                setDependancies(res.taskInfo)
            }).catch(e => {
                setDependancies(null)
            })
            AwsServerService.getTaskFlowStatusDependancies(open.node.id,open?.flowId).then(res => {
                  
                setStatusdependancies(res)
            }).catch(e => {
                setStatusdependancies(null)
            })
            setTimeout(() => {
                setLoading(false);
            }, 1000)
              
            let upcommingNodes = [];
            let incommingNodes = [];
            let upcommingIds = [];
            let incommingIds = [];
            let tempIIds = [];
            let tempOIds = [];
            let upcommingStepArr = [];
            let incommingStepArr = [];
            Edges?.forEach(ed => {
                if (ed.source == open.node.id) {
                    let temp = Nodes?.find(n => n.id == ed.target)
                    if (temp) {
                        upcommingNodes.push(temp);
                        let tt = Edges?.filter(ed => ed.target == open.node.id && ed.source == temp.id);
                        if (tt) {
                            tt.forEach(t => {
                                if (!tempOIds.includes(t.id + "_" + temp.id)) {
                                    upcommingStepArr.push({
                                        edge: t, node: temp
                                    })
                                    tempOIds.push(t.id + "_" + temp.id)
                                }
                            })
                        }
                    }
                }
                if (ed.target == open.node.id) {
                    let temp = Nodes?.find(n => n.id == ed.source)
                    if (temp) {
                        incommingNodes.push(temp);
                        let tt = Edges?.filter(ed => ed.target == open.node.id && ed.source == temp.id);
                        if (tt) {
                            tt.forEach(t => {
                                if (!tempIIds.includes(t.id + "_" + temp.id)) {
                                    incommingStepArr.push({
                                        edge: t, node: temp
                                    })
                                    tempIIds.push(t.id + "_" + temp.id)
                                }
                            })
                        }
                    }
                }
            })
            setOutgoingNodesToCurrent(upcommingNodes)
            setIncommingNodesToCurrent(incommingNodes)
            setIncommingSteps(incommingStepArr);
            setOutgoingSteps(upcommingStepArr)
            setApprovedIds([]);
        }
    }, [open])
    useEffect(e => {
        if (Nodes && Edges && open?.open && open?.node?.data?.label) {
            let arr = [];
            let idArr = [];
            Edges?.forEach(ed => {
                if (ed.source == open?.node.id) {
                    let temp = Nodes?.find(n => n.id == ed.target)
                    if (temp && !idArr.includes(temp.id)) {
                        arr.push(temp);
                        idArr.push(temp.id)
                    }
                }
                if (ed.target == open.node.id) {
                    let temp = Nodes?.find(n => n.id == ed.source)
                    if (temp && !idArr.includes(temp.id)) {
                        arr.push(temp);
                        idArr.push(temp.id)
                    }
                }
            })
            setConnectedNodes(arr);
        } else {
            setConnectedNodes([]);
        }
    }, [Nodes, Edges, open])
    const onChangeTargetInput = (value, option) => {
        let temp = Nodes.find(k => k.id == value);
        setTargetStatus(temp);
    }
    const getIcon = (condition) => {
        return <div style={{ opacity: condition ? 1 : 0 }} className='Icon-Circle'>
            {condition && <FontAwesomeIcon color='black' icon={faCheck} />}
        </div>
    }
    const getStepComponent = (ed, node) => {
        return <Tooltip title={"Click to confirm or reject"}
            onClick={e => {
                if (approvedIds.includes(ed.id)) {
                    setApprovedIds(approvedIds.filter(k => k != ed.id));
                } else {
                    setApprovedIds([...approvedIds, ed.id]);
                }
            }}
        ><Steps className='Custom-Step'>
                <Steps.Step data={node.id} icon={getIcon(true)} status='finish' title={node.data.label}></Steps.Step>
                <Steps.Step data={ed.id} icon={getIcon(true)} status='finish' title={ed.label}></Steps.Step>
                <Steps.Step
                    data={targetStatus.id}
                    status={!approvedIds.includes(ed.id) ? "wait" : 'finish'}
                    icon={getIcon(approvedIds.includes(ed.id))}
                    title={targetStatus.data.label}
                ></Steps.Step>
            </Steps></Tooltip>
    }
    const getEditStatusAndTransitionsComponent = () => {
        return <>
            <Row className='m-0 Table-Row' sm={12}>
                <Col sm={2}></Col>
                <Col className={"mb-3 Input-Field-Column"} sm={4}>
                    <span className="labeltext">
                        <Label>Current Status</Label>
                    </span>
                    <Select
                        className='Global-Text-Color Custom-Select Custome-Disabled'
                        showSearch
                        placeholder="Select type"
                        disabled={true}
                        value={open?.node.id}
                    >
                        <Select.Option
                            className='Status-Card mb-1 Global-Text-Color' value={open?.node.id}>
                            <FontAwesomeIcon className='mr-2' color={open?.node?.data?.color} icon={faCircle} />{open?.node?.data?.label}
                        </Select.Option>
                    </Select>

                </Col>
                <Col className={"mb-3 Input-Field-Column"} sm={4}>
                    <span className="labeltext">
                        <Label>Status to map with<span className='ml-1' style={{ color: 'red' }}>*</span></Label>
                    </span>

                    <Select
                        className={`Global-Text-Color Custom-Select ${errors.includes("TARGET-STATUS") && "Error-Border"}`}
                        showSearch
                        placeholder="Select type"
                        value={targetStatus?.id}
                        onSelect={(value, option) => {
                            let arr = errors.filter(k => k != "TARGET-STATUS");
                            setErrors(arr);
                            onChangeTargetInput(value, option)
                        }}
                    >
                        {Nodes?.map((n, i) => {
                            if (n.id != open?.node.id && !n.NonSelectable && n.type!="diverse") {
                                return <Select.Option
                                    className='Status-Card mb-1 Global-Text-Color' value={n.id}>
                                    <FontAwesomeIcon className='mr-2' color={n?.data?.color} icon={faCircle} />{n?.data?.label}
                                </Select.Option>
                            }
                        })
                        }

                    </Select>
                </Col>
                <Col className='Custome-Base-Column' sm={4}>
                </Col>
                <Col sm={2}></Col>
            </Row>
            {/* <Row className='m-0 Table-Row' sm={12}>
                <Col className={"mb-3 Input-Field-Column"} sm={4}>
                </Col>
                <Col className={"mb-3 Input-Field-Column"} sm={4}>
                {errors.includes("TARGET-STATUS") && <span style={{color:"red"}}>
                        Please fill required fields
                    </span>}
                </Col>
                <Col className='Custome-Base-Column' sm={4}>
                </Col>
            </Row> */}
            {/* { targetStatus && <><CustomeCollapse
                rootClassName="Custome-Class"
                defaultOpen={[]}
                items={[
                    {
                        key: "1",
                        label: <span style={{color:"red"}}>New incomming transitions</span>,
                        rightComponent: <>
                        </>,
                        children: <>
                            {incommingSteps?.map((n) =>getStepComponent(n.edge, n.node))}
                        </>,
                    },
                ]}
            />
            <CustomeCollapse
                defaultOpen={[]}
                items={[
                    {
                        key: "1",
                        label: <span style={{color:"red"}}>New outgoing transitions</span>,
                        rightComponent: <>
                        </>,
                        children: (
                            <>
                            {outgoingSteps?.map((n) =>getStepComponent(n.edge, n.node))}
                            </>
                        ),
                    },
                ]}
                rootClassName={''}
            /></>} */}
        </>
    }
    return (
        <div>
            <Modal open={open}
                maskClosable={false}
                onCancel={e => {
                    setDependancies(null)
                    setTargetStatus(null)
                    setOpen(false);
                }}
                className='Custome-Backdrop Node-Delete-Conformation-Popover'
                // title={
                //     <Badge.Ribbon placement='start' text={<div>{loading ? <Spin className='mr-2' size='small' /> : ""}{`${loading ? "Checking Status Dependancies" : (dependancies?.Task || dependancies?.SubTask) ? "Warning! Status is in use." : "Confirm Delete!"}`}</div>} color={`${loading ? "blue" : "red"}`} >
                //         <Row sm={12}>
                //             <Col sm={1}></Col>
                //         </Row>
                //     </Badge.Ribbon>
                // }
                title={
                    <Badge.Ribbon placement='start' text={<div>{loading ? <Spin className='mr-2' size='small' /> : ""}{`${loading ? "Checking Status Dependancies" : connectedNodes?.length > 0 ? "Warning!" : "Confirm Delete!"}`}</div>} color={`${loading ? "blue" : connectedNodes?.length > 0 ? "yellow" : "red"}`} >
                        <Row sm={12}>
                            <Col sm={1}></Col>
                        </Row>
                    </Badge.Ribbon>
                }
                onOk={e => {
                    if (connectedNodes?.length == 0) {
                        if ((statusdependancies?.length>0 || dependancies?.Task || dependancies?.SubTask) && connectedNodes?.length == 0 && dependancies && (dependancies?.Task || dependancies?.SubTask)) {
                            if (!targetStatus) {
                                setErrors([...errors, "TARGET-STATUS"]);
                                return
                            }
                        }
                        onOk(open.node, targetStatus, dependancies,statusdependancies);
                        setTargetStatus(null);
                    }
                }}
                okText={"Confirm"}
                cancelText="Cancel"
                okButtonProps={connectedNodes?.length > 0 && { style: { display: 'none' } }}
            >
                {loading ? <>
                    <div className='Msg-Block'>
                    <Spin className='mr-2' size='small' /> Loading 
                    </div>
                </> : <>
                        {/* {
                            connectedNodes?.length == 0 && statusdependancies?.length > 0 && <>
                                <Row style={{ backgroundColor: "#fbfbfb" }} className='m-0 Table-Row Heading' sm={12}>
                                    <div className='Msg-Block mb-1'>
                                        There are dependancies mapped with currant status.
                                    </div>
                                </Row>
                            </>
                        } */}
                    {connectedNodes?.length == 0 && dependancies && (statusdependancies?.length>0 || dependancies?.Task || dependancies?.SubTask) && <>
                        {getEditStatusAndTransitionsComponent()}
                    </>}
                   
                    {connectedNodes?.length == 0 && dependancies && (dependancies?.Task || dependancies?.SubTask) && <>
                        <>
                            {/* <Row style={{ backgroundColor: "#fbfbfb" }} className='m-0 Table-Row Heading' sm={12}>
                                <span>{`${dependancies?.Task?.length>0 && `${dependancies?.Task?.length} tasks `} {} subtasks are in the 'In Progress' stage.`}</span>
                            </Row> */}
                            <Row style={{ backgroundColor: "#fbfbfb" }} className='m-0 Table-Row Heading' sm={12}>
                                <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={2}>
                                    Task ID
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={10}>
                                    Task Name
                                </Col>
                            </Row>
                            {dependancies["Task"] && <> {dependancies["Task"]?.map((task, i) => {
                                return <Row style={{ backgroundColor: i % 2 == 0 ? "white" : "#fbfbfb" }} className='m-0 Table-Row' sm={12}>
                                    <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={2}>
                                        {task.TaskId}
                                    </Col>
                                    <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={10}>
                                        {task.TaskName}
                                    </Col>
                                </Row>
                            })}</>
                            }
                            {dependancies["SubTask"] && <> {dependancies["SubTask"]?.map((task, i) => {
                                return <Row style={{ backgroundColor: i % 2 == 0 ? "white" : "#fbfbfb" }} className='m-0 Table-Row' sm={12}>
                                    <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={2}>
                                        {task.TaskId}
                                    </Col>
                                    <Col style={{ display: "flex", alignItems: "center", color: '#5b6c97' }} sm={10}>
                                        {task.TaskName}
                                    </Col>
                                </Row>
                            })}</>
                            }
                        </>

                    </>}
                    {
                        connectedNodes?.length > 0 ? <div className='Msg-Block'>
                            Please remove the associated transitions before deleting.
                        </div> : (statusdependancies?.length==0 && !dependancies?.Task && !dependancies?.SubTask) && <div className='Msg-Block'>
                            Are you sure you want to delete this status?
                        </div>
                    }
                </>}

            </Modal>
            {/* </Badge.Ribbon> */}
        </div>
    )
}
