import React, { useEffect, useState } from 'react'
import { Col,Row } from 'reactstrap'
import './common.css';
import ActivityStreamTab from '../ViewPipelinesTabs/ActivityStreamTab';

export const ActivitySection = ({task,taskId,module, refreshData,allTasksAndSubtasks, viewTaskFunction}) => {
	// const [id,setId]=useState();
	// useEffect(e=>{
	// 	setId(taskId);
	// },[taskId]);

	return (
		<Col sm={12}>
			<Row style={{height:"90%"}}>
				<ActivityStreamTab task={task} pipelineId={taskId} module={module} refreshData={refreshData} allTasksAndSubtasks={allTasksAndSubtasks} viewTaskFunction={viewTaskFunction}/>
			</Row>
		</Col>
	)
}
