// CustomNode.js
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Handle } from 'reactflow';
import LeftBottom from '../../../assets/images/svg/Left-Bottom.svg';
import BottomRight from '../../../assets/images/svg/Bottom-Right.svg';
import BottomLeft from '../../../assets/images/svg/Bottom-Left.svg';
import TopLeft from '../../../assets/images/svg/Top-Left.svg';
import RightTop from '../../../assets/images/svg/Right-Top.svg';
import RightBottom from '../../../assets/images/svg/Right-Bottom.svg';
import LeftRight from '../../../assets/images/svg/Left-Right.svg';
import LeftTop from '../../../assets/images/svg/Left-Top.svg';
import TopBottom from '../../../assets/images/svg/Top-Bottom.svg';
import RightLeft from '../../../assets/images/svg/Right-Left.svg';
import BottomTop from '../../../assets/images/svg/Bottom-Top.svg';
import TopRight from '../../../assets/images/svg/Top-Right.svg';
const icons = {
    TopBottom: < img id="TopBottom" className="Direction-icons" src={TopBottom} alt="" />,
    RightLeft: <img id="RightLeft" className="Direction-icons" src={RightLeft} alt="" />,
    BottomTop: <img id="BottomTop" className="Direction-icons" src={BottomTop} alt="" />,
    LeftRight: <img id="LeftRight" className="Direction-icons" src={LeftRight} alt="" />,
    TopLeft: <img id="TopLeft" className="Direction-icons" src={TopLeft} alt="" />,
    TopRight: <img id="TopRight" className="Direction-icons" src={TopRight} alt="" />,
    RightTop: <img id="RightTop" className="Direction-icons" src={RightTop} alt="" />,
    RightBottom: <img id="RightBottom" className="Direction-icons" src={RightBottom} alt="" />,
    BottomRight: <img id="BottomRight" className="Direction-icons" src={BottomRight} alt="" />,
    BottomLeft: <img id="BottomLeft" className="Direction-icons" src={BottomLeft} alt="" />,
    LeftTop: <img id="LeftTop" className="Direction-icons" src={LeftTop} alt="" />,
    LeftBottom: <img id="LeftBottom" className="Direction-icons" src={LeftBottom} alt="" />,
}
const Directions = {
    "BottomTop": <>
        <Handle type="target" position="bottom" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="top" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "BottomRight": <>
        <Handle type="target" position="bottom" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="right" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "BottomLeft": <>
        <Handle type="target" position="bottom" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="left" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "TopBottom": <>
        <Handle type="target" position="top" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="bottom" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "TopRight": <>
        <Handle type="target" position="top" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="right" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "TopLeft": <>
        <Handle type="target" position="top" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="left" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "LeftBottom": <>
        <Handle type="target" position="left" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="bottom" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "LeftTop": <>
        <Handle type="target" position="left" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="top" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "RightLeft": <>
        <Handle type="source" position="left" id="source" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="target" position="right" id="target" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "RightBottom": <>
        <Handle type="target" position="right" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="bottom" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "RightTop": <>
        <Handle type="source" position="top" id="source" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="target" position="right" id="target" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "LeftRight": <>
        <Handle type="target" position="left" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="right" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
    "LeftTop": <>
        <Handle type="target" position="left" id="target" style={{ background: 'rgb(177 177 177)' }} />
        <Handle type="source" position="top" id="source" style={{ background: 'rgb(177 177 177)' }} />
    </>,
}
const DiverseNode = ({ data, showProgress }) => {
    const [direction, setDirection] = useState(data.Direction)
    return (
        <div onClick={e => {}}>
            <div style={{ fontWeight: 'bold' }}>
                {data.Direction && icons[data.Direction]}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between',height:"100%" }}>
                {data.Direction && Directions[data.Direction]}
            </div>
        </div>
    );
};

export default DiverseNode;
