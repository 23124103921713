import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import "./RenderAllTasks.css";
import '../../tabs/Task/CreateTaskModal.css'
import { Badge, Checkbox, Popover, Select, Tooltip } from "antd";
import {
  SelectedUserAvatar,
  UserPicker,
} from "../../common/UserPicker/UserPicker";
import { Option } from "antd/es/mentions";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  FlagFilled,
} from "@ant-design/icons";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCheckCircle,
  faCircle,
  faClock,
  faGripVertical,
  faMinusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { convertToUSDate, getPriorityValue, invertColor } from "../HR/TimeClock/Util";
import AwsServerService from "../../common/util/AwsServerService";
import minusCircle from "../../assets/images/svg/minus-circle.svg";
import AppContext from "../../Context/AppContext";
import Vector from "../../assets/images/vector.png";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import { useHistory } from "react-router-dom";
import TimeTrackerForList from "./TimeTrackerForList";
import {
  Modal as AntModal,
  Button as AntButton,
  Select as AntSelect,
  Button,
} from "antd";
import { number } from "mathjs";
import _ from 'lodash';
import { TaskFlowStatus } from "../../tabs/Task/TaskStatus/TaskFlowStatus";
import transitions from "@material-ui/core/styles/transitions";
import { evaluateEdgeVisibility } from "../../Util/CommonUtil";
import DependencyModal from "../../tabs/Task/ViewPipelinesTabs/DependencyModal";
import swal from "sweetalert2";
import { Socket } from "../../Context/AppActions";


export const TaskGroupCard = ({
  header,
  data,
  loader,
  setLoader,
  allPipelineData,
  groupFilter,
  viewTaskFunction,
  setTaskHeadersData,
  taskHeadersData,
  setIsSubTaskView,
  taskFilterSetting,
  setPageRefreshData,
  allDependeniesData,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  allTimeRecords,
  selectedIds,
  setSelectedIds,
  setDummyID,
  key1,
  dummyId,
  setDependencyRefresh,
  setHoveredRow
}) => {
  const [rowData, setRowData] = useState(data);
  const AppDataContext=useContext(AppDataRequired);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // State for sorting
  const { AllUsers } = useContext(AppDataRequired);
  const AppData = useContext(AppContext);
  const { dispatch, state } = AppData || {};
  const [viewTaskPayload,setViewTaskPayload] = useState();
  const [dropdowndata, setdropdownTasksData] = useState(
    new Map(dropdownTasksDataCache.map((item) => [item.value, item]))
  );
  const [collapsedStates, setCollapsedStates] = useState({
    [header]: true, 
  });
  const [updated,setUpdated]=useState(false);
  const anotherSessionActiveFlag = useRef({Active:false,Record:{}});
  let user = JSON.parse(localStorage.getItem("userinfo"));
  
  let history = useHistory(); 

  useEffect((e) => {
      setRowData(data);
    },
    [data]
  );
  useEffect(e=>{
    if(viewTaskPayload && viewTaskPayload.task && viewTaskPayload.module){
      viewTaskFunction(viewTaskPayload.task,viewTaskPayload.module);
    }
  },[viewTaskPayload])
  const toggleExpandIconOfSubtask = (e) => {
    e.stopPropagation();
    let arr = e.currentTarget.childNodes;
    let taskId = e.currentTarget.id;
    let arrofsubtasks = document.querySelectorAll(`.${taskId}`);
    arr.forEach((element) => {
      if (element.classList.contains("SHOW")) {
        element.classList.remove("SHOW");
        element.classList.add("HIDE");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.add("HIDEROW");
        });
      } else if (element.classList.contains("HIDE")) {
        element.classList.remove("HIDE");
        element.classList.add("SHOW");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.remove("HIDEROW");
        });
      }
    });
    console.log(arr);
  };

  const getRelatedTimeRecords = (data,type) =>{ 
    let records=allTimeRecords.filter(k => k.TaskROWID == data.TaskROWID && k.TaskType==type);
    return records;
  }

  const navigateToDeal = (deal) => {
    deal = { ...deal }
    deal.Created_By = deal[0].Created_By.name;
    deal.Owner = deal[0].Owner.name;
    deal.Account_Name = deal[0].Account_Name ? deal[0].Account_Name.name : "";
    history.push({
      pathname: '/d/Deals/DetailView/' + deal[0].id,
      state: { data: deal[0], action: 'DetailView', module: 'Deals' }
    });
  }
  useEffect(() => {
    const allTasks = document.querySelectorAll(".Expand-Icon-Section");
    
    allTasks.forEach(task => {
      let arr = task.childNodes;
      let taskId = task.id;
  
      arr.forEach((element) => {
        if (taskFilterSetting.ExpandTasks) {
          if(element.classList.contains("anticon-caret-right")){
            element.classList.add("HIDE");
            element.classList.remove("SHOW");
          }else{
            element.classList.remove("HIDE");
            element.classList.add("SHOW");
          }
        } else {
          if(element.classList.contains("anticon-caret-right")){
            element.classList.add("SHOW");
            element.classList.remove("HIDE");
          }else{
            element.classList.add("HIDE");
            element.classList.remove("SHOW");
          }
        }
      });
    });
  }, [taskFilterSetting.ExpandTasks]);
  const showTaskLocationNames = (data) => {
    if(!data.hasOwnProperty("SubTaskROWID")){
      let allDeals = [...state?.AllDeals];
      let filteredDeal = allPipelineData?.filter(item => item?.PipelineROWID==data.PipelineROWID)[0];
      let accountName=allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName ? e?.Account_Name:'');
      let filterDealData = allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName);
      
      return (
        <>
          <span
            className="cont-box2"
          >
            <Tooltip color="rgb(54 54 54)" placement="right" title={accountName[0].Account_Name.name}> 
              <span
                onClick={() => {
                history.push({
                  pathname: '/c/Accounts/DetailView/' + accountName[0].Account_Name.id,
                  state: { data: accountName[0].Account_Name, action: 'Update', module: 'Accounts' }
                });
              }}
              // style={clientColorObj}
              className="cont-box3 Elipsis-Class"
            >{accountName[0].Account_Name.name}
  
              </span>
            </Tooltip>
            <span className="custom-slash">{"/"}</span>
            <Tooltip color="rgb(54 54 54)" placement="right" title={filteredDeal?.DealName}> 
              <span  onClick={() => { navigateToDeal(filterDealData)}} 
                className="cont-box4 cursor-pointer Elipsis-Class">
                {filteredDeal?.DealName}
              </span>
            </Tooltip>
          </span>
        </>
      )
    }else{
      return "-"
    }
      
    }

  const showSubtaskParentNames = (tasks) => {

    let filteredTasks = allPipelineData?.flatMap(item =>
      item?.Tasks?.filter(task => task?.TaskROWID === tasks?.TaskRowId) || []
    );
      
     if (filteredTasks && filteredTasks?.length != 0) {
      return (
        <span style={{
          background: 'rgb(8, 174, 234)',
          color: '#fff'
        }}>{filteredTasks ? filteredTasks[0].TaskName : ""}</span>
      )
    }
  }

  const getDescriptionPopover = (data) => {
    const content = (
      <p style={{ overflowWrap: "break-word", fontWeight:"500" }}>
        {data?.TaskDescription}
      </p>
    );
    return (
      <Popover content={content} title="" trigger="hover" rootClassName="Description-Popover">
        <FontAwesomeIcon icon={faAlignLeft} style={data?.TaskDescription != "null" && data?.TaskDescription ? {display: ""} : {display: "none"}}/>
      </Popover>
    );
  };
  const sortData = (field) => {
    let direction = "ascending";
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = "none";
      }
    }

    if (direction === "none") {
      setRowData(data); // Reset to original data
      setSortConfig({ key: "", direction: "" });
    } else {
      const sortedData = [...rowData].sort((a, b) => {
        if (a[field] < b[field]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setRowData(sortedData);
      setSortConfig({ key: field, direction });
    }
  };

  // Render sorting icons based on the current sort state
  const renderSortIcon = (field) => {
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        return <CaretUpOutlined className="sort-icon"/>;
      } else if (sortConfig.direction === "descending") {
        return <CaretDownOutlined className="sort-icon"/>;
      }
    }
    return null; // No icon when not sorted
  };
  const updateTaskData = (data, field, val, type) => {
    
    const data2 = {
      ROWID: type !== "subtask" ? data.TaskROWID : data.SubTaskROWID,
      [field]: val || "",
      MODIFIEDBY: user.email_id,
    };
    
    const updateService = type !== "subtask" 
      ? AwsServerService.updateTask 
      : AwsServerService.updateSubtask;
  
    updateService(data2)
      .then((data) => {
        setPageRefreshData(prev => !prev);
      })
      .catch(er => console.log("Er", er));
  };

  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]}) 
  const [dealSelected,setSelectedDeal]=useState('') 
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0, Blocking:0}) 
  const [isDependencyModalOpen,setIsDependencyModalOpen]=useState(false) 
  const [filteredDropdownTasksData,setFilteredDropdownTasksData]=useState([]) 
  const viewTaskData=useRef({});
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  });
  useEffect(()=>{  
      let data={...selectedDependenciesCache} 
      setSelectedDependencies(data);  
  },[selectedDependenciesCache]) 
  useEffect(()=>{ 
    if(dealSelected){
      let pipelines=allPipelineData.filter(e=>e.DealName==dealSelected);  
      const availableTaskIds = new Set(); 
      pipelines.forEach(pipeline => {
          pipeline.Tasks.forEach(pipelineTask => { 
              availableTaskIds.add(`Tasks_${pipelineTask.TaskROWID}`); 
              if (pipelineTask.SubTasks) {
                  pipelineTask.SubTasks.forEach(subtask => {
                      availableTaskIds.add(`SubTasks_${subtask.SubTaskROWID}`);
                  });
              }
          });
      }); 
     let filteredTasks= dropdownTasksDataCache.filter(task => availableTaskIds.has(task.value));
     setFilteredDropdownTasksData(filteredTasks);
    }
    },[dealSelected])
    function getOptionsForDropdown(data,selected,viewTaskData){
      let options=[];
      let selectedData={...selected}
      let type=viewTaskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let id=viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID;
      const valuesToExclude = new Set([
        ...selectedData.Blocking.map(item => item.value),
        ...selectedData.WaitingOn.map(item => item.value),
        `${type}_${id}`
      ]) 
      data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
      return options;
    }
    const handleCreateActivity =async (sourceTaskType, message, ROWID, template)=>{
      let type=sourceTaskType=='Tasks'?'TASK':'SUBTASK';
      if(template){
        type=sourceTaskType=='Tasks'?'TASKTEMPLATE':'SUBTASKTEMPLATE';
      }
      let payload={
        SourceId:ROWID,
        ActivityType:type,
        Message:message,
        ActivityOwner:user.email_id
      }  
     await AwsServerService.createActivity(payload).then(res=>{
      //  dispatch(Socket({trigger:"refresh_activity",data:''}))
     }).catch(errMessage=>{
      console.log('Error saving Activity',errMessage);
     })
  }
    function openTaskFromDependencies(data){ 
      const [type,id]=data?.value?.split('_');  
      if(type=='Tasks'){
        setLoader(true);
        AwsServerService.getTaskById(number(id)).then(res=>{
          setLoader(false);
          let k=res[0]?.taskInfo;
          if(k){ 
            // viewTaskFunction(k,"TASK"); 
            setViewTaskPayload({task:k,module:"TASK"});
          }
        }).catch(e=>{
          setLoader(false);
        })
      }else{
        setLoader(true)
        AwsServerService.getSubTaskBySubTaskId(number(id)).then(res=>{
          setLoader(false);
          let k=res[0]?.taskInfo;
          if(k){ 
            // viewTaskFunction(k,"SUBTASK"); 
            setViewTaskPayload({task:k,module:"SUBTASK"}); 
          }
        }).catch(e=>{
          setLoader(false);
        })  
      }
      setIsDependencyModalOpen(false);
    }
    const handleSaveDependencies=async (data,deptype)=>{  
      try { 
        let sourceTaskType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
        let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
        const [type,id]=data.value.split('_');
        const payload={
          TargetTaskId:id,
          STaskType:sourceTaskType,
          SourceTaskId:sourceId,
          TaskType:type,
          DependancyType:deptype,
          CREATEDBY:user.email_id,
          TargetDependencyStatus: data.TargetDependencyStatus,
          SourceDependencyStatus: data.SourceDependencyStatus,
        } 
        return AwsServerService.createDependency(payload).then(async (e)=>{ 
          let selections = [...selectedDependencies[deptype]];
          selections.push({...data,ROWID:e.insertId,sourceValue:`${sourceTaskType}_${sourceId}`});
          setSelectedDependenciesCache({
            ...selectedDependencies,
            [deptype]: selections,
          })
          let dependencytype=deptype=='Blocking'?'Blocking':'Waiting';
          let message=`USER added ${dependencytype} dependency of " ${data.label} "`
          handleCreateActivity(sourceTaskType,message,sourceId);
          let typeOfDep=dependencytype=='Blocking'?'WaitingOn':'Blocking';
          const reversePayload={
            TargetTaskId:sourceId,
            STaskType:type,
            SourceTaskId:id,
            TaskType:sourceTaskType,
            DependancyType:typeOfDep,
            CREATEDBY:user.email_id,
            TargetDependencyStatus: data.SourceDependencyStatus,
            SourceDependencyStatus: data.TargetDependencyStatus,
          }
           
          await AwsServerService.createDependency(reversePayload).then(res=>{
            let typ=typeOfDep=='Blocking'?'Blocking':'Waiting';
          let message2=`USER added ${typ} dependency of " ${viewTaskData.current.TaskName} "`
          handleCreateActivity(type,message2,id); 
          }).catch((err)=>swal('Failed','Error saving reverse dependency','Alert'))
        }).catch(err=> swal('Failed','Error saving dependency','Alert')); 
    } catch (error) {
        // swal('Failed','Error saving dependencies','Alert');
      }
    }
    function handleDeleteDependency(e,isWaiting,taskName){
      let [type,id]=e.value.split('_'); 
      let sourceType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
      AwsServerService.deleteDependency(sourceId,sourceType,id,type,e.SourceDependencyStatusId,e.TargetDependencyStatusId).then(r=>{
        if(isWaiting=='waiting'){
          let selections=[...selectedDependenciesCache.WaitingOn];
          let data=selections?.filter(t=>t.ROWID!==e.ROWID);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'WaitingOn':0});
          }
            setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
        }else{
          let selections=[...selectedDependenciesCache.Blocking];
          let data=selections?.filter(t=>t.ROWID!==e.ROWID);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'Blocking':0});;
          } 
            setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
        }
        let dependencytype=isWaiting?'Waiting':'Blocking';
        let message=`USER removed ${dependencytype} dependency of " ${taskName} "`
        handleCreateActivity(sourceType,message,sourceId);
        }) 
    }
  const DependecyModal = () => {
    return ( 
        <AntModal
          open={isDependencyModalOpen} 
          wrapClassName={"Add-dependecny-Wrappersss"}
          rootClassName="Add-dependecny-Root-Wrappersss"
          draggable
          title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?`Edit Dependency of '${viewTaskData.current.TaskName}'`:`Add Dependency of '${viewTaskData.current.TaskName}'`}
          maskClosable={false}
          width={300}
          className=""
          style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
          onCancel={() => {
            setIsDependencyModalOpen(false); 
            viewTaskData.current={};
            setSelectedDependenciesCache({WaitingOn:[], Blocking:[]});
            // let data={...selectedDependenciesCache};
            // setSelectedDependencies(data); 
            setDependencyRefresh(prev=>!prev);
            setDropdownCount({WaitingOn:0,Blocking:0})
          }}
          footer={[<>
          <Button type="primary" onClick={()=>{
            handleSaveDependencies(selectedDependencies) 
          }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
          </>]}
        > 
          <div className="stickyDivWrapper"> 
                <div className="sticky-container">
          <DependencyModal userOptions={AllUsers} handleSaveDependencies={handleSaveDependencies}
      dropdownTasksDataCache={dropdownTasksDataCache} setDropdownCount={setDropdownCount} openTaskFromDependencies={openTaskFromDependencies}
      getOptionsForDropdown={()=>{return filteredDropdownTasksData}} selectedDependenciesCache={selectedDependenciesCache} handleDeleteDependency={handleDeleteDependency}
      allTasksAndSubtasks={allTasksAndSubtasks} selectedDependencies={selectedDependencies} dropdowncount={dropdowncount}
      getAllDependenciesForTask={getAllDependenciesForTask} handlecreateactivity={handleCreateActivity}
      filteredDropdownTasksData={filteredDropdownTasksData} openTaskData={viewTaskData.current} dealSelected={dealSelected}
      />
      </div></div>
        </AntModal>
    );
  };
  // const handleTransitionChange = (e, data) => {
  //   let key = statusOptions?.find(key => key.label == e)
  //   let edge = data?.Edges?.filter(res => res.id == key.key)[0];
  //   data = {
  //     ...data, Edges: processEdgeConnections(edge,data.Edges)
  //   }
  //   let nextNode = data?.Nodes?.filter(res => res.id == edge.target)[0];
  //   let status = nextNode?.data?.label;
  //   setTaskData({ ...taskData, TaskFlowObject: data, 'TaskStatus': status });
  //   getBlueprintDataOptions(data, status);
  // }

  const getBlueprintDataOptions = (data,status,taskData)=>{
    if(data){
    let currentStatus=status; 
    let node=  data?.Nodes?.filter(e=>e.id==currentStatus)[0];
    let Edges=data?.Edges?.filter(e=>!e.visited && e.source==node?.id); 
    let options = [];
    let optionsStatus = [];
      Edges?.map((item) => {
        if (evaluateEdgeVisibility({ Project: state?.AllDeals?.find(d=>d.Deal_Name==dealSelected), Task: {...taskData} }, item, data.Edges)) {
          if (item.label) {
            let temp = {
              value: item?.label,
              label: item?.label,
              key: item?.id,
            }
            if (item?.data?.color) {
              temp["color"] = item?.data?.color
            }
            options.push(temp);
          }
        }
      });
      data?.Nodes?.map((item) => {
      if(item.data?.label){
        let temp={
          value: item?.data?.label,
          label: item?.data?.label,
          key: item?.id,
        }
        if(item.data?.color){
          temp["color"]=item.data.color == "grey" ? "#808080" : item.data.color
        }
        optionsStatus.push(temp);
      }
      }) 
      // setMainStatusOptions(optionsStatus);
      // setStatusOptions(options)
      return {mainStatus:optionsStatus,options:options}
    }
    return {mainStatus:[],options:[]} 
  }
  const taskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              width:'100%',
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              setLoader(true)
                
              AwsServerService.getTaskById(data.TaskId).then(res => {
                let k = res[0]?.taskInfo;
                setLoader(false);
                if (k) {
                  // viewTaskFunction(k, "SUBTASK");
                  setViewTaskPayload({task:k,module:"SUBTASK"});
                }
              }).catch(e => {
                setLoader(false);
              })  
              // viewTaskFunction(data, "TASK");
            }}
          >
            <Col
              id={`TaskId-${data.TaskId}`}
              className="Expand-Icon-Section"
              onClick={(e) => toggleExpandIconOfSubtask(e)}
              sm={1}
            >
              {data?.SubTasks?.length > 0 && (
                <>
                  <CaretRightOutlined className="LEFT SHOW" />
                  <CaretDownOutlined className="RIGHT HIDE" />
                </>
              )}
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
               <Tooltip color="rgb(54 54 54)" placement="right" title={data[field]}>
                <span
                  className="Elipsis-Class"
                  style={{ width: "150px" }}
                >
                  {data[field]}
                </span>
              </Tooltip>
              <Tooltip color="rgb(54 54 54)" placement="right" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 

                <div
                  className="dependencyicon"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    viewTaskData.current=data;
                    let pipeline=allPipelineData.filter(pid=>pid.PipelineROWID==data?.PipelineROWID)[0];
                    setSelectedDeal(pipeline?.DealName);
                    setIsDependencyModalOpen(true)
                    setSelectedDependenciesCache(data.deps);
                    }}
                    style={data?.deps.Blocking.length > 0 ||
                      data?.deps.WaitingOn.length > 0 ? {width:"2em"} : {width: "0px"}}
                >
                  {data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                    <img
                      className="minus-circle-v"
                      src={minusCircle}
                    />
                  ) : data?.deps.Blocking.length > 0 &&
                    data?.deps.WaitingOn.length == 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      color={"#f40600"}
                    />
                  ) : data?.deps.Blocking.length == 0 &&
                    data?.deps.WaitingOn.length > 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      color={"#FFA500"}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Tooltip>
              {getDescriptionPopover(data)}
              {data?.SubTasks?.length > 0 ? (
                <div 
                onClick={(e)=>{
                  e.stopPropagation();
                  let classes = e.currentTarget.id;
                  if(classes){
                    document.getElementById(classes).click();
                  }                    
                }} 
                style={{width: "1rem"}} 
                id={`TaskId-${data.TaskId}`}
                >
                  <Badge
                  color="#7a7a7a"
                  count={data?.SubTasks?.length}
                  className="subtask-badge"
                  size="small"
                  offset={[3, 0]}
                >
                  <img src={Vector} alt="subtask-icon" className="subtask-icon"/>
                </Badge>
                </div>) : (
                <></>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={assignee ? assignee : ""}
              mode="multiple"
              onChange={(e) => {
                updateTaskData(data,"Assignee",e)
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Assignee","")}
            />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={Supervisor ? Supervisor : ""}
              mode=""
              onChange={(e) => {
                updateTaskData(data,"Supervisor",e)
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Supervisor","")}
            />
       );
      },
    },
    {
      label: "Time Tracking",
      field: "TimeTracking",
      render: (data, field) => {
        let records = getRelatedTimeRecords(data,"Tasks");       
        return ( 
          <div className="TaskGroupCard-Time-Track" style={{position:"relative"}}>
            <TimeTrackerForList 
              updated={updated}
              setUpdated={setUpdated}
              anotherSessionActiveFlag={anotherSessionActiveFlag}
              records={records} 
              viewTaskData={data}  
              allTasksAndSubtasks={allTasksAndSubtasks}
              viewTaskFunction={viewTaskFunction}
              handleHistoryAdd={()=>{}}
              />  
          </div>
        )
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <Tooltip color="rgb(54 54 54)" placement="top" title={description}>
            <span
              className="Elipsis-Class"
              style={{ width: "400px" }}
            >
              {description != "null" && description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (<>
        <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
          <Select
            className="No-Border priority-icon"
            name="Priority"
            placeholder="Select an option"
            style={{ width: 200 }}
            value={data[field] ? data[field] : "Not Set"}
            onChange={(val) => {
              updateTaskData(data,"Priority",val)
            }}
            rootClassName="table-dropdown"
            dropdownRender={(menu) => <>{menu}</>}
            optionLabelProp="icon"
            onClear={()=> updateTaskData(data,"Priority","")}
          >
            <Option
              value="Not Set"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
              Set
            </Option>
            <Option
              value="Low"
              icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
            </Option>
            <Option
              value="Medium"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
              Medium
            </Option>
            <Option
              value="High"
              icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("High") }} /> High
            </Option>
          </Select>
        </Tooltip>
          </>);
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          <CustomTimeSelect 
            rootClassName={"Task-Table-TimePicker"}
            className={(data[field] != "null" && data[field]) ? "Task-Table-TimePicker-Class" : "Task-Table-TimePicker-Class1"}
            placement={"top"}
            taskData={data}
            setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field])}}
          />
        );
      },
    },
    {
      label: "Status",
      field: "TaskStatus",
      render: (data, field) => {
        let blueprint = AppDataContext?.TaskFlows[data.TaskFlowId];
        const temp= getBlueprintDataOptions(blueprint,data[field],data)
        return <>
          <TaskFlowStatus
            type={"Both"}
            statusOptions={temp.mainStatus}
            onChangeStatus={(data)=>{

            }}
            taskData={data}
            transitions={temp.options}
          />
        </>;
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == data.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    }
  ];
  const subTaskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              setLoader(true)
              AwsServerService.getSubTaskBySubTaskId(data.SubTaskId).then(res=>{
                let k=res[0]?.taskInfo;
                setLoader(false);
                if(k){ 
                  // viewTaskFunction(k,"SUBTASK");  
                  setViewTaskPayload({task:k,module:"SUBTASK"});
                }
              }).catch(e=>{
                setLoader(false);
              })  
              // viewTaskFunction(data, "SUBTASK");
              // setIsSubTaskView(true);
            }}
          >
            <Col sm={1}></Col>
            <Col sm={1}></Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
            <Tooltip color="rgb(54 54 54)" placement="right" title={data[field]}>
             <span className="Elipsis-Class" style={{ width: "130px" }}>{data[field]}</span>
            </Tooltip>
            <Tooltip color="rgb(54 54 54)" placement="right" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 
              <div
                className="dependencyicon"
                onClick={(e) => {
                  e.stopPropagation();
                  viewTaskData.current=data;
                  let data2=allTasksAndSubtasks['Tasks']?.get(data?.TaskRowId); 
                  let pipeline=allPipelineData.filter(pid=>pid.PipelineROWID==data2?.PipelineROWID)[0];
                  setSelectedDeal(pipeline?.DealName);
                  setIsDependencyModalOpen(true);
                  setSelectedDependenciesCache(data.deps);
                }}
                style={data?.deps.Blocking.length > 0 ||
                  data?.deps.WaitingOn.length > 0 ? {width:"2em"} : {width: "0px"}}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {}}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={assignee ? assignee : ""}
              mode="multiple"
              onChange={(e) => {
                updateTaskData(data,"Assignee",e,"subtask")
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Assignee","","subtask")}
            />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={Supervisor ? Supervisor : ""}
              mode=""
              onChange={(e) => {
                updateTaskData(data,"Supervisor",e,"subtask")
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Supervisor","","subtask")}
            />
        );
      },
    },
    {
      label: "Time Tracking",
      field: "TimeTracking",
      render: (data, field) => {
        let records = getRelatedTimeRecords(data,"SubTasks");
        return ( 
            <TimeTrackerForList 
              updated={updated}
              setUpdated={setUpdated}
              anotherSessionActiveFlag={anotherSessionActiveFlag}
              records={records} 
              viewTaskData={data}  
              allTasksAndSubtasks={allTasksAndSubtasks}
              viewTaskFunction={viewTaskFunction}
              handleHistoryAdd={()=>{}}
              />  
          )
        // let description = data[field];
        // return (
        //   <span
        //     title={description}
        //     className="Elipsis-Class"
        //     style={{ width: "400px" }}
        //   >
        //     {description}
        //   </span>
        // );
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <Tooltip color="rgb(54 54 54)" placement="top" title={description}>
            <span
              title={description}
              className="Elipsis-Class"
              style={{ width: "400px" }}
            >
              {description != "null" && description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (
        <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
            <Select
              className="No-Border priority-icon"
              name="Priority"
              placeholder="Select an option"
              style={{ width: 200 }}
              value={data[field] ? data[field] : "Not Set"}
              onChange={(val) => {
                updateTaskData(data,"Priority",val,"subtask")
              }}
              // suffixIcon={<CaretDownFilled />}
              dropdownRender={(menu) => <>{menu}</>}
              optionLabelProp="icon"
              rootClassName={"table-dropdown"}
              onClear={()=> updateTaskData(data,"Priority","","subtask")}
            >
              <Option
                value="Not Set"
                icon={
                  <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
                }
              >
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
                Set
              </Option>
              <Option
                value="Low"
                icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
              >
                <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
              </Option>
              <Option
                value="Medium"
                icon={
                  <FlagFilled style={{ color: getPriorityValue("Medium") }} />
                }
              >
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
                Medium
              </Option>
              <Option
                value="High"
                icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
              >
                <FlagFilled style={{ color: getPriorityValue("High") }} /> High
              </Option>
            </Select>
          </Tooltip>
        );
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          <CustomTimeSelect 
            rootClassName={"Task-Table-TimePicker"}
            className={"Task-Table-TimePicker-Class"}
            placement={"top"}
            taskData={data}
            setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field],"subtask")}}
          />
        );
      },
    },
    {
      label: "Status",
      field: "Status",
      render: (data, field) => {
        return data[field];
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field, task) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == task.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    }
  ];

  const [taskHeaders, setTaskHeaders] = useState(taskHeadr);
  const [subTaskHeaders, setSubTaskHeaders] = useState(subTaskHeadr);


  useEffect(() => {
    const newTaskHeaders = [...taskHeaders]; // Create a copy of the existing array
    const newSubTaskHeaders = [...subTaskHeaders]; // Create a copy of the existing array
    const newHeader = {
      label: "Task Location",
      field: "TaskLocation",
      render: (data, field) => {
        return (
          showTaskLocationNames(data)
        );
      },
    };
  
    if (taskFilterSetting.TaskLocation) {
      // Check if the object is already added; if not, add it at index 1
      const isHeaderPresent = newTaskHeaders.some(header => header.field === newHeader.field);
      const isSubHeaderPresent = newSubTaskHeaders.some(header => header.field === newHeader.field);
      if (!isHeaderPresent) {
        newTaskHeaders.splice(1, 0, newHeader);
      }
      if (!isSubHeaderPresent) {
        newSubTaskHeaders.splice(1, 0, newHeader);
      }
    } else {
      // Remove the object if the condition is false
      const indexToRemove = newTaskHeaders.findIndex(header => header.field === newHeader.field);
      const indexToRemove1 = newSubTaskHeaders.findIndex(header => header.field === newHeader.field);
      if (indexToRemove !== -1) {
        newTaskHeaders.splice(indexToRemove, 1);
      }
      if (indexToRemove1 !== -1) {
        newSubTaskHeaders.splice(indexToRemove1, 1);
      }
    }
  
    setTaskHeaders(newTaskHeaders);
    setSubTaskHeaders(newSubTaskHeaders);
  }, [taskFilterSetting.TaskLocation]);
  
  useEffect(() => {
    if (taskHeadersData.length > 0) {
      setTaskHeaders(taskHeadersData);
      let d = taskHeadersData.filter((e, index) => index != 0);
      d.splice(0, 0, subTaskHeadr[0]);
      setSubTaskHeaders(d);
    }
  }, [taskHeadersData]);
  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if(dropIndex==0 || draggedIndex==0){
      return
    }
    const newHeaders = [...taskHeaders];
    const [movedHeader] = newHeaders.splice(draggedIndex, 1);
    newHeaders.splice(dropIndex, 0, movedHeader);
    setTaskHeadersData(newHeaders);
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());  // Returns true if it's a valid date
  };

  const getTabCard = () => {
    if (groupFilter.group2.name == "Pipeline") {
      let pipeline = allPipelineData.find((e) => e.PipelineROWID == header);
      return (<div className="Group-Titel-Card">
        
          <span style={{ fontSize: "11px" }}>
            {pipeline?.PipelineName ? pipeline.PipelineName : "N/A"}
          </span>
          <span style={{cursor: 'pointer'}} onClick={(e)=>{
              e.stopPropagation();
              toggleCollapse()}}>  {collapsedStates[header]?<CaretDownOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}} />:<CaretRightOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}}  />} 
            </span>
        </div>);
    } else if (
      groupFilter.group2.name == "Assignee" ||
      groupFilter.group2.name == "Supervisor"
    ) {
      let user = AllUsers.find((e) => e.zuid == header);
      return (<div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>
            {SelectedUserAvatar({ label: user?.full_name, value: user?.zuid })}
          </span>
          <span style={{cursor: 'pointer'}} onClick={(e)=>{
              e.stopPropagation();
              toggleCollapse()}}>  {collapsedStates[header]? <CaretDownOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}} />:<CaretRightOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}}  />} 
            </span>
        </div>);
    } else if (groupFilter.group2.name == "Priority") {
      let color = getPriorityValue(header);
      return (
        header && (
          <div
            style={{ backgroundColor: color, color: invertColor(color, true) }}
            className="Group-Titel-Card" 
          >
            <span style={{ fontSize: "11px" }}>{header}</span>  
            <span style={{cursor: 'pointer'}} onClick={(e)=>{
              e.stopPropagation();
              toggleCollapse()}}>  {collapsedStates[header]?<CaretDownOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}} />:<CaretRightOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}}  />} 
            </span></div>)
      );
    } else {
      return (
        header != "undefined" && header ? 
        <div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>{isValidDate(header) ? convertToUSDate(header) : header}</span>
          <span style={{cursor: 'pointer'}} onClick={(e)=>{
              e.stopPropagation();
              toggleCollapse()}}>  {collapsedStates[header]?<CaretDownOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}} />:<CaretRightOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}}  />} 
            </span>
        </div> : 
       <div> <span style={{ fontSize: "11px", marginLeft: "7rem" }}>Task Name</span>
        <span style={{cursor: 'pointer'}} onClick={(e)=>{
          e.stopPropagation();
          toggleCollapse()}}>  {collapsedStates[header]?<CaretDownOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}} />:<CaretRightOutlined className="card-expand-icon" style={{marginLeft: '5px', marginTop: "4px"}}  />} 
        </span> </div>
        )
    }
  };
  const toggleCollapse = () => {
    setCollapsedStates((data) => ({
      ...data,
      [header]: !data[header],  
    }));
  };
  useEffect(e=>{
    if(taskFilterSetting.ExpandGroups){
      setCollapsedStates((data) => ({
        ...data,
        [header]: true,  
      }));
    }else{
      setCollapsedStates((data) => ({
        ...data,
        [header]: false,  
      }));
    }
  }, [taskFilterSetting.ExpandGroups])
  const getTaskHeaders = () => {
    return taskHeaders.map((col, index) => {
      return (
        <th
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, index)}
          className={`${
            index === 0
              ? "min-width-250 task_table_header task-card-title"
              : col.label === "Assignee" ||
                col.label === "Supervisor" ||
                col.label === "Priority"                 
              ? "column-header min-width-100" :
              col.label === "Time Estimate" ?
              "column-header min-width-120" :
              col.label === "Task Location" ?
              "min-width-250"
              : "column-header"
          }`}
          onClick={() => sortData(col.field)} // Add onClick for sorting
        >
          {index === 0 ? getTabCard() : col.label}
          {renderSortIcon(col.field)}
          {index > 0 && <FontAwesomeIcon icon={faGripVertical} className="dragOptionIcon" />}
        </th>
      );
    });
  };
  const getTaskRow = (data) => {
    return taskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            data?.SubTasks?.length > 0 && index == 0 ? "connecting-task-1" : ""
          }`}
        >
          <div style={{width:'2.5em'}} 
          className={selectedIds.Task.includes(data.TaskROWID)?'':"checkBoxClass"} 
          onClick={(e)=>e.stopPropagation()}>
          {index==0 ? 
            <Checkbox 
                checked={selectedIds.Task.includes(data.TaskROWID)} 
                className='Individual-Checkbox'
                // style={{opacity: '0.7'}}
                onClick={(e)=>{
                  setSelectedIds((prev) => ({
                  ...prev,
                  Task: prev.Task.includes(data.TaskROWID)
                    ? prev.Task.filter(id => id !== data.TaskROWID)  
                    : [...prev.Task, data.TaskROWID],  
                })); 

                const dynamicKey = `${key1}_${header}`;  // Construct the dynamic key

              // Safeguard: Initialize relevantArray as an empty array if undefined
              const relevantArray = Array.isArray(dummyId[dynamicKey]) ? dummyId[dynamicKey] : []; 

              // Update dummyId state dynamically
              if (relevantArray.includes(data.TaskROWID)) {
                setDummyID(prev => ({
                  ...prev,
                  [dynamicKey]: relevantArray.filter(id => id !== data.TaskROWID), // Remove ID from dummyId
                }));
              } else {
                setDummyID(prev => ({
                  ...prev,
                  [dynamicKey]: [...relevantArray, data.TaskROWID], // Add ID to dummyId
                }));
              }
              }}
          />
           :
           ''}
          </div>
          {col.render(data, col.field)}
        </td>
      );
    });
  };
  const getSubTaskRow = (data, task) => {
    return subTaskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            index == 0 ? "connecting-task-2" : ""
          }`}
        >
          <div style={{width:'2.5em'}} 
          className={selectedIds.Subtask.includes(data.SubTaskROWID)?'':"checkBoxClass"} 
          onClick={(e)=>e.stopPropagation()}>
          {index==0 ? 
          <Checkbox 
              checked={selectedIds.Subtask.includes(data.SubTaskROWID)} 
              className='Individual-Checkbox'
              // style={{opacity: '0.7'}}
              onClick={()=>setSelectedIds((prev) => ({
              ...prev,
              Subtask: prev.Subtask.includes(data.SubTaskROWID)
                ? prev.Subtask.filter(id => id !== data.SubTaskROWID) 
                : [...prev.Subtask, data.SubTaskROWID], 
            }))}
          />
           :
           ''}
          </div>
          {col.render(data, col.field, task)}
        </td>
      );
    });
  };
  const getSubtaskCollapse = (subtasks, task) => {
    return subtasks.map((sub, i) => {
      let deps = getAllDependenciesForTask(true, sub.hasOwnProperty('SubTaskROWID')?sub.SubTaskROWID : sub.TaskROWID);
      sub = { ...sub, deps: deps };
      return (<>
      {/* <div className="subtask-parent-name">{(taskFilterSetting.SubtaskParntNames) ? showSubtaskParentNames(sub) : ""}</div> */}
        <tr
          className={`showCheckBox TaskId-${task.TaskId} ${
            taskFilterSetting.ExpandTasks ? "" : "HIDEROW"
          } `}
        >
          {getSubTaskRow(sub, task)}
        </tr>
        </>);
    });
  };
  const getAllDependenciesForTask = (subtask, ROWID) => { 
    let sourceTaskType = subtask ? "SubTasks" : "Tasks";
    // let ROWID = sourceTaskType == "Tasks" ? data.TaskROWID : data.SubTaskROWID;
    const result = allDependeniesData?.filter((e) => e.SourceTaskId == ROWID && e.STaskType==sourceTaskType);
    if (result.length > 0) {
      let obj = {
        WaitingOn:[],
        Blocking: [],
      };
      result.forEach(element=>{
              let waitingObj={};
              let blockingObj={};
              if(element.DependancyType=='WaitingOn'){
                waitingObj['ROWID']=element.ROWID
                waitingObj['IsResolved']=element.IsResolved
                waitingObj['SourceDependencyStatus']=element.SourceDependencyStatus
                waitingObj['TargetDependencyStatus']=element.TargetDependencyStatus
                waitingObj['sourceValue']=`${element.STaskType}_${element.SourceTaskId}`
                waitingObj['value']=`${element.TaskType}_${element.TargetTaskId}`
                obj.WaitingOn.push(waitingObj);
              }else{
                blockingObj['ROWID']=element.ROWID
                blockingObj['IsResolved']=element.IsResolved
                blockingObj['SourceDependencyStatus']=element.SourceDependencyStatus
                blockingObj['TargetDependencyStatus']=element.TargetDependencyStatus
                blockingObj['sourceValue']=`${element.STaskType}_${element.SourceTaskId}`
                blockingObj['value']=`${element.TaskType}_${element.TargetTaskId}`
                obj.Blocking.push(blockingObj);
              }
            })
      return obj;
    } else {
      return { WaitingOn: [], Blocking: [] };
    }
  };

  return (
    <Col sm={12} className="TaskGroup2Card">
      {DependecyModal()}
      <Row sm={12} className="Category-Row">
        <table className="table task-table task-content-table table-hover">
          <thead>
            <tr id={`${key1?.replace(/\s+/g, '')}_${header?.replace(/\s+/g, '')}`} className="showCheckBox"
              onMouseEnter={(e)=>{
                let hoverId = e.currentTarget.id;
                document.querySelectorAll(`.${hoverId}`).forEach(k=>{
                  k.style.visibility = 'visible';
                })
              }}
              onMouseLeave={(e)=>{
                let hoverId = e.currentTarget.id;
                document.querySelectorAll(`.${hoverId}`).forEach(k=>{
                  k.style.visibility = 'hidden';
                })
              }}
            >
              {getTaskHeaders(header)}
            </tr>
            </thead>
          {collapsedStates[header] && ( <tbody>
            {rowData?.map((row, index) => {
              let deps = getAllDependenciesForTask(
                row.hasOwnProperty("SubTaskROWID"),
                row.hasOwnProperty("SubTaskROWID")?row.SubTaskROWID : row.TaskROWID
              );
              row = { ...row, deps: deps };
              return (
                <>
                  {/* {taskFilterSetting &&
                  taskFilterSetting.TaskLocation
                    ? showTaskLocationNames(row)
                    : ""} */}
                  <tr className="showCheckBox">{getTaskRow(row)}</tr>
                  {row?.SubTasks?.length > 0 &&
                    getSubtaskCollapse(row.SubTasks, row)}
                </>
              );
            })}
          </tbody>
        )}
        </table>
      </Row>
    </Col>
  );
};
