import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal as AntModal,
  Input,
  Popover,
  Tabs,
  Button,
  Badge,
  theme,
  Select,
  Tooltip,
  Checkbox,
  Popconfirm,
} from "antd";
import "./CreateTaskModal.css";
import { Row, Col} from "reactstrap";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faUser,
  faClock,
  faFolder,
  faPaperclip,
  faCodeBranch,
  faArrowLeft,
  faTimes,
  faCheck,
  faAngleRight,
  faPlus,
  faMinus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "react-date-range";
import { CaretDownFilled, CaretDownOutlined, CaretRightOutlined, CheckCircleOutlined, CloseOutlined, FlagFilled, MinusCircleFilled, MinusCircleOutlined, MinusCircleTwoTone, MinusOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, PlusSquareTwoTone, } from "@ant-design/icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { UserPicker } from "../../common/UserPicker/UserPicker";
import { AppDataRequired } from "../../App";
import SubTaskTab from "./AddTaskTabs/SubTaskTab";
import { Checklist } from "./AddTaskTabs/Checklist";
import { ActivitySection } from "./AddTaskTabs/ActivitySection";
import TextArea from "antd/es/input/TextArea";
import { CustomeCollapse } from "./AddTaskTabs/CustomeCollapse";
import AppContext from "../../Context/AppContext";
import AwsServerService from "../../common/util/AwsServerService";
import _ from 'lodash';
import swal from "sweetalert";
import {convertToUSTime,getPriorityValue, invertColor,} from "../../components/HR/TimeClock/Util";
import { Option } from "antd/es/mentions";
import { number } from "mathjs";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import TimeTracker from "../../common/TimeTracker/TimeTracker";
import {getNextStepOfFlow} from '../../components/ProjectTasks/Util'
import AttachmentModal from "./attachmentModal";
import axios from "axios";
import SettingIconDark from '../../assets/images/svg/SettingIconDark.svg';
import StatusTimelineIcon from '../../assets/images/svg/StatusTimeline.svg';
import { TaskFlowStatus } from "./TaskStatus/TaskFlowStatus";
import DependencyModal from "./ViewPipelinesTabs/DependencyModal";
import { evaluateEdgeVisibility } from "../../Util/CommonUtil";
import { modifyEdgesAndNodes, processEdgeConnections, updateNodeVisitedStatus } from "./TaskUtil";
import { Flow } from "../../components/TaskBluePrints/Flow";
import Loader from "../../common/loader";
import { InputFields } from "../../components/InputFields/InputFields";
import { InputFieldsTable } from "../../components/InputFields/InputFieldsTable";
import { Socket } from "../../Context/AppActions";
import moment from "moment";
import { NewCustomePopover } from "../../components/TaskBluePrints/HelperComponents/NewCustomePopover";
import { StatusTimeline } from "./StatusTimeline/StatusTimeline";
import JoditTextEditor from "../../common/JoditEditor/JoditTextEditor";


const { Search } = Input;

const CustomeTaskField = ({ name, icon, children ,type}) => {
  return (
    <Row className="Custom-Field-Row" sm={12}>
      <Col style={{ padding: "0px" }} sm={4}>
        <Row sm={12}>
          <Col style={{ padding: "0px" }} sm={2}>
            {icon}
          </Col>
          <Col style={{ padding: "0px 3px", fontWeight: "500", fontSize: "12px" }} sm={10}>
            {name}
          </Col>
        </Row>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

const CreateTaskModal = ({
  open,
  history,
  setOpen,
  viewTaskData,
  taskModalState,
  viewPipelineData,
  refreshData,
  setPageRefreshData,
  createPiplineModalState,
  selectedTable,
  isSubTaskView,
  setIsSubTaskView,
  allPipelineData,
  viewTaskFunction,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  allBlueprintsData,
  dependencyRefresh,
  setDependencyRefresh,
  getHeaderForDeal,
  setDropdownTasksDataCache,
  setTaskModalState
}) => {
  const backendUrl = "https://catly-795086611.catalystserverless.com/server/apiservice/";
  const { token } = theme.useToken();
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const [loader,setLoader] = useState(false);
  const [accountSelected, setAccountSelected] = useState();
  const [selectedBlueprintData,setSelectedBlueprintData] = useState({
    type: "view flow",
    data: null,
    action: "view"
})
  const [dealSelected, setDealSelected] = useState();
  const [statusTimeline,refreshStatusTimeline] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [taskHistory, setTaskHistory] = useState([]); 
  const [statusOptions, setStatusOptions] = useState([]);
  const [mainStatusOptions, setMainStatusOptions] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [relativePathId, setRelativePathId] = useState(null);
  const [filteredDropdownTasksData,setFilteredDropdownTasksData]=useState([]);
  const [BluePrintDropDownOptions,setBluePrintDropDownOptions] = useState();
  const [selectedButton, setSelectedButton] = useState(null);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [transitionChangeState,setTransitionChangeState]=useState(false);
    ////// Attachment Functions /////

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleAttachementRemove = (one) => {
      let tempFileCollection = [...fileNames];
      tempFileCollection = tempFileCollection.filter((file) => file.fileName !== one.fileName);
      setFileNames(tempFileCollection);
    }
      
    const uploadFiles = (arr) => {
      let doArr = []
      let fileUploadArr = []
      let names = []
      let tempFileCollection = [...fileNames];
      tempFileCollection.forEach((obj) => {
        obj.Status = "Pending"
      })
      arr.forEach((one) => {
        names.push(one.fileName);
        // doArr.push(addZohoTaskAttachements(taskData.project_id, taskData.id_string, one))
        fileUploadArr.push(handleUploadFileWorkDrive(one))
      })
  
      // setProgress({ active: true, percent: 1 })
      // let startInterval = setInterval(() => {
      //   setProgress((prev) => {
      //     if (prev && prev.percent < 100) {
      //       prev.percent = prev.percent + 1;
      //       setProgress(prev);
      //     }
      //   })
      // }, 1)
      Promise.all(doArr)
        .then((result) => {
  
          console.log(result);
  
          return Promise.all(fileUploadArr)
        })
        .then((result) => {
          arr.forEach((one) => {
            one.Status = "Success"
          })
  
          // setProgress({ active: true, percent: 100 });
          let filterTemp = tempFileCollection.filter(e => e.Status != "Success")
          // setLoader(false);
          setFileNames(filterTemp);
          // clearInterval(startInterval);
          // setProgress({ active: false, percent: 0 });
          alert("file upload successfully");
          // setAttachmentModal(false)
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        })
  
      // let text = `You upload ${tempFileCollection.length} files `
      // let text = `You upload one file `
      // let filename = names.join(' , ')
      // swal({
      //   title: "Are you sure?",
      //   text: text + filename,
      //   icon: "warning",
      //   buttons: [
      //     'No',
      //     'Yes'
      //   ],
      //   dangerMode: true,
      // }).then(function (isConfirm) {
      //   if (isConfirm) {
  
      //   }
      // })
  
    }

    const handleUploadFiles = () => {
      // let link = document.createElement('input');
      // link.setAttribute('type', 'file');
      // link.setAttribute('name', 'uploadFiles');
      // link.setAttribute('multiple', "multiple");
      // document.body.appendChild(link);
      // link.click();
      // link.onchange = function (e) {
      //   // let formData = new FormData();
      //   // formData.append('uploaddoc', e.currentTarget.files[0])
      //   // updateZohoTask(formData)
      //   handleFileAttachments(e.currentTarget.files);
      // }
  
      // document.body.removeChild(link);
    }
    const getBreadCrumbs = (task) => {
      if(!viewTaskData){
       return;
      }
     if(task.TaskId && task.PipelineROWID && false){
       let pipeline=viewTaskData?.Pipeline;
       // let pipeline = allPipelineData.find(e=>e.PipelineROWID==task?.PipelineROWID)
       return <>
       <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{pipeline.PipelineName}</span>
        </>
     }else if(task.SubTaskId && task.TaskRowId){
         // let t=allTasksAndSubtasks["Tasks"].get(Number(task.TaskRowId));
         let t=viewTaskData?.Task;
         let pipeline=viewTaskData?.Pipeline;
         // if(t.PipelineROWID){
         //   pipeline = allPipelineData.find(e=>e.PipelineROWID==task?.PipelineROWID)
         // }
       if(!pipeline || !t){
         return<></>
       }
       return <>
       {/* <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{pipeline.PipelineName}</span> */}
         <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{t.TaskName}</span>  
        </>
     }
 }
  const handleUploadFileWorkDrive = (fileObj) => {
    return new Promise((resolve, reject) => {
      if (relativePathId) {
        var config = {
          Type: "Upload",
          data: {
            filename: fileObj.fileName,
            content: fileObj.file,
            parent_id: relativePathId,
          },
          url: "https://www.zohoapis.com/workdrive/api/v1/upload",
          method: "POST",
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        };
        axios.post(backendUrl + "workdrive", { config: config })
          .then(async function (response) {
            // ActivitieService.AddActivityLocal('Workdrive', { DealId: props.dealObj.Project_ID }, { action_type: 'Upload', data_type: 'File', data: { name: fileObj.fileName, path: relativeFolderId, pathId: relativePathId } })
            // resolve(response)
            // console.log("-------------------------files data-----------------------------------")

          })
          .catch(function (error) {
            reject(error);
          });
      }
    })
  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...fileNames];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    setFileNames(tempFileCollection);
    // let doArr = []
    // let fileUploadArr = []
    // let names = []
    // tempFileCollection.forEach((one) => {
    //   names.push(one.fileName);
    //   doArr.push(addZohoTaskAttachements(props.dealObj.Project_ID, taskData.id_string, one))
    //   fileUploadArr.push(handleUploadFileWorkDrive(one))
    // })

    // let text = `You upload ${tempFileCollection.length} files `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     setLoader(true);
    //     Promise.all(doArr)
    //       .then((result) => {
    //         console.log(result);
    //         return Promise.all(fileUploadArr)
    //       })
    //       .then((result) => {
    //         setLoader(false);
    //         alert("file upload successfully");
    //         setAttachmentModal(false)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //   }
    // })


  };
    ////// Attachment Functions /////

  const taskEmptyTemplate = {
    TaskName: "",
    TaskDescription: "",
    Priority: "Not Set",
    Assignee: "",
    Supervisor: "",
    PipelineROWID: "",
    DueDate: new Date(),
    StartDate:new Date(),
    Account: "",
    Project: "",
    TaskTemplateId: "",
    TaskStatus:1,
    // TaskFlowId:'1',
    TimeEstimate: "",
    SubTasks: [],
    CheckList: [],
    InputFields:[],
    LookUpFields:[],
    TaskFlowObject:null,
  };
  
  const [taskData, setTaskData] = useState({ ...taskEmptyTemplate });
  const { AllUsers } = useContext(AppDataRequired);
  const { DealFields } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [allDealsData, setAllDealsData] = useState([]);
  const [allPipelinesData, setAllPipelinesData] = useState([]);
  const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
  const [subTaskData, setSubTaskData] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  let allDealsAndUsers = state.AllDeals || [];
  let allAccounts = state.AllAccounts || [];
  // const  comment = useRef({ROWID:null,flag:false});
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0,Blocking:0}); 
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]})
  const [allDependencies,setAllDependencies]=useState([])
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  }); 
  const [lookUpDeal, setLookUpDeal] = useState();

  const clockInterval=useRef(null);
  const [startTimer, setStartTimer] = useState(false)
  const [description, setDescription] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [anotherSessionActiveFlag, setAnotherSessionActiveFlag] = useState({Active:false,Record:{}});
  const [lastEntry, setLastEntry] = useState(null); 
  const [userList, setUserList] = useState([]);
  const [timer3, setTimer3] = useState(0);
  const [timer, setTimer] = useState(0); 
  const [timer2, setTimer2] = useState(0); 
  const [typeOfTask, setTypeOfTask] = useState({ROWID:'',Type:''});

  function calculateTimeDifference(startTime, stopTime) { 
    const start = moment(startTime);
    const stop = moment(stopTime); 
    const duration = moment.duration(stop.diff(start)); 
    return duration.asSeconds();
  } 
  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
  };
  const checkRecordIfAvailable = (taskId, taskType) => {
    let tp=taskType=='Tasks'?'Task':'Sub-Task';
    AwsServerService.getAllTimelineBYIDANDTYPE(taskId,tp).then(rspons=>{
       
      const response=rspons.data;
      AwsServerService.getTimeRecordsByTask(taskId, taskType).then(r => {
      const data = r.data; 
      let obj = {};
      data.forEach(entry => {
        if (obj[entry.CreatedBy]) {
          obj[entry.CreatedBy].push(entry);
        } else {
          obj[entry.CreatedBy] = [entry];
        }
      });
      let totalSeconds = 0;
      let taskRecord = {};
      let flag = true;
      let userLists = [];
      Object.keys(obj).forEach(e => {
        let info = userOptions.find(el => el.value == e);
        let totalSec = 0;
        obj[e].forEach(record => {
          if (record.StopTime == '-' && flag == true) {
            const now = new Date();
            const StopTime = convertToUSTime(now);
            const { StartTime } = record;
            totalSeconds += calculateTimeDifference(StartTime, StopTime);
            let currentSessionTime = calculateTimeDifference(StartTime, StopTime);
            setTimer2(currentSessionTime);
            setTimer3(currentSessionTime);
            setStartTimer(true);
            taskRecord = record;
            flag = false;
          } else {
            const { StartTime, StopTime } = record;
            totalSeconds += calculateTimeDifference(StartTime, StopTime);
            totalSec += calculateTimeDifference(StartTime, StopTime);
          }
        })
        let object = {
          userName: info?.label,
          userId: info?.value,
          Entrys: obj[e],
          TotalTime: formatTime(totalSec)
        }
        userLists.push(object);
      })
      setTimer(totalSeconds);
      if (!flag) {
        setLastEntry(taskRecord);
      }
       
      let dummyUserList=[];
      userLists.forEach(element=>{
      let entrys={};
      for(let i=0;i<response.length;i++){ 
        let firstTime=response[i].UTCTimeString;
        let secondTime='';
        let nextEntry=response.find(k=>k.UTCTimeString>firstTime);
        if(nextEntry){ 
          secondTime=nextEntry.UTCTimeString;
        }else{
          const now = new Date();
          secondTime= convertToUSTime(now);
        }; 
        let records=element['Entrys'].filter(e=>e.StartTime>firstTime && (e.StopTime<secondTime || e.StopTime=='-'));
        if(records.length>0){ 
          if(entrys[`${response[i].TaskStatus.data.label}_${response[i].TaskStatus.data.color}`]){
            entrys[`${response[i].TaskStatus.data.label}_${response[i].TaskStatus.data.color}`].push(...records)
          }else{
            entrys[`${response[i].TaskStatus.data.label}_${response[i].TaskStatus.data.color}`]=[...records]
          }
        }
       }
      dummyUserList.push({...element,Entrys:entrys})
      })
      setUserList(dummyUserList);
       
    })
  })
  }
  const checkForActiveTrackingSessions = () => {
    AwsServerService.getAllTimeRecords().then(r => {
      let data = r.data;
      let flag = false;
      let record = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].StopTime == '-') {
          flag = true;
          record = data[i];
        }
      }
      let tasksD = { ...allTasksAndSubtasks };
      if (record && record.TaskType == 'Tasks') {
        const newData = tasksD.Tasks.get(number(record.TaskROWID));
        record['TaskName'] = newData.TaskName;
      } else if (record && record.TaskType == 'SubTasks') {
        const newData = tasksD.SubTasks.get(number(record.TaskROWID));
        record['TaskName'] = newData.TaskName;
      }
      setAnotherSessionActiveFlag({ Active: flag, Record: record });
    })
  }
  
  useEffect(()=>{
    if(open && !viewTaskData){
      let flowObj= allBlueprintsData?.filter(e=>e.DefaultFlow=="true")[0]; 
      if (flowObj) {
        let status = flowObj.Nodes.find(k=>k.NonSelectable);
        setSelectedBlueprintData({...selectedBlueprintData,data:flowObj})
        let ob={ ...taskData ,TaskStatus:status?.id,TaskFlowId:flowObj?.ROWID, TaskFlowObject:flowObj};
        getBlueprintDataOptions(ob, ob.TaskStatus);
        setTaskData(ob);
      }
    }
  },[open])
  useEffect(e=>{
    if(taskData && dealSelected && allDealsAndUsers){
      let deal = allDealsAndUsers.find(d=> d.Deal_Name == dealSelected);
      setLookUpDeal(deal)
    }
  }, [taskData, allDealsAndUsers, DealFields ,dealSelected])

  useEffect(e=>{
    if(DealFields && DealFields?.length > 0){
      setFilteredFields(DealFields)
    }
  }, [DealFields])
  

  async function handleSaveTask(data) {
    let user = JSON.parse(localStorage.getItem("userinfo"));
    const {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
      LookUpFields,
      InputFields
    } = data;
    const payload = {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
      LookUpFields,
      InputFields
    };
    if(!payload.TaskFlowId){
      let flowObj= allBlueprintsData?.filter(e=>e.DefaultFlow=="true")[0];
      payload.TaskFlowId =flowObj?.ROWID;
      payload.TaskFlowObject = flowObj;
    }
    payload["PipelineROWID"] = data.PipelineROWID;
    if(!payload?.TaskName){
      swal('Alert','Task Name can not be empty!','warning');
      return
    }
    if (taskModalState.action == "create") {
      payload["CREATEDBY"] = user.email_id;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.saveTask(payload)
            .then(async (res) => {
              setOpen(false);
              const promises = Object.keys(selectedDependencies).map(async (e) => {
                return Promise.all(selectedDependencies[e].map(async (a) => {
                  const [type, id] = a.value.split('_');
                  const payload = {
                    TargetTaskId: id,
                    STaskType: 'Tasks',
                    SourceTaskId: res[0]?.insertId,
                    TaskType: type,
                    DependancyType: e,
                    CREATEDBY: localStorage.getItem("ZUID"),
                    TargetDependencyStatus: data.TargetDependencyStatus,
                    SourceDependencyStatus: data.SourceDependencyStatus
                  }
                  return AwsServerService.createDependency(payload);
                }));
              });
              await Promise.all(promises).then(r => {
                if (selectedDependencies.Blocking.length > 0 || selectedDependencies.WaitingOn.length > 0) {
                  let data = { ...selectedDependencies }
                  let message = 'USER added ';
                  data.WaitingOn?.forEach((e, index) => {
                    if (index == 0) {
                      message += 'Waiting dependency of "'
                    }
                    message += `${e.label}`;
                    if (index == data.WaitingOn.length - 1) {
                      message += '" '
                    } else {
                      message += ', '
                    }
                  })
                  data.Blocking?.forEach((e, index) => {
                    if (index == 0) {
                      message += 'Blocking dependency of "'
                    }
                    message += `${e.label}`;
                    if (index == data.Blocking.length - 1) {
                      message += '"'
                    } else {
                      message += ', '
                    }
                  })
                  handleCreateActivity('Tasks', message, res[0]?.insertId);
                }
                setSelectedDependenciesCache(data)
                setIsDependencyModalOpen(false);
              });

              setTaskData({ ...taskEmptyTemplate });
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                if (subTaskData.length > 0) {
                  let message = `USER added ${subTaskData.length > 1 ? 'subtasks' : 'subtask'} "`;
                  subTaskData?.forEach((r, index) => {
                    message += `${r.TaskName}`
                    if (index == subTaskData.length - 1) {
                      message += '"';
                    } else {
                      message += ', ';
                    }
                  })
                  handleCreateActivity('Tasks', message, res[0]?.insertId)
                  console.log("allRes", allRes);
                }
              });
            })
            .catch((err) => { }).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });
        } else {
          delete payload["PipelineROWID"];
          if(!payload["TaskROWID"]){
            payload["TaskROWID"] = taskData.TaskROWID
          }
          await AwsServerService.createSubtask(payload)
            .then(async (res) => {
              setOpen(false);
            })
            .catch((err) => { }).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.saveTaskInTemplate(payload)
            .then(async (res) => {
              const promises = Object.keys(selectedDependencies).map(async (e) => { 
                return Promise.all(selectedDependencies[e].map(async (a) => { 
                  const [type,id]=a.value.split('_');
                  const payload={
                   TargetTaskId:id,
                   STaskType:'Tasks',
                   SourceTaskId:res[0]?.insertId,
                   TaskType:type,
                   DependancyType:e,
                   CREATEDBY:localStorage.getItem("ZUID"),
                   TargetDependencyStatus: a.TargetDependencyStatus,
                   SourceDependencyStatus: a.SourceDependencyStatus
                 } 
                 return AwsServerService.createDependency(payload);
               }));
             }); 
             await Promise.all(promises).then(r=>{
              if(selectedDependencies.Blocking.length>0 || selectedDependencies.WaitingOn.length>0){
               let data={...selectedDependencies}
               let message='USER added ';
               data.WaitingOn?.forEach((e,index)=>{
                if(index==0){
                  message+='Waiting dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.WaitingOn.length-1){
                  message+='" '
                }else{
                  message+=', '
                }
               })
               data.Blocking?.forEach((e,index)=>{
                if(index==0){
                  message+='Blocking dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.Blocking.length-1){
                  message+='"'
                }else{
                  message+=', '
                }
               })
               handleCreateActivity('Tasks',message,res[0]?.insertId,true);
              }
               setSelectedDependenciesCache(data)
               setIsDependencyModalOpen(false); 
             });
              console.log("res", res);
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  delete item["TaskStatus"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                if(subTaskData.length>0){
                  let message=`USER added ${subTaskData.length>1?'subtasks':'subtask'} "`;
                  subTaskData?.forEach((r,index)=>{
                    message+=`${r.TaskName}`
                    if(index==subTaskData.length-1){
                      message+='"';
                    }else{
                      message+=', ';
                    }
                  })
                  handleCreateActivity('Tasks',message,res[0]?.insertId,true) 
                  console.log("allRes", allRes);
                }
                console.log("allRes", allRes);
              }); 
              // setPageRefreshData(!refreshData);
              setTaskData({ ...taskEmptyTemplate });
              
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        } else {
          delete payload["PipelineROWID"];
          delete payload["TaskStatus"];
          await AwsServerService.createSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setOpen(false);
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        }
      }
    } else if (taskModalState.action == "edit") {
      payload["MODIFIEDBY"] = user.email_id;
      payload["ROWID"] = isSubTaskView
        ? taskData?.SubTaskROWID
        : taskData?.TaskROWID;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.updateTask(payload)
            .then(async (res) => { 
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtask(payload)
            .then(async (res) => {
              setOpen(false);
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.updateTaskInTemplate(payload)
            .then(async (res) => {
              setOpen(false);
              console.log("res", res);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              // setPageRefreshData(!refreshData);
              
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtaskInTemplate(payload)
            .then(async (res) => {
              // console.log("res", res);
              // setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {}).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });;
        }
      }
    }
    setPageRefreshData(prev => !prev);
    setDependencyRefresh(prev => !prev);
    setTaskHistory([]);
    // setLookUpArray([])
    setIsSubTaskView(false);
  } 

  useEffect(e=>{
    if(taskModalState?.action == "create"){
      setSubTaskData([])
    }
  }, [taskModalState?.action])
  
  useEffect(
    (e) => {
      if (isSubTaskView) {
        setActiveTab("2");
      } else {
        setActiveTab(activeTab);
      }
    },
    [isSubTaskView]
  );

  useEffect(e=>{
    if(taskData && allDealsAndUsers && allDealsAndUsers.length>0){
      getBlueprintDataOptions(taskData, taskData.TaskStatus);
    }
  },[dealSelected])

  useEffect((e) => {
    if (viewTaskData && allDealsAndUsers && allDealsAndUsers.length > 0) {
    
      let newObject;
      let pipeline = viewTaskData.Pipeline;
      let task = viewTaskData.Task;
      let subtask = viewTaskData.SubTask;
      getAllDependenciesForTask(subtask?.hasOwnProperty('SubTaskROWID'), subtask?.hasOwnProperty('SubTaskROWID') ? subtask?.SubTaskROWID : task?.TaskROWID);

      let id = '';
      let param = '';
      id = subtask && subtask.SubTaskROWID ? subtask?.SubTaskROWID : task?.TaskROWID
      param = subtask && subtask.SubTaskROWID ? "SubTask" : "Task"

      if (subtask && subtask?.hasOwnProperty("SubTaskId")) {
        //subtask logic...
        let account;
        account = allDealsAndUsers?.filter(e => e.Deal_Name == pipeline?.DealName ? e?.Account_Name : '')
        const compareId = task?.TaskRowId || task?.TaskROWID;

        newObject = { ...subtask, PipelineROWID: pipeline?.PipelineROWID }

        setDealSelected(pipeline?.DealName);
        setAccountSelected(account[0]?.Account_Name?.name)

        let array = [];
        array.push({
          value: pipeline?.PipelineROWID,
          label: pipeline?.PipelineName,
          key: pipeline?.PipelineROWID,
        });

        setAllPipelinesData(array);
        let blueprint;
        if (!newObject.TaskFlowObject) {
          blueprint = allBlueprintsData?.find(k => k.ROWID == newObject.TaskFlowId);
        } else {
          blueprint = newObject.TaskFlowObject;
        }
        if (blueprint) {
          getBlueprintDataOptions(newObject, newObject.TaskStatus);
        }
        newObject={...newObject,TaskFlowObject: blueprint}
        setTaskData(newObject);
        setSelectedBlueprintData({ ...selectedBlueprintData, data: blueprint });
        (newObject?.SubTasks && newObject.SubTasks.length > 0) ? setSubTaskData(newObject?.SubTasks) : setSubTaskData([]);
        // checkForCommentsForTask(id,param);
        clearInterval(clockInterval.current);
          
        setTimer(0);
        setStartTimer(false);
        setTimer2(0);
        setTimer3(0);
        checkForActiveTrackingSessions();
        let type = newObject.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
        if (type == 'Tasks') {
          checkRecordIfAvailable(newObject.TaskROWID, type)
          setTypeOfTask({ ROWID: newObject.TaskROWID, Type: type });
          setIsSubTaskView(false);
        } else {
          checkRecordIfAvailable(newObject.SubTaskROWID, type)
          setTypeOfTask({ ROWID: newObject.SubTaskROWID, Type: type });
          setIsSubTaskView(true);
        }
      } else if (task && task?.hasOwnProperty("TaskId")) {
        //task logic...
        let account;
        account = allDealsAndUsers?.filter(e => e.Deal_Name == pipeline?.DealName ? e?.Account_Name : '')
        setDealSelected(pipeline?.DealName);
        setAccountSelected(account[0]?.Account_Name?.name)

        let array = [];
        array.push({
          value: pipeline?.PipelineROWID,
          label: pipeline?.PipelineName,
          key: pipeline?.PipelineROWID,
        });

        setAllPipelinesData(array);
        let blueprint;
        if (!task.TaskFlowObject) {
          blueprint = allBlueprintsData?.find(k => k.ROWID == task.TaskFlowId);
        } else {
          blueprint = task.TaskFlowObject;
        }
        if (blueprint) {
          getBlueprintDataOptions(task, task.TaskStatus);
        }
        task={...task,TaskFlowObject: blueprint};
        setTaskData(task);
        setSelectedBlueprintData({ ...selectedBlueprintData, data: blueprint });
        (task?.SubTasks && task.SubTasks.length > 0) ? setSubTaskData(task?.SubTasks) : setSubTaskData([]);
        // checkForCommentsForTask(id,param);
        clearInterval(clockInterval.current);
          
        setTimer(0);
        setStartTimer(false);
        setTimer2(0);
        setTimer3(0);
        checkForActiveTrackingSessions();
        let type = task.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
        if (type == 'Tasks') {
          checkRecordIfAvailable(task.TaskROWID, type)
          setTypeOfTask({ ROWID: task.TaskROWID, Type: type });
        } else {
          checkRecordIfAvailable(task.SubTaskROWID, type)
          setTypeOfTask({ ROWID: task.SubTaskROWID, Type: type });
        }
      }
    } else {
      setTaskData({ ...taskEmptyTemplate });
      setAllPipelinesData([]);
      setSubTaskData([])
      setDealSelected();
      setAccountSelected();
      clearInterval(clockInterval.current);
      setTimer(0);
      setStartTimer(false);
      setTimer2(0);
      setTimer3(0);
      setAnotherSessionActiveFlag({ Active: false, Record: {} })
      setUserList([]);
      setDescription('');
      setTypeOfTask({ ROWID: '', Type: '' });
      setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] })
      setDropdownCount({ WaitingOn: 0, Blocking: 0 })
    }
  },
    [viewTaskData, dependencyRefresh,allDealsAndUsers]
  );

    useEffect(()=>{ 
    if(selectedTable=='pipelines'){ 
     setFilteredDropdownTasksData(dropdownTasksDataCache);
    }
    },[selectedTable,dropdownTasksDataCache])

  useEffect(
    (e) => {
      if (viewPipelineData && viewPipelineData?.length > 0) {
        // console.log("viewPipelineData", viewPipelineData);
        let temp = { ...taskData };
        temp["PipelineROWID"] = viewPipelineData[0]?.PipelineROWID || viewPipelineData[0]?.ROWID;
        setTaskData(temp);
      }
    },
    [viewPipelineData]
  );
  // const checkForCommentsForTask=(ROWID,Type)=>{
  //   AwsServerService.getCommentsByTaskIdAndType(ROWID,Type).then((r)=>{ 
  //     if(r.data[0][0]?.Message || r.data[0][0]?.ROWID){
  //       comment.current = {ROWID:r.data[0][0]?.ROWID,flag:true};
  //     }else{
  //       comment.current = {ROWID:null,flag:false};
  //     }
  //     setTaskData(task=>({...task,'Comment':r.data[0][0]?.Message}));
  //   })
  // }
  const saveCommentForTask= async ()=>{ 
    let sourceTaskType=taskData?.hasOwnProperty('SubTaskROWID')?"SubTask":"Task";
    let sourceId=taskData?.hasOwnProperty('SubTaskROWID')?taskData?.SubTaskROWID:taskData?.TaskROWID; 
    if(taskData['Comment']){
     let commentPayload = {
       "Message" : taskData['Comment'],
       "CREATEDBY" : userInfo.email_id,
       "TaskId" : sourceId,
       "TaskType" : sourceTaskType
     } 
     await AwsServerService.addComment(commentPayload).then(r=>{
       let message=`USER added comment " ${taskData["Comment"]} "`
       handleCreateActivity(sourceTaskType=='Task'?'Tasks':'SubTasks',message,sourceId,selectedTable=='pipelines');
       setTaskData({...taskData,'Comment':''});
      }).catch(e=>{
       console.log("error in comment", e);                  
     })  
    }
  }
  function getOptionsForDropdown(data,selected){
    let options=[];
    let selectedData={...selected}
    let type=taskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let id=taskData?.hasOwnProperty('SubTaskROWID')?taskData?.SubTaskROWID:taskData?.TaskROWID;
    const valuesToExclude = new Set([
      ...selectedData.Blocking.map(item => item.value),
      ...selectedData.WaitingOn.map(item => item.value),
      `${type}_${id}`
    ]) 
    console.log("data ......>>>>", data)
    data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
    return options;
  }
  // console.log("statusOptions", statusOptions)
  const getBlueprintDataOptions = async (data,status)=>{
    if(data){
    let currentStatus=status; 
    let node =  data?.TaskFlowObject?.Nodes?.filter(e=>e.id==currentStatus)[0];
    let Edges = data?.TaskFlowObject?.Edges?.filter(e=>!e?.data?.visited && e.source==node?.id); 
    let options = [];
    let optionsStatus = [];
      Edges?.map((item) => {
        let isValid = evaluateEdgeVisibility({ 
          Project: allDealsAndUsers?.find(d=>d.Deal_Name==dealSelected), 
          Task: {...taskData},
          "Input Fields": taskData?.TaskFlowObject?.InputFields
        }, item, data.Edges)
          if (item.label) {
            let temp = {
              value: item?.label,
              label: item?.label,
              key: item?.id,
              disable: !isValid,
            }
            if (item?.data?.color) {
              temp["color"] = item?.data?.color
            }
            options.push(temp);
          }
      });
      data?.TaskFlowObject?.Nodes?.map((item) => {
      if(item.data?.label && item.type=="custom"){
        let temp={
          value: item?.data?.label,
          label: item?.data?.label,
          key: item?.id,
        }
        if(item.data?.color){
          temp["color"]=item.data.color == "grey" ? "#808080" : item.data.color
        }
        optionsStatus.push(temp);
      }
      }) 
      setMainStatusOptions(optionsStatus);
      setStatusOptions(options) 
  } 
    }
    const modifyDependenciesData=(result,sourceTaskType,ROWID)=>{
      let obj = {
        WaitingOn: [],
        Blocking: []
      }; 
      let allDep=[];
      result.forEach(element=>{
        let waitingObj={};
        let blockingObj={};
        let t={...element}
        if(sourceTaskType==element.STaskType && ROWID==element.SourceTaskId){
          if(element.DependancyType=='WaitingOn'){ 
            waitingObj['ROWID']=element.ROWID
            waitingObj['DependancyType']=element.DependancyType
            waitingObj['CREATEDBY']=element.CREATEDBY
            waitingObj['MODIFIEDBY']=element.MODIFIEDBY
            waitingObj['IsResolved']=element.IsResolved
            waitingObj['SourceDependencyStatus']=element.SourceDependencyStatus
            waitingObj['TargetDependencyStatus']=element.TargetDependencyStatus
            waitingObj['sourceValue']=`${element.STaskType}_${element.SourceTaskId}`
            waitingObj['value']=`${element.TaskType}_${element.TargetTaskId}`
            obj.WaitingOn.push(waitingObj);
          }else{ 
            blockingObj['ROWID']=element.ROWID
            blockingObj['DependancyType']=element.DependancyType
            blockingObj['CREATEDBY']=element.CREATEDBY
            blockingObj['MODIFIEDBY']=element.MODIFIEDBY
            blockingObj['IsResolved']=element.IsResolved
            blockingObj['SourceDependencyStatus']=element.SourceDependencyStatus
            blockingObj['TargetDependencyStatus']=element.TargetDependencyStatus
            blockingObj['sourceValue']=`${element.STaskType}_${element.SourceTaskId}`
            blockingObj['value']=`${element.TaskType}_${element.TargetTaskId}`
            obj.Blocking.push(blockingObj);
          }
        }
        t["SourceTaskId"] = t["STaskType"] + "_" + t["SourceTaskId"]
        t["TargetTaskId"] = t["TaskType"] + "_" + t["TargetTaskId"]
        allDep.push(t);
      })
      setAllDependencies(allDep);
      return obj;
    }
    const getAllDependenciesForTask = (subtask,ROWID) => { 
      if(ROWID==undefined||ROWID==null){
        return
      }

      let sourceTaskType=subtask?'SubTasks':'Tasks';
      if(selectedTable=='pipelines'){
        AwsServerService.getAllTempDependencies().then(r => {
          const result = r.data;
          if (result.length > 0) {
            let obj = modifyDependenciesData(result, sourceTaskType, ROWID);
            setSelectedDependenciesCache(obj);
          } else {
            setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
            setAllDependencies([]);
          }
        });
      }else{
        AwsServerService.getAllDependencies().then(res => {
          const result = res.data;
          if (result.length > 0) {
            let obj = modifyDependenciesData(result, sourceTaskType, ROWID);
            setSelectedDependenciesCache(obj);
          } else {
            setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
            setAllDependencies([]);
          }
        })
      
    }
  };
  
  useEffect(() => {
    let data = { ...selectedDependenciesCache }
    setSelectedDependencies(data);
  }, [selectedDependenciesCache])

  useEffect(() => {
    if (allAccounts) {
      let array = [];
      const filteredAccounts = allAccounts.filter((item) => item?.Account_Name !== "All");
      filteredAccounts.forEach((item) => {
        array.push({
          value: item?.Account_Name,
          label: item?.Account_Name,
          key: item?.id,
        });
      });
      array.unshift({
        value: "Miscellaneous",
        label: "Miscellaneous",
        key: "Miscellaneous",
      });
  
      setAllAccountsData(array);
    }
  }, []);
  

  useEffect(
    (e) => {
      let array = [];
      allDealsAndUsers = allDealsAndUsers?.filter(
        (item) =>
          item.hasOwnProperty("Created_Time") &&
          item?.Account_Name?.name == accountSelected
      );
      allDealsAndUsers?.map((item) => {
        array.push({
          value: item?.Deal_Name,
          label: item?.Deal_Name,
          key: item?.id,
        });
      });
      setAllDealsData(array);
    },
    [accountSelected]
  );

  useEffect(() => {
    if (dealSelected) {
      AwsServerService.getPipelineDataByDeal(dealSelected)
        .then((res) => {
          let array = [];
          res?.map((item) => {
            array.push({
              value: item?.PipelineROWID,
              label: item?.PipelineName,
              key: item?.PipelineROWID,
            });
          });
          setAllPipelinesData(array);
          let data=res;
          let result =[]; 
          if (data) {
            for (let i = 0; i < data.length; i++) {
              let pipeline = data[i];
              if (pipeline.Tasks) {
                for (let j = 0; j < pipeline.Tasks.length; j++) {
                  let task = pipeline.Tasks[j];
                  let statusColor = "#808080";  
                  if (task?.TaskFlowObject) {
                    let filterData = task?.TaskFlowObject.Nodes.find(e => e.data.label == task.TaskStatus);
                    statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                  } 
                  let tempStatus=task?.TaskFlowObject?.Nodes?.find(k=>k.id==task.TaskStatus);
                   
                  result.push({
                    label: task?.TaskName,
                    value: `Tasks_${task?.TaskROWID}`,
                    status:tempStatus?.data?.label,
                    TaskStatus: task?.TaskStatus,
                    statusColor: tempStatus?.data?.color,
                    taskData: task
                  }); 
                  if (task.SubTasks) {
                    for (let k = 0; k < task.SubTasks.length; k++) {
                      let subtask = task.SubTasks[k];
                      if (subtask?.TaskFlowObject) {
                        let filterData = subtask?.TaskFlowObject.Nodes.find(e => e.data.label == subtask.TaskStatus);
                        statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                      } 
                      let tempStatus1=subtask?.TaskFlowObject?.Nodes?.find(k=>k.id==subtask.TaskStatus);
                      result.push({
                        label: subtask?.TaskName,
                        value: `SubTasks_${subtask?.SubTaskROWID}`,
                        status: tempStatus1?.data?.label,
                        TaskStatus: subtask?.TaskStatus,
                        statusColor: tempStatus1?.data?.color,
                        taskData: subtask
                      });
                    } } } } } } 
                  setFilteredDropdownTasksData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAllPipelinesData([]);
    }
  }, [dealSelected]);

  useEffect(() => {
    if (viewPipelineData && viewPipelineData?.length > 0) {
      let array = [];
      array.push({
        value: viewPipelineData[0]?.PipelineROWID,
        label: viewPipelineData[0]?.PipelineName,
        key: viewPipelineData[0]?.PipelineROWID,
      });
      setAllPipelinesData(array);
    } else {
      setAllPipelinesData([]);
    }
  }, [viewPipelineData]);

  const activitySections = [
    {
      key: "Activity",
      open: true,
      icon: <FontAwesomeIcon icon={faCommentAlt} />,
      component: (taskId, flag, flag2, allTasksAndSubtasks,task) => {
        // let module=flag2? flag ? "SUBTASKTEMPLATE" :"TASKTEMPLATE"
          
        return (
          <ActivitySection task={task} taskId={taskId} module={(flag2 && flag) ? "SUBTASKTEMPLATE" :
            (flag2 && !flag) ? "TASKTEMPLATE" :
            (!flag2 && flag) ? "SUBTASK" : "TASK"} 
            refreshData={refreshData}
            allTasksAndSubtasks={allTasksAndSubtasks}
            viewTaskFunction={openTaskFromActivity}
            />
        );
      },
    },
    {
      key: "Timeline",
      open: false,
      icon: <img src={StatusTimelineIcon} style={{width:"17px"}}/>,
      component: (taskId, flag, flag2, allTasksAndSubtasks,task,refresh) => {
        // let module=flag2? flag ? "SUBTASKTEMPLATE" :"TASKTEMPLATE"
        return <StatusTimeline task={task} refresh={refresh} />
      },
    },
    // {
    //   key: "Settings",
    //   open: false,
    //   icon: <img src={SettingIconDark} alt="SettingIcon" style={{width:"17px"}}/>,
    //   component: () => <></>,
    // },
  ];


  ///// new changes for settings options ////

  const defaultDuplicateObject = {
    TaskName: '',
    Everything: true, 
    // Attachements: true, Comments: true, InputFields: true,LookUpFields: true, Dependencies: true, 
    StartDate: true, DueDate: true, Assignee: true, Supervisor:true,
    TimeEstimate: true, Priority: true, 
    // Status: true, 
    Subtask: true,
  }

  const [popoverVisible, setPopoverVisible] = useState(false); // State for controlling Popover visibility
  const [duplicateValueObj, setDuplicateValueObj] = useState({...defaultDuplicateObject});
  const [duplicatePopUp, setDuplicatePopUp] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [popUpType, setPopUpType] = useState(null);
  
  const handleOnChangeDuplicateCheckBox = (e) => {
    const { name, checked } = e.target;
    const updatedObj = { ...duplicateValueObj };
    if (name === 'TaskName') {
        updatedObj[name] = e.target.value; // Handle TaskName input change
    } else if (name === 'Everything') {
      // Select/Deselect all checkboxes when 'Everything' is clicked
      const newValue = checked;
      Object.keys(updatedObj).forEach((key) => {
        if (key !== 'TaskName') {
          updatedObj[key] = newValue;
        }
      });
    } else {
      // Handle individual checkbox changes
      updatedObj[name] = checked;
    }
  
    // Check if all checkboxes (except 'TaskName' and 'Everything') are selected
    const allChecked = Object.keys(updatedObj).every(
      (key) => key === 'TaskName' || key === 'Everything' || updatedObj[key]
    );
  
    // Check if at least one checkbox (other than 'TaskName') is selected but not all
    const someChecked = Object.keys(updatedObj).some(
      (key) => key !== 'TaskName' && key !== 'Everything' && updatedObj[key]
    );
  
    // Update the 'Everything' checkbox state
    updatedObj['Everything'] = allChecked;
    setIndeterminate(someChecked && !allChecked); // Set indeterminate if some are checked but not all
  
    setDuplicateValueObj(updatedObj);
  };

  const hanleCreateDuplicateTask = async(duplicateFields, data) =>{
    let duplicateObj = { ...duplicateFields };
    let duplicateTaskObj = { ...data };
    if (!duplicateObj.Priority) {
      duplicateTaskObj.Priority = "Not Set"
    }
    if (!duplicateObj.Assignee) {
      duplicateTaskObj.Assignee = '';
    }
    if (!duplicateObj.DueDate) {
      duplicateTaskObj.DueDate = new Date();
    }
    if (!duplicateObj.Supervisor) {
      duplicateTaskObj.Supervisor = ''
    }
    if (!duplicateObj.StartDate) {
     duplicateTaskObj.StartDate = new Date()
    }
      delete duplicateTaskObj?.TaskROWID
      delete duplicateTaskObj?.SubTasks
      delete duplicateTaskObj?.TaskTemplateId
      delete duplicateTaskObj?.TaskId
      delete duplicateTaskObj?.MilestoneName
      delete duplicateTaskObj?.IsMilestone
      delete duplicateTaskObj?.deps
      delete duplicateTaskObj?.Edges
      delete duplicateTaskObj?.Nodes

      duplicateTaskObj.TaskName = duplicateValueObj.TaskName
      const TaskApiToCall =
        popUpType === "Duplicate"
            ? AwsServerService.saveTask
            : AwsServerService.saveTaskInTemplate;

            const SubTaskApiToCall =
            popUpType === "Duplicate"
                ? AwsServerService.createSubtask
                : AwsServerService.createSubtaskInTemplate;

      await TaskApiToCall(duplicateTaskObj).then(async res=>{
        if(duplicateObj.Subtask && data.SubTasks?.length > 0){
          await Promise.all(
            data.SubTasks.map((item) => {
              delete item["id"];
              delete item["flag"];
              delete item["PipelineROWID"];
              delete item["SubTaskROWID"];
              delete item["TaskRowId"];
              delete item["SubTaskId"];
              delete item["SubTaskTemplateId"];
              item["TaskROWID"] = res[0]?.insertId;
              item["TaskName"] = `${item["TaskName"]} - Copy`;
              return SubTaskApiToCall(item);
            })
          ).then((allRes) => {
            console.log("allRes dupl", allRes);
          });
        }
        setDuplicateValueObj({...defaultDuplicateObject})
        setTaskData({...taskEmptyTemplate})
        setPopUpType(null)
        setPageRefreshData(prev => !prev)
        setDuplicatePopUp(prev => !prev)
        setOpen(false)
      })
  }

  const renderDuplicateModal = (type) => {
    return (
      <AntModal
        title={type ==  "Duplicate" ?"Duplicate Task" : "Save As Template"}
        open={duplicatePopUp}
        onCancel={()=>{setDuplicatePopUp(false); setPopUpType(null)}}
        footer={null}
        className="taskComplete-warning duplicate-task-modal"
      >
        <Row>
          <Col sm={12}>
            <h5 className='text-left'>{type ==  "Duplicate" ?"New Task Name" : "Template Name"}</h5>
            <Input
              onChange={(e)=>{
                const trimmedValue = e.target.value.trimStart(); // Remove leading whitespace
                  e.target.value = trimmedValue;

                  if (trimmedValue) {
                    handleOnChangeDuplicateCheckBox(e);
                  }else{
                    e.target.value = '';
                    handleOnChangeDuplicateCheckBox(e);
                  }
              }}
              defaultValue={duplicateValueObj.TaskName}
              value={duplicateValueObj.TaskName}
              name='TaskName'
              placeholder={type ==  "Duplicate" ?"New Task Name" : "Template Name"}
              className='form-control form-control-sm duplicate-taskname'
            />
          </Col>
        </Row>
  
        <div className='duplicate-task-box' style={{ padding: '20px 15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 16 }} className='small text-left'>
              What do you want to copy?
            </p>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Everything}
              indeterminate={indeterminate} // Add indeterminate state
              name='Everything'
              style={{width:"max-content"}}
            >
              Everything
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Supervisor}
              name='Supervisor'
              style={{width:"max-content"}}
            >
              Supervisor
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Assignee}
              name='Assignee'
              style={{width:"max-content"}}
            >
              Assignee
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Priority}
              name='Priority'
              style={{width:"max-content"}}
            >
              Priority
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.TimeEstimate}
              name='TimeEstimate'
              style={{width:"max-content"}}
            >
              Time Estimate
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.StartDate}
              name='StartDate'
              style={{width:"max-content"}}
            >
              Start Date
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.DueDate}
              name='DueDate'
              style={{width:"max-content"}}
            >
              Due Date
            </Checkbox>
            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Subtask}
              name='Subtask'
              style={{width:"max-content"}}
            >
              Subtask
            </Checkbox>
          </div>
        </div>
  
        <Row>
          <Col sm={12} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              onClick={async () => {
                if(duplicateValueObj?.TaskName){
                  hanleCreateDuplicateTask(duplicateValueObj, taskData)
                }else{
                  swal('Alert',`${type == "Duplicate" ? "Task" : "Template"} Name can not be empty!`,'warning');
                }
              }}
            >
              {type ==  "Duplicate" ?"Duplicate Task" : "Save Template"}
            </Button>
          </Col>
        </Row>
      </AntModal>
    );
  };

  const getLinkOfTaskModal = (parent) => {
    if (parent.TaskId) {
        // Use window.location.origin for the full base URL
        const locationUrl = window.location.origin;
        // Combine pathname and hash to include the full path
        const pathName = window.location.pathname + window.location.hash;
        // Construct the complete URL
        const link = `${locationUrl}${pathName}`;
        // Copy the constructed URL to the clipboard
        navigator.clipboard.writeText(link);
        // console.log("Copied Link:", link);
    }
};

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    setPopUpType(option.value)
    const parent = { ...taskData };
    const duplicateObject = {...duplicateValueObj}
    if (option && option.value == 'Duplicate') {

      let duplicate = {
        TaskName: '',
        Everything: true, 
        // Attachements: true, Checklist: true, Comments: true, InputFields: true,
        // LookUpFields: true, Dependencies: true, 
        StartDate: true, DueDate: true, Assignee: true,Supervisor:true,
        TimeEstimate: true, Priority: true, 
        // Status: true, 
        Subtask: true
      };
      duplicate.TaskName = parent.TaskName + ' - Copy'
      setDuplicateValueObj(duplicate);
      setDuplicatePopUp(true);

    }
    if (option && option.value == 'Template') {
      // setTemplatePopUp(true)
      // setServiceRequested(serviceReqVariable);
      duplicateObject.TaskName = parent.TaskName + ' - Template';
      setDuplicateValueObj(duplicateObject);
      setDuplicatePopUp(true);
    }
    if (option && option.value == 'Link') {
      getLinkOfTaskModal(parent)
    }
    if (option && option.value == 'Path') {
      // setRelativeModal(true);
    }
    
    console.log(`Selected option: ${option.value}`);

    setPopoverVisible(false); // Close popover after selection
    // Add your handling logic here
  };

  // Options for the settings popover
  const settingsOptions = [
    { label: "Duplicate Task", value: "Duplicate" },
    { label: "Save as Template", value: "Template" },
    { label: "Copy Link", value: "Link" },
    { label: "Add Relative Path", value: "Path" },
  ];

  const renderSettingsPopoverContent = () => (
    <div>
      {taskModalState.action !== "create" && settingsOptions.map((option) => (
        <Button
          key={option.value}
          style={{ width: "100%"}}
          className="Duplicate-modal-btns"
          onClick={(e) => { 
            if (taskModalState.action === "create") {
              e.preventDefault();
              return;
            }
            handleOptionSelect(option)
          }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );

  //////////// End ////////////

  const [sidebarOptions, setSidebarOptions] = useState([...activitySections]);
  const [activitySection, setActivitySection] = useState(0);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  useEffect((e) => {
      if (selectionRange[0]?.startDate && selectionRange[0]?.endDate) {
        let temp = { ...taskData };
        temp["StartDate"] = convertToUSTime(selectionRange[0]?.startDate);
        temp["DueDate"] = convertToUSTime(selectionRange[0]?.endDate);
        setTaskData(temp);
      }
    },
    [selectionRange]
  );

  useEffect(() => {
    let temp = { ...taskData };
  
    // Only set dates if they are missing
    if (!temp.StartDate || !temp.DueDate) {
      if (!temp.StartDate) {
        temp["StartDate"] = convertToUSTime(new Date());
      }
      if (!temp.DueDate) {
        temp["DueDate"] = convertToUSTime(new Date());
      }
      setTaskData(temp);
    }
  }, [taskData]);
  

  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );
  const changeDateRange = (item) => { 
    // Update the selection range
    setSelectionRange([item.selection]);
  };
  useEffect(e=>{
    if(statusOptions){
      let modifiedEdgesAndNodes=modifyEdgesAndNodes(taskData?.TaskFlowObject?.Edges,taskData?.TaskFlowObject?.Nodes,statusOptions.map(k=>k.key))
      let obj={...taskData};
      if(modifiedEdgesAndNodes && modifiedEdgesAndNodes.updatedNodes && modifiedEdgesAndNodes.updatedEdges){
        obj={
          ...obj,Nodes:modifiedEdgesAndNodes.updatedNodes,Edges:modifiedEdgesAndNodes.updatedEdges
        }
      }
      setTaskData(obj)
    }
  },[statusOptions])
  // console.log('filtered',filteredDropdownTasksData,selectedDependencies.WaitingOn);

  const [waitingPopupFlag,setWaitingPopupFlag]=useState({records:[],flag:false,taskStatus:''});
  // console.log('filtered',filteredDropdownTasksData,selectedDependencies.WaitingOn);
  const customePopup=()=>{
    return (<NewCustomePopover
      width={30}
      open={waitingPopupFlag.flag}
      title={"Warning!"}
      okText={"OK"}
      cancelText={"Cancel"}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={e=>{ 
        setWaitingPopupFlag(prev=>({...prev,flag:false}))
      }}
      onOk={e=>{
        setWaitingPopupFlag(prev=>({...prev,flag:false})) 
      }}
      fromDependency={true}
    >
      <div className="depClasspopup"> 
          <div>
        {/* <span style={{padding: '0px 6px',borderRadius: '28px',
          background:taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==waitingPopupFlag.taskStatus)?.data?.color||'lightgray',color:invertColor(taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==waitingPopupFlag.taskStatus)?.data?.color,true)}}>
             {taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==waitingPopupFlag.taskStatus)?.data?.label} 
        </span> */}
         {waitingPopupFlag.taskStatus}
        </div>
        <div>
          {
            waitingPopupFlag.records.map((res,index)=> ( 
               <div>
                <span>{index+1}. </span>
                <span style={{
                  padding: '0px 6px', borderRadius: '28px',
                  background: res?.record?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id == res?.depRecord?.TargetDependencyStatus)?.data?.color || 'lightgray',
                  color: invertColor(res?.record?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id == res?.depRecord?.TargetDependencyStatus)?.data?.color, true)
                }}>
                  {res?.TargetDependencyStatus=="S"?"Start":"Finish"}</span>
                <span> of {filteredDropdownTasksData?.find(r=>r.value==res.value)?.label}</span>
               </div>  
              ) )
          }
          </div> 
      </div>
    </NewCustomePopover>)
  }
  
  const getDependancyValidArrr = (thisTaskData, thisTaskOldStatus, waitingOnDep) => {
    let pendingDep = [];
    if (waitingOnDep?.length > 0 && thisTaskOldStatus == "S") {
      waitingOnDep = waitingOnDep.filter(d => d.SourceDependencyStatus.startsWith("S"));
    } else if (waitingOnDep?.length > 0 && thisTaskOldStatus == "F") {
      waitingOnDep = waitingOnDep.filter(d => d.SourceDependencyStatus.startsWith("F"));
    }
    if (waitingOnDep?.length > 0) {
      waitingOnDep.filter(d => {
        if (d.IsResolved != "1") {
          pendingDep.push(d);
        }
      })
    }
    return pendingDep;
  }
  const updateBlockings = (deps) =>{
    Promise.all(
      deps.map((item) => {
        let [type, id] = item.value.split('_');
        let payload = {
          TASKID: id,
          TASKTYPE: type,
          TASKDEPTYPE: 'Blocking',
          TASKSTATUS: item.SourceDependencyStatus,
          REVDEPTASKTYPE: 'WaitingOn'
        }
        return AwsServerService.updateIsResolvedInDependency(payload).then(res =>res);
      })
    ).then((resolve) => {
      console.log(resolve);
      getAllDependenciesForTask(viewTaskData?.SubTask?.hasOwnProperty('SubTaskROWID'), viewTaskData?.SubTask?.hasOwnProperty('SubTaskROWID') ? viewTaskData?.SubTask?.SubTaskROWID : viewTaskData.Task?.TaskROWID)
    })
  }
  const reopenDependancies = (deps) =>{
    Promise.all(
      deps.map((item) => {
        let [type, id] = item.value.split('_');
        let payload = {
          TASKID: id,
          TASKTYPE: type,
          TASKDEPTYPE: 'Blocking',
          TASKSTATUS: item.SourceDependencyStatus,
          REVDEPTASKTYPE: 'WaitingOn'
        }
        return AwsServerService.reopenResolvedDependency(payload).then(res =>res);
      })
    ).then((resolve) => {
      console.log(resolve);
      getAllDependenciesForTask(viewTaskData?.SubTask?.hasOwnProperty('SubTaskROWID'), viewTaskData?.SubTask?.hasOwnProperty('SubTaskROWID') ? viewTaskData?.SubTask?.SubTaskROWID : viewTaskData.Task?.TaskROWID)
    })
  }
  const handleTransitionChange =async  (e, data,comment) => {
      

    let key = statusOptions?.find(key => key.label == e)
    let edge = data?.Edges?.filter(res => res.id == key.key)[0];
    data = {
      ...data, Edges: processEdgeConnections(edge,data.Edges)
    }
    data = {
      ...data, Nodes: updateNodeVisitedStatus(data.Nodes,data.Edges)
    }

    let prevNode = data?.Nodes?.filter(res => res.id == edge.source)[0]
    let nextNode = data?.Nodes?.filter(res => res.id == edge.target)[0];

    while(nextNode.type=="diverse"){
      let nextEd = data.Edges.find(k=>k.source==nextNode.id);
      if(nextEd && nextEd.target)
      nextNode = data?.Nodes?.filter(res => res.id == nextEd.target)[0];
    }
    // let status = nextNode?.data?.label;
    let status = nextNode?.id;

    if(prevNode?.data?.state=="Start"){
      let pendingWaitingOn = getDependancyValidArrr(null,"S",selectedDependenciesCache.WaitingOn)
      if(pendingWaitingOn?.length>0){
        setWaitingPopupFlag({records:pendingWaitingOn,flag:true,taskStatus:"Start of this task is waiting on"});
        return;
      }
    }else if(nextNode?.data?.state=="Close"){
      let pendingWaitingOn = getDependancyValidArrr(null,"F",selectedDependenciesCache.WaitingOn)
      if(pendingWaitingOn?.length>0){
        setWaitingPopupFlag({records:pendingWaitingOn,flag:true,taskStatus:"Finish of this task is waiting on"});
        return;
      }
    }
    let resolveBlockingsArr=[];
    if(prevNode?.data?.state=="Start"){
      resolveBlockingsArr = getDependancyValidArrr(null,"S",selectedDependenciesCache.Blocking)
    }else if(nextNode?.data?.state=="Close"){
      resolveBlockingsArr = getDependancyValidArrr(null,"F",selectedDependenciesCache.Blocking)
    }

    let temp={ ...taskData, TaskFlowObject: data, 'TaskStatus': status }
    setTaskData(temp);
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if(taskData.hasOwnProperty('SubTaskROWID')){
      await AwsServerService.updateSubtask({ TaskStatus: status, ROWID: taskData.SubTaskROWID ,TaskStatusText:nextNode?.data?.label}).then(async (res) => {
        let StatusTimelineEdgePayload = {
          CREATEDBY: userInfo?.email_id,
          TaskStatus: edge,
          TaskROWID: taskData.SubTaskROWID,
          TaskType: "Sub-Task",
          IsTransition: 1,
          UTCTimeString: convertToUSTime(new Date()),
          Comment: comment
        }
        let StatusTimelinePayload = {
          CREATEDBY: userInfo?.email_id,
          TaskStatus: nextNode,
          TaskROWID: taskData.SubTaskROWID,
          TaskType: "Sub-Task",
          IsTransition: 0,
          UTCTimeString: convertToUSTime(new Date()),
          Comment: comment
        }

        AwsServerService.createTimeline(StatusTimelineEdgePayload).then(res => {
          AwsServerService.createTimeline(StatusTimelinePayload).then(res => {
            refreshStatusTimeline(!statusTimeline);
          }).catch(e => { })
        }).catch(e => { })

        updateBlockings(resolveBlockingsArr);
      }).catch(e=>{
        console.log(e);
      }).finally(e => {
        dispatch(Socket({ trigger: "refresh_activity", data: '' }))
      });
    }else{
      await AwsServerService.updateTask({TaskStatus:status,ROWID:taskData.TaskROWID,TaskStatusText:nextNode?.data?.label}).then(async (res)=>{
        let StatusTimelineEdgePayload = {
          CREATEDBY: userInfo?.email_id,
          TaskStatus: edge,
          TaskROWID:taskData.TaskROWID,
          TaskType:"Task",
          IsTransition:1,
          UTCTimeString:convertToUSTime(new Date()),
          Comment:comment
        }
        let StatusTimelinePayload = {
          CREATEDBY: userInfo?.email_id,
          TaskStatus: nextNode,
          TaskROWID:taskData.TaskROWID,
          UTCTimeString:convertToUSTime(new Date()),
          IsTransition:0,
          TaskType:"Task",
          Comment:comment
        }
        AwsServerService.createTimeline(StatusTimelineEdgePayload).then(res=>{
          AwsServerService.createTimeline(StatusTimelinePayload).then(res=>{
            refreshStatusTimeline(!statusTimeline);
          }).catch(e=>{})
        }).catch(e=>{})
        updateBlockings(resolveBlockingsArr);
      }).catch(e=>{
        console.log(e);
      }).finally(e => {
        dispatch(Socket({ trigger: "refresh_activity", data: '' }))
      });
    }
    setSelectedBlueprintData({...selectedBlueprintData,data:data})
    getBlueprintDataOptions(temp, temp.TaskStatus);
  }
  const handleDropDownChange = (e, data) => {
    if (data == "Account") {
      setAccountSelected(e);
      setDealSelected();
    } else if (data == "Project") {
      setDealSelected(e);
    } else {
      
      let temp = { ...taskData };
      if(data=="TaskFlowId"){
        let bluePrint = allBlueprintsData?.find(d=>d.ROWID==e);
        if(bluePrint){
          temp["TaskFlowObject"] = bluePrint;
          setSelectedBlueprintData({...selectedBlueprintData,data:bluePrint})
          let node = bluePrint?.Nodes?.find(k=>k.data.state=="Start");
          temp["TaskStatus"] = node?.id;
          let resolveBlockingsArr=[];
          if(node){
            resolveBlockingsArr = [...selectedDependenciesCache.Blocking,...selectedDependenciesCache.WaitingOn]
          }
          if (!isSubTaskView) {
            AwsServerService.updateTask({ TaskStatus:node?.id,TaskFlowId:bluePrint.ROWID,TaskFlowObject:bluePrint,ROWID: taskData.TaskROWID }).then(async (res) => {
              reopenDependancies(resolveBlockingsArr.filter(d=>d.IsResolved==1));
            }).catch(e=>{
              console.log(e);
            }).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });
          }else{
            AwsServerService.updateSubtask({ TaskStatus:node?.id,TaskFlowId:bluePrint.ROWID,TaskFlowObject:bluePrint,ROWID: taskData.SubTaskROWID }).then(async (res) => {
              reopenDependancies(resolveBlockingsArr.filter(d=>d.IsResolved==1));
            }).catch(e=>{
              console.log(e);
            }).finally(e => {
              dispatch(Socket({ trigger: "refresh_activity", data: '' }))
            });
          }
        }
        getBlueprintDataOptions(temp, temp.TaskStatus);
      }
      
      temp[data] = e;
      setTaskData(temp);
    }
  };
  const getAntSelectDropDown = (data) => {
    let options = data == "Project" ? allDealsData : allAccountsData;
    return (
      <Select
        disabled={
          (data == "Project" && accountSelected == "Miscellaneous") ||
          ((data == "Project" || data == "Account") && createPiplineModalState)|| taskModalState.action == "edit"
        }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={
          data == "Priority"
            ? "dropdown-create-task"
            : "dropdown-create-task header-dropdowns"
        }
        placeholder={data == "Account" ? "Select Account" : "Select Project"}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={
          data == "Account"
            ? accountSelected
            : data == "Project"
            ? dealSelected
            : taskData[data]
        }
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  useEffect(e=>{
    if(allBlueprintsData){
      let arr = allBlueprintsData?.map((item) => {
        return ({
          value: item?.ROWID,
          label: item?.Name,
          // key: item?.Name,
        });
      })
      setBluePrintDropDownOptions(arr);
    }
  },[allBlueprintsData])

  const getBlueprintsDropDown = (data) => {
    
    return (
      <Select
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dropdown-create-task header-dropdowns" }
        placeholder={"Select blueprint"}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={taskData[data]}
        options={BluePrintDropDownOptions}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  const checkWaitingDependencies = () => {
    let flag = false;
    let data = { ...selectedDependenciesCache };

    // Create a map of waitingOnTaskIds with TargetDependencyStatus
    const waitingOnTaskIdsWithStatus = data.WaitingOn.map(item => ({
        id: item.value,
        TargetDependencyStatus: item.TargetDependencyStatus,
        SourceDependencyStatus : item.SourceDependencyStatus
    }));

    let filteredDeps = [];
    for (const el of waitingOnTaskIdsWithStatus) {
        let type = el.id.split('_');
        let taskObject = allTasksAndSubtasks[type[0]].get(Number(type[1]));
        console.log(":Taskadatatatataat", taskObject)
        // Filter Edges based on both visited status and matching TargetDependencyStatus
        let Nodes = taskObject?.TaskFlowObject?.Nodes?.filter(e => 
             !e?.data?.visited
        ).filter(item=> item.data.label == el.TargetDependencyStatus) || [];
        // Proceed only if Edges with matching TargetDependencyStatus are found
        if (taskObject && Nodes && Nodes.length > 0) {
            taskObject.TargetDependencyStatus = el.TargetDependencyStatus; // Add the TargetDependencyStatus key directly
            filteredDeps.push(taskObject);
            flag = true;
        }
    }

    return { flag: flag, data: filteredDeps };
};

  const onChangeTransion= (e,comment) => {
    setSelectedButton(e);
      if(anotherSessionActiveFlag.Active){ 
        swal({
          title: 'Clock is running!',
          text: 'Do you want to stop the clock?',
          icon: 'warning',
          showCancelButton: true,  
          buttons: ['No', 'Yes'],   
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result) { 
            setTransitionChangeState(true); 
            setTimeout(() => {
              handleTransitionChange(e, taskData?.TaskFlowObject,comment);
            }, 200);
          } 
        });
      }else{
        handleTransitionChange(e, taskData?.TaskFlowObject,comment);
      }
    const taskNameElements = document.querySelectorAll('.swalDiv'); 
      taskNameElements.forEach(taskNameElement => {
          taskNameElement.addEventListener('click', (e) => {
              swal.close();  
              // let data2 = result.data.find(task => task === e.currentTarget.dataset.task); 
              let data2 = e.currentTarget.dataset.task;   
              // let tasksData = { ...allTasksAndSubtasks };
              let step=data2.split('_'); 
                  // const newData = tasksData[step[0]].get(Number(step[1]));
                  if(Number(step[1])){
                    setLoader(true);
                    if(step[0]=='Tasks'){
                      AwsServerService.getTaskById(Number(step[1])).then(res=>{
                        let k=res[0]?.taskInfo;
                        if(k){
                          handleHistoryAdd()
                          viewTaskFunction(k,"TASK");
                          setLoader(false);
                          // setIsSubTaskView(true);
                        }
                      }).catch(e=>{
                        setLoader(false);
                      })
                    }else{
                      AwsServerService.getSubTaskBySubTaskId(Number(step[1])).then(res=>{
                        let k=res[0]?.taskInfo;
                        if(k){
                          handleHistoryAdd()
                          viewTaskFunction(k,"SUBTASK");
                          setLoader(false);
                          // setIsSubTaskView(true);
                        }
                      }).catch(e=>{
                        setLoader(false);
                      })
                    }
                  }  
          });
    }) 
    
  }

  const handleConfirm = () => {
    handleTransitionChange(selectedButton, taskData?.TaskFlowObject)
    setPopConfirmVisible(false);
  }
  const handleCancel = () => {
    setPopConfirmVisible(false)
  }

  const showWarningModal = () =>{
    return (
      <AntModal
        title={<span style={{ fontSize: '18px', fontWeight: 'bold', color: '#5a6c97' }}>Waiting on Dependencies</span>}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        wrapClassName="Warning-Modal-Popup"
        footer={[<>
          <Popconfirm
          className="Do-Not-Close"
          overlayClassName="Do-Not-Close"
          open={popConfirmVisible}
          onConfirm={() => {
            handleConfirm();
            setTimeout(() => setIsModalVisible(false), 300); // Delay modal close to let Popconfirm update
          }}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
          title="Confirmation"
          description="Are you sure you want to move ahead?"
          icon={<></>}
          >
            <Button key="ignore" size="middle" onClick={()=>{
                setPopConfirmVisible(true)
              }}
              >
              Ignore
            </Button>
          </Popconfirm>
          <Button key="ok" size="middle" type="primary" onClick={handleModalOk}>
            OK
          </Button>,
          </>]}
      >
        {modalContent}
      </AntModal>
    )
  }

  const handleModalOk = () => {
    setIsModalVisible(false);
  };
  
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  
  const getStyleObj=(status)=>{
    let temp=mainStatusOptions?.find(k=>k.label==status);
    let sty={
      backgroundColor:"white",
      color:"black",
      border:"1px solid white"
    }
    if(temp && temp.color){
      sty["backgroundColor"]=temp.color;
      sty["border"]= `1px solid ${temp.color}`
      sty["color"]= invertColor(temp.color,true)
    }
    return sty;
  }
  const getStatusDropdown=(data,listtype) => {

    // let arr=[];
    // if(listtype=="reject"){
    //   arr=statusOptions?.filter(one=>one.color=="red")
    // }else{
    //   arr=statusOptions?.filter(one=>one.color!="red")
    // }
    const content = (
      <div className="Task-Status-Div">
        {mainStatusOptions?.map((one) => {
          return (
            <p
              style={getStyleObj(one.label)}
              className="Task-Status-P"
              onClick={() => {
                onChangeTransion(one.label)
              }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(mainStatusOptions.length==0){
      return (<Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: "#1677ff",
            borderColor: "#1677ff",
            color:"white",
          }}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >No Task Flow</Button>)
    }
    return (
      <Popover
        style={{ zIndex: 9999 }}
        // open={true}
        onOpenChange={(e) => {
          // setIsOpen(!isOpen);
        }}
        trigger="click"
        // overlayClassName={"Prject-Status-Container" }
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={getStyleObj(taskData.TaskStatus)}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >{taskData.TaskStatus}</Button>
      </Popover>
    )
    
  }
  const getBlueprintsStatuses = (data,listtype) => {

    let arr=[];
    if(listtype=="reject"){
      arr=statusOptions?.filter(one=>one.color=="red")
    }else{
      arr=statusOptions?.filter(one=>one.color!="red")
    }
    const content = (
      <div>
        {arr?.map((one) => {
          return (
            <p
              className="Task-Status-P"
              style={getStyleObj()}
              onClick={() => {
                // onSelect(one);
                onChangeTransion(one.label)
                // setSelected(!selected);
              }}
              // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(arr.length==0){
      return (
        <Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      )
    }
    return (
      <Popover
        style={{ zIndex: 9999 }}
        onOpenChange={(e) => {
        }}
        trigger="hover"
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      </Popover>
    )
    
  };

  const DueDateRangePopover = () => {
    return (
      <Popover
        overlayClassName="create-task-datepicker"
        content={
          <div className="due-date-range">
            <DateRangePicker
              onChange={changeDateRange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectionRange}
              direction="vertical"
              scroll={{ enabled: true }}
            />
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <Button
          id="setDueDate"
          className="SupervisorBtn"
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
          style={{ width: "100%" , color: "#5a6c97"}}
        >
          {`${dayjs(taskData?.StartDate).format("MM/DD/YYYY")} - ${dayjs(taskData?.DueDate).format("MM/DD/YYYY")}`}
        </Button>
      </Popover>
    );
  };
  const handleHistoryAdd=()=>{ 
    let history=[...taskHistory]
    history.push(taskData)
    setTaskHistory(history);
  }
  const handleBack = () => { 
    const previousHistory = [...taskHistory]; 
    const lastTask = previousHistory.pop();
    // let type=lastTask?.hasOwnProperty('SubTaskROWID')?'SUBTASK':'TASK';
    if(lastTask){
      if(selectedTable=='tasks'){
      if(lastTask?.hasOwnProperty('SubTaskROWID')){
        AwsServerService.getSubTaskBySubTaskId(lastTask.SubTaskId).then(res=>{
          let k=res[0]?.taskInfo;
          if(k){ 
            viewTaskFunction(k,"SUBTASK");
            setLoader(false);
            setTaskHistory(previousHistory) 
          }
        }).catch(e=>{
          setLoader(false);
        })
      }else{
        AwsServerService.getTaskById(lastTask.TaskId).then(res=>{
          let k=res[0]?.taskInfo;
          if(k){ 
            viewTaskFunction(k,"TASK");
            setLoader(false);
            setTaskHistory(previousHistory) 
          }
        }).catch(e=>{
          setLoader(false);
        })
      }
    }else{
      if(lastTask?.hasOwnProperty('SubTaskROWID')){
        AwsServerService.getSubTaskBySubTaskIdFromTemplate(lastTask?.SubTaskROWID).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            viewTaskFunction(k,"SUBTASK");  
          }
        }) 
      }else{
        AwsServerService.getTaskByIdFromTemplate(lastTask?.TaskROWID).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            viewTaskFunction(k,"TASK");  
          }
        }) 
      } 
    } 
    }
  };
  function openTaskFromDependencies(data){ 
    const [type,id]=data?.value?.split('_');
    setLoader(true)
    if(selectedTable=='tasks'){
    if(type=='Tasks'){
      AwsServerService.getTaskById(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"TASK");
          setLoader(false)
        }
      }).catch(e=>{
        setLoader(false);
      }) 
    }else{
      AwsServerService.getSubTaskBySubTaskId(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"SUBTASK");
          setLoader(false);
        }
      }).catch(e=>{
        setLoader(false);
      })   
    }
  }else{
      if(type=='Tasks'){
        AwsServerService.getTaskByIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"TASK"); 
            setLoader(false); 
          }
        }) 
      }else{
        AwsServerService.getSubTaskBySubTaskIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"SUBTASK"); 
            setLoader(false) 
          }
        }) 
      } 
    }
    setLoader(false); 
    setIsDependencyModalOpen(false);
  }
  function openTaskFromActivity(data){
    const [type,id]=[...data];
    setLoader(true)
    if(selectedTable=='tasks'){
    if(type=='Tasks'){
      AwsServerService.getTaskById(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"TASK");
          setLoader(false)
        }
      }).catch(e=>{
        setLoader(false);
      }) 
    }else{
      AwsServerService.getSubTaskBySubTaskId(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"SUBTASK");
          setLoader(false);
        }
      }).catch(e=>{
        setLoader(false);
      })   
    }
  }else{
      if(type=='Tasks'){
        AwsServerService.getTaskByIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"TASK"); 
            setLoader(false); 
          }
        }) 
      }else{
        AwsServerService.getSubTaskBySubTaskIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"SUBTASK"); 
            setLoader(false) 
          }
        }) 
      } 
    }
  }
  const handleCreateActivity = (sourceTaskType, message, ROWID, template,blockRefresh)=>{
    let type=sourceTaskType=='Tasks'?'TASK':'SUBTASK';
    if(template){
      type=sourceTaskType=='Tasks'?'TASKTEMPLATE':'SUBTASKTEMPLATE';
    }
    let payload={
      SourceId:ROWID,
      ActivityType:type,
      Message:message,
      ActivityOwner:userinfo.email_id
    }  
   AwsServerService.createActivity(payload).then(res=>{
    if(!blockRefresh){
      dispatch(Socket({trigger:"refresh_activity",data:''}))
    }
   }).catch(errMessage=>{
    console.log('Error saving Activity',errMessage);
   })
}
const handleSaveDependencies = async (data, deptype) => {
       
  let sourceTaskType = taskData.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
  let sourceId = taskData.hasOwnProperty('SubTaskROWID') ? taskData.SubTaskROWID : taskData.TaskROWID;
  if (!viewTaskData) {
    let obj = { ...selectedDependenciesCache };
    obj[deptype].push({ ...data, sourceValue: `${sourceTaskType}_${sourceId}` });
    setSelectedDependenciesCache(obj)
    setIsDependencyModalOpen(false);
    return;
  }
  try {
    setLoader(true);
     //logic to check already resolved dep
  let isResolvedDependancy = 0;
  let currentStatusOfSourceTask = taskData?.TaskFlowObject?.Nodes?.find(k => k.id == taskData.TaskStatus);
  let currentStatusOfTargetTask = data.taskData?.TaskFlowObject?.Nodes?.find(k => k.id == data?.taskData.TaskStatus);
  let tempTaskS;
  let tempTaskState;
  if(deptype=="WaitingOn"){
    tempTaskS = data.toStatus;
    tempTaskState = currentStatusOfTargetTask?.data?.state
  }else{
    tempTaskS = data.thisStatus;
    tempTaskState = currentStatusOfSourceTask?.data?.state
  }
  if (tempTaskS == "S" && tempTaskState != "Start") {
    isResolvedDependancy = 1;
  } else if (tempTaskS == "F" && tempTaskState == "Close") {
    isResolvedDependancy = 1;
  }
  // -- end -- logic to check already resolved dep
    if (selectedTable == 'tasks') {
      const [type, id] = data.value.split('_');
      const payload = {
        TargetTaskId: id,
        STaskType: sourceTaskType,
        SourceTaskId: sourceId,
        TaskType: type,
        DependancyType: deptype,
        IsResolved:isResolvedDependancy,
        CREATEDBY: localStorage.getItem("ZUID"),
        TargetDependencyStatus: data.TargetDependencyStatus,
        SourceDependencyStatus: data.SourceDependencyStatus,
      }
      return AwsServerService.createDependency(payload).then(async (e) => {

        let selections = [...selectedDependencies[deptype]];
        selections.push({ ...data, ROWID: e.data[0]?.insertId, sourceValue: `${sourceTaskType}_${sourceId}`,CREATEDBY: localStorage.getItem("ZUID") });
        // setSelectedDependenciesCache({
        //   ...selectedDependencies,
        //   [deptype]: selections,
        // }) 
        let dependencytype = deptype == 'Blocking' ? 'Blocking' : 'Waiting on';
        let message = `USER added ${dependencytype} dependency : where ${data.SourceDependencyStatus == "S" ? "Start" : "Finish"} of this 
          task is ${dependencytype} ${data.TargetDependencyStatus == "S" ? "Start" : "Finish"} of ${data.value}`
        handleCreateActivity(sourceTaskType, message, sourceId, null, true);
        let typeOfDep = dependencytype == 'Blocking' ? 'WaitingOn' : 'Blocking';
        const reversePayload = {
          TargetTaskId: sourceId,
          STaskType: type,
          SourceTaskId: id,
          TaskType: sourceTaskType,
          IsResolved:isResolvedDependancy,
          DependancyType: typeOfDep,
          CREATEDBY: localStorage.getItem("ZUID"),
          TargetDependencyStatus: data.SourceDependencyStatus,
          SourceDependencyStatus: data.TargetDependencyStatus,
        }

        await AwsServerService.createDependency(reversePayload).then(res => {
          let typ = typeOfDep == 'Blocking' ? 'Blocking' : 'Waiting on';
          let message2 = `USER added ${typ} dependency : where ${data.TargetDependencyStatus == "S" ? "Start" : "Finish"} of this 
          task is ${typ} ${data.SourceDependencyStatus == "S" ? "Start" : "Finish"} of ${sourceTaskType}_${sourceId}`
          handleCreateActivity(type, message2, id);
        }).catch((err) => swal('Failed', 'Error saving reverse dependency', 'Alert'))
      }).catch(err => swal('Failed', 'Error saving dependency', 'Alert')).finally(e => {
        setLoader(false);
        getAllDependenciesForTask(taskData?.hasOwnProperty('SubTaskROWID'), taskData?.hasOwnProperty('SubTaskROWID') ? taskData?.SubTaskROWID : taskData?.TaskROWID);
      })
    } else {
      const [type, id] = data.value.split('_');
      const payload = {
        TargetTaskId: id,
        STaskType: sourceTaskType,
        SourceTaskId: sourceId,
        TaskType: type,
        DependancyType: deptype,
        IsResolved:isResolvedDependancy,
        PipelineROWID: taskData.PipelineROWID,
        CREATEDBY: localStorage.getItem("ZUID"),
        TargetDependencyStatus: data.TargetDependencyStatus,
        SourceDependencyStatus: data.SourceDependencyStatus
      }
      return AwsServerService.createTempDependency(payload).then(async e => {

        let selections = [...selectedDependencies[deptype]];
        selections.push({ ...data, ROWID: e.data[0]?.insertId, sourceValue: `${sourceTaskType}_${sourceId}`,CREATEDBY: localStorage.getItem("ZUID") });
        // setSelectedDependenciesCache({
        //   ...selectedDependencies,
        //   [deptype]: selections,
        // })
        let dependencytype = deptype == 'Blocking' ? 'Blocking' : 'Waiting';
        let message = `USER added ${dependencytype} dependency : where ${taskData?.TaskFlowObject?.Nodes?.find(k => k.id == data.SourceDependencyStatus)?.data?.label} of this 
          task is ${dependencytype} ${data?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id == data.TargetDependencyStatus)?.data?.label} of ${data.value}`
        handleCreateActivity(sourceTaskType, message, sourceId, true, true);
        let typeOfDep = dependencytype == 'Blocking' ? 'WaitingOn' : 'Blocking';
        const reversePayload = {
          TargetTaskId: sourceId,
          STaskType: type,
          SourceTaskId: id,
          TaskType: sourceTaskType,
          DependancyType: typeOfDep,
          IsResolved:isResolvedDependancy,
          CREATEDBY: localStorage.getItem("ZUID"),
          TargetDependencyStatus: data.SourceDependencyStatus,
          SourceDependencyStatus: data.TargetDependencyStatus,
        }
        await AwsServerService.createDependency(reversePayload).then(res => {
          let typ = typeOfDep == 'Blocking' ? 'Blocking' : 'Waiting';
          let message2 = `USER added ${typ} dependency : where ${data?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id == data.TargetDependencyStatus)?.data?.label} of this 
          task is ${typ} ${taskData?.TaskFlowObject?.Nodes?.find(k => k.id == data.SourceDependencyStatus)?.data?.label} of ${sourceTaskType}_${sourceId}`
          handleCreateActivity(type, message2, id);
        });
      }).catch(err => swal('Failed', 'Error saving dependency', 'Alert')).finally(e => {
        setLoader(false);
        getAllDependenciesForTask(taskData?.hasOwnProperty('SubTaskROWID'), taskData?.hasOwnProperty('SubTaskROWID') ? taskData?.SubTaskROWID : taskData?.TaskROWID);
      });
    }
    } catch {

    }
  }

  function handleDeleteDependency(e,isWaiting,taskName){
      
    let [type,id]=e.value.split('_');
    if(!viewTaskData){
      setLoader(true);
      if(isWaiting=='waiting'){
        let selections=[...selectedDependenciesCache.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
        setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data})
      }else{
        let selections=[...selectedDependenciesCache.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});
        } 
        setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
      }
      return;
    }
    let sourceType=taskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let sourceId=taskData.hasOwnProperty('SubTaskROWID')?taskData.SubTaskROWID:taskData.TaskROWID; 
    if(selectedTable=='tasks'){
        
      AwsServerService.deleteDependency(sourceId, sourceType, id, type, e.SourceDependencyStatusId, e.TargetDependencyStatusId).then(r => {
        if (isWaiting == 'waiting') {
          let selections = [...selectedDependenciesCache.WaitingOn];
          let data = selections?.filter(t => t.ROWID != e.ROWID);
          if (data.length == 0) {
            setDropdownCount({ ...dropdowncount, 'WaitingOn': 0 });
          }
          setSelectedDependenciesCache({ ...selectedDependenciesCache, 'WaitingOn': data })
        } else {
          let selections = [...selectedDependenciesCache.Blocking];
          let data = selections?.filter(t => t.ROWID != e.ROWID);
          if (data.length == 0) {
            setDropdownCount({ ...dropdowncount, 'Blocking': 0 });;
          }
          setSelectedDependenciesCache({ ...selectedDependenciesCache, 'Blocking': data })
        }
        let dependencytype = isWaiting == 'waiting' ? 'Waiting on' : 'Blocking';
        let val = e.value?.split('_');
        let newData = allTasksAndSubtasks[val[0]].get(Number(val[1]));
        let message = `USER removed ${dependencytype} dependency : where ${e.SourceDependencyStatusId == "S" ? "Start" : "Finish"} of this 
            task was ${dependencytype} ${e.TargetDependencyStatusId == "S" ? "Start" : "Finish"} of ${e.value}`
        handleCreateActivity(sourceType, message, sourceId, null, true);
        let deptype = isWaiting == 'waiting' ? 'Blocking' : 'Waiting on';
        let targetType = newData.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
        let targetId = newData.hasOwnProperty('SubTaskROWID') ? newData.SubTaskROWID : newData.TaskROWID;
        let messages = `USER removed ${deptype} dependency : where ${e.TargetDependencyStatusId == "S" ? "Start" : "Finish"} of this 
            task was ${deptype} ${e.SourceDependencyStatusId == "S" ? "Start" : "Finish"} of ${sourceType}_${sourceId}`
        handleCreateActivity(targetType, messages, targetId);
      }).catch(e=>{
        console.log(e);
        
      }).finally(e => {
        setLoader(false);
        getAllDependenciesForTask(taskData?.hasOwnProperty('SubTaskROWID'), taskData?.hasOwnProperty('SubTaskROWID') ? taskData?.SubTaskROWID : taskData?.TaskROWID);
      })
    }else{
      AwsServerService.deleteTempDependency(sourceId, sourceType, id, type, e.SourceDependencyStatusId, e.TargetDependencyStatusId).then(r => {
        if (isWaiting == 'waiting') {
          let selections = [...selectedDependenciesCache.WaitingOn];
          let data = selections?.filter(t => t.ROWID !== e.ROWID);
          if (data.length == 0) {
            setDropdownCount({ ...dropdowncount, 'WaitingOn': 0 });
          }
          setSelectedDependenciesCache({ ...selectedDependenciesCache, 'WaitingOn': data })
        } else {
          let selections = [...selectedDependencies.Blocking];
          let data = selections?.filter(t => t.ROWID !== e.ROWID);
          if (data.length == 0) {
            setDropdownCount({ ...dropdowncount, 'Blocking': 0 });;
          }
          setSelectedDependenciesCache({ ...selectedDependenciesCache, 'Blocking': data })
        }
        let dependencytype = isWaiting == 'waiting' ? 'Waiting on' : 'Blocking';
        let val = e.value?.split('_');
        let newData = allTasksAndSubtasks[val[0]].get(Number(val[1]));
        let message = `USER removed ${dependencytype} dependency : where ${e.SourceDependencyStatusId == "S" ? "Start" : "Finish"} of this 
            task was ${dependencytype} ${e.TargetDependencyStatusId == "S" ? "Start" : "Finish"} of ${e.value}`
        handleCreateActivity(sourceType, message, sourceId, null, true);
        let deptype = isWaiting == 'waiting' ? 'Blocking' : 'Waiting on';
        let targetType = newData.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
        let targetId = newData.hasOwnProperty('SubTaskROWID') ? newData.SubTaskROWID : newData.TaskROWID;
        let messages = `USER removed ${deptype} dependency : where ${e.TargetDependencyStatusId == "S" ? "Start" : "Finish"} of this 
            task was ${deptype} ${e.SourceDependencyStatusId == "S" ? "Start" : "Finish"} of ${sourceType}_${sourceId}`
        handleCreateActivity(targetType, messages, targetId)
      }).catch(e=>{
        console.log(e);
        
      }).finally(e => {
        setLoader(false);
        getAllDependenciesForTask(taskData?.hasOwnProperty('SubTaskROWID'), taskData?.hasOwnProperty('SubTaskROWID') ? taskData?.SubTaskROWID : taskData?.TaskROWID);
      })
    }
  }
  // console.log("DealFields", DealFields)
  // const DependecyModal = () => {
  //   return (
  //     <>
  //       <AntButton
  //         onClick={() => {
  //           setIsDependencyModalOpen(true);
  //         }}
  //         color="primary"
  //         type="primary"
  //       >
  //         {(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0) ?`Dependencies`:`Add Dependency`}
  //       </AntButton>
  //       <AntModal
  //         open={isDependencyModalOpen}
  //         // open={true}
  //         wrapClassName={"Add-dependecny-Wrapper"}
  //         rootClassName="Add-dependecny-Root-Wrapper"
  //         draggable
  //         title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
  //         maskClosable={false}
  //         width={300}
  //         className=""
  //         style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
  //         onCancel={() => {
  //           setIsDependencyModalOpen(false); 
  //           let data={...selectedDependenciesCache};
  //           setSelectedDependencies(data); 
  //           setDropdownCount({WaitingOn:0,Blocking:0})
  //         }}
  //         footer={[<>
  //         <Button type="primary" onClick={()=>{
  //           handleSaveDependencies(selectedDependencies) 
  //         }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
  //         </>]}
  //       >
  //         <div className="add-dependency-wrapper-div">
  //           <div className="dependency-header"></div>
  //           <div className="dependency-modal-body-wrapper">
  //                 <Row>
  //                 <Col sm={2}>
  //                  <label htmlFor="status"><strong>Waiting on:</strong></label>
  //                 </Col>
  //                   <Col sm={10}>
  //                   <div className="waitingOndiv">
  //                   <div className="renderTasksDiv">
  //                     {
  //                       selectedDependencies.WaitingOn.map((e,index)=>{
  //                       let type=e.value.split('_');
  //                       let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
  //                         return <div key={index} className="innerRendertaskdiv">
  //                       <Row style={{padding: "3px"}}>
  //                         <Col sm={1}>  
  //                           {taskData?.TaskStatus=='Not Started'?<span>
  //                           <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
  //                           </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
  //                           <span>
  //                           <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
  //                           </span>:<span>
  //                           <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
  //                           </span>}
  //                         </Col>
  //                         <Col sm={9}>
  //                           <div style={{cursor: 'pointer'}} onClick={()=>{
  //                             openTaskFromDependencies(e);
  //                           }}>
  //                           {e.label}
  //                         </div>
  //                           </Col>
  //                           <Col sm={2} style={{textAlign:'right'}}> 
  //                           <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
  //                             let [type,id]=e.value.split('_');
  //                             console.log(type,id);
  //                           }}>
  //                          <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
  //                          onClick={()=>{ 
  //                           // let selections=[...selectedDependencies.WaitingOn];
  //                           // let data=selections?.filter(t=>t.label!==e.label);
  //                           // if(data.length==0){
  //                           //   setDropdownCount({...dropdowncount,'WaitingOn':0});
  //                           // } 
  //                           // setSelectedDependencies({...selectedDependencies,'WaitingOn':data}) 
  //                           handleDeleteDependency(e,'waiting');
  //                          }}
  //                          />
  //                           </span> 
  //                           </Col>
  //                           </Row>
  //                         </div>
  //                         }
  //                        )
  //                     }
  //                    </div>
  //                    {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
  //                   { dropdowncount.WaitingOn==1 && 
  //                   <div className="dropdownForWaiting">
  //                   <AntSelect
  //                   showSearch
  //                   size={"small"}
  //                   className="form-control"
  //                   popupClassName={"dependncy-list-dropdowns"}
  //                   placeholder={'Select Waiting On'}
  //                   filterOption={(input, option) =>
  //                     (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
  //                   }
  //                   onSelect={(e)=>{
  //                     let data=[...dropdownTasksDataCache]
  //                     let selections=[...selectedDependencies.WaitingOn];
  //                     selections.push(data?.filter(t=>t.value==e)[0]);
  //                     setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
  //                   }} 
  //                   value={null}
  //                   options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
  //                   />
  //                   </div>
  //                 }
  //                 </div>
  //                   </Col>
  //                   </Row>
  //                 <Row style={{ marginTop: '20px' }}>
  //                 <Col sm={2}>
  //                  <label htmlFor="status"><strong>Blocking :</strong></label>
  //                 </Col>
  //                   <Col sm={10}>
  //                   <div className="waitingOndiv">
  //                   <div className="renderTasksDiv">
  //                     {
  //                       selectedDependencies.Blocking.map((e,index)=>{
  //                         let type=e.value.split('_');
  //                         let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
  //                         return <div key={index} className="innerRendertaskdiv">
  //                       <Row style={{padding: "3px"}}>
  //                         <Col sm={1}>
  //                         {taskData?.TaskStatus=='Not Started'?<span>
  //                           <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
  //                           </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
  //                           <span>
  //                           <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
  //                           </span>:<span>
  //                           <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
  //                           </span>}
  //                         </Col>
  //                         <Col sm={9}>
  //                         <div style={{cursor: 'pointer'}} onClick={()=>{
  //                         openTaskFromDependencies(e);
  //                        }}>
  //                           {e.label}
  //                       </div>
  //                           </Col>
  //                           <Col sm={2} style={{textAlign:'right'}}> 
  //                           <span style={{cursor:'pointer', marginRight:"10px"}}>
  //                          <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
  //                          onClick={()=>{ 
  //                           handleDeleteDependency(e); 
  //                          }}
  //                          />
  //                           </span> 
  //                           </Col>
  //                           </Row>
  //                         </div>}
  //                        )
  //                     }
  //                    </div>
  //                    {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
  //                   { dropdowncount.Blocking==1 && 
  //                   <div className="dropdownForWaiting">
  //                   <AntSelect
  //                   showSearch
  //                   size={"small"}
  //                   className="form-control"
  //                   popupClassName={"dependncy-list-dropdowns"}
  //                   placeholder={'Select Blocking'}
  //                   filterOption={(input, option) =>
  //                     (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
  //                   }
  //                   onSelect={(e)=>{
  //                     let data=[...dropdownTasksDataCache]
  //                     let selections=[...selectedDependencies.Blocking];
  //                     selections.push(data?.filter(t=>t.value==e)[0]);
  //                     setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
  //                     // setDropdownTasksData(data?.filter(t=>t.value != e))
  //                   }} 
  //                   value={null}
  //                   options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
  //                   />
  //                   </div>
  //                 }
  //                 </div>
  //                   </Col>
  //                   </Row>
  //           </div>
  //         </div>
  //       </AntModal>
  //     </>
  //   );
  // };
  const [filteredFields, setFilteredFields] = useState(DealFields);

  const getLookUpData = (data, type, filedName) =>{
    if(data && typeof data == "object"){
      return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{(data.name)?.toUpperCase()}</span>
    }
    if(data && filedName == "Closing_Date"){
      return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{moment(new Date(data)).format("MM/DD/YYYY")}</span>
    }
    return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{typeof data == "string" ? data?.toUpperCase() : data}</span>
  }
  const [searchedValue, setSearchedValue] = useState('');
  const handleSearchLookUp = (value) => {
    const lowercasedValue = value?.toLowerCase();
    setSearchedValue(lowercasedValue);
    // Filter DealFields based on the field name or its corresponding data in lookUpDeal
    const filtered = DealFields.filter((one) => {  
      if(one.field == "Project_Status"){
        one.field = "Stage"
      }
      const fieldValue = one.field?.toLowerCase();
      let lookUpValue = '';
      if (lookUpDeal && lookUpDeal[one.field]) {
        if (typeof lookUpDeal[one.field] === "object") {
            // Handle if the value is an object and get the `name` property
            lookUpValue = (lookUpDeal[one.field].name || "")?.toLowerCase();
        } else if (typeof lookUpDeal[one.field] === "number") {
            // Convert numbers to string
            lookUpValue = lookUpDeal[one.field].toString();
        } else {
            // Handle as a string
            lookUpValue = lookUpDeal[one.field]?.toLowerCase();
        }
    }
      return (
        fieldValue?.includes(lowercasedValue) ||
        lookUpValue?.includes(lowercasedValue)
      );
    });

    setFilteredFields(filtered);
  };

  const AddLookUpFields = (label, value) => {
    let lookUpdata = { ...taskData };
      if(!lookUpdata["LookUpFields"]){
        lookUpdata["LookUpFields"] = [];
      }
      
      // If action is "edit", update API and state
      if (taskModalState.action === "edit") {
        const updatedArray = [...lookUpdata["LookUpFields"]? lookUpdata["LookUpFields"] : [], label];
        const data2 = {
          ROWID: lookUpdata.hasOwnProperty("SubTaskROWID") ? lookUpdata.SubTaskROWID : lookUpdata.TaskROWID,
          LookUpFields: updatedArray,
        };
        
        const updateService = lookUpdata.hasOwnProperty("SubTaskROWID") 
          ? AwsServerService.updateSubtask
          : AwsServerService.updateTask;
  
        updateService(data2)
          .then(() => {
            // setPageRefreshData(prev => !prev);
          })
          .catch(er => console.log("Er", er));
        
        lookUpdata["LookUpFields"] = updatedArray;
        setTaskData(lookUpdata);
  
      } else {
        lookUpdata["LookUpFields"].push(label);
        setTaskData(lookUpdata);
      }
  };
  
  const removeLookUpField = (label) => {
    let data = { ...taskData };
    const updatedArray = data["LookUpFields"].filter(item => item !== label);
  
    if (taskModalState.action === "edit") {
      const data2 = {
        ROWID: data.hasOwnProperty("SubTaskROWID") ? data.SubTaskROWID : data.TaskROWID,
        LookUpFields: updatedArray,
        // MODIFIEDBY: user.email_id,
      };
  
      const updateService = data.hasOwnProperty("SubTaskROWID") 
        ? AwsServerService.updateSubtask 
        : AwsServerService.updateTask;
  
      updateService(data2)
        .then(() => {
          // setPageRefreshData(prev => !prev);
        })
        .catch(er => console.log("Er", er));
        data["LookUpFields"] = updatedArray;
        setTaskData(data);
    }else{
      data["LookUpFields"] = updatedArray;
      setTaskData(data);
    }
  };
  
const getTableForLookupFields = () => {
  // Separate selected and non-selected fields
  let data = {...taskData}
  if(!data["LookUpFields"]){
    data["LookUpFields"] = [];
  }
  const selectedFields = filteredFields?.filter(one => data["LookUpFields"].some(item => item === one.field));
  const nonSelectedFields = filteredFields?.filter(one => !data["LookUpFields"].some(item => item === one.field));

  // Define function to render the list of fields
  const renderFields = (fields, isSelected) => (
      <div>
          {fields.map((one,ind) => {
              // if(one.field === "Project_Status"){
              //     one.field = "Stage";
              // }
              // console.log("uppercase", (one.field === "Stage" ? "Project Status" : one.field)?.replaceAll("_", " ")?.toUpperCase());
              
              return (
                  <Row sm={12} className="Look-Up-P" key={ind}>
                      <Tooltip color="rgb(54 54 54)" title={one.field}>
                          <Col sm={5}>
                              <span className="Elipsis-Class" style={{fontWeight:"600", color: "#5a6c97", fontSize: "12px"}}>
                                  {(one.field === "Stage" ? "Project Status" : one.field)?.replaceAll("_", " ")?.toUpperCase()}
                              </span>
                          </Col>
                      </Tooltip>
                      <Tooltip
                          color="rgb(54 54 54)"
                          title={getLookUpData(lookUpDeal && lookUpDeal[one.field == "Project_Status" ? "Stage": one.field], "tooltip",one.field)}
                      >
                          <Col sm={5}>
                              {one.field != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one.field == "Project_Status" ? "Stage": one.field], "table", one.field)}
                          </Col>
                      </Tooltip>
                      <Col sm={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <Button 
                            className="Look-Up-Add-Btn"
                              icon={isSelected ? <FontAwesomeIcon style={{ margin: "0px 7px" }} icon={faMinus} /> : <FontAwesomeIcon style={{ margin: "0px 7px" }} icon={faPlus} />}
                              size="middle" 
                              onClick={(e) => {
                                  e.stopPropagation();
                                  if (isSelected) {
                                      removeLookUpField(one.field);
                                  } else {
                                      AddLookUpFields(one.field);
                                    }
                              }}
                          />
                      </Col>
                  </Row>
              );
          })}
      </div>
  );

  const content = (
      <div className="Look-Up-Div">
          <div className="Look-Up-Header">
              <span className="Look-Up-Title" style={{ color: "#5a6c97" }}>Add Lookup Fields</span>
              <Search
                  placeholder="Search..."
                  size="small"
                  style={{ width: "100%", color: "#5a6c97", marginTop: "6px" }}
                  value={searchedValue}
                  allowClear
                  onChange={(e) => handleSearchLookUp(e.target.value)}
              />
          </div>

          {/* Render selected fields */}
          <div className="Selected-Fields">
              <h6 style={{ color: "#5a6c97", fontWeight: "bold" }}>Selected Fields</h6>
              {renderFields(selectedFields, true)}
          </div>

          {/* Render non-selected fields */}
          <div className="Non-Selected-Fields" style={{ marginTop: "10px" }}>
              <h6 style={{ color: "#5a6c97", fontWeight: "bold" }}>Available Fields</h6>
              {renderFields(nonSelectedFields, false)}
          </div>
      </div>
  );

  return (
      <Popover
          style={{ zIndex: 99990 }}
          onOpenChange={(open) => {
              handleSearchLookUp('')
              console.log("open-->", open)
          }}
          trigger="click"
          overlayClassName={"Look-Up-Container"}
          placement="top"
          content={content}
      >
          <Button 
          className="Add-Lookup-Icon" 
          icon={<FontAwesomeIcon style={{ marginRight: "7px" }} icon={faPlus} />} 
          onClick={(e) => e.stopPropagation()}></Button>
      </Popover>
  );
};


  const getStatusByFlow=async(viewTaskData,accepted)=>{
    let id=viewTaskData?.TaskFlowId; 
    let currentStatus= viewTaskData?.TaskStatus;
    if(id==undefined){
      swal('Alert','Task Not Saved Yet!','info')
      return
    }
    swal({
      title: "Warning!",
      text: `Are you sure you want to ${accepted?'Approve':'Reject'}?`,
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Proceed",
      },
    }).then(async(isTrue)=>{
      if(isTrue){
         await AwsServerService.getTaskFlowById(id).then(r=>{
           let data=r.data[0];
           if(data){
       let status=data[0].Nodes.filter(e=>e.data.label==currentStatus)[0];
       let next=getNextStepOfFlow(data[0].Nodes,data[0].Edges,status.id,accepted);
       let statusToSet=data[0].Nodes.filter(e=>e.id==next)[0];
       if(statusToSet){ 
        let temp = { ...taskData };
        temp["TaskStatus"] = statusToSet.data.label;
        setTaskData(temp);
      }
    }
  }) 
  }
});
}

  return (
    <div>
      {loader && <Loader/>}
      {showWarningModal()}
      {renderDuplicateModal(popUpType)}
      {customePopup()}
      <AntModal
        open={open}
        // open={true}
        wrapClassName={"Add-task-Wrapper"}
        rootClassName="Add-task-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          // history.replace('/project/Tasks/defaultView')
          setIsSubTaskView(false);
          setTaskData({ ...taskEmptyTemplate });
          setStatusOptions([]);
          setAccountSelected('')
          setDealSelected('')
          setTaskHistory([]);
          setOpen(false);
        }}
        footer={[<></>]}
      >
        <div className="Task-Modal-Wrapper">
          <div className="Task-Header">
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              { taskModalState.action == "edit" && <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon
                onClick={() => {
                  handleBack();
                }}
                style={taskHistory.length == 0 ? { cursor: 'not-allowed', color: 'lightgray' } : { cursor: 'pointer' }}
                icon={faArrowLeft}
              />
              </span>}
              {selectedTable!="pipelines" && taskModalState.action != "edit"?<><span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faUser} />
                <div>{getAntSelectDropDown("Account")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faFolder} />
                <div>{getAntSelectDropDown("Project")}</div>
              </span></>
              :
              <span>
                {selectedTable != "pipelines" && getHeaderForDeal(dealSelected)}
                {getBreadCrumbs(taskData)}           
                 </span>
                }
 
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faCodeBranch} />
                <div>{getBlueprintsDropDown("TaskFlowId")}</div>
                {selectedBlueprintData &&
                <Popover
                  overlayClassName="Task-Flow-View-Popup"
                  content={()=>(<Flow
                    showProgress={true}
                    disableMiniMap={true}
                    selectedData={selectedBlueprintData}
                    onSelectedData={()=>{

                    }}
                    onNodeSelect={(node) => {
                    }}
                    onEdgeSelected={(edge) => {
                    }}
                    saveCallback={(res) => {
                    }}
                  />)}
                  title={selectedBlueprintData?.data?.Name?selectedBlueprintData?.data?.Name:"Preview"}
                  trigger="click"
                  overlayStyle={{ width: '60vw', height: '60vh' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="view-flow-icon"
                    viewBox="0 0 512 512"
                  >
                    <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                  </svg>
                </Popover>}
              </span>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
              {taskModalState.action !== "create" && <Popover
                          content={renderSettingsPopoverContent()}
                          title=""
                          trigger="click"
                          open={popoverVisible}
                          onOpenChange={setPopoverVisible}
                          rootClassName="Duplicate-Modal"
                        >
                          <div
                            className="SidebarBtns"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                              <Button
                                style={{ border: "none" , height: '1.8rem', cursor: "pointer"}}
                                icon={<img src={SettingIconDark} alt="SettingIcon" style={{width:"17px"}}/>}
                              />
                            {/* <span>Settings</span> */}
                          </div>
                        </Popover>}
                <Button
                  type="primary"
                  onClick={() => {
                    handleSaveTask(taskData);
                  }}
                  style={{ height: "28px" }}
                >
                  {taskModalState.action == "edit"
                    ? "Update Task"
                    : "Save Task"}
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    if(history.location.pathname?.includes("project/Tasks/defaultView")){
                      history.replace('/project/Tasks/defaultView')
                    }else if(history.location.pathname?.includes("setting/pipeline")){
                      history.replace('/setting/pipeline')
                    }
                    setOpen(false);
                    setIsSubTaskView(false);
                    setDependencyRefresh(prev => !prev);
                    setTaskData({ ...taskEmptyTemplate });
                    setStatusOptions([]);
                    setTaskHistory([]);
                    setAccountSelected('');
                    setDealSelected('');
                    // comment.current = {ROWID:null,flag:false};
                    dispatch(Socket({trigger:"refresh_task",data:selectedTable}))
                  }}
                  style={{ height: "28px" }}
                ></Button>
              </div>
            </div>
          </div>
          <div className="Task-Header-Two">
            {[
             {
              label: "STATUS",
              component: (
                <>
                  <div className="Status-Wrapper-Class">
                    <TaskFlowStatus
                      type={"Status"}
                      activeSidebarObject = {sidebarOptions[activitySection]?.open}
                      statusOptions={mainStatusOptions}
                      onChangeStatus={(data)=>{onChangeTransion(data.label,data.comment)}}
                      taskData={taskData}
                      transitions={statusOptions}
                    />
                    </div>
                </>
              ),
            },
            {
                label: "SUPERVISOR",
                component: (
                  <UserPicker
                    values={taskData["Supervisor"] ? taskData["Supervisor"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Supervisor: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Supervisor: "" })}
                  />
                ),
              },
              {
                label: "ASSIGNEE",
                component: (
                  <UserPicker
                    mode="multiple"
                    values={taskData["Assignee"] ? taskData["Assignee"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Assignee: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Assignee: "" })}
                  />
                ),
              },
              {
                label: "PRIORITY",
                component: (
                  <Tooltip color="rgb(54 54 54)" title={taskData["Priority"]}>
                    <Select
                      className="priority-icon"
                      name="Priority"
                      placeholder="Select an option"
                      style={{ width: 200 }}
                      value={taskData["Priority"] ? taskData["Priority"] : "Not Set"}
                      onChange={(val) =>
                        setTaskData({ ...taskData, Priority: val })
                      }
                      optionLabelProp="icon"
                      rootClassName="table-dropdown"
                    >
                      {["Not Set", "Low", "Medium", "High"].map((priority) => (
                        <Option
                          key={priority}
                          value={priority}
                          icon={
                            <FlagFilled
                              style={{ color: getPriorityValue(priority) }}
                              className="Icon"
                            />
                          }
                        >
                          <FlagFilled
                            style={{ color: getPriorityValue(priority) }}
                            className="Icon"
                          />{" "}
                          {priority}
                        </Option>
                      ))}
                    </Select>
                  </Tooltip>
                  ),
              },
              {
                label: "TIME TRACKED",
                component: (<>
                <TimeTracker 
                checkRecordIfAvailable={checkRecordIfAvailable}
                  viewTaskData={taskData}
                  transitionChangeState={transitionChangeState}
                  setTransitionChangeState={setTransitionChangeState}
                  calculateTimeDifference={calculateTimeDifference}
                  open={open} 
                  anotherSessionActiveFlag={anotherSessionActiveFlag}
                  setAnotherSessionActiveFlag={setAnotherSessionActiveFlag}
                  users={userOptions} 
                  setLoader={setLoader}
                  allTasksAndSubtasks={allTasksAndSubtasks} 
                  viewTaskFunction={viewTaskFunction}
                  handleHistoryAdd={handleHistoryAdd}
                  setTimer={setTimer}
                  setStartTimer={setStartTimer}
                  setTimer2={setTimer2}
                  timer3={timer3}
                  formatTime={formatTime}
                  timer2={timer2}
                  timer={timer}
                  clockInterval={clockInterval}
                  startTimer={startTimer}
                  typeOfTask={typeOfTask}
                  setTimer3={setTimer3}
                  lastEntry={lastEntry}
                  setLastEntry={setLastEntry}
                  userList={userList}
                  setUserList={setUserList}
                  description={description}
                  setDescription={setDescription}
                  taskModalState={taskModalState}
                  />                  
                </>),
              },
              {
                label: "TIME ESTIMATE",
                component: (
                  <CustomTimeSelect
                    rootClassName={"Create-Task-TimePicker"}
                    className={"Create-Task-TimePicker-Class"}
                    placement={"top"}
                    taskData={taskData}
                    setTaskData={setTaskData}
                  />
                ),
              },
              {
                label: "START/DUE DATE",
                component: <DueDateRangePopover />,
              },
            ].map(({ label, component }) => (
              <div key={label}>
                <p className="Text-Center" style={label == "TIME ESTIMATE" ? {marginBottom : "2px"} : {}}>{label}</p>
                {component}
              </div>
            ))}
          </div>
          <div className="Task-Modal-Body-Wrapper">
            <div className="Task-Modal-Body-Child1">
              <Row className="Task-Modal-Body" sm={12}>
                <Col
                  className="Task-Info-Wrapper"
                  sm={sidebarOptions[activitySection] && taskModalState.action =="edit" && sidebarOptions[activitySection]?.open ? 7 : 12}
                >
                  <Row className="TaskNameWrapper margin-0">
                    <Input
                      className="Task-Name"
                      size="large"
                      placeholder="Task Name"
                      name="TaskName"
                      variant="borderless"
                      value={taskData["TaskName"]}
                      onChange={(e) => {
                        e.currentTarget.value = (e.currentTarget.value).trimStart();
                        let temp = { ...taskData };
                        temp["TaskName"] = e.currentTarget.value;
                        setTaskData(temp);
                      }}
                    />
                    <div className="Status-Wrapper-Class">
                    <TaskFlowStatus
                      statusOptions={mainStatusOptions}
                      onChangeStatus={(data)=>{onChangeTransion(data.label,data.comment)}}
                      taskData={taskData}
                      transitions={statusOptions}
                    />
                    </div>
                  </Row>
                  <div className="stickyDivWrapper">
                    <Row className="TaskDescriptionWrapper margin-0">
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={0}>DESCRIPTION</Badge>,
                            children: (
                              <TextArea
                                name="TaskDescription"
                                style={{ border: "none", color:"#5b6c97" }}
                                placeholder="Write description.."
                                value={taskData["TaskDescription"]}
                                onChange={(e) => {
                                  let temp = { ...taskData };
                                  temp["TaskDescription"] = e.currentTarget.value;
                                  setTaskData(temp);
                                }}
                              />
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <Col sm={7}>
                        <CustomeTaskField
                          name={"PIPELINE:"}
                          icon={<FontAwesomeIcon icon={faUser} />}
                        >
                          <Select
                            mode={""}
                            style={{ width: 200 }}
                            value={taskData["PipelineROWID"] ? taskData["PipelineROWID"] : null}
                            placeholder="Select Pipeline"
                            onChange={(val) => {
                              let temp = { ...taskData };
                              temp["PipelineROWID"] = val;
                              setTaskData(temp);
                            }}
                            options={allPipelinesData}
                          />
                        </CustomeTaskField>
                        <CustomeTaskField
                          name={"CREATED AT:"}
                          icon={<FontAwesomeIcon icon={faClock} />}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              opacity: "0.85",
                              padding: "12px",
                              fontSize: "13px"
                            }}
                          >
                            {taskData?.CREATEDTIME
                              ? dayjs(taskData?.CREATEDTIME).format("MM-DD-YYYY")
                              : ""}
                          </span>
                        </CustomeTaskField>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={(taskData?.InputFields && taskData?.InputFields.length>0?taskData?.InputFields.length:0)
                              +(taskData?.TaskFlowObject?.InputFields && taskData?.TaskFlowObject?.InputFields?.length>0?taskData?.TaskFlowObject?.InputFields?.length:0)}>INPUT FIELDS</Badge>,
                            rightComponent:<>
                              <InputFields
                                Module={"TASK"}
                                cardSize={3}
                                onAdd={(obj) => {
                                  let temp={...taskData};
                                  let arr = [];
                                  if (Array.isArray(temp?.InputFields) && temp?.InputFields?.length > 0) {
                                    arr = [...temp?.InputFields];
                                  }
                                  arr.push(obj);
                                  temp["InputFields"] = arr;
                                  setTaskData(temp);
                                }}
                              >
                                <Button className="Add-Input-Icon" icon={<FontAwesomeIcon style={{ marginRight: "7px" }} icon={faPlus} />} onClick={()=>{}}></Button>
                              </InputFields>
                            </>,
                            children: (
                              <>
                              <InputFieldsTable 
                              
                              onDelete={data=>{
                               if(data.Module=="TASK"){
                                 let temp={...taskData}
                                 temp["InputFields"]=temp.InputFields.filter(e=>e.id!=data.id);
                                 setTaskData(temp);
                               }
                                }} 
                              onChange={(fields) => {
                                 
                                  let temp = { ...taskData }
                                  let taskI = fields.filter(e => e.Module == "TASK")
                                  let flowI = fields.filter(e => e.Module != "TASK")
                                  temp["TaskFlowObject"]["InputFields"] = [...flowI];
                                  temp["InputFields"] = [...taskI];
                                  setTaskData(temp);
                                  getBlueprintDataOptions(temp, temp.TaskStatus);
                                }} readOnly={taskModalState.action!="edit"}  
                                Module={"TASK"} 
                                InputFields={taskData}
                                />
                             </>
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                     <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={taskData["LookUpFields"] && taskData["LookUpFields"].length>0?taskData["LookUpFields"].length:0}>LOOK UP FIELDS</Badge>,
                            rightComponent:<>
                              {getTableForLookupFields()}
                            </>,
                            children: (
                              <Row className="look-up-tile-container">
                                <div className="Look-Up-Div">
                                  {taskData["LookUpFields"] && taskData["LookUpFields"]?.map((one) => {
                                    return (
                                      <Row sm={12} className="Look-Up-P" style={{padding: "5px"}}>
                                        <Tooltip color="rgb(54 54 54)" title={one}>
                                          <Col sm={5}>
                                            <span className="Elipsis-Class" style={{fontWeight:"600", color: "#5a6c97", fontSize: "12px"}}>{(one == "Project_Status" ? "Stage": one).replaceAll("_", " ")?.toUpperCase()}</span>
                                          </Col>
                                        </Tooltip>
                                        <Tooltip
                                          color="rgb(54 54 54)"
                                          title={one != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one == "Project_Status" ? "Stage": one], "tooltip", one)}
                                        >
                                          <Col sm={5}>
                                            {one != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one == "Project_Status" ? "Stage": one], "table", one)}
                                          </Col>
                                        </Tooltip>
                                        <Col sm={2} style={{textAlign:"end", paddingRight:"3px"}}>
                                          <Button 
                                          className="Look-Up-Table-Btn"
                                          icon={<FontAwesomeIcon style={{ margin: "7px"}} icon={faMinus} />}
                                          size="small" 
                                          onClick={(e)=>{
                                              removeLookUpField(one);
                                          }}
                                          ></Button>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                                </Row>
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    {/* <Row
                      sm={12}
                      className="margin-15"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e4e4e4",
                      }}
                    >
                      <Col sm={7}>
                        <CustomeTaskField
                          name={"ATTACHMENTS:"}
                          icon={<FontAwesomeIcon icon={faPaperclip} />}
                        >
                          <Button
                            title="Upload attachments"
                            onClick={() => {
                                setAttachmentModal(!attachmentModal)
                            }}
                            // className="compose-btns"
                            style={{ position: "relative" , color:"#5a6c97"}}
                          >
                            Upload files
                          </Button>
                          <AttachmentModal
                            handleAttachementRemove={handleAttachementRemove}
                            setAttachmentModal={setAttachmentModal}
                            attachmentModal={attachmentModal}
                            handleUploadFiles={handleUploadFiles}
                            // data={props.dealObj}
                            fileNames={fileNames}
                            setFileNames={setFileNames}
                            uploadFiles={uploadFiles}
                          />
                        </CustomeTaskField>
                      </Col>
                      <Col sm={6}>
                      </Col>
                    </Row> */}
                    <div className="sticky-container">
                      <Row className="margin-15">
                        <Tabs
                          activeKey={activeTab}
                          onTabClick={(key) => {
                            setActiveTab(key);
                          }}
                          defaultActiveKey={isSubTaskView ? "2" : "3"}
                          items={[
                            {
                              label: (
                                <Badge
                                  color="blue"
                                  count={taskData?.SubTasks?.length}
                                  offset={[10, 0]}
                                >
                                  <span>Sub Tasks</span>
                                </Badge>
                              ),
                              key: "1",
                              disabled: isSubTaskView,
                              children: (
                                <SubTaskTab
                                  allPipelineData={allPipelineData}
                                  groupFilter={""}
                                  module={"View Task"}
                                  viewTaskFunction={(data,module) => {
                                    setActiveTab("2");
                                    handleHistoryAdd();
                                    viewTaskFunction(data,module);
                                  }}
                                  taskData={taskData}
                                  setOpen={setOpen}
                                  setTaskData={()=>{ 
                                    setIsSubTaskView(true);
                                    setTaskModalState({ module: "SUBTASK", action: "create" })
                                    let entryData = {...taskEmptyTemplate};
                                    entryData["TaskROWID"] = taskData.TaskROWID
                                    setTaskData(entryData)
                                  }}
                                  key={"Task Name"}
                                  selectedTable={selectedTable}
                                  allBlueprintsData={allBlueprintsData}
                                  subTaskData={subTaskData}
                                  setSubTaskData={setSubTaskData}
                                  isSubTaskView={isSubTaskView}
                                  setIsSubTaskView={setIsSubTaskView}
                                  setPageRefreshData={setPageRefreshData}
                                  refreshData={refreshData}
                                  handleCreateActivity={handleCreateActivity}
                                />
                              ),
                            },
                            {
                              label: (
                                <Badge color="blue" offset={[10, 0]}>
                                  <span>Check List</span>
                                </Badge>
                              ),
                              key: "2",
                              children: <Checklist taskData={taskData} selectedTable={selectedTable} activeTab={activeTab}/>,
                            },
                            {
                              label: (
                                <Badge color="blue" count={(selectedDependenciesCache?.Blocking?.length + selectedDependenciesCache?.WaitingOn?.length)} offset={[10, 0]}>
                                  <span>Dependency</span>
                                </Badge>
                              ),
                              key: "3",
                              children: <DependencyModal 
                              setLoader={setLoader}
                              taskData={taskData}
                              allDependencies={allDependencies}
                              selectedDependenciesCache={selectedDependenciesCache} 
                              getOptionsForDropdown={getOptionsForDropdown} 
                              handleDeleteDependency={handleDeleteDependency} 
                              allTasksAndSubtasks={allTasksAndSubtasks}
                              selectedDependencies={selectedDependencies}
                              dropdowncount={dropdowncount}
                              dealSelected={selectedTable=='pipelines'?'NoDeal':dealSelected}
                              setSelectedDependencies={setSelectedDependencies}
                              filteredDropdownTasksData={filteredDropdownTasksData}
                              openTaskFromDependencies={openTaskFromDependencies}
                              setDropdownCount={setDropdownCount}
                              dropdownTasksDataCache={dropdownTasksDataCache}
                              setDropdownTasksDataCache={setDropdownTasksDataCache}
                              handleSaveDependencies={handleSaveDependencies}
                              userOptions={userOptions}
                              statusOptions={mainStatusOptions}
                              openTaskData={taskData}
                              getAllDependenciesForTask={getAllDependenciesForTask}
                              handlecreateactivity={handleCreateActivity}
                              />,
                            },
                            {
                              label: (
                                <Badge color="blue" offset={[10, 0]}>
                                  <span>Attachments</span>
                                </Badge>
                              ),
                              key: "4",
                              children: <h1>Attachment</h1>,
                            },
                          ]}
                        />
                      </Row>
                    </div>
                  </div>
                </Col>
                {activitySection!=-1 && taskModalState.action=="edit" && sidebarOptions[activitySection]?.open  && (
                  <Col className="Task-Activity-Wrapper" sm={5}>
                    <div className="Activity-Header">
                      <span className="header">{sidebarOptions[activitySection]?.key}</span>
                    </div>
                    <div className="Activity-Body">
                      {/* {taskModalState.action == "edit" &&
                        sidebarOptions[activitySection]?.component(
                          isSubTaskView ? taskData?.SubTaskROWID : taskData?.TaskROWID ,
                          isSubTaskView,selectedTable=='pipelines', allTasksAndSubtasks,taskData,statusTimeline
                        )} */}
                      {taskModalState.action == "edit" &&
                        activitySection == 0 && <>
                          <ActivitySection taskId={isSubTaskView ? taskData?.SubTaskROWID : taskData?.TaskROWID}
                            task={taskData} module={(isSubTaskView && selectedTable == 'pipelines') ? "SUBTASKTEMPLATE" :
                              (!isSubTaskView && selectedTable == 'pipelines') ? "TASKTEMPLATE" :
                                (isSubTaskView && selectedTable !== 'pipelines') ? "SUBTASK" : "TASK"}
                            refreshData={refreshData}
                            allTasksAndSubtasks={allTasksAndSubtasks}
                            viewTaskFunction={openTaskFromActivity}
                          />
                        </>
                        }
                        {taskModalState.action == "edit" &&
                        activitySection == 1 && <>
                          <StatusTimeline task={taskData} refresh={statusTimeline} />
                        </>
                        }
                    </div>
                    <div className="Activity-Comment-Body">
                      {activitySection==0 && 
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: (
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,height:'3.5vh'}}>
                                <span>Comments</span>
                                <Button style={selectedTable!='tasks'?{pointerEvents:'none',color:'grey'}:{}} onClick={saveCommentForTask}>
                                  Send
                                </Button>
                              </div>
                            ),
                            children: (
                              // <TextArea
                              //   name="Comment"
                              //   style={{ border: "none" }}
                              //   placeholder="Write Comment.."
                              //   value={taskData["Comment"]}
                              //   onChange={(e) => {
                              //     setTaskData(result=>({...result,Comment:e.currentTarget.value}))
                              //   }}
                              //   rootClassName={''}
                              // />
                              <JoditTextEditor 
                                id={"transition"}
                                commentContent={taskData["Comment"]}
                                setEmailContent={(result1)=>{setTaskData(result=>({...result,Comment:result1}))}}
                              />
                            ),
                          },
                        ]}
                      />
                      
                      }
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="Task-Modal-Body-Child2">
              <div className="Task-Sidebar" sm={2}>
              {sidebarOptions &&
                  sidebarOptions.map((item, i) => {
                    if (item.key === "Settings") {
                      return taskModalState.action === "create" ? (
                        <div
                          className="mb-3 SidebarBtns"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "not-allowed",  // Change cursor to indicate non-clickable
                            opacity: 0.5,           // Optional: Make it look disabled
                          }}
                        >
                          {/* <Badge
                            showZero={false}
                            count={i !== 0 ? 0 : 1}
                            color="#ec4242d1"
                            offset={[0, 0]}
                          > */}
                            <Button
                              style={{ border: "none" }}
                              icon={item.icon}
                              disabled  // Disable button click
                            />
                          {/* </Badge> */}
                          <span>{item.key}</span>
                        </div>
                      ) : (
                        <Popover
                          content={renderSettingsPopoverContent()}
                          title=""
                          trigger="click"
                          open={popoverVisible}
                          onOpenChange={setPopoverVisible}
                          rootClassName="Duplicate-Modal"
                        >
                          <div
                            className="mb-3 SidebarBtns"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                              <Button
                                style={{ border: "none" }}
                                icon={item.icon}
                              />
                            <span>{item.key}</span>
                          </div>
                        </Popover>
                      );
                    }
                    return (
                      <div
                        className="mb-3 SidebarBtns"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          if (taskModalState.action === "create") {
                            e.preventDefault();
                            return;
                          }
                          let arr = [...sidebarOptions];
                          arr = arr.map((a, k) => {
                            // a.open=false;
                            if (i == k && a.open) {
                              a.open = false;
                            }else if(i == k && !a.open){
                              a.open = true;
                            }else{
                              a.open = false;
                            }
                            return a;
                          });
                          setSidebarOptions(arr);
                          setActivitySection(i);
                        }}
                      >
                        {/* <Badge
                          showZero={false}
                          count={i !== 0 ? 0 : 1}
                          color="#ec4242d1"
                          offset={[0, 0]}
                        > */}
                          <Button
                            style={{ border: "none" }}
                            disabled={taskModalState.action === "create"}
                            icon={item.icon}
                          />
                        {/* </Badge> */}
                        <span>{item.key}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default CreateTaskModal;
