import React from 'react'
import useOnClickOutside from 'use-onclickoutside'
import './emojipicker.css'
export const OtsideClickClosingWrapper = (props) => {
    const ref = React.useRef(null)
    const closeFunction=(e)=>{
        let c = e?.target?.closest('.chat-overlay');
        let s = e?.target?.closest('.swal-modal');
        let a = props.forceClose? null :e?.target?.closest('.Do-Not-Close');
        let isKey = e?.target?.closest(`.${props.avoidClasses}`);
        let k = e?.target?.closest('.create-user-group-icon');
        let t = e?.target?.closest('.list-icon');
        if(c || a || k || t || s || isKey){
            return
        }else{
            props.close(e)
        }
    }
    useOnClickOutside(ref, closeFunction)
    return <div ref={ref} {...props} >
        {props.children}
    </div>
}