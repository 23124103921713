import { faClock, faEye, faEyeSlash, faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import AwsServerService from '../../../common/util/AwsServerService';
import { Collapse, Modal, Popover, Spin, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Col, Row } from 'reactstrap';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import './StatusTimeline.css';
import { CCollapse } from '@coreui/react';
import { AppDataRequired } from '../../../App';
import { convertToUSTime } from '../../../components/HR/TimeClock/Util';
const { Panel } = Collapse;

export default function RenderTimeRecordsForTransition({allTimelineData,time,taskId,taskType}) {
    const [isPopupOpen,setIsPopupOpen]=useState(false);
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [userList,setUserList]=useState([]);
    const [expandedEntry, setExpandedEntry] = useState([]); 
    const [userOptions, setUserOptions] = useState([]);
    const { AllUsers } = useContext(AppDataRequired);
    useEffect(
      (e) => {
        if (AllUsers && AllUsers.length > 0) {
          let arr = AllUsers.map((d) => {
            return {
              label: d.full_name,
              value: d.zuid,
              subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
            };
          });
          setUserOptions(arr);
        }
      },
      [AllUsers]
    );
    function calculateTimeDifference(startTime, stopTime) { 
      const start = moment(startTime);
      const stop = moment(stopTime); 
      const duration = moment.duration(stop.diff(start)); 
      return duration.asSeconds();
    } 
    const formatTime = (timer) => {
      const getSeconds = `0${timer % 60}`.slice(-2);
      const minutes = `${Math.floor(timer / 60)}`;
      const getMinutes = `0${minutes % 60}`.slice(-2);
      const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
  
      return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
    };
    useEffect(e=>{
        if(isPopupOpen){
            setLoading(true);
              
            let nextTime='';
            let nextEntry=allTimelineData.find(k=>k.ActualUTCTime>time);
            if(nextEntry){
              // nextTime=moment(nextEntry.ActualUTCTime).subtract(1, 'seconds').format();
              // nextTime=convertToUSTime(nextTime)
            nextTime=nextEntry.ActualUTCTime;
            }else{
            const now = new Date();
            nextTime= convertToUSTime(now);
            }
            let type=taskType=='Task'?'Tasks':'SubTasks'
            AwsServerService.getTimeEntryByTime(time,taskId,type,nextTime).then(res=>{
                  
                let data=res.data[0];
                setLoading(false) 
                let obj = {};
                data.forEach(entry => {
                  if (obj[entry.CreatedBy]) {
                    obj[entry.CreatedBy].push(entry);
                  } else {
                    obj[entry.CreatedBy] = [entry];
                  }
                });
                let userLists = [];
                Object.keys(obj).forEach(e => {
                  let totalSec = 0;  
                  let info = userOptions.find(el => el.value == e); 
                  obj[e].forEach(record => {
                    if (record.StopTime != '-') { 
                      const { StartTime, StopTime } = record; 
                      totalSec += calculateTimeDifference(StartTime, StopTime);
                    }
                  })
                  let object = {
                    userName: info?.label,
                    userId: info?.value,
                    Entrys: obj[e],
                    TotalTime: formatTime(totalSec)
                  }
                  userLists.push(object);
                })  
                setUserList(userLists);  
            }).catch(er=>setLoading(false))
        }else{
          setUserList([]); 
        }
    },[isPopupOpen]) 
    
  const toggleEntry = (entry) => {
    if (expandedEntry.some(e => e.ROWID === entry.ROWID)) {
      setExpandedEntry(expandedEntry.filter(e => e.ROWID !== entry.ROWID));
    } else {
      setExpandedEntry([...expandedEntry, entry]);
    }
  };
    
    const popupForTimeTrackRecords=()=>{
        return (<div className='recordsPopupForTimeTracked'>
                 {loading ? <Spin className='mr-2' size='small' /> :userList.length==0?'No Records':<Collapse expandIconPosition='end'>
        {userList.map((user, index) => (
          <Panel
            header={
              <div className="timetracklist">
                <div className="timetracklist2">
                <img src={ "https://contacts.zoho.com/file?ID=" + user.userId + "&fs=thumb"} alt={'s Profile'} style={{height: '2rem', borderRadius: '1rem'}}/>
                <div>{user.userName}</div>
                <div>{user.TotalTime}</div>
                </div>
              </div>
            }
            key={user.userId}
            // showArrow={false}
          >
            <div className="time_track_scroll">
              {user['Entrys'].map((el, i) => (
                <Row className="no-gutters user-time-track" key={i}>
                  <Col sm={8}>
                    <ul className="list-unstyled">
                      <li>
                        {el.StartTime ? (
                          <FontAwesomeIcon icon={faPlayCircle} />
                        ) : (
                          <FontAwesomeIcon icon={faSlidersH} />
                        )}
                      </li>
                      <li>{el.TotalTime}</li>
                      <li>{moment(el.StartTime).format('MM-DD-YYYY')}</li>
                    </ul>
                  </Col>
                  <Col className="text-right" sm={4}>
                    <ul className="list-unstyled">
                    <li>
                      {expandedEntry.some(e => e.ROWID === el.ROWID) ? (
                        <FontAwesomeIcon icon={faEyeSlash} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      )}
                    </li> 
                    </ul>
                  </Col> 
                  {expandedEntry.some(e => e.ROWID === el.ROWID) && ( 
                      <CCollapse verticle visible className='View-Time-Collapse'>
                        <div className='View-Time-Ettry'>
                          <p><span>From:</span> {moment(el.StartTime).format('MM-DD-YYYY HH:mm:ss A')}</p>
                          <p><span>To:</span> {el.StopTime === '-' ? '-' : moment(el.StopTime).format('MM-DD-YYYY HH:mm:ss A')}</p>
                          {el.Description && <p>
                          <span style={{fontWeight:'700'}}>Description:</span> {<div style={{maxHeight: '5rem', overflow: 'auto',minWidth:'10.4rem'}}>
                            {el.Description}</div> }
                          </p>
                          }
                        </div>
                      </CCollapse>
                    )}
                </Row>
              ))}
            </div> 
          </Panel>
        ))}
      </Collapse>
             }</div>
        )
    }
  return (
    <Popover
            placement='top'
            open={isPopupOpen}
            overlayClassName='testClass'
            trigger={"click"}
            onOpenChange={e => {
              setIsPopupOpen(e)
            }}
            content={popupForTimeTrackRecords}
    >
        {/* {popupForTimeTrackRecords()} */}
        <FontAwesomeIcon icon={faClock} onClick={()=>setIsPopupOpen(true)}/>
    </Popover>
  )
}
