import { Row, Select, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./UserPicker.css";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Option } from "antd/es/mentions";
import { UserOutlined } from "@ant-design/icons";

export const SelectedUserAvatar = (props) => {
  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="CustomeUserTag"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      icon={
        value ? (
          <img
            style={{ cursor: "default" }}
            className="Icon"
            src={getUserThumbnailUrl(value)}
          />
        ) : (
          <UserOutlined className="Icon" style={{ border: "0px" }} />
        )
      }
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};
export const NewUserPicker = ({
  values,
  onChange,
  mode,
  className,
  style,
  onBlur,
  rootClassName,
  onClear,
}) => {
  const { AllUsers } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            icon: <FontAwesomeIcon icon={faTags} />,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        let emptyObj = {
          label: "None",
          value: "",
          icon: <FontAwesomeIcon icon={faTags} />,
        };
        // arr.unshift(emptyObj);
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );


  return (
    <Select
      mode={mode}
      value={values?values:null}
      style={style? {...style,width:"100%"}:{width:"100%"}}
      onChange={onChange}
      onBlur={onBlur}
      rootClassName={rootClassName}
      optionLabelProp="icon"
      allowClear={values !== ""}
      onClear={onClear}
    >
      {userOptions.map((o) => {
        return (
            <Option
            key={o.value}
            value={o.value}
            label={o.label}
            icon={
              <Tooltip color="rgb(54 54 54)" title={o.label}>
                <img
                  style={{
                    cursor: "pointer",
                    height: "19px",
                    width: "19px",
                    borderRadius: "50%",
                  }}
                  className="Icon"
                  src={
                    "https://contacts.zoho.com/file?ID=" +
                    o.value + // Use o.value here instead of values
                    "&fs=thumb"
                  }
                />
              </Tooltip>
            }
          >
            <Row>{SelectedUserAvatar(o)}</Row>
          </Option>          
        );
      })}
    </Select>
  );
};
