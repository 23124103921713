import React, { useContext, useEffect, useState } from 'react'
import './RenderAllPipelineTemplates.css';
import { Button, Checkbox, Collapse } from 'antd';
import { Col, Row } from 'reactstrap';
import AppContext from '../../Context/AppContext';
import AwsServerService from '../../common/util/AwsServerService';
import { TaskGroupCardForTemplate } from './TaskGroupCardForTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import _ from 'lodash';

const { Panel } = Collapse;
export const HeaderContent = ({ data, key  }) => {
  console.log(data[key]);

  return <Row sm={12}>
    <Col sm={6}>{data[key]}</Col>
    <Col sm={6}></Col>
  </Row>
}
export const RenderAllPipelineTemplates = ({allPipelineData,viewTaskFunction,refreshData, setPageRefreshData,
  group,setOpen,setViewPipelineData,selectedTable,isSubTaskView, setIsSubTaskView,setSelectedIdsForTemp, selectedIdsForTemp
}) => {
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [deals, setDeals] = useState([]);
  const [group1Data,setGrpoup1Data] = useState({});
  const [group2Data,setGrpoup2Data] = useState([]);
  const [allTempDependeniesData, setAllTempDependencies] = useState([]);
  const [dropdownTempTasksDataCache,setDropdownTempTasksDataCache]=useState([]);
  const [refreshDependency,setDependencyRefresh]=useState(false);
  const [dummyObj, setDummyObj] = useState({});
  const [groupFilter,setGroupFilter] = useState({
    group1 : {name:"Pipeline", value:"Pipeline" },
    group2 : {name:"Pipeline", value:"PipelineROWID" },
  })

  useEffect(e=>{
    if(selectedIdsForTemp?.Task?.length == 0 ){
      setDummyObj({})
    }
  },[selectedIdsForTemp])
   
  useEffect(e=>{
    setGroupFilter(group)
  },[group])
  useEffect(e=>{
    if(allPipelineData){
        let obj={};
        allPipelineData?.forEach(data=>{
          if(obj[data.PipelineROWID]){
            // let allTasks = allPipelineData.filter(p=>p.DealName==data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[data.PipelineROWID]=[...obj[data.PipelineROWID],...data.Tasks];
          }else{
            // let allTasks = allPipelineData.filter(p=>p.DealName==data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[data.PipelineROWID]=[...data.Tasks];
          }
        });
        // console.log("---------- new template11111 ",obj);
        setGrpoup1Data(obj);
    }
    
  },[groupFilter,allPipelineData])
  const [allTaskAndSubtask,setAllTasksAndSubtasks]=useState([]);
  const getTasksAndSubtasks = (data,task) => {
    const result = []; 
    const taskMap = new Map();
    const subtaskMap = new Map(); 
    data?.forEach(pipeline => {
      pipeline.Tasks?.forEach(task => { 
        taskMap.set(task.TaskROWID, task);
        result.push({
          label: task.TaskName,
          value: `Tasks_${task.TaskROWID}`
        }); 
        if (task.SubTasks) {
          task.SubTasks?.forEach(subtask => {
            subtaskMap.set(subtask.SubTaskROWID,subtask)
            result.push({
              label: subtask.TaskName,
              value: `SubTasks_${subtask.SubTaskROWID}`
            });
          });
        }
      });
    }); 
    // if(task){
      setAllTasksAndSubtasks({Tasks:taskMap,SubTasks:subtaskMap})
    // }else{
      // setAllTasksAndSubtasksTemp({Tasks:taskMap,SubTasks:subtaskMap})
    // }    
    return result; 
  };
  useEffect(()=>{   
    AwsServerService.getAllTempDependencies().then(r=>{
      let data=r.data[0];
      setAllTempDependencies(data)
    })
  },[refreshDependency,refreshData])
  useEffect(()=>{ 
    if(!_.isEmpty(allPipelineData)){
      let data=getTasksAndSubtasks(allPipelineData);  
      setDropdownTempTasksDataCache(data); 
    }
  },[allPipelineData]) 
  useEffect(e=>{
    handleViewPipeline(e);
  },[refreshData])

  const handleViewPipeline = (e,id) => {
    e?.preventDefault();
    e?.stopPropagation();
    let pipeline = allPipelineData.find(e=>e.PipelineROWID==id);
    if(pipeline){
      setViewPipelineData(pipeline);
      setOpen(true);
    }
  }
  useEffect(e => {
    setDeals(state.AllDeals)
    // console.log(state.AllDeals);

  }, [state.AllDeals])
  const getPipelineName=(id)=>{
    let pipeline = allPipelineData.find(e=>e.PipelineROWID==id);
    if(pipeline){
      return pipeline.PipelineName;
    }else{
      return "N/A"
    }
  }
  return (
    <div className='PiplinesList-Wrapper'>
      <Collapse defaultActiveKey={['1']} expandIconPosition="start" >
        {group1Data && Object.keys(group1Data)?.length>0 && Object.keys(group1Data)?.map((key, index) => {       
          return (
            <Panel
              className="Pipline-Panel-hover"
              header={
                <Row sm={12}>
                  <Col style={{ display: "flex", flexDirection: "row" }} sm={6}>
                        <Checkbox 
                          checked={dummyObj[key]?.length==group1Data[key].length}
                          className='pipeline-checkbox'
                          indeterminate={dummyObj[key]?.length > 0 && dummyObj[key]?.length !== group1Data[key].length}
                          onClick={(e) => {
                            e.stopPropagation();
                            let obj = { ...dummyObj };
                            const isIndeterminate = dummyObj[key]?.length > 0 && dummyObj[key]?.length !== group1Data[key].length;
                          
                            // Treat indeterminate checkbox as uncheck action
                            if (e.target.checked && !isIndeterminate) {
                              // Select All
                              let data = group1Data[key];
                              let array = data?.map(item => item?.TaskROWID);
                              setSelectedIdsForTemp(prev => ({
                                ...prev, 
                                Task: [...prev.Task, ...array]
                              }));
                              obj[key] = [...array];
                              setDummyObj(obj);
                            } else {
                              // Unselect All (including indeterminate)
                              let ids = obj[key];
                              delete obj[key];
                              setDummyObj(obj);
                              setSelectedIdsForTemp((prevSelectedIds) => ({
                                ...prevSelectedIds,
                                Task: prevSelectedIds.Task.filter(taskId => !ids.includes(taskId))
                              }));
                            }
                          }}
                         />
                    <span
                      style={{
                        backgroundColor: "rgb(91, 108, 151)",
                        color: "white",
                      }}
                      className="Group1-Card"
                    >
                      {getPipelineName(key)}
                    </span>
                    <Button
                      className="Pipeline-View-Btn"
                      type="primary"
                      icon={<FontAwesomeIcon icon={faEye} />}
                      ghost
                      onClick={(e) => handleViewPipeline(e, key)}
                    >
                      View
                    </Button>
                  </Col>
                  <Col sm={5}></Col>
                  <Col sm={1} className="Pipeline-Delete-Btn-Wrapper">
                    <FontAwesomeIcon
                      className="Pipeline-Delete-Btn"
                      onClick={(e) => {
                        // console.log("key", key);
                        e.stopPropagation();
                        swal({
                          title: "Warning!",
                          text: "Are you sure you want to delete the Pipeline?",
                          icon: "warning",
                          buttons: {
                            cancel: "Cancel",
                            confirm: "Proceed",
                          },
                        }).then(async (isDelete) => {
                          if (isDelete) {
                           await AwsServerService.deletePipelineFromTemplate(key)
                              .then((res) => {
                                console.log("Deleted Id");
                                setPageRefreshData(prev => !prev);
                              })
                              .catch((e) => {
                                console.log("error while deleting pipeline");
                              });
                          }
                        });
                      }}
                      icon={faTrashAlt}
                      style={{ color: "red" }}
                    />
                  </Col>
                </Row>
              }
              key={key}
              style={{ height: "auto" }}
            >
                    
              <TaskGroupCardForTemplate
                viewTaskFunction={viewTaskFunction}
                groupFilter={groupFilter}
                allPipelineData={allPipelineData}
                data={group1Data[key]}
                header={key}
                selectedTable={selectedTable}
                isSubTaskView={isSubTaskView}
                setIsSubTaskView={setIsSubTaskView}
                editData={[]}
                refreshData={refreshData}
                setPageRefreshData={setPageRefreshData}
                allDependeniesData={allTempDependeniesData} 
                dropdownTasksDataCache={dropdownTempTasksDataCache}
                selectedIdsForTemp={selectedIdsForTemp}
                setSelectedIdsForTemp={setSelectedIdsForTemp}
                allTaskAndSubtask={allTaskAndSubtask}
                setDependencyRefresh={setDependencyRefresh}
                setDummyObj={setDummyObj}
                dummyObj={dummyObj}
              />
            </Panel>
          );
        })}

      </Collapse>
    </div>
  )
}
