import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import { OtsideClickClosingWrapper } from "../../../common/EmojiReactionPicker/OtsideClickClosingWrapper";
import TextArea from "antd/es/input/TextArea";
import JoditTextEditor from "../../../common/JoditEditor/JoditTextEditor";

const SingleTransition = ({ button, customeClass ,disabled ,getStyleObj, onClickButton , onCloseCallback}) => {
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleCancel = () => {
    setPopConfirmVisible(false);
    setInputValue('');
  };
  const handleConfirm = () => {
      
    let data={...button,comment:inputValue}
    onClickButton(data);
    setPopConfirmVisible(false);
    onCloseCallback();
    setInputValue('');
  };
  return (
    <OtsideClickClosingWrapper
        avoidClasses={"transitionCommentPopup"}
        close={e=>{
            setPopConfirmVisible(false);
            onCloseCallback();
            setInputValue('');
        }}
    >
      <Popconfirm
        // disabled={inputField.Type != "PICKLIST"}
        key={button.id}
        className={"transitionCommentPopup"}
        overlayClassName={"transitionCommentPopup"}
        open={popConfirmVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        placement="topLeft"
        cancelText="No"
        title={<div>
          <span style={{fontWeight: "700"}}>Confirm transition change?</span>
           {/* <TextArea 
           placeholder="Write a transition comment ..." 
           style={{width: '21rem'}}
           value={inputValue}
           onChange={e=>setInputValue(e.currentTarget.value)}
           />  */}
            <JoditTextEditor 
              id={"transition"}
              setEmailContent={setInputValue}
            />
           </div>}
        // description=""
        icon={
          <QuestionCircleOutlined
            style={{
              color: "red",
            }}
          />
        }
      >
        <Tooltip overlayStyle={!disabled && {visibility:"hidden"}} style={!disabled && {visibility:"hidden"}} title={"There might be criteria applied."} >
          <Button
            // key={button.id}
            disabled={disabled}
            style={{ marginRight: "8px", ...getStyleObj(button),cursor:`${disabled?"not-allowed":"pointer"}`,opacity:`${disabled?0.5:1}`}}
            onClick={() => {
              setPopConfirmVisible(true);
              // onClickButton(button)
            }}
          >
            {button.label}
          </Button>
        </Tooltip>
      </Popconfirm>
    </OtsideClickClosingWrapper>
  );
};

export default SingleTransition;
