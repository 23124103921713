// CustomNode.js
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Handle } from 'reactflow';
import Loader from '../../../common/loader';

const CustomNode = ({ data,showProgress}) => {
  return (
    // <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', background: '#fff' }}>
    //   <div style={{ fontWeight: 'bold' }}><FontAwesomeIcon className='mr-1' color={data.color?data.color:"#fff5e0"} icon={faCircle}/>{data.label}</div>
    //   <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
    //     <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
    //     <Handle type="target" position="top" id="target1" style={{ background: '#555' }} />
    //     <Handle type="target" position="top" id="target2" style={{ background: '#555' }} />

    //     <Handle type="source" position="bottom" id="destination" style={{ background: '#555' }} />
    //     <Handle type="source" position="bottom" id="destination1" style={{ background: '#555' }} />
    //     <Handle type="source" position="bottom" id="destination2" style={{ background: '#555' }} />
    //     <Handle type="source" position="right" id="reject" style={{ background: '#f00' }} />
    //   </div>
    // </div>
    <div style={{ padding: '10px', minWidth: "100px" , border: '1px solid #ddd', borderRadius: '5px', background: data.disable? "rgb(236 236 236)" : '#fff',color: data.disable? "rgb(118 118 118)" : '#00000' }}>
      <div style={{ fontWeight: 'bold' }}>
        <FontAwesomeIcon className='mr-1' color={data.color ? data.color : "#fff5e0"} icon={faCircle}/>
        {data.label}
        {/* <Loader onlyIcon={true} width={"10px"} height={"10px"} /> */}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        {/* Bottom Source Handlers with 5px gap */}
       
        {data.state != "Start" && <>
          <Handle className='On-Hover-Show' type="target" position="top" id="target_16" style={{ background: '#555', left: '0%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target" style={{ background: '#555', left: '10%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_2" style={{ background: '#555', left: '20%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_3" style={{ background: '#555', left: '30%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_4" style={{ background: '#555', left: '40%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_5" style={{ background: '#555', left: '50%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_6" style={{ background: '#555', left: '60%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_7" style={{ background: '#555', left: '70%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_8" style={{ background: '#555', left: '80%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_9" style={{ background: '#555', left: '90%' , top: "-31px" }} />
          <Handle className='On-Hover-Show' type="target" position="top" id="target_17" style={{ background: '#555', left: '100%' , top: "-31px" }} />

          <Handle className='On-Hover-Show' type="target" position="left" id="target_15" style={{ background: '#555', left: "-14px",top: "-22px"}} />
          <Handle className='On-Hover-Show' type="target" position="left" id="target_13" style={{ background: '#555', left: "-14px",top: "-16px"}} />
          <Handle className='On-Hover-Show' type="target" position="left" id="target_11" style={{ background: '#555', left: "-14px",top: "-10px"}} />

          <Handle className='On-Hover-Show' type="target" position="right" id="target_10" style={{ background: '#555', right: "-14px",top: "-22px"}} />
          <Handle className='On-Hover-Show' type="target" position="right" id="target_12" style={{ background: '#555', right: "-14px",top: "-16px"}} />
          {/* <Handle className='On-Hover-Show' type="target" position="right" id="target_14" style={{ background: '#555', right: "-14px",top: "-10px"}} /> */}
        </>}
        {data.state != "Start" && data.state != "Close" && <>
          <Handle type="source" position="right" id="reject" style={{ background: '#f00',right: "-13px",top: "-7px"}} />
        </>}
       {data.state != "Close" && <>
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_15" style={{ background: '#555', left: '0%',top: "9px"  }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination" style={{ background: '#555', left: '10%',top: "9px"  }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_14" style={{ background: '#555', left: '20%',top: "9px"  }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_15" style={{ background: '#555', left: '30%',top: "9px"  }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_3" style={{ background: '#555', left: '40%',top: "9px"}} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_4" style={{ background: '#555', left: '50%',top: "9px"}} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_5" style={{ background: '#555', left: '60%',top: "9px" }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_6" style={{ background: '#555', left: '70%',top: "9px" }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_7" style={{ background: '#555', left: '80%',top: "9px" }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_8" style={{ background: '#555', left: '90%',top: "9px" }} />
          <Handle className='On-Hover-Show' type="source" position="bottom" id="destination_17" style={{ background: '#555', left: '100%',top: "9px" }} />

          {/* <Handle className='On-Hover-Show' type="source" position="right" id="destination_6" style={{ background: '#555', right: "-14px",top: "-4px"}} /> */}
          <Handle className='On-Hover-Show' type="source" position="right" id="destination_9" style={{ background: '#555', right: "-14px",top: "2px"}} />
          <Handle className='On-Hover-Show' type="source" position="right" id="destination_11" style={{ background: '#555', right: "-14px",top: "8px"}} />
         
          <Handle className='On-Hover-Show' type="source" position="left" id="destination_12" style={{ background: '#555', left: "-14px",top: "-4px"}} />
          <Handle className='On-Hover-Show' type="source" position="left" id="destination_13" style={{ background: '#555', left: "-14px",top: "2px"}} />
          <Handle className='On-Hover-Show' type="source" position="left" id="destination_14" style={{ background: '#555', left: "-14px",top: "8px"}} />
        </>}
        
      </div>
    </div>
  );
};

export default CustomNode;
