import React, { useEffect, useState } from 'react'
import './StatusTimeline.css'
import { Popover, Spin, Steps, Tooltip } from 'antd'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { invertColor, modifyTimezoneString } from '../../../components/HR/TimeClock/Util';
import AwsServerService from '../../../common/util/AwsServerService';
import { getDateDifference } from '../TaskUtil';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderTimeRecordsForTransition from './RenderTimeRecordsForTransition';
import DOMPurify from 'dompurify';

export const StatusTimeline = ({refresh,task}) => {
  const [loading, setLoading] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [allTimelineData, setAllTimelineData] = useState([]);
  const [activePopover, setActivePopover] = useState(null); 
  useEffect(e => {
    setLoading(true);
    let TaskTypeToCompare = task?.SubTaskROWID?"Sub-Task" : 'Task';
    let taskIdToCompare = task?.SubTaskROWID? task?.SubTaskROWID : task?.TaskROWID;
    AwsServerService.getAllTimelineBYIDANDTYPE(taskIdToCompare,TaskTypeToCompare,true).then(async res => {
      setAllTimelineData(res.data);
        
      console.log(task);
      let arr= res.data.map(element => {
        element.ActualUTCTime= element.UTCTimeString 
        element.UTCTimeString=modifyTimezoneString(element.UTCTimeString, "MMM DD YYYY hh:mm A")
        return element;
      })
      setTimelineData(arr);
      console.log("timeline Data arr",arr); 
      setTimeout(() => setLoading(false), 1000);
    }).catch(e => {
      console.log(e);
      setLoading(false)
    })
  }, [refresh])
  const handlePopover = (index) => {
    setActivePopover(activePopover === index ? null : index); // Toggle the popover
  };
  return (
    <div className='Status-Timeline-Wrapper'>
      {loading ? <div style={{
        display: "flex", width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }} ><Spin className='mr-2' tip="Loading" size='small' /></div>
        :
        <VerticalTimeline>
          {timelineData && timelineData.map((timeline, index) => {  
            return (<> {timeline.IsTransition==0?
              <VerticalTimelineElement
                className=""
                // contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
                date={timeline.UTCTimeString}
                iconStyle={timeline?.TaskStatus?.data?.color && { backgroundColor: timeline?.TaskStatus?.data?.color }}
                iconClassName='Status-Timeline-Icon-Class'
              // iconStyle={{width:"20px",height:"20px", backgroundColor:"red"}}
              >
                <div
                  className='Status'
                  style={timeline?.TaskStatus?.data?.color && {
                    backgroundColor: timeline?.TaskStatus?.data?.color,
                    color: invertColor(timeline.TaskStatus.data.color, true),
                    cursor:"default"
                  }}
                >
                  {timeline.TaskStatus.data.label}
                </div> 
                <div className='Duration'>
                {timeline.Comment && timeline.Comment!=''?
                <div className='renderTimeDiv'>
                  {timeline.Comment && timeline.Comment !== "" ? (
                    <Popover
                      content={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(timeline.Comment) }} />}
                      title=""
                      trigger="click"
                      open={activePopover === index}
                      onOpenChange={() => handlePopover(index)}
                      rootClassName='Transition-Comment-Popover'
                    >
                      <div
                        className="SidebarBtns"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon style={{ marginTop: "2px", color: "#a7a7a7" }} icon={faCommentAlt} />
                      </div>
                    </Popover>
                  ) : null}
                <RenderTimeRecordsForTransition allTimelineData={allTimelineData} time={timeline.ActualUTCTime} taskId={timeline.TaskROWID} taskType={timeline.TaskType}/></div>:
                <div className='renderTimeDiv' style={{marginTop:'3px', cursor : "default"}}><RenderTimeRecordsForTransition allTimelineData={allTimelineData} time={timeline.ActualUTCTime} taskId={timeline.TaskROWID} taskType={timeline.TaskType}/></div>}
                {getDateDifference(timeline.UTCTimeString,timelineData[index+1]?.UTCTimeString)}</div>
                {/* <p>
                  Creative Direction, User Experience, Visual Design, Project
                  Management, Team Leading
                </p> */}
              </VerticalTimelineElement>:
              <VerticalTimelineElement
                className=""
                iconStyle={timeline?.TaskStatus?.data?.color && { backgroundColor: timeline?.TaskStatus?.data?.color }}
                iconClassName='Status-Timeline-Icon-Class-Transition'
              // iconStyle={{width:"20px",height:"20px", backgroundColor:"red"}}
                icon={<Tooltip title={timeline?.TaskStatus?.label}><div className='Transition-Icon Elipsis-Class' style={timeline?.TaskStatus?.data?.color && { color: invertColor(timeline?.TaskStatus?.data?.color,true) }}>
                  {/* <span>{getDateDifference(timeline.UTCTimeString,timelineData[index-1]?.UTCTimeString)}</span> */}
                  {timeline?.TaskStatus?.label}
                </div></Tooltip>}
              />
            }
              </>
            )
          })}


        </VerticalTimeline>}
    </div>
  )
}
