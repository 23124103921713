import React from 'react'
import './CustomeTableComponent.css'
import { Col, Row } from 'reactstrap'

export const CustomeTableComponent = ({firstHeaderLabel,Headers,data,enableInlineAdd,children,}) => {
    const getRowCallBack = (data) => {
        return Headers.map((col, index) => {            
            return <td className={`${col.label=="Action" && "custom-stick custome-border-left"} ${index==0?"connecting-task-1 custom-stick custome-border-right":""}`}>
              {col.render(data, col.field)}
              </td>
        })
    }
    const getHeaders = () => {
        return Headers.map((col, index) => {
            return <th
            className={`${col.label=="Action" && "custom-stick custome-border-left"} ${
              index === 0
                ? "min-width-250 custome-border-right task_table_header task-card-title"
                : col.label === "Assignee" ||
                  col.label === "Supervisor" ||
                  col.label === "Priority"                 
                ? "column-header min-width-100" :
                col.label === "Time Estimate" ?
                "column-header min-width-120"
                : "column-header"
            }`}
            style={index == 0 ? {justifyContent:"center"} : {}}
             >{index == 0 && firstHeaderLabel ? firstHeaderLabel : col.label}
            </th>
        })
    }
  return (
    <div className='Dependency-Table-Wrapper'>
        <Col sm={12} className='TaskGroup2Card'>
            <Row sm={12}>
                <table className='table Custome-Table task-content-table'>
                    <thead className=''>
                        {getHeaders(Headers)}
                    </thead>
                    <tbody>
                        {data?.map((row, index) => {                       
                            return <>
                                <tr style={row.IsResolved==1?{backgroundColor:"#deffec"}:{}} key={index} className='showCheckBox'>
                                    {getRowCallBack(row)}
                                </tr>
                            </>
                        })}
                        <>{enableInlineAdd && children}</>
                    </tbody>
                </table>
            </Row>
        </Col>
    </div>
  )
}
