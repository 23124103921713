import { Row, Select, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./UserPicker.css";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Option } from "antd/es/mentions";
import { UserOutlined } from "@ant-design/icons";
import assigntask from "../../assets/images/svg/assigntask.svg";

export const SelectedUserAvatar = (props) => {
  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tooltip title={label}>

        <Tag
        className="CustomeUserTag Justify-Centre-Class"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        icon={
            value ? (
            <img
                style={{ cursor: "default" }}
                className="Icon"
                src={getUserThumbnailUrl(value)}
            />
            ) : (
            <UserOutlined className="Icon User-Outline-Class" style={{ border: "0px" }} />
            )
        }
        style={{ marginInlineEnd: 4 }}
        >
        {/* {label} */}
        </Tag>
    </Tooltip>
  );
};
export const UserPicture = ({
  values,
}) => {
  const { AllUsers } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            icon: <FontAwesomeIcon icon={faTags} />,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        let emptyObj = {
          label: "None",
          value: "",
          icon: <FontAwesomeIcon icon={faTags} />,
        };
        arr.unshift(emptyObj);
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );

    return <>
        {userOptions?.find(k=>k.value==values) && SelectedUserAvatar(userOptions?.find(k=>k.value==values))}
    </>
};
