import React, { useContext, useEffect, useState } from "react";
import { Select as AntSelect, Button, Input, Popconfirm, Popover, Select, Tooltip } from "antd";
import "./DependencyModal.css";
import { faCheckCircle, faCircle, faClock, faExclamationCircle, faEye, faMinusCircle, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import swal from "sweetalert";
import { CaretRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import assigntask from "../../../assets/images/svg/assigntask.svg";
import { invertColor } from "../../../components/HR/TimeClock/Util";
import { CreateDependencyPopover } from "./CreateDependencyPopover/CreateDependencyPopover";
import { checkSingleBackflowPath, getUserProfileUrl, hasInvalidDependency } from "../TaskUtil";
import { NewCustomePopover } from "../../../components/TaskBluePrints/HelperComponents/NewCustomePopover";
import AwsServerService from "../../../common/util/AwsServerService";
import { CustomeTableComponent } from "./DependencyTable/CustomeTableComponent";
import {
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AppDataRequired } from "../../../App";
import { CustomePopConfirm } from "./CustomePopConfirm";

const {Search} = Input;

export default function DependencyModal({
  allDependencies,
  setLoader,
  userOptions,taskData,
  handleSaveDependencies,
  dropdownTasksDataCache,
  setDropdownCount,
  openTaskFromDependencies,
  getOptionsForDropdown,dealSelected,
  selectedDependenciesCache,
  handleDeleteDependency,
  allTasksAndSubtasks,
  selectedDependencies,
  dropdowncount,
  filteredDropdownTasksData,
  openTaskData,
  getAllDependenciesForTask,
  handlecreateactivity
}) {
  const { AllUsers } = useContext(AppDataRequired);
  const [collapsed, setCollapsed] = useState({
    WaitingOn: false,
    Blocking: false,
  });
  const [waitingOndata, setWaitingOndata] = useState({
    WaitingOn: [],
    Blocking: [],
  });
  // const [allDependencies,setAllDependencies] = useState([]);
  const onChangeDependancyType = (e, data, dType) => {
     
    let arr = e.split("_");
    let obj = {
      ...data,
      thisStatus: arr[0],
      task: data,
      toStatus: arr[1],
    }
    handleUpdateDependancy(dType,obj)
     
  }
  const DependanciesHeaders = [
    {
      label: "Task Name",
      field: "taskName",
      render: (data, field) => {
        return (
          <Row className="D-Task-Name" style={{
            display: "flex",
            height: "41px",
            alignItems: "center",
            "justify-content": "center",
            "width":"100%",
            cursor: "pointer"
          }} sm={12}
          onClick={e=>{
            openTaskFromDependencies(data);
          }}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
                "justify-content": "center",
                "width":"100%",
                gap: "10px",
              }}>
               {data.IsResolved==1 && <FontAwesomeIcon style={{
                position:"absolute",
                left:"0px",
              }} className="ml-2" color={data.IsResolved==1?"green":"#ffce00"} icon={faCheckCircle} />} 
              <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}><span className='Elipsis-Class Highlight-Class' style={{ width: "150px" }}>{data[field]}</span></Tooltip>
            </Col>
          </Row>
        );
      }
    },
    {
        label: "Dependency type",
        field: "Assignee",
      render: (data, field) => {
        return (
          // <><span>{data?.SourceDependencyStatusId+data?.TargetDependencyStatusId}</span></>
          <Select onChange={e=>{
            onChangeDependancyType(e,data,data.DependancyType);
          }} value={data?.SourceDependencyStatusId+"_"+data?.TargetDependencyStatusId}>
            <Select.Option value={"F_F"}>FF</Select.Option>
            <Select.Option value={"F_S"}>FS</Select.Option>
            <Select.Option value={"S_S"}>SS</Select.Option>
            <Select.Option value={"S_F"}>SF</Select.Option>
          </Select>
        )
        }
    },
    {
      label: "Task Status",
      field: "IsResolved",
    render: (data, field) => {
       
      let Status = data["data"]?.TaskFlowObject?.Nodes?.find(n=>n.id==data.TaskStatus);
      return (
      //  <span style={data[field]==1 ? {fontWeight:"bold",color:"green"}:{}}>{data[field]==1?"Resolved":"Pending"}</span>
       <span
          style={{
            padding: "3px",
            display: "flex",
            height: "32px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Status.data.color,
            borderRadius: "5px",
            color: invertColor(Status.data.color,true),
          }}
       >{Status?.data?.label}</span>
      )
      }
    },
    {
        label: "Created By",
        field: "CREATEDBY",
        render: (data, field) => {
          let user = AllUsers?.find(u => u.zuid === data[field])
          return (
            <>
              <span>
                <Tooltip title={user?.full_name}>
                  <img
                    style={{
                      height: "31px",
                      "border-radius": "32px"
                    }}
                    className="User-Image"
                    src={getUserProfileUrl(data[field])}
                  />
                </Tooltip>
              </span>
            </>
          )
        }
    },
    {
        label: "Modified By",
        field: "MODIFIEDBY",
        render: (data, field) => {
           
          let user = AllUsers?.find(u => u.zuid === data[field])
          return (
            <>
              <span>
               {data[field] ? <Tooltip title={user?.full_name}>
                  <img
                    style={{
                      height: "31px",
                      "border-radius": "32px"
                    }}
                    className="User-Image"
                    src={getUserProfileUrl(data[field])}
                  />
                </Tooltip>:"-"}
              </span>
            </>
          )
        }
    },
    {
        label: "Action",
        field: "Action",
      render: (data, field) => {
        return (
          <>
            <CustomePopConfirm
              className="Do-Not-Close"
              overlayClassName="Do-Not-Close"
              callBackData={data}
              onConfirm={(e) => {
                 
                handleDeleteDependency(e,e?.DependancyType=="WaitingOn"?"waiting":"")
              }}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
              trigger={"click"}
              title="Confirmation"
              description="Are you sure you want to delete?"
              icon={<QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }}
                // onClick={()=>handleDeleteDependency(data,data.DependancyType=="WaitingOn"?"waiting":"")} 
                color="red" icon={faTrashAlt} />
            </CustomePopConfirm>
          </>
        )
        }
    },
    
]
  
  const [clear,setClear] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState({Blocking : false, Waiting : false});
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [innerPopoverOpen, setInnerPopoverOpen] = useState(false); // for nested popover control
  const [dropDownData, setDropDownData] = useState([...dropdownTasksDataCache]);
  const [searchText, setSearchText] = useState("");
  const [errorPopConfirm, setErrorPopConfirm] = useState();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [currentTask, setCurrentTask] = useState(openTaskData || {});
  let currentObject = currentTask?.TaskFlowObject?.Nodes?.find(e=>e.id==currentTask.TaskStatus);
  const [sourceTaskSelect, setSourceTaskSelect] = useState(currentObject); // Default label

  useEffect(e=>{
    if(openTaskData){
      setCurrentTask((prev) => ({ ...prev, ...openTaskData }))
    }
  }, [openTaskData])
  
  const toggleCollapse = (section,length,e) => {
    // if(length==0){
    //   e.preventDefault();
    //   return;
    // }
    setCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    setDropdownCount({ ...dropdowncount, [section]: 0 });
  };

  useEffect(() => {
    let depsObject = { WaitingOn: [], Blocking: [] };
    selectedDependencies.WaitingOn.map((e, index) => {  
      let type = e?.value?.split("_");
      let type2 = e?.sourceValue?.split("_");
      let taskData = allTasksAndSubtasks[type[0]].get(Number(type[1]));
      let sourceTaskData = allTasksAndSubtasks[type2[0]].get(Number(type2[1]));
      let id=type[0]=='Tasks'?taskData?.TaskId:taskData?.SubTaskId;
      depsObject.WaitingOn.push({
        taskName: taskData?.TaskName,
        CREATEDBY:e.CREATEDBY,
        MODIFIEDBY:e.MODIFIEDBY,
        assignee: taskData?.Assignee,
        DependancyType: e.DependancyType,
        priority: taskData?.Priority,
        status: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==taskData?.TaskStatus)?.data?.label,
        TaskStatus: taskData?.TaskStatus,
        TargetDependencyStatus: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==e.TargetDependencyStatus)?.data?.label,
        SourceDependencyStatus: sourceTaskData?.TaskFlowObject?.Nodes?.find(k=>k.id==e.SourceDependencyStatus)?.data?.label,
        SourceDependencyStatusId:e.SourceDependencyStatus,
        TargetDependencyStatusId:e.TargetDependencyStatus,
        data: taskData,
        sourceValue: e?.sourceValue,
        value:e?.value,
        ROWID:e?.ROWID,
        id:e?.id?e?.id:id,
        IsResolved:e.IsResolved
      });
    });
    selectedDependencies.Blocking.map((e, index) => {  
      let type = e?.value?.split("_");
      let type2 = e?.sourceValue?.split("_");
      let taskData = allTasksAndSubtasks[type[0]].get(Number(type[1]));
      let sourceTaskData = allTasksAndSubtasks[type2[0]].get(Number(type2[1]));
      let id=type[0]=='Tasks'?taskData?.TaskId:taskData?.SubTaskId;
      depsObject.Blocking.push({
        taskName: taskData?.TaskName,
        CREATEDBY:e.CREATEDBY,
        MODIFIEDBY:e.MODIFIEDBY,
        assignee: taskData?.Assignee,
        DependancyType: e.DependancyType,
        priority: taskData?.Priority,
        status: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==taskData?.TaskStatus)?.data?.label,
        TaskStatus: taskData?.TaskStatus,
        TargetDependencyStatus: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==e.TargetDependencyStatus)?.data?.label,
        SourceDependencyStatus: sourceTaskData?.TaskFlowObject?.Nodes?.find(k=>k.id==e.SourceDependencyStatus)?.data?.label,
        SourceDependencyStatusId:e.SourceDependencyStatus,
        TargetDependencyStatusId:e.TargetDependencyStatus,
        data: taskData,
        sourceValue: e?.sourceValue,
        value:e?.value,
        ROWID:e?.ROWID,
        id:e?.id?e?.id:id,
        IsResolved:e.IsResolved
      });
    });
    setWaitingOndata(depsObject);
  }, [selectedDependencies]); 
  const getCurrentDependencyStatusOptions = () => {
    let renderOptions = [];
    if(currentTask.TaskFlowObject){
      renderOptions = currentTask.TaskFlowObject.Nodes.filter(e=> !e?.data?.visited)
    }
    const content = (
      <div className="Task-Status-Div">
          {renderOptions && renderOptions.map((one, idx) => (
              <p
                  key={idx}
                  className="Task-Status-P"
                  style={{
                    background: one.data.color === "grey" || !one.data.color ? "#808080" : one.data.color,
                    color: invertColor(one.data.color === "grey" || !one.data.color ? "#808080" : one.data.color, true)
                    }}
                  onClick={() => {
                    setSourceTaskSelect(one);
                  }}
              >
                  {one.data.label}
              </p>
          ))}
      </div>
  );
    return (
      <Popover
        style={{ zIndex: 9999 }}
        onOpenChange={(e) => {
        }}
        trigger="click"
        placement="bottomLeft"
        content={content}
      >
        <Button
          size="small"
          style={{
            maxWidth: "150px", // Set max width for ellipsis to take effect
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            background: sourceTaskSelect?.data?.color === "grey" || !sourceTaskSelect?.data?.color ? "#808080" : sourceTaskSelect?.data?.color,
            color: invertColor(sourceTaskSelect?.data?.color === "grey" || !sourceTaskSelect?.data?.color ? "#808080" : sourceTaskSelect?.data?.color, true)
          }}
          className="Elipsis-Class Status-Change-Dependency"
        >
          {sourceTaskSelect?.data?.label}
        </Button>
      </Popover>
    );
  };

  const getDependencyStatusOptions = (option, type, index) => {
    const renderOptions = option.taskData?.TaskFlowObject?.Nodes.filter(e => !e?.data?.visited) || [];

    const content = (
      <div className="Task-Status-Div">
          {renderOptions.map((one, idx) => (
              <p
                  key={idx}
                  className="Task-Status-P"
                  style={{
                    background: one.data.color || "#808080",
                    color: invertColor(one.data.color || "#808080", true),
                  }}
                  onClick={() => {
                      const updatedDropDownData = dropDownData.map(item => 
                          item.value === option.value
                              ? {
                                  ...item,
                                  targetTaskSelect: {
                                      label: one.data.label,
                                      color: one.data.color,
                                      id:one.id
                                  }
                              }
                              : item
                      );
                      setDropDownData(updatedDropDownData);
                  }}
              >
                  {one.data.label}
              </p>
          ))}
      </div>
    );

    // Retrieve the specific target selection for the current option
    const currentTargetSelect = dropDownData.find(item => item.value === option.value)?.targetTaskSelect;

    return (
      <Popover
        style={{ zIndex: 9999 }}
        trigger="click"
        placement="bottomLeft"
        content={content}
      >
        <Button
          type="primary"
          size="small"
          style={{
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            background: currentTargetSelect?.color || "#808080",
            color: invertColor(currentTargetSelect?.color || "#808080", true),
          }}
          className="Elipsis-Class Status-Change-Dependency"
        >
          {currentTargetSelect?.label || "Select Status"}
        </Button>
      </Popover>
    );
};

  useEffect(() => {
    const filteredOptions = getOptionsForDropdown(filteredDropdownTasksData, selectedDependencies)
        .filter(option =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        );

    setFilteredOptions(filteredOptions);

    const targetSelectOptions = filteredOptions
        .filter(option => option.taskData?.TaskFlowObject?.Nodes.some(e => e.data.state === "Close"))
        .map(option => {
            const closeNode = option.taskData.TaskFlowObject.Nodes.find(e => e.data.state === "Close");
            return {
                ...option,
                label: closeNode ? closeNode.data.label : option.label,
                color: closeNode ? closeNode.data.color : option.color,
            };
        });

    const updatedDropDownData = dropDownData.map(item => {
        const matchingOption = targetSelectOptions.find(opt => opt.value === item.value);
        return {
            ...item,
            targetTaskSelect: matchingOption || item.targetTaskSelect // Set default or existing targetTaskSelect
        };
    });
    setDropDownData(updatedDropDownData);
}, [filteredDropdownTasksData, selectedDependencies, searchText]);


  useEffect(e=>{
    if(!innerPopoverOpen){
      setSourceTaskSelect(currentObject)
    }
  },[innerPopoverOpen]);

// // Example Data
// const apiData = [
//     { SourceTaskId: "9", SourceDependencyStatus: "0", TargetTaskId: "10", TargetDependencyStatus: "0", DependancyType: "Blocking" },
//     { SourceTaskId: "10", SourceDependencyStatus: "0", TargetTaskId: "11", TargetDependencyStatus: "0", DependancyType: "Blocking" }
// ];

// // Example Input
// const newDependency = {
//     sourceTaskId: "11",
//     sourceStatus: "0",
//     targetTaskId: "9",
//     targetStatus: "1",
//     dependencyType: "Blocking"
// };
  //
  const ValidateDependenciesNew = (option, type, isEditView) => {
      
    let valid = true;

    let sourceTaskId=taskData.hasOwnProperty('SubTaskROWID')?"SubTasks_"+taskData.SubTaskROWID:"Tasks_"+taskData.TaskROWID;
    let sourceTaskStatusId=option.SourceDependencyStatus;
    let targetTaskId=option.value;
    let targetTaskStatusId=option.TargetDependencyStatus;
    
    let tempDObject={
      TourceTaskId:targetTaskId,
      SourceTaskId:sourceTaskId,
      sourceStatus:sourceTaskStatusId,
      targetStatus:targetTaskStatusId,
      dependencyType: type,
    }

    let typeData = [];
    let typeCrossData = [];
    if(type == "WaitingOn"){
      typeData = waitingOndata.WaitingOn;
      typeCrossData = waitingOndata.Blocking; 
    }else if(type=="Blocking"){
      typeData = waitingOndata.Blocking;
      typeCrossData = waitingOndata.WaitingOn; 
    }
    if (type) {
      //checking duplicates
      let isDuplicat = typeData?.some(task => {
        if (isEditView && task.ROWID == option.ROWID) {
          return false;
        }
        return (!task.IsResolved && (task.value == targetTaskId) && (task.sourceValue == sourceTaskId))
      });
      if (isDuplicat) {
        setClear("prevent_dependency");
        setErrorPopConfirm(`Duplicate ${type} dependancy!`)
        return false;
      }
     // checking intertask circular dep..
       
      if(hasInvalidDependency(allDependencies,tempDObject)){
        setClear("prevent_dependency");
        setErrorPopConfirm("Potential dependency conflict detected. Unable to create the dependency.")
        valid = false;
      }
    }
    return valid;
  }

  const ValidateDependancies=(option,type,isEditView)=>{
      
    let valid=true;
    const [Tasktype,id] = option.value.split('_');
    let sourceId=taskData.hasOwnProperty('SubTaskROWID')?"SubTasks_"+taskData.SubTaskROWID:"Tasks_"+taskData.TaskROWID;
    if(type=="WaitingOn"){
      if(waitingOndata.WaitingOn?.some(task=>{
        if(isEditView && task.ROWID==option.ROWID){
          return false;
        }
        return ( !task.IsResolved && (task.value==option.value && task.TargetDependencyStatusId==option.TargetDependencyStatus)
          && (task.sourceValue==sourceId && task.SourceDependencyStatusId==option.SourceDependencyStatus))
      }
         
      )){
        setClear("prevent_dependency");
        setErrorPopConfirm("Duplicate Waiting on dependancy!")
        return false;
      }
      waitingOndata.Blocking.forEach(task=>{
        if(isEditView && task.ROWID==option.ROWID){
          return;
        }
        let taskFlow=task.data?.TaskFlowObject;
        if(taskFlow && !checkSingleBackflowPath(task.TargetDependencyStatusId,option.TargetDependencyStatus,taskFlow.Edges)){
          setClear("prevent_dependency");
          setErrorPopConfirm("Potential dependency conflict detected. Unable to create the dependency.")
          valid = false;
        }
      })
    }else if(type=="Blocking"){
      if (waitingOndata.Blocking?.some(task => {
        if (isEditView && task.ROWID == option.ROWID) {
          return false;
        }
        return (!task.IsResolved && (task.value == option.value && task.TargetDependencyStatusId == option.TargetDependencyStatus)
          && (task.sourceValue == sourceId && task.SourceDependencyStatusId == option.SourceDependencyStatus))
      }

      )) {
        setClear("prevent_dependency");
        setErrorPopConfirm("Duplicate Blocking dependancy!")
        return false;
      }
      waitingOndata.WaitingOn.forEach(task=>{
        if(isEditView && task.ROWID==option.ROWID){
          return;
        }
        let taskFlow=openTaskData?.TaskFlowObject;
        if(taskFlow && !checkSingleBackflowPath(task.SourceDependencyStatusId,option.SourceDependencyStatus,taskFlow.Edges)){
          setClear("prevent_dependency");
          setErrorPopConfirm("Potential dependency conflict detected. Unable to create the dependency.")
          valid = false;
        }
      })
    }
    return valid;
  }

  const handleUpdateDependancy = (type, saveObj) => {
     
    setLoader(true);
    if (ValidateDependenciesNew(saveObj, type, true)) {
      setClear("after_save");
      // handleSaveDependencies(saveObj, type);
    }else{
      setLoader(false);
      return false
    }
    let [types, id] = saveObj.task?.value ? saveObj.task.value.split('_') : saveObj.value.split('_');

    let flag = saveObj?.value != saveObj?.task?.value;
    let [stype, sId] = saveObj.sourceValue.split('_');

    //logic to check already resolved dep
    let isResolvedDependancy = 0;
    let currentStatusOfSourceTask = taskData?.TaskFlowObject?.Nodes?.find(k => k.id == taskData.TaskStatus);
    let currentStatusOfTargetTask = saveObj.task?.data?.TaskFlowObject?.Nodes?.find(k => k.id == saveObj.task?.data.TaskStatus);
    let tempTaskS;
    let tempTaskState;
    if(type=="WaitingOn"){
      tempTaskS = saveObj.toStatus;
      tempTaskState = currentStatusOfTargetTask?.data?.state
    }else{
      tempTaskS = saveObj.thisStatus;
      tempTaskState = currentStatusOfSourceTask?.data?.state
    }
    if (tempTaskS == "S" && tempTaskState != "Start") {
      isResolvedDependancy = 1;
    } else if (tempTaskS == "F" && tempTaskState == "Close") {
      isResolvedDependancy = 1;
    }
    // -- end -- logic to check already resolved dep

    let payload = {
      TargetTaskId: id,
      TaskType: types,
      TargetDependencyStatus: saveObj.toStatus,
      IsResolved:isResolvedDependancy,
      SourceDependencyStatus: saveObj.thisStatus,
      MODIFIEDBY: localStorage.getItem("ZUID"),
      ROWID: saveObj.ROWID
    }
    AwsServerService.updateDependency(payload,).then(response => {
      
    
      let dependencytype = type == 'Blocking' ? 'Blocking' : 'Waiting on';
      // let newData=allTasksAndSubtasks[types].get(Number(id)); 
      let flagSecond = saveObj?.thisStatus != saveObj.SourceDependencyStatusId;
      let flagThird = saveObj?.toStatus != saveObj.TargetDependencyStatusId;
      let message = 'USER updated ' + `${dependencytype}` + ' dependency: where status changed from '
        + (flagSecond ? `''${saveObj?.SourceDependencyStatusId == "S" ? "Start" : "Finish"}'' of this task to `
          + `''${saveObj?.thisStatus == "S" ? "Start" : "Finish"}''` : '')
        + (flagThird ? ' and ' + `''${saveObj?.TargetDependencyStatusId == "S" ? "Start" : "Finish"}'' to `
          + `''${saveObj?.toStatus == "S" ? "Start" : "Finish"}'' for ` + `${types}_${id}` : '')
        + (flag ? ' and task changed from ' + `${saveObj.value} to ${saveObj.task.value}` : '')
      console.log('message updated', message);
      handlecreateactivity(stype, message, sId, dealSelected == 'NoDeal',true);
      
      let reversePayoad = {
        STaskType: types,
        SourceTaskId: id,
        TargetDependencyStatus: saveObj.thisStatus,
        SourceDependencyStatus: saveObj.toStatus,
        DependancyType: type == 'Blocking' ? 'WaitingOn' : 'Blocking',
        sourceType: stype,
        sourceId: sId,
        IsResolved:isResolvedDependancy,
        sourceStatus: saveObj.TargetDependencyStatusId,
        MODIFIEDBY: localStorage.getItem("ZUID"),
        targetStatus: saveObj.SourceDependencyStatusId,
        
      }
      AwsServerService.updateDependencyReverse(reversePayoad).then(res => {
        // getAllDependenciesForTask(openTasktype == 'SubTasks', openTaskId) 
        let deptype = type == 'Blocking' ? 'Waiting on' : 'Blocking';
        let messages = "";
        if (flag) {
          messages = `USER added ${deptype} dependency : where ''${saveObj.toStatus == "S" ? "Start" : "Finish"}'' of this 
            task is ${deptype} ''${saveObj.thisStatus == "S" ? "Start" : "Finish"}'' of ${stype}_${sId}`;
          //   let reMessage = `USER removed ${deptype} dependency : where ''${saveObj?.data?.TaskFlowObject?.Nodes?.find(k => k.id == saveObj.TargetDependencyStatusId)?.data?.label}'' of this 
          //   task was ${deptype} ''${openTaskData?.TaskFlowObject?.Nodes?.find(k => k.id == saveObj.SourceDependencyStatusId)?.data?.label}'' of ${stype}_${sId}`
          // let [revtype, revId] = saveObj?.value?.split('_');
          handlecreateactivity(types, messages, id, dealSelected == 'NoDeal')
          // handlecreateactivity(revtype, reMessage, revId, dealSelected == 'NoDeal')
        } else {
          messages = 'USER updated ' + `${deptype}` + ' dependency: where status changed from '
            + (flagSecond ? `''${saveObj?.TargetDependencyStatusId == "S" ? "Start" : "Finish"}'' of this task to `
              + `''${saveObj?.toStatus == "S" ? "Start" : "Finish"}''` : '')
            + (flagThird ? ' and ' + `''${saveObj?.SourceDependencyStatusId == "S" ? "Start" : "Finish"}'' to `
              + `''${saveObj?.thisStatus == "S" ? "Start" : "Finish"}'' for ` + `${stype}_${sId}` : '')
            + (flag ? ' and task changed from ' + `${saveObj.value} to ${saveObj.task.value}` : '')
          handlecreateactivity(types, messages, id, dealSelected == 'NoDeal')
        }
        console.log('message updated reverse', messages);
      }).catch((e) => {
        swal('Alert', 'Reverse Dependency Error', 'Alert')
      })
    }).finally(e => {
      setLoader(false);
      getAllDependenciesForTask(openTaskData?.hasOwnProperty('SubTaskROWID'), openTaskData?.hasOwnProperty('SubTaskROWID') ? openTaskData?.SubTaskROWID : openTaskData?.TaskROWID);
    })

  }
  const handleOptionSelect = (option, type,saveObj) => {
      
    // let data = [...dropDownData];
    // let record = data?.find((t) => t.value === option.value);
    option["SourceDependencyStatus"] = saveObj.thisTaskStage;
    option["TargetDependencyStatus"] = saveObj.toTaskStage;
    option["thisStatus"] = saveObj.thisTaskStage;
    option["toStatus"] = saveObj.toTaskStage;

    if(ValidateDependenciesNew(option,type,false)){
      setClear("after_save");
      handleSaveDependencies(option, type);
    }
    

    setInnerPopoverOpen(false);
    setOpenPopoverId(null)
    setTimeout(() => setDropdownOpen({Blocking : false, Waiting : false}), 300); 
    setSourceTaskSelect(currentObject);
  };
//   const getMapDependencyPopover = (option, type, index) => {
//     const content = (
//       <div>
//           <span style={{ display: "flex", gap: "50px", margin: "10px 0px" }}>
//               Source Task Status: {getCurrentDependencyStatusOptions()}
//           </span>
//           <span style={{ display: "flex", gap: "50px", margin: "10px 0px" }}>
//               Target Task Status: {getDependencyStatusOptions(option, type, index)}
//           </span>
//           <p style={{display: "flex", justifyContent: "end", marginRight: "15px"}}>
//             <Button 
//               onClick={()=>{handleOptionSelect(option, type)}}
//             > 
//               Save {type} Dependency
//             </Button>
//           </p>
//       </div>
//     );

//     return (
//       <Popover
//         content={content}
//         trigger="click"
//         open={innerPopoverOpen && openPopoverId?.value === option.value}
//         onOpenChange={(visible) => {
//           setInnerPopoverOpen(visible);
//           setOpenPopoverId(visible ? option : null);
//         }}
//         placement="top"
//         overlayClassName="Mapping-Dependency-popover"
//       >
//         <Button
//           className="Add-Lookup-Icon"
//           color="link"
//           onClick={(e) => {
//             e.stopPropagation();
//             setInnerPopoverOpen(true);
//             setOpenPopoverId(option);
//           }}
//         >
//           <FontAwesomeIcon icon={faPlus} style={{ marginRight: "7px" }} />
//         </Button>
//       </Popover>
//     );
// };

  // const getWaitingOnPopover = (type) => {
  //   const content = (
  //     <div className="dependncy-list-dropdowns">
  //       <Search
  //         size="small"
  //         placeholder="Search"
  //         value={searchText}
  //         onChange={(e) => {
  //           e.target.value = e.target.value.trimStart();
  //           setSearchText(e.target.value);
  //         }}
  //         style={{ marginBottom: ".8em", width: '99%' }}
  //         className="dependency-search-input"
  //       />
  
  //       {/* Header Row for Columns */}
  //       <Row className="custom-option-header" style={{ padding: "4px 8px", fontWeight: "bold", backgroundColor: "#f0f0f0", borderBottom: "1px solid #ccc" }}>
  //         <Col sm={6}>Task Name</Col>
  //         <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>Status</Col>
  //         <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>Actions</Col>
  //       </Row>
  
  //       <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}>
  //         {filteredOptions.map((option, index) => (
  //           <Row
  //             key={option.value}
  //             className="custom-option"
  //             style={{
  //               cursor: "pointer",
  //             }}
  //           >
  //             {/* Task Name Column */}
  //             <Col sm={6}>
  //               <Tooltip title={option.label}>
  //                 <span className="option-task-name Elipsis-Class">{option.label}</span>
  //               </Tooltip>
  //             </Col>
  
  //             {/* Status Column */}
  //             <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>
  //               {option?.status && <Tooltip title={option.status}>
  //                 <span
  //                   className="option-status Elipsis-Class"
  //                   style={{
  //                     background: option.statusColor === "grey" || !option.statusColor ? "#808080" : option.statusColor,
  //                     color: invertColor(option.statusColor === "grey" || !option.statusColor ? "#808080" : option.statusColor, true),
  //                     padding: "2px 6px",
  //                     borderRadius: "3px",
  //                   }}
  //                 >
  //                   {option.status}
  //                 </span>
  //               </Tooltip>}
  //             </Col>
  
  //             {/* Actions Column */}
  //             <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>
  //               {getMapDependencyPopover(option, type, index)}
  //             </Col>
  //           </Row>
  //         ))}
  //       </div>
  //     </div>
  //   );
  
  //   return (
  //     <div className="dropdownForWaiting" style={{ width: "50%", marginTop: ".8em", position: "absolute" }}>
  //       <Popover
  //         content={content}
  //         trigger="click"
  //         open={dropdownOpen[type]}
  //         onOpenChange={(visible) => {
  //           if (!visible) setInnerPopoverOpen(false); // Close inner popover when outer is closed
  //           setDropdownOpen((prev) => ({ ...prev, [type]: visible }));
  //       }}
  //         placement="rightTop"
  //         overlayClassName="Waiting-on-popover"
  //       >
  //         <Button icon={<FontAwesomeIcon icon={faPlus}/>}>
  //           Add Dependency 
  //         </Button>
  //       </Popover>
  //     </div>
  //   );
  // }

  function viewDataForDep(data) {
    const formatDate = (date) => {
      if (!date) return "N/A";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    };
    const taskType = data?.SubTaskROWID ? "SubTask" : "Task";
    return (
      <div className="popup">
        <div className="popup-content">
          <span>
            <strong>Task Type:</strong> {taskType}
          </span>
          <span>
            <strong>Task Name:</strong> {data?.TaskName}
          </span>
          <span>
            <strong>Task Status:</strong> {data?.TaskFlowObject?.Nodes?.find(k=>k.id==data.TaskStatus)?.data?.label}
          </span>
          <span>
            <strong>Task Description:</strong> {data?.TaskDescription || ""}
          </span>
          <span>
            <strong>Created By:</strong> {data?.CREATEDBY}
          </span>
          <span>
            <strong>Created Date:</strong> {formatDate(data?.CREATEDTIME)}
          </span>
          <span>
            <strong>Modified Date:</strong> {formatDate(data?.MODIFIEDTIME)}
          </span>
          <span>
            <strong>Assignee:</strong>{" "}
            {userOptions?.filter((e) => e.value == data?.Assignee)[0]?.label}
          </span>
          <span>
            <strong>Supervisor:</strong>{" "}
            {userOptions?.filter((e) => e.value == data?.Supervisor)[0]?.label}
          </span>
          <span>
            <strong>Priority:</strong> {data?.Priority}
          </span>
          <span>
            <strong>Start Date:</strong> {formatDate(data?.StartDate)}
          </span>
          <span>
            <strong>Due Date:</strong> {formatDate(data?.DueDate)}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="dependencyClassDiv">
      <NewCustomePopover
          width={30}
          open={errorPopConfirm}
          title={"Warning!"}
          okText={"OK"}
          cancelText={"Cancel"}
          onCancel={e => {
            setErrorPopConfirm(null)
          }}
          onOk={e => {
            setErrorPopConfirm(null)
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className='Msg-Block'>
            {errorPopConfirm}
          </div>
        </NewCustomePopover>
      <div
        className="subDivForDependencyClass"
        // style={collapsed.WaitingOn ? { marginBottom: "4em" } : {}}
      >
        <Row
          className="Collapse-Header-Dependancies WaitingOnCollapse"
          onClick={(e) => toggleCollapse("WaitingOn",selectedDependenciesCache.WaitingOn.length,e)}
        >
          <label htmlFor="status" className="labelStatus">
            <CaretRightOutlined rotate={collapsed.WaitingOn ? 90 : 0} />
            <FontAwesomeIcon className="ml-2" color="#ffce00" icon={faClock} />
            <strong>
              Waiting on {"( " + selectedDependenciesCache.WaitingOn.length +" )"}
            </strong>
          </label>
        </Row>
        {
          collapsed.WaitingOn && (
            
            <>
               
              <CustomeTableComponent
                enableInlineAdd={true}
                Headers={DependanciesHeaders}
                firstHeaderLabel="Predecessor Task"
                data={waitingOndata.WaitingOn}
              >
                <tr>
                    <td className={"connecting-task-1 custom-stick custome-border-right"}>
                      <CreateDependencyPopover
                        Tasks={filteredDropdownTasksData}
                        Dependencydata={waitingOndata.WaitingOn?.map(k => k.value)}
                        thisTask={openTaskData}
                        handleDeleteDependency={handleDeleteDependency}
                        clear={clear}
                        setClear={setClear}
                        onSave={d => {
                          handleOptionSelect(d.task, "WaitingOn", d)
                        }}
                        dealSelected={dealSelected}
                        type={'Waiting on'}
                      >
                        <span style={{ 
                              margin: '3px' ,
                              color: "#5b6c97 !important",
                              "font-weight": "600",
                              cursor: "pointer"
                        }}>
                          <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Dependency
                        </span>
                      </CreateDependencyPopover>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
              </CustomeTableComponent>
             
            </>
          )
        }
      </div>
      <div
        className="subDivForDependencyClass"
        // style={selectedDependenciesCache.Blocking.length==0 ? { opacity:"0.5" } : {}}
      >
        <Row
          className={"Collapse-Header-Dependancies BlockingCollapse"}
          onClick={(e) => toggleCollapse("Blocking",selectedDependenciesCache.Blocking.length,e)}
        >
          <label htmlFor="status" className="labelStatus">
            <CaretRightOutlined rotate={collapsed.Blocking ? 90 : 0} />
            <FontAwesomeIcon className="ml-2" color="red" icon={faExclamationCircle} />
            <strong>
              Blocking {"( "+ selectedDependenciesCache.Blocking.length +" )"}
            </strong>
          </label>
        </Row>
        {
          collapsed.Blocking &&  (
            
            <>
              <CustomeTableComponent
                Headers={DependanciesHeaders}
                enableInlineAdd={true}
                firstHeaderLabel="Successor Task"
                data={waitingOndata.Blocking}
              >
              <tr>
                    <td className={"connecting-task-1 custom-stick custome-border-right"}>
                    <CreateDependencyPopover
                      Tasks={filteredDropdownTasksData}
                      Dependencydata={waitingOndata.Blocking?.map(k => k.value)}
                      thisTask={openTaskData}
                      handleDeleteDependency={handleDeleteDependency}
                      onSave={d => {
                         
                        handleOptionSelect(d.task, "Blocking", d)
                      }}
                      clear={clear}
                      setClear={setClear}
                      dealSelected={dealSelected}
                      type={'Blocking'}
                    >
                      <span style={{
                        margin: '3px',
                        color: "#5b6c97 !important",
                        "font-weight": "600",
                        cursor: "pointer"
                      }}>
                        <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Dependency
                      </span>
                    </CreateDependencyPopover>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </CustomeTableComponent>
              
            </>
          )
        }
      </div>
    </div>
  );
}