import { Button, Checkbox, Divider, Input, Modal, Popconfirm, Popover, Select, Switch } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import './InputFields.css';
import { Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheck, faDollarSign, faHashtag, faKeyboard, faList, faPhone, faPlus, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import OptionCards from './OptionCards';
import { values } from 'lodash';
import swal from 'sweetalert'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AppDataRequired } from '../../App';

export const InputFields = ({
    // open,
    // setOpen,
    setSelectInputField,
    selectInputField,
    Module,
    onSave,
    cardSize,
    children,
    onAdd,
}) => {
    const { DealFields } = useContext(AppDataRequired);
    // console.log("Deal-Fields",DealFields.map(k=>({field:k.field,id:k.id,name:k.name})));

    const [DealInputFields,setDealInputFields] = useState([]);
    
    const [error, setError] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [isSelectOpenForPrecision, setSelectOpenForPrecision] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [open,setOpen]=useState(false);
    const handleSelectClick = (open) => {
        // Show confirmation dialog only when the select dropdown is closed
        if (!isSelectOpen && open && inputField.Type == "PICKLIST") {
            setConfirmVisible(true);
        } else {
            setSelectOpen(open); // Allow closing the dropdown normally
        }
    };
    const handleSelectClickForPrecision = (open) => {
        // Show confirmation dialog only when the select dropdown is closed
        if (!isSelectOpenForPrecision && open && inputField.Type == "NUMBER") {
            setSelectOpenForPrecision(true);
        } else {
            setSelectOpenForPrecision(open); // Allow closing the dropdown normally
        }
    };

    const handleConfirm = () => {
        setConfirmVisible(false); // Hide the Popconfirm
        setSelectOpen(true);   // Open the select dropdown
    };
    function onCloseCallBack(){
        if(setSelectInputField){
            setSelectInputField(null);
        }
        setInputField({...inputFieldTemplate})
        setSelectOpen(false);
        setSelectOpenForPrecision(false);
    }
    // useEffect(e=>{
    //     if(!open){
    //         setSelectOpen(false);
    //         setSelectOpenForPrecision(false);
    //     }
    // },[open])

    const handleCancel = () => {
        setConfirmVisible(false);  // Hide the Popconfirm without opening the dropdown
    };
    const inputFieldTemplate = {
        id:Date.now(),
        Name: "",
        Description: "",
        Mandatory:false,
        Module: Module ,
        Type: "TEXT",
        ExtraData: [],
        Precision:"0"
    }
    const [inputField, setInputField] = useState({ ...inputFieldTemplate })
    // useEffect(e => {
    //      
    // }, [inputField])
    useEffect(e=>{
        if(selectInputField && selectInputField.type=="edit" && selectInputField.data){
            setInputField(selectInputField.data);
            setOpen(true);
        }
    },[selectInputField])
    const [types, setTypes] = useState([
        {
            label: "TEXT",
            value: "TEXT",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faKeyboard} /></>
        },
        {
            label: "PICKLIST",
            value: "PICKLIST",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faList} /></>
        },
        {
            label: "USER",
            value: "USER",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faUser} /></>
        },
        {
            label: "MULTI-PICKLIST",
            value: "MULTI-PICKLIST",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faList} /></>
        },
        {
            label: "NUMBER",
            value: "NUMBER",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faHashtag} /></>
        },
        {
            label: "CURRENCY",
            value: "CURRENCY",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faDollarSign} /></>
        },
        {
            label: "DATE",
            value: "DATE",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faCalendar} /></>
        },
        {
            label: "PHONE",
            value: "PHONE",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faPhone} /></>
        },
        {
            label: "CHECKBOX",
            value: "CHECKBOX",
            icon:<><FontAwesomeIcon className='ml-1 mr-1' icon={faCheck} /></>
        },
    ])
    const [significantDigits, setSignificantDigits] = useState([
        {
            label: "0",
            value: "1",
        },
        {
            label: ".0",
            value: ".1",
        },
        {
            label: ".00",
            value: ".01",
        },
        {
            label: ".000",
            value: ".001",
        },
        {
            label: ".0000",
            value: ".0001",
        },
        {
            label: ".00000",
            value: ".00001",
        },
    ])
    function toggleSection(e) {
        setToggle(e)
    }
    function onChangeInputField(key, value) {
        let obj = { ...inputField };
        if (obj.Type=="PICKLIST" || obj.Type=="MULTI-PICKLIST") {
            obj["ExtraData"] = null;
            obj["Precision"] = "0";
        }
        obj[key] = value;
        setInputField(obj);
    }

    return (
        <Popover
            title={"CREATE INPUT FIELD"}
            placement="left"
            // arrowPointAtCenter
            open={open}
            onOpenChange={e=>{
                if(!e){
                    onCloseCallBack()
                }
                setOpen(e)
            }}
            className='Input-Field-Popover-Main'
            overlayClassName='Input-Field-Popover'
            trigger={"click"}
            content={
                <div className='Input-Field-Wrapper'>
                    {/* <div className='container' sm={12}>
                        {createdFields && createdFields.map(field => {
                            return <div className='Card'>
                                {field.Type == "PICKLIST" ? <FontAwesomeIcon style={{ "margin-right": "7px" }} icon={faList} />
                                    : <FontAwesomeIcon style={{ "margin-right": "7px" }} icon={faKeyboard} />}
                                {field.Name}
                                <FontAwesomeIcon className='Close-Icon' style={{ "margin-right": "7px" }} icon={faTimes} />
                            </div>
                        })}
                        <div className='Card Add-Icon'>
                            <FontAwesomeIcon style={{ "margin-right": "7px" }} icon={faPlus} />
                            {"Add"}
                        </div>
                    </div>
                    <Divider /> */}
                    <div className='Create-Input-Fields-Section' id="inputSection">
                        <Row sm={12}>
                            <Col className={"mb-3 mt-3"} sm={12}>
                                <span className="labeltext">
                                    <Label>Name</Label>
                                </span>
                                <Input
                                    className='Global-Text-Color'
                                    // disabled={bluePrintData?.lock=="true"}
                                    // className="input"
                                    value={inputField?.Name}
                                    onChange={(e) => {
                                        e.currentTarget.value=e.currentTarget.value.trimStart();
                                        onChangeInputField("Name", e.currentTarget.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row sm={12}>
                            <Col className={"mb-3"} sm={12}>
                                <span className="labeltext">
                                    <Label>Description</Label>
                                </span>
                                <Input
                                    className='Global-Text-Color'
                                    // disabled={bluePrintData?.lock=="true"}
                                    // className="input"
                                    // value={selectedData?.data?.data?.label}
                                    value={inputField?.Description}
                                    onChange={(e) => {
                                        e.currentTarget.value=e.currentTarget.value.trimStart();
                                        onChangeInputField("Description", e.currentTarget.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row sm={12}>
                            <Col style={{    display: "flex",color: "#5a6c97"}} className={"mb-3"} sm={12}>
                                {/* <span className="labeltext">
                                    <Label>Description</Label>
                                </span> */}
                                Mandatory
                                <Input
                                    style={{width: "max-content","margin-left": "10px"}}
                                    type='checkbox'
                                    className='Global-Text-Color'
                                    // disabled={bluePrintData?.lock=="true"}
                                    // className="input"
                                    // value={selectedData?.data?.data?.label}
                                    checked={inputField?.Mandatory}
                                    onChange={(e) => {
                                        onChangeInputField("Mandatory", e.currentTarget.checked)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row sm={12}>
                            <Col className={"mb-3"} sm={12}>
                                <span className="labeltext">
                                    <Label>Type</Label>
                                </span>
                                <Popconfirm
                                    // disabled={inputField.Type != "PICKLIST"}
                                    open={confirmVisible}
                                    onConfirm={handleConfirm}
                                    onCancel={handleCancel}
                                    okText="Ok"
                                    cancelText="Cancel"
                                    title="Change Input Type"
                                    description="Options are going to erase."
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Select
                                        // style={{color:"#5b6c97"}}
                                        className='Global-Text-Color'
                                        showSearch
                                        placeholder="Select type"
                                        open={isSelectOpen}
                                        onDropdownVisibleChange={handleSelectClick}
                                        // options={types}
                                        value={inputField?.Type}
                                        onSelect={(value, option) => {
                                            onChangeInputField("Type", value)
                                        }}
                                    >
                                       {types.map((option,i)=> (<Select.Option className='Global-Text-Color' value={option.value}>
                                            {option?.icon && option.icon}{option.label}
                                        </Select.Option>))}
                                    </Select>
                                </Popconfirm>

                            </Col>
                        </Row>
                        {(inputField?.Type == "PICKLIST" || inputField?.Type == "MULTI-PICKLIST") && <Row style={{ height: "123px", overflow: "scroll" }}>
                            <OptionCards inputField={inputField} setInputField={setInputField}></OptionCards>
                        </Row>}
                        {inputField?.Type == "NUMBER" && <Row sm={12}>
                            <Col className={"mb-3"} sm={12}>
                                <span className="labeltext">
                                    <Label>Precision</Label>
                                </span>
                                <Select
                                    // style={{color:"#5b6c97"}}
                                    className='Global-Text-Color'
                                    showSearch
                                    placeholder="Select type"
                                    open={isSelectOpenForPrecision}
                                    onDropdownVisibleChange={handleSelectClickForPrecision}
                                    options={significantDigits}
                                    value={inputField?.Precision}
                                    onSelect={(value, option) => {
                                        onChangeInputField("Precision", value)
                                    }}
                                ></Select>

                            </Col>
                        </Row>}
                        {/* {inputField?.Type == "NUMBER" && <Row style={{ height: "156px", overflow: "scroll" }}>
                            <Select
                                // style={{color:"#5b6c97"}}
                                className='Global-Text-Color'
                                showSearch
                                placeholder="Select type"
                                open={isSelectOpenForPrecision}
                                onDropdownVisibleChange={handleSelectClickForPrecision}
                                options={significantDigits}
                                value={inputField?.Type}
                                onSelect={(value, option) => {
                                    onChangeInputField("Type", value)
                                }}
                            ></Select>
                        </Row>} */}
                        {/* <Divider /> */}
                        <Row style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                            <Button style={{ width: "auto", padding: "6px" }} className='mr-1'
                                onClick={e=>{
                                    setInputField(inputFieldTemplate);
                                    onCloseCallBack() 
                                    setOpen(false)
                                }}
                            >Cancel</Button>
                            <Button type='primary' style={{ width: "auto", padding: "6px" }} className='ml-1'
                                onClick={e=>{
                                    onAdd(inputField,selectInputField?.type);
                                    setInputField(inputFieldTemplate);
                                    onCloseCallBack()
                                    setOpen(false)
                                }}
                            >Save</Button>
                        </Row>
                    </div>
                    {/* {Module=="TASK" && <div className='Create-Input-Fields-Section' id="inputSection"> 
                        <Row sm={12}>
                            <Col className={"mb-3"} sm={12}>
                                <span className="labeltext">
                                    <Label>Deal Fields</Label>
                                </span>
                                    <Select
                                        mode='multiple'
                                        // style={{color:"#5b6c97"}}
                                        className='Global-Text-Color'
                                        showSearch
                                        placeholder="Select type"
                                        open={isSelectOpen}
                                        onDropdownVisibleChange={handleSelectClick}
                                        // options={types}
                                        value={DealInputFields}
                                        onChange={(value, option) => {
                                            setDealInputFields(value)
                                        }}
                                    >
                                       {DealFields && DealFields.map((option,i)=> (<Select.Option className='Global-Text-Color' value={option.id}>
                                            {option.name}
                                        </Select.Option>))}
                                    </Select>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                            <Button style={{ width: "auto", padding: "6px" }} className='mr-1'
                                onClick={e=>{
                                    onCloseCallBack() 
                                    setOpen(false)
                                }}
                            >Cancel</Button>
                            <Button type='primary' style={{ width: "auto", padding: "6px" }} className='ml-1'
                                onClick={e=>{
                                    if(module=="TASK" && DealInputFields?.length>0 && false){
                                        // DealFields.forEach(element => {
                                        //     let obj={...inputFieldTemplate};
                                        //     if(DealInputFields.includes(element.id)){
                                        //         obj.Type = element.type;
                                        //         obj.id = element.id;
                                        //         obj.Name = element.name;
                                        //         onAdd(obj,selectInputField?.type);
                                        //     }
                                        // });
                                    }else{
                                        onAdd(inputField,selectInputField?.type);
                                    }
                                    setInputField(inputFieldTemplate);
                                    onCloseCallBack()
                                    setOpen(false)
                                }}
                            >Save</Button>
                        </Row>
                    </div>} */}
                </div>
            }
        >
            {children}
        </Popover>
    )
}
