import React, { useEffect, useState } from 'react'
import './InputFields.css';
import { Checkbox, Col, DatePicker, Input, InputNumber, Row, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCodeBranch, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import { UserPicker } from '../../common/UserPicker/UserPicker';
import CurrencyInput from 'react-currency-input-field';
import { NumericFormat } from 'react-number-format';
import { NewUserPicker } from '../../common/UserPicker/NewUserPicker';
const { Option } = Select;
export const InputFieldsTable = ({
    InputFields,
    readOnly,
    Module,
    onChange,
    onDelete,
}) => {
    const [inputField, setInputField] = useState([]);
    const [validation,setValidation] = useState({Text:0,Multipick:0,Phone:0,Number:0,Currency:0,User:0,Date:0,Picklist:0});
    const validationCss={
        "transition": "width 0.4s ease-in-out",
        "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
      }
    const TypeInputComponents = {
        "TEXT": (field, index) => <Input style={validation.Text==-1?validationCss:{}} disabled={readOnly} readOnly={readOnly} className='Input-Text'
            value={field?.value}
            onChange={(e) => {
                
                e.currentTarget.value=e.currentTarget.value.trimStart().replace(/[^a-zA-Z\s]/g,'')
                let arr = [...inputField];
                arr[index]["value"] = e.currentTarget.value;
                // setInputField(arr);
                if(field.Mandatory && (e.currentTarget.value && e.currentTarget.value!='' && e.currentTarget.value!=undefined)){
                    setValidation({...validation,Text:0})
                }
                onChange(arr,field.Module);
            }}
            onBlur={(e)=>{
                
                if(field.Mandatory && (!field?.value || field?.value==''|| field?.value==undefined)){
                    setValidation({...validation,Text:-1})
                }else{
                    setValidation({...validation,Text:0})
                }
            }}
        />,
        "CHECKBOX": (field, index) => <Checkbox disabled={readOnly} readOnly={readOnly} className='Input-Text'
            value={field?.value}
            onChange={(e) => {
                let arr = [...inputField];
                arr[index]["value"] = e.target.checked;
                // setInputField(arr);
                onChange(arr);
            }}
        />,
        "MULTI-PICKLIST": (field, index) => <Select style={validation.Multipick==-1?validationCss:{}} mode='multiple' disabled={readOnly} className='Input-Select'
            value={field.value}
            onChange={(values, option) => {
                let arr = [...inputField];
                arr[index]["value"] = values;
                // setInputField(arr);
                if(field.Mandatory && values?.length>0){
                    setValidation({...validation,Multipick:0})
                }
                onChange(arr);
            }}
            onBlur={(e)=>{
                
                if(field.Mandatory && !field?.value?.length>0){
                    setValidation({...validation,Multipick:-1})
                }else{
                    setValidation({...validation,Multipick:0})
                }
            }}
            allowClear
        >
            {field?.ExtraData?.map((option, idx) => (
                <Option key={idx} value={option.id}>
                    {option.text}
                </Option>
            ))}
        </Select>,
        "PHONE": (field, index) => <ReactInputMask
            style={validation.Phone==-1?validationCss:{}}
            type="text"
            mask="(999) 999-9999"
            maskChar='_'
            className="form-control arrow-hide createdeal-textfield"
            name="phoneNumber" id="phoneno"
            placeholder="(###) ###-####"
            minLength={3}
            value={field.value ? field.value : ''}
            // style={validation.phoneNumber=='-1'?{...validationCss}:{}}
            onKeyDown={(evt) =>
                ((evt.keyCode > 64 && evt.keyCode < 91) || evt.key === ".") &&
                evt.preventDefault()
            }
            onChange={(e) => {
                e.target.value = e.target.value.trimStart();
                let value = e.target.value
                var value1 = value.replace(/[-#*$+/?=%() ]/g, '')
                let onlyNo = value1.replace(/\D/g, '');
                if (onlyNo.length > 10) {
                    return
                }
                let arr = [...inputField];
                arr[index]["value"] = value;
                // setInputField(arr);
                if (onlyNo.length < 10 ) {
                    setValidation({...validation,Phone:'-1'})
                }else if(onlyNo.length == 10){
                    setValidation({...validation,Phone:'0'})
                }
                onChange(arr);
            }}
            onBlur={(e)=>{
                var v1 = e.currentTarget.value.replace(/[-#*$+/?=%() ]/g, "");
                let value1= v1.replace(/\D/g, '');
                if(field.Mandatory){
                    if (value1.length < 10 ) {
                        setValidation({...validation,Phone:'-1'}) 
                    }else{
                        setValidation({...validation,Phone:'0'}) 
                    }
                }
            }}
        />,
        "NUMBER": (field, index) => <InputNumber style={validation.Number==-1?{...validationCss,width:"100%"}:{ width: "100%" }} min={0}
            step={field?.Precision ? Number(field?.Precision) : 1}
            precision={field?.Precision ? field?.Precision?.split(".")[1]?.length : 0}
            disabled={readOnly}
            readOnly={readOnly}
            className='Input-Text'
            value={field.value}
            onChange={(e) => { 
                let arr = [...inputField];
                arr[index]["value"] = e;
                // setInputField(arr);
                if(field.Mandatory && (e && e !='' && e !=undefined)){
                    setValidation({...validation,Number:0})
                }
                onChange(arr);
            }}
            onBlur={(e)=>{
                if(field.Mandatory && (field.value=='' || field.value==undefined)){
                    setValidation({...validation,Number:-1})
                }else{
                    setValidation({...validation,Number:0})
                }
            }}
        />,
        "CURRENCY": (field, index) => <NumericFormat
        style={validation.Currency==-1?validationCss:{}}
        className="custome-currency-input"
        name="currency"
        step={field?.Precision ? Number(field?.Precision) : 1}
        placeholder="Enter amount"
        value={field.last}
        defaultValue={0}
        thousandSeparator
        disabled={readOnly}
        readOnly={readOnly}
        decimalScale={field?.Precision}
        onValueChange={(e) => {
            let arr = [...inputField];
            arr[index]["value"] = e.floatValue;
            // setInputField(arr);
            if(field.Mandatory && (e.value && e.value!='' && e.value!="$0")){
                setValidation({...validation,Currency:0})
            }
            onChange(arr);
        }}
        onBlur={(e)=>{
            
            if(field.Mandatory && (!field.value || field.value=='' || field.value==undefined)){
                setValidation({...validation,Currency:-1})
            }else{
                setValidation({...validation,Currency:0})
            }
        }}
        prefix="$"
      />,
        "USER": (field, index) => <NewUserPicker
            rootClassName={validation.User==-1?'validationCssClass':''}
            mode="multiple"
            values={field.value}
            style={validation.User==-1?validationCss:null}
            onBlur={e=>{
                if(field.Mandatory && (!field.value || field.value=='' || field.value==undefined)){
                    setValidation({...validation,User:-1})
                }else{
                    setValidation({...validation,User:0})
                }
            }}
            onChange={(val) =>{
                let arr = [...inputField];
                arr[index]["value"] = val;
                if(field.Mandatory && (!val || val.length==0)){
                    setValidation({...validation,User:-1})
                }else{
                    setValidation({...validation,User:0})
                }
                onChange(arr);
            }} 
        />,
        "DATE": (field, index) => <><DatePicker className='Input-Date'
            format="MM/DD/YYYY"
            style={validation.Date==-1?validationCss:{}}
            onBlur={e=>{
                if(field.Mandatory && !field.value){
                    setValidation({...validation,Date:-1})
                }else{
                    setValidation({...validation,Date:0})
                }
            }}
            value={field.value ? dayjs(field.value) : dayjs(new Date())}
            onChange={(date, dateString) => {
                 
                let arr = [...inputField];
                arr[index]["value"] = dateString;
                if(field.Mandatory && !dateString){
                    setValidation({...validation,Date:-1})
                }else{
                    setValidation({...validation,Date:0})
                }
                // setInputField(arr);
                onChange(arr);
            }} 
        ></DatePicker></>,
        "PICKLIST": (field, index) => <Select style={validation.Picklist==-1?validationCss:{}} disabled={readOnly} className='Input-Select'
            value={field.value}
            onChange={(value) => {
                let arr = [...inputField];
                arr[index]["value"] = value;
                // setInputField(arr);
                if(field.Mandatory && !value){
                    setValidation({...validation,Picklist:-1})
                }else{
                    setValidation({...validation,Picklist:0})
                }
                onChange(arr);
            }}
            // onSelect={(value, option) => {
            //     let arr=[...inputField];
            //     arr[index]["value"]= value;
            //     setInputField(arr);
            // }}
            onBlur={(e)=>{
                if(field.Mandatory && !field.value){
                    setValidation({...validation,Picklist:-1})
                }else{
                    setValidation({...validation,Picklist:0})
                }
            }}
            allowClear
        >
            {field.ExtraData?.map((option, idx) => (
                <Option key={idx} value={option.id}>
                    {option.text}
                </Option>
            ))}
        </Select>,
    }
    useEffect(e => {
         
        if (Module == "TASK") {
              
            let arr = []
            if (InputFields?.InputFields && InputFields?.InputFields?.length > 0) {
                arr = [...InputFields?.InputFields];
            }
            if (InputFields?.TaskFlowObject?.InputFields && InputFields?.TaskFlowObject?.InputFields?.length > 0) {
                arr = [...arr, ...InputFields.TaskFlowObject.InputFields];
            }
            setInputField(arr);
            // onChange(arr);
        } else {
            if (InputFields && InputFields.length > 0) {
                setInputField([...InputFields]);
                // onChange(InputFields);
            } else {
                setInputField([]);
                // onChange([]);
            }
        }
    }, [InputFields, Module])
    return (
        <div style={{ paddingBottom: "4px" }}>
            <div className='InputFields-Table-Wrapper'>
                {inputField?.map((field, i) => {
                    if (TypeInputComponents[field.Type]) {
                        return (<Row style={{ backgroundColor: "transparent" }} className='Field-Verticle-Row' sm={12}>
                            <Col className='Field-Name' sm={6}>
                                {field.Module == "TASKFLOW" ? <FontAwesomeIcon className='mr-1 ml-1' color="#7a7a7a" icon={faCodeBranch} /> : <FontAwesomeIcon className='mr-1 ml-1' color="transparent" icon={faCodeBranch} />}
                                {field?.Name?.toUpperCase()} 
                                {/* {field?.Mandatory && <span className='ml-1' style={{ color: 'red' }}>*</span>} */}
                            </Col>
                            <Col className='Field-Value' sm={6}>{TypeInputComponents[field.Type](field, i)}</Col>
                            {field.Module != "TASKFLOW" && <FontAwesomeIcon onClick={e => {
                                onDelete(field);
                            }} className='Delete-Icon-For-Field' color='red' icon={faTrashAlt}></FontAwesomeIcon>}
                        </Row>)
                    }
                })}
                {/* <Row className='Field-Verticle-Row' sm={12}>
                    <Col className='Field-Name' sm={6}>Name</Col>
                    <Col className='Field-Value' sm={6}>test</Col>
                </Row> */}
            </div>
        </div>
    )
}
