import React, { useContext, useEffect, useState } from "react";
import { TaskGroupCardForTemplate } from "../../../components/ProjectTasks/TaskGroupCardForTemplate";
import "./common.css";
import AddInlineData from "../../../components/ProjectTasks/AddInlineData";
import swal from "sweetalert";
import AppContext from "../../../Context/AppContext";
import BottomBar from "../../../components/ProjectTasks/BottomBar";
import AwsServerService from "../../../common/util/AwsServerService";
import { Button } from "antd";
import { convertToUSTime } from "../../../components/HR/TimeClock/Util";


export default function SubTaskTab({
  taskData,
  allPipelineData,
  module,
  viewTaskFunction,
  groupFilter,
  selectedTable,
  subTaskData,
  setSubTaskData,
  isSubTaskView,
  setIsSubTaskView,
  setPageRefreshData,
  refreshData,
  handleCreateActivity,
  setOpen,
  allBlueprintsData,
  setTaskData
}) {
  const AppData = useContext(AppContext);
  const { state } = AppData || {};

  const { isTaskModalClosed } = state;

  let user = JSON.parse(localStorage.getItem("userinfo"));
  const [isAdding, setIsAdding] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [selectedIdsForTemp, setSelectedIdsForTemp] = useState({Task:[],Subtask:[]});
  const [selectedIds, setSelectedIds] = useState([]);
  const [subEditData, setSubEditData] = useState({
    id: Date.now(),
    Assignee: "",
    Priority: "",
    CREATEDBY: user.email_id,
    Supervisor: "",
    TaskDescription: "",
    TaskName: "",
    TimeEstimate: "",
    SubTaskROWID: '',
    DueDate: convertToUSTime(new Date()),
    StartDate: convertToUSTime(new Date()),
  });
  

  useEffect(
    (e) => {
      if (isTaskModalClosed) {
        setSubEditData({
          id: Date.now(),
          Assignee: "",
          Priority: "",
          CREATEDBY: user.email_id,
          Supervisor: "",
          TaskDescription: "",
          TaskName: "",
          TimeEstimate: "",
          // SubTaskROWID: '',
          PipelineROWID: taskData?.PipelineROWID
        });
        setSelectedIdsForTemp({Task:[],Subtask:[]})
        setIsAdding(false);
      }
    },
    [isTaskModalClosed]
  );

  useEffect(() => {
    if (taskData?.PipelineROWID && isAdding) {
      let updatedSubTaskData = subTaskData.map((item) => {
        return { ...item, PipelineROWID: taskData.PipelineROWID }; 
      });
      // If you want to update a state or pass this data somewhere, you can do it here:
      setSubTaskData(updatedSubTaskData); // Assuming you have a setState function
    }
  }, [taskData?.PipelineROWID, isAdding]);
  

 const handleAddRow = () => {  
    setIsAdding(true);
    setSubTaskData([...subTaskData, subEditData]);
  };

  useEffect(
    (e) => {
      if (idToDelete) {
        let updatedTasks = [...subTaskData];
        let fileterdData = updatedTasks?.filter(
          (item) => item?.id != idToDelete
        );
        setSubTaskData(fileterdData);
        setIdToDelete("");
        setIsAdding(false);
      }
    },
    [idToDelete]
  );

  // Save the data (simulate API call)
  const handleSave = async() => {  
    // Find the index of the object with the same id in subTaskData
    if(!subEditData?.TaskName){
      swal('Alert','Task Name can not be empty!',"warning")
      return
    }
    let flowObj= allBlueprintsData?.filter(e=>e.DefaultFlow=="true")[0];
    let item={...subEditData};
    let data={...subEditData};
    item['TaskFlowObject']=flowObj;
    item['TaskFlowId']=flowObj?.id;
    if(selectedTable=='tasks'){
    if(taskData?.TaskROWID || taskData?.TaskId){
    delete item["id"];
    delete item["flag"];
    delete item["PipelineROWID"];
    item["TaskROWID"] = taskData.TaskROWID;
    await AwsServerService.createSubtask(item).then(r=>{
      data['SubTaskROWID']=r[0].insertId;
      // let message=`USER added subtask "${item.TaskName}"`
      // handleCreateActivity('Tasks',message,taskData.TaskROWID);
    });
  }}else{
    if(taskData?.TaskROWID || taskData?.TaskId){
    delete item["id"];
    delete item["flag"];
    delete item["PipelineROWID"];
    item["TaskROWID"] = taskData.TaskROWID;
    await AwsServerService.createSubtaskInTemplate(item).then(r=>{
      data['SubTaskROWID']=r[0].insertId;
      // let message=`USER added subtask "${item.TaskName}"`
      // handleCreateActivity('Tasks',message,taskData.TaskROWID,true);
     })
    }
  }
    // if (subEditData.hasOwnProperty('SubTaskROWID')) {
    //   delete subEditData.SubTaskROWID;
    // }
      
    data["flag"] = true;
    const existingIndex = subTaskData.findIndex(
      (item) => item.id === data.id
    );
    
    if (existingIndex !== -1) { 
      const updatedTaskData = [...subTaskData];
      updatedTaskData[existingIndex] = {
        ...subTaskData[existingIndex],
        ...data,
      };
      setSubTaskData(updatedTaskData);
    } else { 
      setSubTaskData([...subTaskData, data]);
    } 
    setSubEditData({
      id: Date.now(),
      Assignee: "",
      Priority: "",
      CREATEDBY: user.email_id,
      Supervisor: "",
      TaskDescription: "",
      TaskName: "",
      TimeEstimate: "",
    });

    setIsAdding(false);
  }; 
  return (
    <div className="Subtask-Table">
      <div className="add-row-btn">
        <Button
          // disabled={!pipelineData?.PipelineName}
          onClick={() => {
            setOpen(true);
            setTaskData();
          }}
        >
          Add SubTask
        </Button>
      </div>
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <AddInlineData 
        onAdd={(e) => {
          handleAddRow(e);
        }}
        onSave={(e) => {
          handleSave();
        }}
        isAdding={isAdding}
        name={"Add SubTask"}
      /> 
      {selectedIds.length>0 && <Button onClick={()=>{
       let data=[...subTaskData];
       setSubTaskData(data.filter(e=>!selectedIds.includes(e.id)));
       setSelectedIds([]);
      }}>
        Delete
      </Button>}
      </div>
      <TaskGroupCardForTemplate
        editData={subEditData}
        setEditData={setSubEditData}
        module={module}
        taskData={taskData}
        viewTaskFunction={viewTaskFunction}
        groupFilter={groupFilter}
        allPipelineData={allPipelineData}
        data={subTaskData}
        header={"Task Name"}
        selectedTable={selectedTable}
        isSubTaskView={isSubTaskView}
        setIdToDelete={setIdToDelete}
        setIsSubTaskView={setIsSubTaskView}
        setPageRefreshData={setPageRefreshData}
        selectedIdsForTemp={selectedIdsForTemp}
        setSelectedIdsForTemp={setSelectedIdsForTemp}
        setDummyObj={()=>{}}
        dummyObj={{}}
        emptyData={!taskData?.TaskROWID}
        setSelectedIds={setSelectedIds}
        selectedIds={selectedIds}
      />

    {(selectedIdsForTemp?.Task?.length > 0 || selectedIdsForTemp?.Subtask?.length > 0) && 
          <BottomBar 
            selectedIds={selectedIdsForTemp}
            setSelectedIds={setSelectedIdsForTemp} 
            setPageRefreshData={setPageRefreshData} 
            refreshData={refreshData}
            selectedTable={selectedTable}
            subTaskData={subTaskData}
            setSubTaskData={setSubTaskData}
            setIsAdding={setIsAdding}
            />
        }

    </div>
  );
}
