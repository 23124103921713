import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Modal, Popover, Select, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { AppDataRequired } from '../../../App';
import './HelperComponent.css'
import Config from '../../../config.json'
import _ from "lodash";
import AppContext from '../../../Context/AppContext';
import { index } from 'mathjs';
import axios from 'axios';
import AntdRangePicker from '../../../common/DateRangePicker';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'react-date-range';
import { DateRangePickerNew } from './DateRangePickerNew';
import { getDefaultValues, getPickListOptions } from '../../../tabs/Task/TaskUtil';
import { NewUserPicker } from '../../../common/UserPicker/NewUserPicker';
import { invertColor } from '../../HR/TimeClock/Util';
import CurrencyInput from 'react-currency-input-field';
import { NumberFormatBase, NumericFormat } from 'react-number-format';
const { Option, OptGroup } = Select;
export const CriteriaModal = ({ open, setOpen, mainFields , onSave,action,criteriaRows}) => {
    const AppDataReq = useContext(AppDataRequired);
    const [AllDeals,setAllDeals] = useState([])
    const AppData = useContext(AppContext);
    const { dispatch ,state} = AppData || {};
    
    // const { dispatch ,state} = AppData || {};
    const [rows, setRows] = useState([{ id: Date.now() }]);
    const [error,setError] = useState(false);
    const [criteriaFieldGroups, setCriteriaFieldGroups] = useState({});
    const [lastSelectOptions,setLastSelectOptions] = useState([
        { value: 'Required', label: 'Required' },
        { value: 'Not Required', label: 'Not Required' },
        { value: 'Approved', label: 'Approved' }
    ]);
    const defaultOptions = [
        { value: 'Required', label: 'Required' },
        { value: 'Not Required', label: 'Not Required' },
        { value: 'Approved', label: 'Approved' }
    ]
    const MiddleOptions={
        "TEXT":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
            { value: 'Contains', label: 'Contains' },
            { value: 'Not Contains', label: 'Not Contains' },
            { value: 'Starts With', label: 'Starts With' },
            { value: 'Ends With', label: 'Ends With' },
            { value: 'Is Empty', label: 'Is Empty' },
            { value: 'Is Not Empty', label: 'Is Not Empty' },
        ],
        "DEFAULT":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
            { value: 'Contains', label: 'Contains' },
        ],
        "PHONE":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
            { value: 'Contains', label: 'Contains' },
            { value: 'Not Contains', label: 'Not Contains' },
            { value: 'Starts With', label: 'Starts With' },
            { value: 'Ends With', label: 'Ends With' },
            { value: 'Is Empty', label: 'Is Empty' },
            { value: 'Is Not Empty', label: 'Is Not Empty' },
        ],
        "CHECKBOX":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
        ],
        "BOOLEAN":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
        ],
        "USER":[
            { value: 'Any Of', label: 'Any Of' },
            { value: 'All Of', label: 'All Of' },
            { value: 'None Of', label: 'None Of' },
            { value: 'Is Empty', label: 'Is Empty' },
            { value: 'Is Not Empty', label: 'Is Not Empty' },
        ],
        "PICKLIST":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
            { value: 'Is Empty', label: 'Is Empty' },
            { value: 'Is Not Empty', label: 'Is Not Empty' },
        ],
        "MULTI-PICKLIST":[
            { value: 'Any Of', label: 'Any Of' },
            { value: 'All Of', label: 'All Of' },
            { value: 'None Of', label: 'None Of' },
            { value: 'Is Empty', label: 'Is Empty' },
            { value: 'Is Not Empty', label: 'Is Not Empty' },
        ],
        "NUMBER":[
            { value: '=', label: '=' }, 
            { value: '!=', label: '!=' }, 
            { value: '<', label: '<' },
            { value: '<=', label: '<=' },
            { value: '>', label: '>' },
            { value: '>=', label: '>=' },
        ],
        "CURRENCY":[
            { value: '=', label: '=' }, 
            { value: '!=', label: '!=' }, 
            { value: '<', label: '<' },
            { value: '<=', label: '<=' },
            { value: '>', label: '>' },
            { value: '>=', label: '>=' },
        ],
        "DATE":[
            { value: 'Is', label: 'Is' }, 
            { value: 'Is Not', label: 'Is Not' }, 
            { value: 'Before', label: 'Before' },
            { value: 'After', label: 'After' },
            { value: 'Between', label: 'Between' },
        ],
    }
    const [lastOptionValues,setLastOptionValues] = useState([]);
    const [middleOptionValues,setMiddleOptionValues] = useState({...MiddleOptions});
    useEffect(e=>{
        const backendUrl = Config.backend_url+"getAllDealPages";
        var config = {
            'token': localStorage.getItem('token')
    };
    axios.post(backendUrl, {...config})
    .then((res)=> {
      if(res.data.data){
        setAllDeals(res.data.data);
      }
    }).catch(e=>{

    })
    },[])
    useEffect(e => {
        setCriteriaFieldGroups(mainFields);
    }, [mainFields])
    useEffect(e => {
        if(criteriaRows?.length>0){
            let arr=[...criteriaRows];
            // if(criteriaFieldGroups["Input Fields"]?.length>0){
            //     arr=arr.filter(k=>{
            //         if(k.group!="Input Fields" || criteriaFieldGroups["Input Fields"]?.find(j=>j.id==k.id)){
            //             return k
            //         }
            //     }) 
            // }
            // if(arr?.length==0){
            //     arr.push({ id: Date.now() });
            // }
            setRows(arr)
        }else{
            setRows([{ id: Date.now() }])
        }
    }, [criteriaRows,action,criteriaFieldGroups])
    const isConditionsCompleted=()=>{
        if(rows){
            let arr=rows.filter(row=>!row.initial || !row.middel || !row.last);
            if(arr?.length>0 && !["Is Empty","Is Not Empty"].includes(arr[0].middel)){
                return false;
            }
        }
        return true;
    }
    function handleChangeCriteria(row,option,value, type, index,group) {
        let obj = [...rows];
        if (obj[index]) {
            obj[index][type] = value;
            if(option && option.type){
                obj[index]["type"] = option.type;
            }
            if(group){
                obj[index]["group"] = group;
            }

            // other conditions 
            if(type=="initial"){
                obj[index]["middel"] ="";  
                obj[index]["last"] = obj[index]?.type=="DATE"?new Date():"";   
            }
            if(type=="middel"){
                obj[index]["last"] = obj[index]?.type=="DATE"?new Date():"";  
                if(row && row.type == "DATE" && value=="Between"){
                    obj[index]["last"] = {startDate: new Date(),endDate: new Date(),key: 'selection' }
                }  
            }
        } 
        
        if(!isConditionsCompleted()){
            setError(false);
        }
        setRows(obj)
    }
    
    const handleAddRow = () => {
        if(isConditionsCompleted()){
            setRows([...rows, { id: Date.now(),condition:"AND" }]); // Add new row with unique id
        }else{
            setError(true);
        }
    };
    const handleRemoveRow = (id, keyToRemove) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        if(keyToRemove==0){
            if(updatedRows?.length!=0 && updatedRows[0].condition){
                delete updatedRows[0].condition
            }else{
                updatedRows.push({ id: Date.now() });
            }
        }
        setRows(updatedRows);
    };
    const getOptions=(row,index)=>{
        if(row?.group=="Project"){
            if(row &&  row.initial && row.middel && AllDeals){
                let arr = _.uniqBy(AllDeals, row.initial)?.map(deal=>({label:deal[row.initial],value:deal[row.initial]}))
                return arr;
            }
        }else if(row?.group=="Task"){
            if(row &&  row.initial && row.middel && AppDataReq.AllTasks){
                let arr = _.uniqBy(AppDataReq.AllTasks, row.initial)?.map(deal=>({label:deal[row.initial],value:deal[row.initial]}))
                return arr;
            }
        }
        return defaultOptions;
    }
    const getLastOptionComponent = (row,index) => {

        if(["TEXT","PHONE","DEFAULT"].includes(row.type)){
            return ["Contains","Starts With","Ends With"].includes(row.middel) ?
                <><Input
                    style={{ width: "100%", height: 'auto' }}
                    value={row.last}
                    placeholder="Type.."
                    id='last'
                    onChange={(e) => handleChangeCriteria(row,null,e.currentTarget.value, 'last', index)}
                /></>:
                ["Is Empty","Is Not Empty"].includes(row.middel)? <></>: <Select
                    // mode="multiple"
                    showSearch
                    style={{ width: "100%", height: 'auto' }}
                    value={row.last}
                    placeholder="Select"
                    id='last'
                    onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'last', index)}
                    // options={getOptions(row,index)}
                >
                    {getOptions(row,index)?.map(opt=>{
                        return (<Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>)
                    })}    
                </Select>
        }else if(["NUMBER"].includes(row.type)){
            return [">","<",">=","<="].includes(row.middel) ?
                <><InputNumber
                    style={{ width: "100%", height: 'auto' }}
                    value={row.last}
                    step={1}
                    precision={0}
                    placeholder="Type.."
                    id='last'
                    onChange={(e) => handleChangeCriteria(row,null,e, 'last', index)}
                /></>
                : <Select
                    showSearch
                    // mode="multiple"
                    style={{ width: "100%", height: 'auto' }}
                    value={row.last}
                    placeholder="Select"
                    id='last'
                    onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'last', index)}
                    // options={getOptions(row,index)}
                >
                    {getOptions(row,index)?.map(opt=>{
                        return (<Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>)
                    })}    
                </Select>
        }else if(["CURRENCY"].includes(row.type)){
            return [">","<",">=","<="].includes(row.middel) ?
                <><NumericFormat
                className="custome-currency-input"
                name="currency"
                step={row?.Precision ? Number(row?.Precision) : 1}
                placeholder="Enter amount"
                value={row.last}
                defaultValue={0}
                thousandSeparator
                decimalScale={row?.Precision}
                // decimalsLimit={2} // Sets the precision to 2 decimal places
                onValueChange={(value) =>  handleChangeCriteria(row,null,value.floatValue, 'last', index)}
                prefix="$"
                // intlConfig={{ locale: 'en-US', currency: 'USD' }}
              /></>
                : <Select
                    showSearch
                    // mode="multiple"
                    style={{ width: "100%", height: 'auto' }}
                    value={row.last}
                    placeholder="Select"
                    id='last'
                    onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'last', index)}
                    // options={getOptions(row,index)}
                >
                    {getOptions(row,index)?.map(opt=>{
                        return (<Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>)
                    })}    
                </Select>
        }else if(["DATE"].includes(row.type)){
            return row.middel != "Between" ?
                <><DatePicker
                    onChange={(date, dateString) => {
                        handleChangeCriteria(row, null, dateString, 'last', index)
                    }}
                    style={{ width: "100%", height: 'auto' }}
                    value={dayjs(row.last)}
                    placeholder="Type.."
                    id='last'
                /></>
                : <>
                    <DateRangePickerNew
                        key={index}
                        onChange={(item)=>handleChangeCriteria(row,null,item, 'last', index)}
                        ranges={row.last}
                    />
                </>
        }else if(["BOOLEAN","CHECKBOX"].includes(row.type)){
            return <><Select
            showSearch
                // mode="multiple"
                style={{ width: "100%", height: 'auto' }}
                value={row.last}
                placeholder="Select"
                id='last'
                onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'last', index)}
            >
                <Option key={true} value={true}>
                    {"True"}
                </Option>
                <Option key={false} value={false}>
                    {"False"}
                </Option>    
            </Select></>
                
        }else if(["USER"].includes(row.type)){
            return <>
            <NewUserPicker
                mode="multiple"
                values={row.last}
                onChange={d=>{
                    handleChangeCriteria(row,null,d, 'last', index)
                }}
            /></>
                
        }else if(["PICKLIST","MULTI-PICKLIST"].includes(row.type)){
            let tempOptions=[];
            if(["Is Empty","Is Not Empty"].includes(row.middel)){
                return;
            }
            if(row.initial && row.group=="Input Fields"){
                let arr=mainFields["Input Fields"]?.find(k=>k.id==row.initial)?.ExtraData;
                if(Array.isArray(arr)){
                    tempOptions=[...arr];
                }
                return <><Select
                showSearch
                    mode={`${row.type == "MULTI-PICKLIST" ? "multiple" : ""}`}
                    style={{ width: "100%", height: 'auto' }}
                    value={row.type == "MULTI-PICKLIST" && !row.last ? [] : row.last}
                    placeholder="Select"
                    id='last'
                    onChange={(option) => handleChangeCriteria(row, null, option, 'last', index)}
                >
                    {tempOptions?.map((o,i )=> {
                        return (<Option key={i} value={o.id}>
                            {o.text}
                        </Option>)
                    })}
                </Select></>
            }else{
                tempOptions = getPickListOptions("Project_Status",state);
                return <><Select
                showSearch
                mode={`${row.type=="MULTI-PICKLIST"?"multiple":""}`}
                style={{ width: "100%", height: 'auto' }}
                value={row.type=="MULTI-PICKLIST" && !row.last?[]:row.last}
                placeholder="Select"
                id='last'
                onChange={(option) => handleChangeCriteria(row,null,option, 'last', index)}
            >
                {tempOptions?.map((o,i)=>{
                    return (<Option className="mb-1" style={ o.color && {backgroundColor:o.color,color:invertColor(o.color,true)}} key={i} value={o.value}>
                        {o.name}
                    </Option>)
                })}
            </Select></>
            }
                
        }

        // default box
        return <><Input
            style={{ width: "100%", height: 'auto' }}
            value={row.last}
            placeholder="Type.."
            id='last'
            onChange={(e) => handleChangeCriteria(row, null, e.currentTarget.value, 'last', index)}
        /></>
    }
    return (
        <div>
            <Modal
                open={open}
                // open={false}
                wrapClassName={"Add-criteria-Wrapper"}
                rootClassName="Add-criteria-Root-Wrapper"
                draggable
                title={"Transition Criteria"}
                maskClosable={false}
                width={600}
                style={{ bottom: '2rem', right: '9rem', zIndex: "1000", minHeight: "" }}
                onCancel={() => setOpen(false)}
                footer={[
                    <div className='Criteria-Footer'>  {error ? <span className='Error' color='red'>
                       Please fill all required fields.
                    </span>:<span></span>}
                        <Button type="primary" onClick={() => {
                           onSave(rows)
                           setOpen(false)
                        }}>Save</Button>
                    </div>
                ]}
            >

                {rows.map((row, index) => { 
                    return <>
                    <Row key={row.id} style={{display: "flex","align-items":"center",marginBottom: '10px' }} align="middle">
                        <Col sm={1}>
                            {index > 0 && <div className='Criteria-ToggleButton'>
                                <Switch
                                    checked={row?.condition == "AND" ? true : false}
                                    checkedChildren={"AND"}
                                    unCheckedChildren={"OR"}
                                    onChange={(checked, event) => {
                                        const condition = checked ? "AND" : "OR";
                                        handleChangeCriteria(row,null,condition, 'condition', index)
                                    }}
                                />
                            </div>}
                        </Col>
                        <Col className="pr-0 mr-0" sm={3}>
                            <Select
                                // mode="multiple"
                                style={{ width: "100%", height: 'auto' }}
                                placeholder="Select"
                                value={row.initial}
                                id='initial'
                                onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'initial', index,option.group)}
                            >
                                {Object.keys(criteriaFieldGroups)?.map(group => {
                                    return <OptGroup label={group}>
                                        {group!="Input Fields"?criteriaFieldGroups[group]?.map(e => (
                                            <Option key={e.ROWID} type={e.type} group={group} value={e?.field}>{e?.label}</Option>
                                        )) : criteriaFieldGroups[group]?.map(e => (
                                            <Option key={e.id} type={e.Type} group={group} value={e?.id}>{e?.Name}</Option>
                                        ))}
                                    </OptGroup>
                                })}

                            </Select>
                        </Col>
                        <Col sm={3}>
                            <Select
                                // mode="multiple"
                                value={row.middel}
                                style={{ width: "100%", height: 'auto' }}
                                placeholder="Select"
                                id='middel'
                                onChange={(e, option) => handleChangeCriteria(row,option,option.value, 'middel', index)}
                                options={row.type?middleOptionValues[row.type]:middleOptionValues["DEFAULT"]}
                            />
                        </Col>
                        <Col className='p0m0' sm={4}>
                                {getLastOptionComponent(row,index)}
                        </Col>
                        <Col className='p0m0' sm={1}>
                            <Button
                                icon={<MinusCircleOutlined />}
                                ghost
                                onClick={() => handleRemoveRow(row.id, index)}
                                style={{ border: "none" }}
                                danger
                            />
                            <Button
                                onClick={handleAddRow}
                                icon={<PlusCircleOutlined width={10} height={10} />}
                                style={{ border: "none" }}
                            />
                        </Col>
                    </Row></>
                })}
            </Modal>
        </div>
    )
}
