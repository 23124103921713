import { resolve } from "mathjs";
import TaskService from "../../common/util/TaskService";
import { reject } from "lodash";
import AwsServerService from "../../common/util/AwsServerService";

export function modifyEdgesAndNodes(edges, nodes, filteredEdgeIds) {
      
    if (edges && nodes && filteredEdgeIds) {
        // Step 1: Create a Set of node IDs and edge keys (source-target pairs) from filtered edges
        const unvisitedNodeIds = new Set();
        const unvisitedEdgeKeys = new Set();
        let filteredEdges = edges.filter(ed => filteredEdgeIds.includes(ed.id));
        // Add the nodes and edges from filteredEdges to the sets
        filteredEdges.forEach(edge => {
            unvisitedNodeIds.add(edge.source);
            unvisitedNodeIds.add(edge.target);
            unvisitedEdgeKeys.add(`${edge.source}-${edge.target}`);
        });

        // Step 2: Modify the nodes
        nodes = nodes.map(node => {
            if (unvisitedNodeIds.has(node.id)) {
                node.data["disable"] = false; // Mark as not disabled if in the filtered edges
            } else {
                node.data["disable"] = true; // Otherwise, mark as disabled
            }
            return node;
        });

        // Step 3: Modify the edges
        edges = edges.map(edge => {
            const edgeKey = `${edge.source}-${edge.target}`;
            if (edge["data"]) {
                if (unvisitedEdgeKeys.has(edgeKey)) {
                    edge["data"]["disable"] = false; // Mark as not disabled if in the filtered edges
                } else {
                    edge["data"]["disable"] = true; // Otherwise, mark as disabled
                }
            }
            return edge
        });

        return { updatedNodes: nodes, updatedEdges: edges };
    } else {
        return;
    }

}
//     // Step 1: Create an adjacency list and an edge map from the edges array

//     const adjacencyList = {};
//     const edgeMap = {};

//     edgesArray.forEach((e) => {
//         const { source, target } = e;
//         if (!adjacencyList[source]) {
//             adjacencyList[source] = [];
//         }
//         adjacencyList[source].push(target);
//         edgeMap[`${source}-${target}`] = e; // Map source-target pair to the edge object
//     });

//     // Step 2: Define variables to track visited nodes, the path, and edges in a cycle
//     const visited = new Set();
//     const path = [];
//     let cycleEdges = [];

//     // Step 3: Helper function to perform DFS for cycle detection
//     const dfs = (currentNode, targetNode) => {
//         if (currentNode === targetNode) {
//             // We've found a cycle, add edges to cycleEdges array
//             path.forEach((edge) => cycleEdges.push(edge));
//             return true;
//         }

//         visited.add(currentNode);

//         if (adjacencyList[currentNode]) {
//             for (let neighbor of adjacencyList[currentNode]) {
//                 if (!visited.has(neighbor)) {
//                     // Add the edge to the path
//                     const edgeKey = `${currentNode}-${neighbor}`;
//                     path.push(edgeMap[edgeKey]);

//                     // Perform DFS on the neighbor
//                     const result = dfs(neighbor, targetNode);
//                     if (result) {
//                         return true; // Stop searching if a cycle is found
//                     }

//                     // Backtrack if no cycle found
//                     path.pop();
//                 }
//             }
//         }

//         return false;
//     };

//     // Step 4: Check if the current edge is a "reject" edge
//     if (currentEdge.sourceHandle === "reject") {
//         // Perform DFS to find if the edge is part of a cycle
//         visited.clear(); // Clear previous visited nodes
//         dfs(currentEdge.target, currentEdge.source);

//         // Step 5: If a cycle is detected, remove the "visited" flag from edges in the cycle
//         if (cycleEdges.length > 0) {
//             edgesArray = edgesArray.map((e) => {
//                 if (cycleEdges.includes(e)) {
//                     let temp=e.data
//                     const { visited, ...rest } = temp;
//                     temp.data={ ...rest };
//                     return temp;
//                 }
//                 return e;
//             });
//         }
//     } else {
//         // Step 6: If the edge is not rejected, mark it as visited
//         let obj;
//         edgesArray = edgesArray.map((e) => {
//             if (e.id === currentEdge.id) {
//                 let temp=e.data
//                 const { visited, ...rest } = temp;
//               //   updateEdgeVisitedStatus()
//                 obj={ ...e,data:{...rest,visited:true}}
//                 return obj;
//             }
//             return e;
//         });
//         if(obj){
//             edgesArray = updateEdgeVisitedStatus(obj,edgesArray)
//         }
//     }

//     // Step 7: Return the modified edges array
//     return edgesArray;
//   };
export const processEdgeConnections = (currentEdge, edgesArray) => {
    // Step 1: Create an adjacency list and an edge map from the edges array
    const adjacencyList = {};
    const edgeMap = {};

    edgesArray.forEach((e) => {
        const { source, target } = e;
        if (!adjacencyList[source]) {
            adjacencyList[source] = [];
        }
        adjacencyList[source].push(target);
        edgeMap[`${source}-${target}`] = e; // Map source-target pair to the edge object
    });

    // Step 2: Define variables to track visited nodes, the path, and edges in a cycle
    const visited = new Set();
    const path = [];
    let cycleEdges = [];

    // Step 3: Helper function to perform DFS for cycle detection
    const dfs = (currentNode, targetNode) => {
        if (currentNode === targetNode) {
            // We've found a cycle, add edges to cycleEdges array
            path.forEach((edge) => cycleEdges.push(edge));
            return true;
        }

        visited.add(currentNode);

        if (adjacencyList[currentNode]) {
            for (let neighbor of adjacencyList[currentNode]) {
                if (!visited.has(neighbor)) {
                    // Add the edge to the path
                    const edgeKey = `${currentNode}-${neighbor}`;
                    path.push(edgeMap[edgeKey]);

                    // Perform DFS on the neighbor
                    const result = dfs(neighbor, targetNode);
                    if (result) {
                        return true; // Stop searching if a cycle is found
                    }

                    // Backtrack if no cycle found
                    path.pop();
                }
            }
        }

        return false;
    };

    // Step 4: Check if the current edge is a "reject" edge
    if (currentEdge.sourceHandle === "reject") {
        // Perform DFS to find if the edge is part of a cycle
        visited.clear(); // Clear previous visited nodes
        dfs(currentEdge.target, currentEdge.source);

        // Step 5: If a cycle is detected, remove the "visited" flag from edges in the cycle
        if (cycleEdges.length > 0) {
            edgesArray = edgesArray.map((e) => {
                if (cycleEdges.includes(e)) {
                    let temp = e.data
                    if (temp?.hasOwnProperty("visited")) {
                        delete temp["visited"];
                    }
                    // const { visited, ...rest } = temp;
                    e.data = { ...temp };
                    return e;
                }
                return e;
            });
        }
    } else {
        // Step 6: If the edge is not rejected, mark it as visited
        let obj;
        edgesArray = edgesArray.map((e) => {
            if (e.id === currentEdge.id) {
                let temp = e.data
                // const { visited, ...rest } = temp;
                //   updateEdgeVisitedStatus()
                obj = { ...e, data: { ...temp, visited: true } }
                return obj;
            }
            return e;
        });
        //   if(obj){
        //       edgesArray = updateEdgeVisitedStatus(obj,edgesArray)
        //   }
    }

    // Step 7: Return the modified edges array
    return edgesArray;
};
export function updateNodeVisitedStatus(nodes, edges) {
    // Create a map to track visited status for each node based on its edges
    const visitedStatusMap = new Map();

    // Initialize all nodes with visited = true (default assumption if no incoming edges)
    nodes.forEach(node => {
        visitedStatusMap.set(node.id, true);
    });

    // Iterate over the edges to track visited status for each target node
    edges.forEach(edge => {
        const { target, data: { visited: edgeVisited } = {} } = edge;

        // If any edge has visited = false, mark the target node as false in the map
        if (!edgeVisited) {
            visitedStatusMap.set(target, false);
        }
    });

    // Iterate through the nodes and modify their visited status based on the map
    return nodes.map(node => {
        const { id, data } = node;
        const nodeVisited = visitedStatusMap.get(id);

        // If all incoming edges have visited=true (or no edges), add visited=true to the node
        if (nodeVisited) {
            return {
                ...node,
                data: {
                    ...data,
                    visited: true
                }
            };
        } else {
            // If not all incoming edges have visited=true, remove the visited key from the node
            const { visited, ...remainingData } = data;
            return {
                ...node,
                data: remainingData
            };
        }
    });
}
export const getDefaultValues = (type) => {
    switch (type) {
        case "DATE":
            return new Date();
            break;
        case "NUMBER":
            return 0;
            break;
        case "TEXT":
            return "";
            break;
        default:
            break;
    }
}
function mapKeysInArrayAsync(arr, keysFrom, keysTo) {
    if (keysFrom.length !== keysTo.length) {
        reject(new Error("keysFrom and keysTo arrays must have the same length"));
        return;
    }

    const result = arr.map((obj) => {
        let newObj = {};
        keysFrom.forEach((key, index) => {
            if (key in obj) {
                newObj[keysTo[index]] = obj[key];
                if (newObj?.hasOwnProperty(key)) {
                    delete newObj[key];
                }
            }
        });

        return newObj;
    });

    return result;
}
export const getStatusOptions = () => {
    return new Promise((resolve, reject) => {
        TaskService.GetDealStatus()
            .then((res) => {
                let arr = mapKeysInArrayAsync(res, ["id", "actual_value", "display_value", "colour_code"], ["id", "value", "name", "color"])
                resolve(arr);
            }).catch((err) => {
                console.log(err);
            });
    })
}
export const getPickListOptions = (key, state) => {
    switch (key) {
        case "Project_Status":
            return state.DealStatusOptions;
            break;
        default:
            break;
    }
}
export function getUpcomingNodes(nodeId, nodes, edges) {
    const result = new Set();
    function findUpcomingNodes(currentNodeId) {
        const connectedEdges = edges.filter(edge => edge.source === currentNodeId);

        connectedEdges.forEach(edge => {
            const targetNode = nodes.find(node => node.id === edge.target);
            if (targetNode && !result.has(targetNode.id)) {
                result.add(targetNode.id);
                findUpcomingNodes(targetNode.id);
            }
        });
    }

    findUpcomingNodes(nodeId);
    return Array.from(result).map(id => {
        let n = nodes.find(node => node.id === id);
        if (n && n.type != "diverse" && n.id != nodeId) {
            return n;
        }
    }).filter(n => {
        if (n) {
            return n;
        }
    });
}
export function checkSingleBackflowPath(source1, source2, edges) {
    let isValid = true;
    if (source1 == source2) {
        return false;
    }
    let backEd = edges.filter(e => e.target == source2);
    // let arr=edges.filter(e=>e.source==backEd.source);
    if (backEd.length == 1 && backEd[0].source != source1) {
        isValid = checkSingleBackflowPath(source1, backEd[0].source, edges);
    } else if (backEd.length == 1 && backEd[0].source == source1) {
        isValid = false;
    }
    return isValid
}
//   export function checkSingleBackflowPath(source1, source2, edges) {
//     let isValid=true;
//     if(source1==source2){
//         return false;
//     }
//     let backEd=edges.filter(e=>e.target==source2);
//     // let arr=edges.filter(e=>e.source==backEd.source);
//     if(backEd.length==1 && backEd[0].source!=source1){
//         isValid = checkSingleBackflowPath(source1,backEd[0].source,edges);
//     }else if(backEd.length==1 && backEd[0].source==source1){
//         isValid = false;
//     }
//     return isValid
// }
export function getDateDifference(date1, date2) {
    try {

        const startDate = new Date(date1);
        const endDate = new Date(date2);

        if (isNaN(startDate) || isNaN(endDate)) {
            throw new Error("Invalid date format");
        }

        const differenceInMillis = Math.abs(endDate - startDate);

        if (differenceInMillis <= 0) {
            return "0s";
        }

        const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24)); // Days
        const hours = Math.floor((differenceInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Hours
        const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60)); // Minutes
        const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000); // Seconds

        const result = [
            days > 0 ? `${days}d` : '',
            hours > 0 ? `${hours}h` : '',
            minutes > 0 ? `${minutes}m` : ''
            // `${seconds}s`
        ]
            .filter(Boolean)
            .join(' ');

        return result;
    } catch (error) {

        return ``;
    }
}

export function hasInvalidDependency(dependencies, newDependency) {
    
    const graph = {};

    dependencies.forEach(dep => {
        const sourceKey = `${dep.SourceTaskId}_${dep.SourceDependencyStatus}`;
        const targetKey = `${dep.TargetTaskId}_${dep.TargetDependencyStatus}`;
        let targetKey1;
        if((sourceKey.includes('S') && targetKey.includes('F'))){
            targetKey1 = `${dep.TargetTaskId}_S`;
        }
        else if(sourceKey.includes('F') && targetKey.includes('S')){
            targetKey1 = `${dep.TargetTaskId}_F`;
        }
        else if(sourceKey.includes('F') && targetKey.includes('F')){
            targetKey1 = `${dep.TargetTaskId}_S`;
        }else if(sourceKey.includes('S') && targetKey.includes('S')){
            targetKey1 = `${dep.TargetTaskId}_F`;
        }

        if (!graph[sourceKey]) {
            graph[sourceKey] = [];
        }
        graph[sourceKey].push({ target: targetKey, type: dep.DependancyType });
        if(targetKey1){
            graph[sourceKey].push({ target: targetKey1, type: dep.DependancyType });
        }
    });

    const newSourceKey = `${newDependency.SourceTaskId}_${newDependency.sourceStatus}`;
    const newTargetKey = `${newDependency.TourceTaskId}_${newDependency.targetStatus}`;
    let newtargetKey1;

    if(newSourceKey.includes('S') && newTargetKey.includes('F')){
        newtargetKey1 = `${newDependency.TourceTaskId}_S`;
    }
    else if(newSourceKey.includes('F') && newTargetKey.includes('S')){
        newtargetKey1 = `${newDependency.TourceTaskId}_F`;
    }
    else if(newSourceKey.includes('F') && newTargetKey.includes('F')){
        newtargetKey1 = `${newDependency.TourceTaskId}_S`;
    }else if(newSourceKey.includes('S') && newTargetKey.includes('S')){
        newtargetKey1 = `${newDependency.TourceTaskId}_F`;
    }
    if (!graph[newSourceKey]) {
        graph[newSourceKey] = [];
    }
    graph[newSourceKey].push({ target: newTargetKey, type: newDependency.dependencyType });
    if(newtargetKey1){
        graph[newSourceKey].push({ target: newtargetKey1, type: newDependency.dependencyType });
    }
    
    console.log(graph)

    function detectCycle(node, visited, stack) {
        if (!graph[node]) return false; 

        visited[node] = true;
        stack[node] = true;

        for (const neighbor of graph[node]) {
            if (neighbor.type === newDependency.dependencyType) { 
                const target = neighbor.target;
                if (!visited[target] && detectCycle(target, visited, stack)) {
                    return true;
                } else if (stack[target]) {
                    return true; 
                }
            }
        }

        stack[node] = false; 
        return false;
    }

    const visited = {};
    const stack = {};
    for (const node in graph) {
        if (!visited[node] && detectCycle(node, visited, stack)) {
            console.log("Error: Circular dependency detected.");
            return true; 
        }
    }

    return false; 
}
export const getUserProfileUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
};