import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import './JoditEditor.css'

const JoditTextEditor = (props) => {
	const { preValues , isDraftAlready, contentShowFlag , composeMailToShow, setEmailContent, id, commentContent} = props;
    const editor = useRef(null);
	const [content, setContent] = useState('');

	useEffect(e=>{
		if(commentContent != undefined){
			setContent(commentContent)
		}
	}, [commentContent])
	useEffect(e=>{
		if(id == "email"){
			setEmailContent(content)
		}else if(id == "transition"){
			setEmailContent(content)
		}
	}, [content])


	let forwardContent = id == "email" ? `
    <hr><pre class='forward-content'}>
      ============ Forwarded message ============
      From: ${composeMailToShow?.from}
      To: ${(composeMailToShow?.to)?.map((item) => item.email).join("; ")}
      ${composeMailToShow?.cc?.length > 0 ? `Cc: ${(composeMailToShow?.cc)?.map((item) => item.email).join("; ")}` : ""}
      ${composeMailToShow?.bcc?.length > 0 ? `Bcc: ${(composeMailToShow?.bcc)?.map((item) => item.email).join("; ")}` : ""}
      Subject: ${composeMailToShow?.subject}
      ============ Forwarded message ============</pre>
  `
  .trim()
  .split("\n")
  .filter(line => line.trim().length > 0)
  .join("\n") : "";

	  useEffect(() => {
		if(id=="email"){
		  let sign;
		  if(contentShowFlag == "forward"){
			sign = "<br><br>" + props.signature + forwardContent + preValues;
		  }else{
		  sign = props.signature
		  }
		  let signatureWithBreaks;
		  if(!isDraftAlready){
			if(preValues){
			  if(contentShowFlag == "forward"){
				signatureWithBreaks =`${sign}`;
			  }else{
				signatureWithBreaks = `${preValues}<br><br>${sign}`;
			  }
			}else{
			  signatureWithBreaks = `<br><br>${sign}`;
			}
			
			// const newContent = RichTextEditor.createValueFromString(signatureWithBreaks, 'html');
			setContent(signatureWithBreaks);
		  }else{
			if(preValues){
				signatureWithBreaks =`${preValues}`;
			  }else{
			  signatureWithBreaks = `<br><br>${sign}`;
			}
			setContent(signatureWithBreaks);
		  }
		}
		// Set the signature text upon component mount
	  }, [props?.signature, preValues]);

	  const config = useMemo(() => ({
		toolbar: true,
		buttons: [
		  'bold',
		  'italic',
		  'underline',
		  'strikethrough',
		  '|',
		  'font',
		  'fontsize',
		  'brush',
		  'copyformat',
		  '|',
		  'eraser',
		  '|',
		  'align',
		  '|',
		  'ul',
		  'ol',
		  'hr',
		  '|',
		  'paragraph',
		  '|',
		  'table',
		  '|',
		  'speechRecognize',
		  'spellcheck',
		  '|',
		  'undo',
		  'redo',
		  '|',
		  'print'
		],
		removeButtons: [
		  'video',
		  'superscript',
		  'subscript',
		  'file',
		  'indent',
		  'outdent',
		  'formatPainter',
		  'link',
		  'unlink',
		  'source',
		  'code',
		  'symbol',
		  'selectall',
		  'cutformat',
		  'copy',
		  'cut',
		  'paste',
		  'copyhtml',
		  'cuthtml',
		  'insertImage',
		  'filebrowser',
		  'about',
		  "breakline",
		],
	buttonsMD: ['bold', 'italic','underline','strikethrough','ul','ol','font','fontsize','align','dots'], // Medium screens
    buttonsSM: ['bold', 'italic','underline','strikethrough','ul','ol','font','fontsize', 'copyformat', 'dots'],          // Small screens
    buttonsXS: ['bold', 'italic','underline','strikethrough','ul','ol','font','fontsize',  'dots'] 
	  }), []);

	  const handleChangeEditor = (e) =>{
		setContent(e); 
	}
	
  	return (<div className='jodit-text-editor-wrapper'>
    	<JoditEditor
			ref={editor}
			value={content}
			tabIndex={1} 
			config={config}
			onChange={(e)=>handleChangeEditor(e)}
		/>
  </div>
  )
}

export default JoditTextEditor