import moment from "moment";
import momentTimeZone from "moment-timezone";
import Geocode from "react-geocode";
import AttendenceService from "../../../common/util/AttendenceService";
import { resolve } from "mathjs";
import dayjs from "dayjs";
import { reject } from "lodash";
Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export function getWorkingHours(startDate, endDate) {

  // const [inHour, inMinute, inPeriod] = intime.split(/:| /).map((val, index) => index === 2 ? val.toUpperCase() : parseInt(val));
  // const [outHour, outMinute, outPeriod] = outtime.split(/:| /).map((val, index) => index === 2 ? val.toUpperCase() : parseInt(val));

  // let startHour = inPeriod === 'PM' && inHour !== 12 ? inHour + 12 : inHour;
  // let endHour = outPeriod === 'PM' && outHour !== 12 ? outHour + 12 : outHour;

  // if (inPeriod === 'AM' && inHour === 12) startHour = 0;
  // if (outPeriod === 'AM' && outHour === 12) endHour = 0;

  let workingHours = {};
  // let startDate = new Date(date);
  //startDate.setHours(startHour, inMinute, 0, 0);
  // let endDate = new Date(date);
  // endDate.setHours(endHour, outMinute, 0, 0);

  workingHours["start"] = startDate.toISOString();
  if ((startDate.getDate() + 1) == endDate.getDate()) {
    let midEndDateTime = startDate;
    midEndDateTime.setDate(startDate.getDate());
    midEndDateTime.setHours(23, 59, 0);
    // endDate.setDate(endDate.getDate() + 1);
    workingHours["end"] = endDate.toISOString();
    let midStartDateTime = endDate;
    midStartDateTime.setHours(0, 0, 0);
    workingHours["midEnd"] = midEndDateTime.toISOString();
    workingHours["midStart"] = midStartDateTime.toISOString();
  } else {
    workingHours["end"] = endDate.toISOString();
  }
  console.log("data", workingHours)
  return modifyOutput(workingHours);
}
function modifyOutput(workingHours) {
  let arr = []
  if (workingHours.midEnd && workingHours.midStart) {
    arr.push({
      date: new Date(workingHours.start),
      in: new Date(workingHours.start),
      out: new Date(workingHours.midEnd)
    })
    arr.push({
      date: new Date(workingHours.midStart),
      in: new Date(workingHours.midStart),
      out: new Date(workingHours.end)
    })
  } else if (workingHours.start && workingHours.end) {
    arr.push({
      date: getWorkingHoursAndMinutes(
        new Date(workingHours.start), new Date(workingHours.end)
      )
    })
  }
  return arr;
}
export function getWorkingHoursAndMinutes(startDateTime, endDateTime) {

  // startDateTime?.setSeconds(0);
  // endDateTime?.setSeconds(0);
  const totalMinutes = Math.abs(endDateTime - startDateTime) / (1000 * 60); // Absolute difference in minutes
  let workingHours = Math.floor(totalMinutes / 60); // Extract hours
  let workingMinutes = Math.floor(totalMinutes % 60); // Extract minutes
  if (workingMinutes == "59") {
    workingHours += 1;
    workingMinutes = 0;
  }
  return { hours: workingHours, minutes: workingMinutes };
}
export function getWorkingHoursMinutesAndSeconds(startDateTime, endDateTime) {
  // Ensure consistent formatting by setting milliseconds to 0
  startDateTime?.setMilliseconds(0);
  endDateTime?.setMilliseconds(0);

  const totalSeconds = Math.abs(endDateTime - startDateTime) / 1000; // Absolute difference in seconds
  let workingHours = Math.floor(totalSeconds / 3600); // Extract hours
  let workingMinutes = Math.floor((totalSeconds % 3600) / 60); // Extract minutes
  let workingSeconds = Math.floor(totalSeconds % 60); // Extract seconds

  // Adjust time values if minutes are 59 or seconds are 59
  if (workingSeconds === 59) {
    workingMinutes += 1;
    workingSeconds = 0;
  }
  if (workingMinutes === 60) {
    workingHours += 1;
    workingMinutes = 0;
  }

  return {
    hours: workingHours,
    minutes: workingMinutes,
    seconds: workingSeconds,
  };
}
export function getTimeInAMPMFormat(datestr, skip) {
  if (!skip) {
    datestr = modifyTimezoneString(datestr, "MM/DD/YYYY HH:mm:ss");
  }
  const currentDate = new Date(datestr);
  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const formattedHours = hours < 10 ? '0' + hours : hours;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

// export function getDeviceId() {
//   let deviceId = '';
//   if (navigator.userAgent) {
//     deviceId += navigator.userAgent;
//   }

//   if (navigator.platform) {
//     deviceId += navigator.platform;
//   }

//   if (navigator.language) {
//     deviceId += navigator.language;
//   }
//   deviceId = hashFunction(deviceId);
//   return deviceId;
// }

// Example hash function
function hashFunction(input) {
  // This is just a placeholder, you should use a proper hashing algorithm
  return input.split('').reduce((hash, char) => {
    return hash + char.charCodeAt(0);
  }, 0);
}
export function addTimeStrings(time1, time2) {
  if (!time1) {
    time1 = "00:00";
  }
  if (!time2) {
    time2 = "00:00";
  }
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  let totalHours = hours1 + hours2;
  let totalMinutes = minutes1 + minutes2;

  // Handle carryover
  if (totalMinutes >= 60) {
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
  }

  // Format result
  const formattedHours = totalHours < 10 ? '0' + totalHours : totalHours;
  const formattedMinutes = totalMinutes < 10 ? '0' + totalMinutes : totalMinutes;

  return `${formattedHours}:${formattedMinutes}`;
}
export const updateWorkingHoursIfExist = (newObject, cacheRowData) => {
  let modifiedObj = { ...newObject };
  let isExist = cacheRowData?.find(old => {
    if (moment(new Date(old.TimesheetDate)).format('YYYY-MM-DD') == moment(newObject.TimesheetDate).format('YYYY-MM-DD')
      && (old.email == newObject.email)
    ) {
      return old;
    }
  })
  if (isExist) {
    isExist.checkIn = new Date((isExist.TimesheetDate + ", " + isExist.checkIn))?.toLocaleString();
    isExist.checkOut = new Date((isExist.TimesheetDate + ", " + isExist.checkOut))?.toLocaleString();
    let timeObj = getWorkingHoursAndMinutes(new Date(newObject.checkIn), new Date(newObject.checkOut));
    let workHrours = addTimeStrings(isExist.TotalHours, timeObj.hours + ":" + timeObj.minutes)
    if (new Date(newObject.checkIn) <= new Date(isExist.checkIn)) {
      modifiedObj.checkIn = newObject.checkIn;
    } else if (new Date(newObject.checkIn) >= new Date(isExist.checkIn)) {
      modifiedObj.checkIn = isExist.checkIn
    }
    if (new Date(newObject.checkOut) >= new Date(isExist.checkOut)) {
      modifiedObj.checkOut = newObject.checkOut;
    } else if (new Date(newObject.checkOut) <= new Date(isExist.checkOut)) {
      modifiedObj.checkOut = isExist.checkOut;
    }
    modifiedObj.ROWID = isExist.ROWID;
    modifiedObj.TotalHours = workHrours;
    return { data: modifiedObj, isEdit: true };
  } else {
    return { data: newObject, isEdit: false };
  }
}
export const updateWorkingHoursIfExistForRequest = (newObject, cacheRowData) => {
  let modifiedObj = { ...newObject };
  let isExist = cacheRowData?.find(old => {
    if (old.requestDate == newObject.date
      && (old.email == newObject.userId)
    ) {
      return old;
    }
  })
  if (isExist && isExist.status == 0) {
    // isExist.inTime = new Date((isExist.requestDate + ", " + isExist.inTime))?.toLocaleString();
    // isExist.outTime = new Date((isExist.requestDate + ", " + isExist.outTime))?.toLocaleString();
    let timeObj = getWorkingHoursAndMinutes(new Date(newObject.in), new Date(newObject.out));
    let workHrours = addTimeStrings(isExist.hours, timeObj.hours + ":" + timeObj.minutes)
    if (new Date(newObject.inTime) <= new Date(isExist.inTime)) {
      modifiedObj.in = newObject.in;
    } else if (new Date(newObject.in) >= new Date(isExist.inTime)) {
      modifiedObj.in = isExist.inTime;
    }
    if (new Date(newObject.out) >= new Date(isExist.outTime)) {
      modifiedObj.out = newObject.out;
    } else if (new Date(newObject.out) <= new Date(isExist.outTime)) {
      modifiedObj.out = isExist.outTime;
    }
    modifiedObj.ROWID = isExist.ROWID;
    modifiedObj.hours = workHrours;
    return { data: modifiedObj, isEdit: true };
  } else {
    return { data: newObject, isEdit: false };
  }
}
export function getDateFormate(date) {
  return moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss");
}
export function formateNoInDecimalString(no) {
  no = parseInt(no);
  return no < 10 ? "0" + no : no + ""
}
export function disabledDate(current, disableDates) {
  // Disable dates from today onwards

  if (disableDates?.includes(moment(current).format("MM/DD/YYYY"))) {
    return true;
  }
  return current && current >= moment().startOf('day');
}

export async function getDeviceId(navigator) {
  let add = "";

  if (navigator.geolocation) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;

      const response = await Geocode.fromLatLng(latitude, longitude);

      add = response.results[0].formatted_address;
    } catch (error) {
      add = "Error!";
    }
  } else {
    add = "Error! Geolocation is not supported by this browser.";
  }
  return add;
}
export function isBeforeEight(baseTime, timeToCompaireWith) {
  const time1 = moment(timeToCompaireWith, 'HH:mm');
  const time2 = moment(baseTime, 'HH:mm');

  return time1.isBefore(time2);
}
export function reverseObjectKeys(obj) {
  return new Promise((resolve, reject) => {
    try {
      const reversedObj = {};
      const keys = Object.keys(obj);
      for (let i = keys.length - 1; i >= 0; i--) {
        const key = keys[i];
        reversedObj[key] = obj[key];
      }
      resolve(reversedObj);
    } catch (error) {
      reject(error);
    }
  });
}
export function modifyTimezoneString(timeString, formate) {
  // const fromTimezone = momentTimeZone.tz(timeString).format('Z');
  // return momentTimeZone.tz(timeString, fromTimezone).tz(momentTimeZone.tz.guess()).format(formate);
  const parsedDateTime = moment(timeString);
  const newTimeString = parsedDateTime.local();
  let str = newTimeString.format(formate);
  return str;
}
export function convertToUSTime(date, timezone) {

  let momentDate;
  if (date instanceof Date) {
    momentDate = momentTimeZone(date);
  } else {
    momentDate = momentTimeZone(new Date(date));
  }
  const usTime = momentDate.tz(timezone ? timezone : "America/Los_Angeles").format();
  return usTime;
}
export function convertToUSDate(date) {
  let momentDate;
  if (date instanceof Date) {
    momentDate = momentTimeZone(date);
  } else {
    momentDate = momentTimeZone(new Date(date));
  }
  const usTime = momentDate.tz("America/Los_Angeles").format("MM/DD/YYYY");
  return usTime;
}
export function getYaerWeekStr(date) {
  return moment(new Date(date)).year() + "-" + moment(new Date(date)).week()
}
export function getDateFromWeekAndDay(weekNo, year, day) {

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dayIndex = daysOfWeek.indexOf(day);
  if (dayIndex === -1) {
    throw new Error("Invalid day of the week");
  }

  let startOfWeek = moment(year, "YYYY").startOf("year").add(weekNo - 1, "weeks").startOf("week").add(dayIndex, "days").format("MM/DD/YYYY");

  return startOfWeek;
}
// Function to generate a random ID
function generateRandomId() {
  return Math.random().toString(36).substring(2);
}

// Function to get a cookie by name
function getCookie(name) {
  const cookieName = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

// Function to set a cookie
function setCookie(name, value, years) {
  var expires = "";
  if (years) {
    var date = new Date();
    date.setTime(date.getTime() + (years * 365 * 24 * 60 * 60 * 1000)); // Calculate expiration for years
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

// Function to check S&R_DeviceId cookie, generate and store new if not present, and return its value
export function checkDeviceIdCookie() {
  const deviceIdCookie = getCookie('S&R_DeviceId');
  if (deviceIdCookie) {
    return deviceIdCookie;
  } else {
    const newDeviceId = generateRandomId();
    setCookie('S&R_DeviceId', newDeviceId, 10);
    AttendenceService.CreateDeviceId({ DeviceCookie: newDeviceId, DisplayCookie: newDeviceId }).then(res => {
      ;
    }).catch(e => {
      console.log(e);
    })
    return newDeviceId;
  }
}
export function splitOvelappingDays(inTime, outTime, entry, isCompletedEntry) {

  return new Promise((resolve, reject) => {
    let arr = []
    try {
      inTime = new Date(modifyTimezoneString(inTime, "MM/DD/YYYY HH:mm:ss"))
      outTime = new Date(modifyTimezoneString(outTime, "MM/DD/YYYY HH:mm:ss"))
      if (isCompletedEntry && (inTime.getDate() + 1) == outTime.getDate() && dayjs(outTime).format("hh:mm A") != "12:00 AM") {//intercecting two days
        let result = getWorkingHours(inTime, outTime)
        // console.log(result);
        if (result?.length > 0) {
          result.forEach(async (o, i) => {

            let tempobj = { ...entry }
            if (i == 0) {
              tempobj["blockEdit"] = "checkOut"
            } else if (i == 1) {
              tempobj["blockEdit"] = "checkIn"
            }
            tempobj.TimesheetDate = dayjs(o?.date).format("MM/DD/YYYY");
            let HObject = getWorkingHoursAndMinutes(o?.in, o?.out);
            tempobj.TotalHours = formateNoInDecimalString(HObject.hours) + ":" + formateNoInDecimalString(HObject.minutes);
            try {
              tempobj.checkIn = getTimeInAMPMFormat(dayjs(o?.in).format("MM/DD/YYYY HH:mm:ss"), true);
              tempobj.rawcheckIn = dayjs(o?.in).format("MM/DD/YYYY HH:mm:ss");
            } catch (err) {
              tempobj.checkIn = "-"
              tempobj.rawcheckIn = "-"
            }

            try {
              tempobj.checkOut = getTimeInAMPMFormat(dayjs(o?.out).format("MM/DD/YYYY HH:mm:ss"), true);
              tempobj.rawcheckOut = dayjs(o?.out).format("MM/DD/YYYY HH:mm:ss");
            } catch (err) {
              tempobj.checkOut = "-"
              tempobj.rawcheckOut = "-"
            }
            arr.push(tempobj)
          })
        }
      } else {
        if (entry['checkIn'] == '-' || entry['checkIn'] == '') {
          entry.checkIn = "-"
          entry.rawcheckIn = "-"
        } else {
          entry.rawcheckIn = modifyTimezoneString(entry['checkIn'], "MM/DD/YYYY HH:mm:ss")
          try {
            entry.checkIn = getTimeInAMPMFormat(entry['checkIn']);
          } catch (err) {
            entry.checkIn = "-"
          }
        }
        if (entry['checkOut'] == '-' || entry['checkOut'] == '') {
          entry.checkOut = "-"
          entry.rawcheckOut = "-"
        } else {
          entry.rawcheckOut = modifyTimezoneString(entry['checkOut'], "MM/DD/YYYY HH:mm:ss")
          try {
            entry.checkOut = getTimeInAMPMFormat(entry['checkOut']);
          } catch (err) {
            entry.checkOut = "-"
          }
        }
        arr.push(entry)
      }
    } catch (e) {
      if (entry['checkIn'] == '-' || entry['checkIn'] == '') {
        entry.checkIn = "-"
        entry.rawcheckIn = "-"
      } else {
        entry.rawcheckIn = modifyTimezoneString(entry['checkIn'], "MM/DD/YYYY HH:mm:ss")
        try {
          entry.checkIn = getTimeInAMPMFormat(entry['checkIn']);
        } catch (err) {
          entry.checkIn = "-"
        }
      }
      if (entry['checkOut'] == '-' || entry['checkOut'] == '') {
        entry.checkOut = "-"
        entry.rawcheckOut = "-"
      } else {
        entry.rawcheckOut = modifyTimezoneString(entry['checkOut'], "MM/DD/YYYY HH:mm:ss")
        try {
          entry.checkOut = getTimeInAMPMFormat(entry['checkOut']);
        } catch (err) {
          entry.checkOut = "-"
        }
      }
      arr.push(entry)
    }
    resolve(arr);
  })

}
export function modifyTimesheetData(data) {
  return new Promise((resolve, reject) => {
    let arr = []
    try {

      data?.forEach(async obj => {

        let d = { ...obj.Attendance }
        d.TimesheetDate = modifyTimezoneString(d.TimesheetDate, "MM/DD/YYYY");
        if (!(d['checkIn'] == '-' || d['checkIn'] == '') && !(d['checkOut'] == '-' || d['checkOut'] == '')) {
          await splitOvelappingDays(d['checkIn'], d['checkOut'], d, true).then(res => {

            res.forEach(ob => {
              arr.push(ob);
            })
          })
        } else {
          await splitOvelappingDays(d['checkIn'], d['checkOut'], d).then(res => {

            res.forEach(ob => {
              arr.push(ob);
            })
          })
        }
      })
      resolve(arr);
    } catch (err) {
      reject(err)
    }
  })
}
export function sortArrayOfObjects(array, key, order) {
  if (!array || !Array.isArray(array) || array.length === 0) {
    return [];
  }

  if (!key || typeof key !== 'string') {
    throw new Error('Key must be a valid string');
  }

  if (!order || (order !== 'asc' && order !== 'desc')) {
    throw new Error('Order must be either "asc" or "desc"');
  }

  return array.slice().sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];

    if (typeof aValue === 'undefined' || aValue === null) {
      return order === 'asc' ? -1 : 1;
    }

    if (typeof bValue === 'undefined' || bValue === null) {
      return order === 'asc' ? 1 : -1;
    }

    // Ensure both values are strings before comparing alphabetically
    const aValueStr = String(aValue);
    const bValueStr = String(bValue);

    return order === 'asc' ? aValueStr.localeCompare(bValueStr) : bValueStr.localeCompare(aValueStr);
  });
}
export const dateRangesDesc = [
  { range: [moment(new Date().setHours(0, 0, 0)), moment(new Date().setHours(0, 0, 0)).subtract(1, 'days')], label: 'Today' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(1, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(2, 'days')], label: 'Yesterday' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(2, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(7, 'days')], label: 'Last week' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(7, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days')], label: 'Two weeks ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days').startOf("month")], label: 'A month ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days').startOf("month"), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days').startOf("month").startOf("year")], label: 'A year ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days').startOf("month").startOf("year")], label: 'Old' },
];
export const dateRangesAsc = [
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days').startOf("month").startOf("year")], label: 'Old' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days').startOf("month"), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days').startOf("month").startOf("year")], label: 'A year ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(14, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days').startOf("month")], label: 'A month ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(7, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(14, 'days')], label: 'Two weeks ago' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(2, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(7, 'days')], label: 'Last week' },
  { range: [moment(new Date().setHours(0, 0, 0)).subtract(1, 'days'), moment(new Date().setHours(23, 59, 59)).subtract(2, 'days')], label: 'Yesterday' },
  { range: [moment(new Date().setHours(0, 0, 0)), moment(new Date().setHours(0, 0, 0)).subtract(1, 'days')], label: 'Today' },

];


// Define size ranges
export const sizeRangesAsc = [
  { range: [0, 5120], label: 'Less than 5MB' },
  { range: [5120, 25600], label: 'Greater than 5MB' },
  { range: [25600], label: 'Greater than 25MB' },
];
export const sizeRangesDesc = [
  { range: [25600], label: 'Greater than 25MB' },
  { range: [5120, 25600], label: 'Greater than 5MB' },
  { range: [0, 5120], label: 'Less than 5MB' },
];
export const getRanges = (sort) => {
  if (sort.type == "Date") {
    return sort.order == "desc" ? dateRangesDesc : dateRangesAsc;
  } else if (sort.type == "Size") {
    return sort.order == "desc" ? sizeRangesDesc : sizeRangesAsc;
  } else {
    return ["No-Sub-Cat"];
  }
}
export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return getFileType(bytes.buffer);
}

// Function to determine the file type based on the magic number
export function getFileType(arrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const magicNumber = uint8Array.subarray(0, 4).reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
  const asciiString = new TextDecoder('ascii').decode(uint8Array.subarray(0, 4));

  // Check the magic number against known file signatures
  switch (magicNumber) {
    case '25504446':
      return 'application/pdf';
    case '89504e47':
      return 'image/png';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
      return 'image/jpeg';
    case '47494638':
      return 'image/gif';
    case '504b0304': // XLSX (PK)
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      // Check for SVG signature
      if (asciiString.startsWith('<svg')) {
        return 'image/svg+xml';
      }
      return 'unknown';
  }
}
const parseDateTime = (dateTimeStr) => {
  // const [dateStr, timeStr] = dateTimeStr.split(' ');
  // const [year, month, day] = dateStr.split('-').map(Number);
  // const [hours, minutes] = timeStr.split(':').map(Number);
  return new Date(dateTimeStr);
};

const formatDateTime = (dateTime) => {
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const checkEqualDatesAndTime = (datestr1, datestr2) => {
  if (modifyTimezoneString(datestr1, "MM/DD/YYYY HH:mm") == modifyTimezoneString(datestr2, "MM/DD/YYYY HH:mm")) {
    return true;
  }
  return false;
}
export const joinAttendanceWithBreakInBetween = (arr, requestObj) => {
  return new Promise((resolve, reject) => {

    let outTime = modifyTimezoneString(requestObj.rawcheckOut, "MM/DD/YYYY HH:mm")
    let inTime = modifyTimezoneString(requestObj.rawcheckIn, "MM/DD/YYYY HH:mm")
    let StartObj = arr.find(e => (checkEqualDatesAndTime(e.rawcheckOut, inTime)));
    let EndObj = arr.find(e => (checkEqualDatesAndTime(e.rawcheckIn, outTime)));
    let obj = {}
    let timeObj = getWorkingHoursAndMinutes(new Date(StartObj.rawcheckIn), new Date(EndObj.rawcheckOut));
    let TotalHours = formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes);
    if (StartObj && EndObj) {
      obj = {
        UpdateObject: {
          ROWID: StartObj.ROWID,
          checkIn: convertToUSTime(StartObj.rawcheckIn),
          checkOut: convertToUSTime(EndObj.rawcheckOut),
          TotalHours: TotalHours,
        },
        DeleteObject: {
          ROWID: EndObj.ROWID,
        }
      }
    }
    resolve(obj);
  })
}
export const getModifiedEntries = (intervals, subtract) => {
  const subtractStart = parseDateTime(subtract.rawcheckIn);
  const subtractEnd = parseDateTime(subtract.rawcheckOut);

  const result = [];
  let splitPerformed = false;

  intervals.forEach(interval => {
    const intervalStart = parseDateTime(interval.rawcheckIn);
    const intervalEnd = parseDateTime(interval.rawcheckOut);

    if (subtractEnd <= intervalStart || subtractStart >= intervalEnd) {
      // No overlap, keep the interval as is
      // result.push(interval);
    } else {
      // Overlap, split the overlapping interval
      if (subtractStart > intervalStart && subtractEnd < intervalEnd) {
        // Subtract interval splits the current interval into two
        let cIn = formatDateTime(intervalStart);
        let cOut = formatDateTime(subtractStart);
        let timeObj = getWorkingHoursAndMinutes(new Date(cIn), new Date(cOut));

        result.push({
          ...interval,
          // rawcheckIn: cIn ,
          // rawcheckOut: cOut ,
          TimesheetDate: convertToUSTime(cIn),
          TotalHours: formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes),
          checkIn: convertToUSTime(cIn),
          checkOut: convertToUSTime(cOut),
          ROWID: interval.ROWID
        });
        let cIn1 = formatDateTime(subtractEnd);
        let cOut1 = formatDateTime(intervalEnd);
        let timeObj1 = getWorkingHoursAndMinutes(new Date(cIn1), new Date(cOut1));
        result.push({
          ...interval,
          // rawcheckIn: cIn1,
          // rawcheckOut: cOut1,
          TimesheetDate: convertToUSTime(cIn1),
          checkIn: convertToUSTime(cIn1),
          checkOut: convertToUSTime(cOut1),
          ROWID: null,
          TotalHours: formateNoInDecimalString(timeObj1.hours) + ":" + formateNoInDecimalString(timeObj1.minutes),
        });
      } else if (subtractStart > intervalStart) {
        // Adjust the end time of the current interval
        let cIn = formatDateTime(intervalStart);
        let cOut = formatDateTime(subtractStart);
        let timeObj = getWorkingHoursAndMinutes(new Date(cIn), new Date(cOut));
        result.push({
          ...interval,
          // rawcheckIn: cIn,
          // rawcheckOut: cOut,
          TimesheetDate: convertToUSTime(cIn),
          TotalHours: formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes),
          checkIn: convertToUSTime(cIn),
          checkOut: convertToUSTime(cOut),
          ROWID: interval.ROWID
        });
      } else if (subtractEnd < intervalEnd) {
        // Adjust the start time of the current interval
        let cIn = formatDateTime(subtractEnd);
        let cOut = formatDateTime(intervalEnd);
        let timeObj = getWorkingHoursAndMinutes(new Date(cIn), new Date(cOut));
        result.push({
          ...interval,
          // rawcheckIn: cIn,
          // rawcheckOut: cOut,
          TimesheetDate: convertToUSTime(cIn),
          TotalHours: formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes),
          checkIn: convertToUSTime(cIn),
          checkOut: convertToUSTime(cOut),
          ROWID: interval.ROWID
        });
      }
      splitPerformed = true;
    }
  });

  return splitPerformed ? result : false;
};

export function UpdateTimesheetWithBreak(entry) {
  return new Promise(async (resolve, reject) => {
    const results = await Promise.all(entry.map(attendanceObj => {
      if (attendanceObj?.ROWID) {
        AttendenceService.UpdateAttendence(attendanceObj?.ROWID, attendanceObj);
      } else {
        AttendenceService.CreateAttendence(null, null, "", attendanceObj);
      }
    }));
    resolve(results);
  })
}
export function combineTimesheetRecords(records) {
  const combinedRecords = {};
  records.forEach(record => {
    const rowId = record.ROWID;
    const blockCol = record.blockEdit;

    if (!combinedRecords[rowId]) {
      combinedRecords[rowId] = { ...record };
    } else {
      // Combine records based on blockCol
      if (blockCol === "checkOut") {
        combinedRecords[rowId].TimesheetDate = dayjs(new Date(record.rawcheckIn)).format("MM/DD/YYYY");
        combinedRecords[rowId].checkIn = record.checkIn;
        combinedRecords[rowId].rawcheckIn = record.rawcheckIn;
      } else if (blockCol === "checkIn") {
        combinedRecords[rowId].checkOut = record.checkOut;
        combinedRecords[rowId].rawcheckOut = record.rawcheckOut;
      }
    }
  });

  return Object.values(combinedRecords);
}
export function convertZohoTimeToLocal(dateTime, timezone, additionalmins) {
  if (dateTime) {
    dateTime = moment(dateTime);
  }
  if (timezone == "US") {
    dateTime = moment(convertToUSTime(dateTime._d));
  }
  if (additionalmins) {
    if (additionalmins < 0) {
      additionalmins = additionalmins * -1;
      dateTime = dateTime.subtract(additionalmins, "minutes").format();
    } else {
      dateTime = dateTime.add(additionalmins, "minutes").format();
    }
  }
  return dateTime;
}
export const AllPaths = {
  "Email": {
    pathname: "/access/e/Email/listview",
    state: { action: "listview", module: "Email" },
  },
  "Calendar": {
    pathname: "/access/Calendar/listview",
    state: { action: "listview", module: "Calendar" },
  }
}
export const getSystemTimezoe = () => {
  return momentTimeZone(new Date()).tz()
}
export const calendarEventType = {
  "All Day": {
    color: "#0285d1"
  },
  "Task": {
    color: "#f5e216"
  },
  "Holiday": {
    color: "#ff3b41"
  },
  "Reccuring": {
    color: "#69ff61"
  },
  "Default": {
    color: "#DCE6F0"
  }
}
export const HexColorRenges =
  ["#F0F8FF", "#E0EEEE", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA", "#00BFFF", "#1E90FF", "#6495ED",
    "#4169E1", "#0000FF", "#0000CD", "#FFF5E0", "#FFE4B5", "#FFDAB9", "#FFD700", "#FFC125", "#FFB90F",
    "#FFA500", "#FF8C00", "#FF7F00", "#FF6347", "#FF4500", "#FF0000", "#F0FFF0", "#E0EEE0", "#C1FFC1",
    "#98FB98", "#7CFC00", "#7FFF00", "#76EE00", "#00FF00", "#32CD32", "#3CB371", "#2E8B57", "#006400"]

export function getSystemTimezone() {
  return moment.tz.guess();
}

export function invertColor(hex, bw) {
  if(hex){
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      return "#000000"
      // throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }else{

  }
}
function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
export function getValueByPath(obj, path) {
  return new Promise((resolve,reject)=>{
      if(path.includes(".")){
        let temp={...obj};
        const keys = path.trim().split('.');
        keys.forEach((key) => {
          if(temp[key]){
            temp=temp[key];
          }
        });
        resolve(temp);
      }else{
        resolve(obj[path]);
      }
  })
}
const colorArray={
  "High":"#FF0000",
  "Medium":"#FFA500",
  "Low":"#0000FF",
  "Not Set":"#808080",
}
export function getPriorityValue(key){
  if(key){
    return colorArray[key];
  }else{
    return "#5b6c97"
  }
}
export function getRandomNumberExcluding(array, min = 0, max = 1000) {
        // Create a Set from the array for O(1) lookups
        const excludedSet = new Set(array);
    
        if (excludedSet.size >= (max - min + 1)) {
            throw new Error("No available numbers to choose from");
        }
    
        let randomNumber;
        do {
            // Generate a random number in the specified range
            randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        } while (excludedSet.has(randomNumber));
    
        return randomNumber+"";
    }