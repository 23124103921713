import React, { useEffect, useState } from 'react'
import './CreateDependencyPopover.css';
import { Button, Checkbox, Collapse, Popover, Select, Spin, Tabs, Tooltip } from 'antd';
import { invertColor } from '../../../../components/HR/TimeClock/Util';
import { Col, Label, Row } from 'reactstrap';
import Search from 'antd/es/input/Search';
import AwsServerService from '../../../../common/util/AwsServerService';
import { getUpcomingNodes } from '../../TaskUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { UserPicker } from '../../../../common/UserPicker/UserPicker';
import { UserPicture } from '../../../../common/UserPicker/UserPicture';
import { FaChevronDown } from 'react-icons/fa';
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
const { Panel } = Collapse;

export const CreateDependencyPopover = ({
    children,
    Dependencydata,
    handleDeleteDependency,
    Tasks,
    setClear,
    clear,
    thisTask,
    editObject,
    dealSelected,
    onSave,
    type
}) => {
    let tempObj = {
        task: null,
        thisStatus: "",
        toStatus: ""
    }
    let tempObjectNew = {
        thisTaskStage:"F",
        thisTaskLabel:"",
        task: null,
        toTaskStage: "S",
        toTaskLabel:"",
    }
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState();
    const [allTasks, setAllTasks] = useState();
    const [activeTab, setActiveTab] = useState(2);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [thisTaskStatusOptions, setThisTaskStatusOptions] = useState([])
    const [thisTaskStages,setThisTaskStages] = useState([]);
    const [targetTaskStages,setTargetTaskStages] = useState([]);
    const [targetTaskStatusOptions, setTargetTaskStatusOptions] = useState([])
    const [loading, setLoading] = useState(false);
    const [stepsData, setStepsData] = useState(tempObjectNew);

    useEffect(e=>{
        if(clear=="after_save"){
            setStepsData(tempObjectNew);
            setClear("");
            setOpen(false);
        }
    },[clear])

    function sortStatusOptions(task, type) {
        if (task && task.TaskFlowObject) {
            let status = task.TaskStatus;
            let startStatus;
            let endStatus;
            if (!task.TaskStatus) {
                const index = task.TaskFlowObject.Nodes.findIndex(e => e.NonSelectable == true);
                status = task.TaskFlowObject.Nodes[index]?.id;
            }
            startStatus = task.TaskFlowObject.Nodes.find(e => e.NonSelectable == true);
            endStatus = task.TaskFlowObject.Nodes.find(e => e.data.state == "Close");
            let op = getUpcomingNodes(status, task.TaskFlowObject.Nodes, task.TaskFlowObject.Edges);
            let arr = [];
            if(status == startStatus?.id){
                arr.push({valid:true,data:startStatus});
            }else{
                arr.push({valid:false,data:startStatus});
            }
            if(status == endStatus?.id){
                arr.push({valid:false,data:endStatus});
            }else{
                arr.push({valid:true,data:endStatus});
            }
            if (type == "this") {
                 
                setThisTaskStages(arr);
                setThisTaskStatusOptions(op);
                console.log("This---",arr)
            } else if (type == "target") {
                setTargetTaskStatusOptions(op);
                setTargetTaskStages(arr);
                console.log("target---",arr)
            }
        }
    }
    useEffect(e => {
        if (thisTask) {
            sortStatusOptions(thisTask, "this")
        }
    }, [thisTask])
    useEffect(e => {
        if (open == false) {
            setActiveTab(2);
        }
    }, [open])

    function arrangeData(data) {
         
        let result = [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
                let pipeline = data[i];
                if (pipeline.Tasks) {
                    for (let j = 0; j < pipeline.Tasks.length; j++) {
                        let task = pipeline.Tasks[j];
                        let statusColor = "#808080";
                        if (task?.TaskFlowObject) {
                            let filterData = task?.TaskFlowObject.Nodes.find(e => e.data.label == task.TaskStatus);
                            statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                        }
                        let tempStatus = task?.TaskFlowObject?.Nodes?.find(k => k.id == task.TaskStatus);

                        let obj = {
                            id: task?.TaskId,
                            TaskType: "Task",
                            label: task?.TaskName,
                            value: `Tasks_${task?.TaskROWID}`,
                            status: tempStatus?.data?.label,
                            TaskStatus: task?.TaskStatus,
                            statusColor: tempStatus?.data?.color,
                            taskData: task,
                            SubTasks: []
                        };
                        if(Dependencydata.includes(obj.value)){
                            obj["disabled"] = true;
                        }
                        if (task.SubTasks) {
                            for (let k = 0; k < task.SubTasks.length; k++) {
                                let subtask = task.SubTasks[k];
                                if (subtask?.TaskFlowObject) {
                                    let filterData = subtask?.TaskFlowObject.Nodes.find(e => e.data.label == subtask.TaskStatus);
                                    statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                                }
                                let tempStatus1 = subtask?.TaskFlowObject?.Nodes?.find(k => k.id == subtask.TaskStatus);
                                let subObject = {
                                    id: subtask?.SubTaskId,
                                    TaskType: "Sub-Task",
                                    label: subtask?.TaskName,
                                    value: `SubTasks_${subtask?.SubTaskROWID}`,
                                    status: tempStatus1?.data?.label,
                                    TaskStatus: subtask?.TaskStatus,
                                    statusColor: tempStatus1?.data?.color,
                                    taskData: subtask
                                }
                                if(Dependencydata.includes(subObject.value)){
                                    subObject["disabled"] = true;
                                }
                                obj.SubTasks.push(subObject);
                            }
                        }
                        result.push(obj);
                    }
                }
            }
        }
        if (result.length > 0) {
            let value = '';
            if (thisTask.hasOwnProperty('SubTaskROWID')) {
                value = `SubTasks_${thisTask.SubTaskROWID}`
            } else {
                value = `Tasks_${thisTask.TaskROWID}`
            }
            const index = result.findIndex(task => task.value === value);
            if (index >= 0) {
                result.splice(index, 1);
            }
        }
        return result;
    }
    useEffect(() => {
        if (dealSelected && open) {
            setLoading(true);
            if (dealSelected == "NoDeal") {
                AwsServerService.getPipelineTemplatesData().then(res => {
                    let data = res;
                    let result = arrangeData(data);
                    if (editObject) {
                        let filteredTask = [];
                        result.forEach(e => {
                            if (e.id == editObject.id) {
                                filteredTask.push(e)
                            } else if (e.SubTasks.length > 0) {
                                let record = e.SubTasks.find(x => x.id == editObject.id);
                                if (record) {
                                    filteredTask.push(record);
                                }
                            }
                        });
                        let obj = { ...editObject, task: filteredTask[0], toStatus: editObject.TargetDependencyStatusId, thisStatus: editObject.SourceDependencyStatusId }
                        setStepsData(obj);
                        sortStatusOptions(obj.data, "target")
                        setActiveTab(2);
                    } else {
                        if(clear!="prevent_dependency"){
                            setStepsData(tempObjectNew)
                        }
                    }
                      
                    console.log(Dependencydata);
                    
                    // result = result.map(t=>{
                    //     if(Dependencydata.includes(t.value)){
                    //         t["disabled"] = true;
                    //     }
                    //     return t;
                    // })
                    setAllTasks(result);
                    setTimeout(() => setLoading(false), 1000);
                }).catch((e) => {
                    console.log(e);
                    setTimeout(() => setLoading(false), 1000);
                });
            } else {
                AwsServerService.getPipelineDataByDeal(dealSelected)
                    .then((res) => {
                        let data = res;
                        let result = arrangeData(data);
                        if (editObject) {
                            let filteredTask = [];
                            result.forEach(e => {
                                if (e.id == editObject.id) {
                                    filteredTask.push(e)
                                } else if (e.SubTasks.length > 0) {
                                    let record = e.SubTasks.find(x => x.id == editObject.id);
                                    if (record) {
                                        filteredTask.push(record);
                                    }
                                }
                            });
                            let obj = { ...editObject, task: filteredTask[0], toStatus: editObject.TargetDependencyStatusId, thisStatus: editObject.SourceDependencyStatusId }
                            setStepsData(obj);
                            sortStatusOptions(obj.data, "target")
                            setActiveTab(2);
                        } else {
                            if(clear!="prevent_dependency"){
                                setStepsData(tempObjectNew)
                            }
                        }
                          
                        // result = result.map(t=>{
                        //     if(Dependencydata.includes(t.value)){
                        //         t["disabled"] = true;
                        //     }
                        //     return t;
                        // })
                        setAllTasks(result);
                        setTimeout(() => setLoading(false), 1000);
                    }).catch((e) => {
                        console.log(e);
                        setTimeout(() => setLoading(false), 1000);
                    });
            }
        } else {
            setAllTasks([]);
        }

    }, [dealSelected, open, editObject]);
    useEffect(e => {
        let arr = allTasks;
        if (searchText) {
            arr = arr?.filter(option => {
                if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
                    return option;
                }

            });
        }
        setFilteredTasks(arr)
    }, [allTasks, searchText])
    const onClickNext = (tabid) => {
        setActiveTab(tabid);
    }
    const [expandedTasks, setExpandedTasks] = useState({});
    const toggleSubtasks = (taskId) => {
        setExpandedTasks(prevState => ({
            ...prevState,
            [taskId]: !prevState[taskId],
        }));
    };
    const getHeaderBar = () => {
        return <>
            <div className='headerSection'>
                <div>
                    Create {type} Dependency
                </div>
                {/* <Button className="ml-1" disabled={!stepsData?.toTaskStage || !stepsData.thisTaskStage} onClick={() => {
                    onSave(stepsData);
                }}>{editObject ? 'Update' : 'Save'}</Button> */}
            </div>
        </>
    }
    const getStep1 = () => {
        return <>
            <div className={`${loading ? "dependncy-list-dropdowns Alin-Centre-Class" : "dependncy-list-dropdowns"}`}>
                {loading ? <Spin className='mr-2' size='small' /> : <>
                    {<div className='dependency-search-input'>
                        <Search
                            size="small"
                            classNames={"Search-Class"}
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => {
                                e.target.value = e.target.value.trimStart();
                                setSearchText(e.target.value);
                            }} />
                    </div>}
                    <Row className='Header-Row'>
                        <Col sm={6}>Task Name</Col>
                        <Col sm={2} style={{ display: "flex", justifyContent: "center" }}>Status</Col>
                        <Col sm={2} style={{ display: "flex", justifyContent: "center" }}>Assignee</Col>
                        <Col sm={2} style={{ display: "flex", justifyContent: "center" }}>Supervisor</Col>
                    </Row>
                    <div>
                        {filteredTasks?.map((option, index) => (
                            <div key={option.value}>
                                <Row
                                    className={`custom-option ${option?.id === stepsData?.task?.id ? "Selected-Task" : ""}`}
                                    title={`${option.disabled && "Dependency already exist!"}`}
                                    style={option.disabled?{cursor: "not-allowed",opacity:"0.6"}: { cursor: "pointer" }}
                                    onClick={() => {
                                        if (!option.disabled) {
                                            let k = { ...stepsData };
                                            k["task"] = option;
                                            // k['thisStatus'] = "";
                                            // k['toTaskStage'] = "";
                                            sortStatusOptions(option.taskData, "target");
                                            onSave(k);
                                        }
                                        setStepsData(tempObjectNew);
                                        // setStepsData(k);
                                        // setActiveTab(3);
                                    }}> <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
                                        {option?.SubTasks?.length > 0 && (
                                            <span
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleSubtasks(option.value);
                                                }}
                                                style={{ cursor: "pointer", marginRight: "10px" }}
                                            >
                                                {(expandedTasks[option.value] || option.disabled) ? (<FontAwesomeIcon icon={faCaretDown} />) : (<FontAwesomeIcon icon={faCaretRight} />)}
                                            </span>
                                        )}
                                        <Tooltip title={option.label}>
                                            <span style={option?.SubTasks?.length == 0 ? { paddingLeft: 'inherit' } : {}}>{option.label}</span>
                                        </Tooltip>
                                    </Col>
                                    <Col sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                        {option?.status && (
                                            <Tooltip title={option.status}>
                                                <span
                                                    className="Elipsis-Class"
                                                    style={{
                                                        background: option?.statusColor ? option?.statusColor : "#808080",
                                                        color: option?.statusColor
                                                            ? invertColor(option?.statusColor, true)
                                                            : invertColor("#808080", true), padding: "2px 6px", borderRadius: "3px"
                                                    }}
                                                >
                                                    {option?.status}
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Col>
                                    <Col sm={2}>
                                        <UserPicture values={option?.taskData?.Assignee} />
                                    </Col>
                                    <Col sm={2}>
                                        <UserPicture values={option?.taskData?.Supervisor} />
                                    </Col>
                                </Row>
                                {(expandedTasks[option.value] || option.disabled) && option?.SubTasks?.length > 0 && (
                                    <div>
                                        {option.SubTasks.map((subtask) => (
                                            <Row
                                                key={subtask.value}
                                                title={`${subtask.disabled && "Dependency already exist!"}`}
                                                className={`custom-option ${subtask?.id === stepsData?.task?.id ? "Selected-Task" : ""}`}
                                                style={subtask.disabled?{cursor: "not-allowed",opacity:"0.6"}: { cursor: "pointer" }}
                                                onClick={() => {
                                                    if(!subtask.disabled){
                                                        let k = { ...stepsData };
                                                        k["task"] = subtask;
                                                        // k['toTaskStage'] = "";
                                                        // k['toStatus'] = "";
                                                        sortStatusOptions(subtask.taskData, "target");
                                                        onSave(k);
                                                        setStepsData(tempObjectNew);
                                                    }
                                                    // setStepsData(k);
                                                    // setActiveTab(3);
                                                }}
                                            >
                                                <Col sm={6} style={{ textIndent: '2rem' }}>
                                                    <Tooltip title={subtask.label}>
                                                        <span>{subtask.label}</span>
                                                    </Tooltip>
                                                </Col>
                                                <Col sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    {subtask?.status && (
                                                        <Tooltip title={subtask.status}>
                                                            <span
                                                                className="Elipsis-Class"
                                                                style={{
                                                                    background: subtask?.statusColor ? subtask?.statusColor : "#808080",
                                                                    color: subtask?.statusColor
                                                                        ? invertColor(subtask?.statusColor, true)
                                                                        : invertColor("#808080", true), padding: "2px 6px", borderRadius: "3px"
                                                                }}
                                                            >
                                                                {subtask?.status}
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </Col>

                                                <Col sm={2}>
                                                    <UserPicture values={subtask?.taskData?.Assignee} />
                                                </Col>
                                                <Col sm={2}>
                                                    <UserPicture values={subtask?.taskData?.Supervisor} />
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </>}
            </div>
        </>
    }
    // const getStep2 = () => {
    //     return <div className="dependncy-list-dropdowns">
    //         {/* {getHeaderBar()} */}
    //         <Row className='pt-4'>
    //             <Col className={"mb-3 Input-Field-Column"} sm={6}>
    //                 <span className="labeltext">
    //                     <Label>Select Source Task Status<span className='ml-1' style={{ color: 'red' }}>*</span></Label>
    //                 </span>

    //                 <Select
    //                     className={`Global-Text-Color Custom-Select`}
    //                     // className={`Global-Text-Color Custom-Select ${errors.includes("TARGET-STATUS") && "Error-Border"}`}
    //                     showSearch
    //                     placeholder="Select type"
    //                     style={{ width: "100%", height: "100%" }}
    //                     value={stepsData["thisStatus"]}
    //                     onSelect={(value, option) => {
    //                         let obj = { ...stepsData }
    //                         obj["thisStatus"] = value
    //                         setStepsData(obj)
    //                         setActiveTab(2)
    //                     }}
    //                 >
    //                     {thisTaskStatusOptions?.map((n, i) => {
    //                         if (n && n?.type != "diverse") {
    //                             return <Select.Option
    //                                 className='Status-Card mb-1 Global-Text-Color' value={n.id}>
    //                                 <FontAwesomeIcon className='mr-2' color={n?.data?.color} icon={faCircle} />{n?.data?.label}
    //                             </Select.Option>
    //                         }
    //                     })
    //                     }

    //                 </Select>
    //             </Col>
    //             {/* <Col className={"mb-3 Input-Field-Column"} sm={6}>
    //                 <span className="labeltext">
    //                     <Label>Select Target Task Status<span className='ml-1' style={{ color: 'red' }}>*</span></Label>
    //                 </span>

    //                 <Select
    //                     className={`Global-Text-Color Custom-Select`}
    //                     // className={`Global-Text-Color Custom-Select ${errors.includes("TARGET-STATUS") && "Error-Border"}`}
    //                     showSearch
    //                     placeholder="Select type"
    //                     style={{ width: "100%",height:"100%"}}
    //                     value={stepsData["toStatus"]}
    //                     onSelect={(value, option) => {
    //                         let obj={...stepsData}
    //                         obj["toStatus"] = value
    //                         setStepsData(obj)
    //                     }}
    //                 >
    //                     {targetTaskStatusOptions?.map((n, i) => {
    //                         if (n && n?.type != "diverse") {
    //                             return <Select.Option
    //                                 className='Status-Card mb-1 Global-Text-Color' value={n.id}>
    //                                 <FontAwesomeIcon className='mr-2' color={n?.data?.color} icon={faCircle} />{n?.data?.label}
    //                             </Select.Option>
    //                         }
    //                     })
    //                     }

    //                 </Select>
    //             </Col> */}
    //         </Row>
    //     </div>
    // }
    // const getStep3 = () => {
    //     return <div className="dependncy-list-dropdowns">
    //         <Row className='pt-4'>            
    //             <Col className={"mb-3 Input-Field-Column"} sm={6}>
    //                 <span className="labeltext">
    //                     <Label>Select Target Task Status<span className='ml-1' style={{ color: 'red' }}>*</span></Label>
    //                 </span>

    //                 <Select
    //                     className={`Global-Text-Color Custom-Select`}
    //                     // className={`Global-Text-Color Custom-Select ${errors.includes("TARGET-STATUS") && "Error-Border"}`}
    //                     showSearch
    //                     placeholder="Select type"
    //                     style={{ width: "100%", height: "100%" }}
    //                     value={stepsData["toStatus"]}
    //                     onSelect={(value, option) => {
    //                         let obj = { ...stepsData }
    //                         obj["toStatus"] = value
    //                         setStepsData(obj)
    //                     }}
    //                 >
    //                     {targetTaskStatusOptions?.map((n, i) => {
    //                         if (n && n?.type != "diverse") {
    //                             return <Select.Option
    //                                 className='Status-Card mb-1 Global-Text-Color' value={n.id}>
    //                                 <FontAwesomeIcon className='mr-2' color={n?.data?.color} icon={faCircle} />{n?.data?.label}
    //                             </Select.Option>
    //                         }
    //                     })
    //                     }

    //                 </Select>
    //             </Col>
    //         </Row>
    //     </div>
    // }
    function onChangeCheckbox(thisS,targetS){
        let temp={...stepsData}
       
        temp["thisTaskStage"] = thisS;
        temp["toTaskStage"] = targetS;
       
        setStepsData(temp);
    }
    function getValidationText(thisT,targetT,thisTS,targetTS){
        if(!thisT){
            return `This task already ${thisTS=="S"?"started":"completed"}!`
        }else if(thisT && !targetT){
            return `${type=="Waiting on"?"predecessor":"successor"} task already ${targetTS=="S"?"started":"completed"}!`
        }
    }
    const getStep4 = () => {
        return <div className="dependncy-list-dropdowns">
            <Row className='pt-3 pb-3'>    
               
                {/* <Col sm={3}>
                    <Tooltip overlayStyle={thisTaskStages[1]?.valid && targetTaskStages[0]?.valid && { visibility: "hidden" }}
                        title={getValidationText(thisTaskStages[1]?.valid, targetTaskStages[0]?.valid, "F", "S")}
                    >
                        <Checkbox 
                        disabled={!thisTaskStages[1]?.valid || !targetTaskStages[0]?.valid} 
                        className='mr-1' 
                        onChange={e => onChangeCheckbox('F',"S",e)} 
                        checked={stepsData["thisTaskStage"] + stepsData["toTaskStage"] == 'FS'} />FS
                    </Tooltip>
                </Col>
                <Col sm={3}>
                    <Tooltip overlayStyle={thisTaskStages[0]?.valid && targetTaskStages[0]?.valid && { visibility: "hidden" }}
                        title={getValidationText(thisTaskStages[0]?.valid, targetTaskStages[0]?.valid, "S", "S")}
                    >
                        <Checkbox 
                        disabled={!thisTaskStages[0]?.valid || !targetTaskStages[0]?.valid} 
                        className='mr-1' 
                        onChange={e => onChangeCheckbox('S',"S",e)} 
                        checked={stepsData["thisTaskStage"] + stepsData["toTaskStage"] == 'SS'} />SS
                    </Tooltip>
                </Col>
                <Col sm={3}>
                    <Tooltip overlayStyle={thisTaskStages[0]?.valid && targetTaskStages[1]?.valid && { visibility: "hidden" }} 
                        title={getValidationText(thisTaskStages[0]?.valid, targetTaskStages[1]?.valid, "S", "F")}
                    >
                        <Checkbox 
                        disabled={!thisTaskStages[0]?.valid || !targetTaskStages[1]?.valid} 
                        className='mr-1' 
                        onChange={e => onChangeCheckbox('S',"F",e)} 
                        checked={stepsData["thisTaskStage"] + stepsData["toTaskStage"] == 'SF'} />SF
                    </Tooltip>
                </Col>
                <Col sm={3}>
                    <Tooltip overlayStyle={thisTaskStages[1]?.valid && targetTaskStages[1]?.valid && { visibility: "hidden" }}  
                        title={getValidationText(thisTaskStages[1]?.valid, targetTaskStages[1]?.valid, "F", "F")}
                    >
                        <Checkbox 
                        disabled={!thisTaskStages[1]?.valid || !targetTaskStages[1]?.valid} 
                        className='mr-1' 
                        onChange={e => onChangeCheckbox('F',"F",e)} 
                        checked={stepsData["thisTaskStage"] + stepsData["toTaskStage"] == 'FF'} />FF
                    </Tooltip>
                </Col> */}
                <Col className={"mb-3 Input-Field-Column"} sm={6}>
                    <span className="labeltext">
                        <Label>Select dependency type<span className='ml-1' style={{ color: 'red' }}>*</span></Label>
                    </span>

                    <Select
                        className={`Global-Text-Color Custom-Select`}
                        // className={`Global-Text-Color Custom-Select ${errors.includes("TARGET-STATUS") && "Error-Border"}`}
                        showSearch
                        placeholder="Select type"
                        style={{ width: "100%", height: "100%" }}
                        value={stepsData["thisTaskStage"] + stepsData["toTaskStage"]}
                        onSelect={(value, option) => {
                            let arr=value.split("_");
                            onChangeCheckbox(arr[0],arr[1])
                        }}
                    >
                        <Select.Option value={"F_F"}>FF
                        </Select.Option>
                        <Select.Option value={"F_S"}>FS
                        </Select.Option>
                        <Select.Option value={"S_S"}>SS
                        </Select.Option>
                        <Select.Option value={"S_F"}>SF
                        </Select.Option>

                    </Select>
                </Col>
                {/* <Col sm={6}><Checkbox className='mr-1' onChange={e=>onChangeCheckbox("Start",e,"toTaskStage")} checked={stepsData["toTaskStage"] && stepsData["toTaskStage"] == "Start"} /> On <span style={{fontWeight:"bold",color:"rgb(64 169 255)"}}>{stepsData["task"]?.label}'s</span> Start</Col>
                <Col sm={6}><Checkbox className='mr-1' onChange={e=>onChangeCheckbox("Completion",e,"toTaskStage")} checked={stepsData["toTaskStage"] && stepsData["toTaskStage"] == "Completion"} /> On <span style={{fontWeight:"bold",color:"rgb(64 169 255)"}}>{stepsData["task"]?.label}'s</span> Completion</Col> */}
            </Row>
        </div>
    }
    const getStep5 = () => {
        return <div className="dependncy-list-dropdowns">
            <Row className='pt-3 pb-3'>      
                <Col sm={6}>
                    <Tooltip overlayStyle={thisTaskStages[0]?.valid && {visibility:"hidden"}} title={"Task is already started!"}>
                        <Checkbox disabled={!thisTaskStages[0]?.valid} className='mr-1' onChange={e => onChangeCheckbox(thisTaskStages[0]?.data?.id, e, "thisTaskStage","thisTaskLabel","Start")} checked={stepsData["thisTaskStage"] && stepsData["thisTaskStage"] == thisTaskStages[0]?.data?.id} />Start
                    </Tooltip>
                </Col>
                <Col sm={6}>
                    <Tooltip overlayStyle={thisTaskStages[1]?.valid && {visibility:"hidden"}} title={"Task is already completed!"}>
                        <Checkbox disabled={!thisTaskStages[1]?.valid} className='mr-1' onChange={e => onChangeCheckbox(thisTaskStages[1]?.data?.id, e, "thisTaskStage","thisTaskLabel","Completion")} checked={stepsData["thisTaskStage"] && stepsData["thisTaskStage"] == thisTaskStages[1]?.data?.id} />Completion
                    </Tooltip>
                </Col>
                {/* <Col sm={6}><Checkbox className='mr-1' onChange={e=>onChangeCheckbox("Start",e,"thisTaskStage")} checked={stepsData["thisTaskStage"] && stepsData["thisTaskStage"] == "Start"} /> On <span style={{fontWeight:"bold",color:"rgb(64 169 255)"}}>this's</span> Start</Col>
                <Col sm={6}><Checkbox className='mr-1' onChange={e=>onChangeCheckbox("Completion",e,"thisTaskStage")} checked={stepsData["thisTaskStage"] && stepsData["thisTaskStage"] == "Completion"} /> On <span style={{fontWeight:"bold",color:"rgb(64 169 255)"}}>this's</span> Completion</Col> */}
            </Row>
        </div>
    }

    const handleCollapseChange = (key) => {
        setActiveTab(key);
    };
    const getHeaderMessage = (value,step) =>{
        if(step=="STEP1"){
            let temp = thisTaskStatusOptions.find(k=>k.id==value);
            return <><span style={{backgroundColor:temp?.data?.color,
                padding: "2px 7px",
                "border-radius": "12px",
                color:invertColor(temp?.data?.color,true)
            }}>{temp?.data?.label}</span> of this task</>;
        }else if(step=="STEP2"){
            // return value?.label;
            if(stepsData["task"]){
                return <span>This task is {type} <span style={{fontWeight:"bold",color:"rgb(64 169 255)"}}>{value?.label}</span></span>
            }else{
                return <span>Select {type=="Waiting on"?"predecessor":"successor"} Task</span>
            }
        }else if(step=="STEP3"){
            let temp = targetTaskStatusOptions.find(k=>k.id==value);
            return <span style={{backgroundColor:temp?.data?.color,
                padding: "2px 7px",
                "border-radius": "12px",
                color:invertColor(temp?.data?.color,true)
            }}>{temp?.data?.label}</span>;
        }else if(step=="STEP4"){
            // let temp = targetTaskStatusOptions.find(k=>k.id==value);
            return <span>Select dependency type</span>
            // if(value){
            //     return <span>{stepsData["toTaskLabel"]}</span>
            // }else{
            //     return <span>Select {type=="Waiting on"?"predecessor":"successor"} Task's stage</span>
            // }
        }else if(step=="STEP5"){
            // let temp = targetTaskStatusOptions.find(k=>k.id==value);
            if(value){
                return <span>This task's {stepsData["thisTaskLabel"]}</span>
            }else{
                return <span>Select this task's stage</span>
            }
        }
    }
    return (
        <Popover
            placement='leftBottom'
            open={open}
            overlayClassName='Create-Dependency-Popover'
            trigger={"click"}
            onOpenChange={e => {
                if(clear=="prevent_dependency"){
                    setClear("");
                    return;
                }
                if(!e){
                    setStepsData(tempObjectNew)
                }
                setOpen(e)
            }}
            content={<>
                {getHeaderBar()}
                <Collapse
                    activeKey={activeTab}
                    className='CustomeCollapseForPopup'
                    defaultActiveKey={['2']}
                    onChange={handleCollapseChange}>
                    {/* 
                    // This code is usefull when we consider mapping status to status Dependencies
                    <Panel
                        header={stepsData["thisStatus"]?getHeaderMessage(stepsData["thisStatus"],"STEP1") : "Select Source Task Status For Mapping"}
                        key="1"
                        >
                        {getStep2()}
                    </Panel> */}

                    {/* <Panel
                        // header={`${stepsData.thisStatus === '' ? `'${thisTask?.TaskFlowObject?.Nodes?.find(k => k.id === thisTask.TaskStatus)?.data?.label}'` + ' of this task is ' + type
                        //     : `'${thisTask?.TaskFlowObject?.Nodes?.find(k => k.id === stepsData.thisStatus)?.data?.label}'` + ' of this task is ' + type}
                        // ${stepsData.toStatus != "" ? `'${stepsData.task?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id === stepsData.toStatus)?.data?.label}' of '${stepsData?.task?.label}'` : ''}`}
                        header={getHeaderMessage(stepsData["thisTaskStage"],"STEP5")}
                        key="1"
                        style={{ marginTop: "6px" }}
                        // collapsible={!stepsData.thisStatus ? 'disabled' : ''}
                    >
                        {getStep5()}
                    </Panel> */}
                    <Panel
                        // header={`${stepsData.thisStatus === '' ? `'${thisTask?.TaskFlowObject?.Nodes?.find(k => k.id === thisTask.TaskStatus)?.data?.label}'` + ' of this task is ' + type
                        //     : `'${thisTask?.TaskFlowObject?.Nodes?.find(k => k.id === stepsData.thisStatus)?.data?.label}'` + ' of this task is ' + type}
                        // ${stepsData.toStatus != "" ? `'${stepsData.task?.taskData?.TaskFlowObject?.Nodes?.find(k => k.id === stepsData.toStatus)?.data?.label}' of '${stepsData?.task?.label}'` : ''}`}
                        header={getHeaderMessage(stepsData["task"],"STEP2")}
                        key="2"
                        style={{ marginTop: "6px" }}
                        // collapsible={!stepsData.thisTaskStage ? 'disabled' : ''}
                    >
                        {getStep1()}
                    </Panel>
                   
                    {/* <Panel
                        header={getHeaderMessage(stepsData["toTaskStage"], "STEP4")}
                        key="3"
                        collapsible={!stepsData.task ? 'disabled' : ''}
                    >
                        {getStep4()}
                    </Panel>  */}
                </Collapse>
            </>}
        >
            {children}
        </Popover>
    )
}
