import React, { useContext, useEffect, useState } from "react";
import "./ActivityStreamTab.css"; // Import the CSS file
import { Col, Row } from "reactstrap";
import { Spin, Timeline } from "antd";
import ActivityTimeLine from "../../../common/ActivityStream/ActivityTimeLine";
import AwsServerService from "../../../common/util/AwsServerService";
import dayjs from "dayjs";
import { AppDataRequired } from "../../../App";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { convertDateFromString } from "../../../components/ProjectTasks/Util";
import AppContext from "../../../Context/AppContext";
import { refershModule } from "../../../Context/AppActions";
import { modifyTimezoneString } from "../../../components/HR/TimeClock/Util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const ActivityStreamTab = ({ task, pipelineId, module, refreshData, allTasksAndSubtasks, viewTaskFunction }) => {
  const [activitiyData, setActivityData] = useState([]);
  const { AllUsers } = useContext(AppDataRequired);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [pageSize,setPageSize] = useState(0);
  const [items,setItems] = useState([])

  function getActivityData(isLoad){
    if(isLoad!="load"){
      setLoading(true);
    }else{
      setLoadMore(true);
    }
    AwsServerService.getAllActivityByModule(pipelineId, module, pageSize)
      .then((res) => {
        // res = res.sort(
        //   (a, b) => new Date(b?.CREATEDTIME) - new Date(a.CREATEDTIME)
        // );
        if(isLoad!="load"){
          setLoading(false);
          setActivityData(res);
        }else{
          setLoadMore(false);
          setActivityData(prev=>[...prev,...res])
        }
      })
      .catch((err) => {
        setLoadMore(false);
        setLoading(false);
        // swal("Error!", "Something went wrong!", "error");
      });
  }
  useEffect(e=>{
    if (pageSize > 50) {
      getActivityData("load");
    } else if(pageSize==50){
      getActivityData();
    }else if(!pageSize || pageSize==-1 ){
      setPageSize(50);
    }
  },[pageSize])
  useEffect((e) => {
    if (pipelineId) {
      setPageSize(-1);
    }
  },
    [pipelineId, refreshData]
  );
  useEffect(e => {
    if (state?.refershModule && state.refershModule.module == 'task-activity') {
      // getActivityData();
      setPageSize(-1);
      // getActivityData();
      dispatch(refershModule({ module: "", data: "" }))
    }
  }, [state.refershModule])

  const getMessage = (text, user, allUsers) => {
    let firstPReplaced = false; // Track the first <p>
    let lastPIndex = text.lastIndexOf('</p>'); // Get the position of the last </p>
    
    let formattedText = text
      // Replace date strings with a formatted version
      .replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2})/g, match => convertDateFromString(match))
      // Replace <p> and </p> based on their positions
      .replace(/<\/?p>/g, (tag, offset) => {
        if (tag === '<p>') {
          if (!firstPReplaced) {
            firstPReplaced = true;
            return '<span>';
          }
          return '<br>';
        }
        if (tag === '</p>') {
          if (offset === lastPIndex) {
            return '</span>';
          }
          return ''; // Remove all other occurrences of </p>
        }
        return tag; // Fallback for unexpected cases
      });


    allUsers.forEach(otherUser => {
      const zuidRegex = new RegExp(`"${otherUser.zuid}"`, 'g');  // Looks for "zuid" with quotes
      formattedText = formattedText.replace(zuidRegex, `__ZUID_${otherUser.zuid}__`);
    });

    if (user) {
      formattedText = formattedText.replace("USER", "__USER__");
    }

    const parts = formattedText.split(/(__ZUID_\d+__|__USER__)/g);

    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith("__ZUID_")) {
            // Extract the ZUID from the placeholder and find the corresponding user
            const zuid = part.replace("__ZUID_", "").replace("__", "");
            const foundUser = allUsers.find(u => u.zuid === zuid);
            return foundUser ? (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/setting/users/${foundUser.id}/Users`,
                    state: { tab: "Users" },
                  });
                }}
                className="user-link"
              >
                {foundUser.full_name + " "}
              </a>
            ) : part;
          } else if (part === "__USER__" && user) {
            // Replace `USER` placeholder with a clickable user link for the main user
            return (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/setting/users/${user.user_id}/Users`,
                    state: { tab: "Users" },
                  });
                }}
                className="user-link"
              >
                {user.full_name + " "}
              </a>
            );
          } else if (/<[^>]+>/.test(part)) {
            // Handle HTML content with dangerouslySetInnerHTML
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: part }}
              />
            );
          }
          else if (part.match(/\S+_\S+$/)) {
            let parts = part.split(/\s+/);
            let result = parts.map((word) => {
              let value = word.match(/\S+_\S+/);
              if (value) {
                let [taskKey, taskId] = value[0].split('_');
                let task = allTasksAndSubtasks[taskKey]?.get(Number(taskId));
                if (task) {
                  return (
                    <span>
                      {word.replace(value[0], "")}
                      <span className="taskNameClass" onClick={() => viewTaskFunction([taskKey, taskId])}>
                        {" " + task?.TaskName + " "}
                      </span>
                    </span>
                  );
                }
              }
              return word + " ";
            });
            return <span>{result}</span>;
          } else {
            // Render plain text for other parts
            return <span key={index}>{part}</span>;
          }
        })}
      </>
    );
  };



  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };

  const RenderChildren = (data) => {
    data = data?.data ? data?.data : data;
    let user = AllUsers.find(d => d.zuid == data?.ActivityOwner || d.email == data?.ActivityOwner);
    return (
      data?.Message && <div className="Activity-Data-Wrapper">
        <span style={{ minWidth: "3rem" }}>
          <img
            className="User-Image"
            src={getUserThumbnailUrl(user?.zuid)}
          />
        </span>
        <div>
          <p className="activity-message">
            {data?.Message ? getMessage(data?.Message, user, AllUsers) : <></>}
          </p>
          <span className="activity-date">
            {dayjs(modifyTimezoneString(data?.CREATEDTIME)).format("MMM DD YYYY hh:mm A")}
          </span>
        </div>
      </div>
    );
  };

  useEffect(e => {
    if (activitiyData) {
      let temp = activitiyData
        .filter(item => item?.Message)
        .map((item, ind) => {
          return {
            children: <RenderChildren data={item} />,
          };
        });
      setItems(temp)
    }else{
      setItems([]);
    }
  }, [activitiyData])
  function loadMoreData(){
    setPageSize((pageSize+50));
  }
  return (
    <div className="Actvity-Items-Wrapper">
      {loading ? <div style={{
        display: "flex", width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }} ><Spin className='mr-2' tip="Loading" size='small' /></div> :
        <>
          <ActivityTimeLine items={items} loadMore={loadMore} />
          <span className="LoadMore-Class" onClick={loadMoreData}>{loadMore && <Spin className='mr-2' tip="Loading" size='small' />}Load more<FontAwesomeIcon className="ml-1" icon={faArrowDown}/></span>
        </>}
    </div>
  );
};

export default ActivityStreamTab;