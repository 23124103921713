import React, { useState } from 'react'
import { OtsideClickClosingWrapper } from '../../../common/EmojiReactionPicker/OtsideClickClosingWrapper'
import { Popconfirm } from 'antd';

export const CustomePopConfirm = ({ callBackData,children,
    onConfirm, onCancel, okText, cancelText, icon
    , className, overlayClassName, title, description }) => {
    const [open, setOpen] = useState(false);
    return (
        <OtsideClickClosingWrapper close={() => {
            setOpen(false);
        }}>
            <Popconfirm
                className={className}
                open={open}
                overlayClassName={overlayClassName}
                onConfirm={() => onConfirm(callBackData)}
                onCancel={() => {
                    onCancel();
                }}
                onOpenChange={state => {
                    setOpen(state)
                }}
                okText={okText}
                cancelText={cancelText}
                title={title}
                description={description}
                icon={icon}
            >{children}</Popconfirm>
        </OtsideClickClosingWrapper>
    )
}
