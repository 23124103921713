import { MarkerType } from "@xyflow/react";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import './Flow.css';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  updateEdge
} from "reactflow";
import "reactflow/dist/style.css";
import EditableCard from "./EditableCard";
import CustomNode from "./HelperComponents/CustomNode";
import { getRandomNumberExcluding } from "../HR/TimeClock/Util";
import AwsServerService from "../../common/util/AwsServerService";
import CustomEdge from "./HelperComponents/CustomEdge";
import { DeleteNodeConformPopup } from "./HelperComponents/DeleteNodeConformPopup";
import swal from "sweetalert";
import { NewCustomePopover } from "./HelperComponents/NewCustomePopover";
import { Col, Row } from "reactstrap";
import { Button, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FlowEdgeAndNodes, OnClickEdge } from "../../Context/AppActions";
import AppContext from "../../Context/AppContext";
import DiverseNode from "./HelperComponents/DiverseNode";

const onInit = (reactFlowInstance) => {
  // console.log("flow loaded:", reactFlowInstance);
}
const nodeTypes = {
  custom:(params) => {return <CustomNode {...params} />},
  diverse:(params) => {return <DiverseNode {...params} />},
};
const edgeTypes = {
  "custom-edge": (params) => {
    return <CustomEdge {...params} />
  },
};
export const Flow = ({view,flowContext, bluePrintData,showProgress,editFlow, onNodeSelect, onEdgeSelected, selectedData, onSelectedData, addNodeButton, resetAddNodeButton, saveCallback ,disableMiniMap}) => {
  // const [selectedEdge, setSelectedEdge] = useState(null);



  let newNodeTemplate = {
    id: "1",
    type: "custom",
    data: {
      label: "New Node",
      color: "#6495ED",
    },
    width: 100,
    height: 50,
    position: { x: 700, y: 0 }
  }
  let newDiverseNodeTemplate = {
    id: "1",
    type: "diverse",
    data: {
      label: "Diverse",
      color: "#fff5e0",
      Direction:"top-bottom"
    },
    width: 100,
    height: 50,
    position: { x: 700, y: 0 }
  }
  const nodesDummy = [
    {
      id: "1",
      type: "custom",
      data: {
        label: "Not Started",
        color: "grey",
        state:"Start"
      },
      position: { x: 250, y: 0 },
      draggable: false,
      NonSelectable: true
    },
  ];

  const edgesDummy = [

  ];
  const [deletePopover,setDeletePopover] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState(nodesDummy);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesDummy);
  const [edgeConflictObj,setEdgeConflictObj] = useState();
  const [edgeDeleteConfirm,setEdgeDeleteConfirm] = useState();
  const [errorPopConfirm,setErrorPopConfirm] = useState();
  const [updateActions,setUpdateActions] = useState();
  const AppData = useContext(AppContext);
  const { dispatch ,state } = AppData || {};
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const onEdgeUpdate = (oldEdge, newConnection) => {

    setEdges((eds) =>updateEdge(oldEdge, newConnection, eds)
    );
  };
  useEffect(e=>{
    let ob={...state.FlowEdgeAndNodes}
    ob["nodes"]=[...nodes];
    ob["edges"]=[...edges];
    dispatch(FlowEdgeAndNodes(ob))
  },[nodes,edges])
  useEffect(e=>{
    if(state.OnClickEdge){
      // let obj={
      //   data:state.OnClickEdge.data,
      //   id:state.OnClickEdge.id,
      //   label:state.OnClickEdge.label,
      //   markerEnd:state.OnClickEdge.markerEnd,
      //   selected:true,
      //   source:state.OnClickEdge.source,
      //   sourceHandle:state.OnClickEdge.sourceHandle,
      //   target:state.OnClickEdge.target,
      //   targetHandle:state.OnClickEdge.targetHandle,
      //   type:state.OnClickEdge.type
      // }
      let obj={...state.OnClickEdge}
      obj["selected"] = true;
      let isDiverseEdge = nodes.find(k => k.id == obj.source);
      if (isDiverseEdge?.type == "diverse") {
        obj["uneditable"] = true;
        // obj["label"] = "This is diverse edge";
      }
      onEdgeSelected(obj);
    }
  },[state.OnClickEdge])
  
  useEffect(e=>{
    let arr={};
    let obj={}
    let finalArr=edges.filter(async edg=>{
      let k=edg.source+"-"+edg.target+"-"+edg.sourceHandle.split("_")[0]+"_"+edg.targetHandle.split("_")[0];
      if(!arr[k]){
        arr[k]=edg;
        return edg;
      }else if(arr[k]){
        if((arr[k]?.label || arr[k]?.Criteria) && (edg?.label || edg?.Criteria)){
          obj={
            first:arr[k],
            second:edg
          }
          setEdgeConflictObj(obj);
          return edg;
        }
      }
    })
    if(edges.length!=finalArr.length){
      setEdges(finalArr);
    }
  },[edges])
  const onEdgeClick = (event, edge) => {
    onEdgeSelected(edge);
    // console.log('Edge clicked:', edge);
  };

  const handleEdgeUpdate = (updatedEdge) => {
    setEdges((eds) => eds.map((e) => (e.id === updatedEdge.id ? updatedEdge : e)));
    onEdgeSelected(null);
  };
  const onEdgeDelete = (edgeId) => {
    setEdges((eds) => eds.filter((e) => e.id !== edgeId));
  };
  const deleteNodeRelatedEdges = (nodeid) => {
    let tempEdges = edges.filter(edge => !(edge.source == nodeid || edge.target == nodeid))
    setEdges(tempEdges);
  }
  const updateEditedData = ({ type, action, data }) => {
    if (type == "node") {
      if (action != "delete") {
        let tempNodes = nodes.map(node => {
          if (node.id == data.id) {
            return data;
          } else {
            return node;
          }
        })
        setNodes(tempNodes);
      } else {
        setDeletePopover({open:true,node:data,flowId:editFlow?.ROWID});
        // let tempNodes = nodes.filter(node => node.id != data.id)
        // deleteNodeRelatedEdges(data.id);
        // setNodes(tempNodes);
      }
    } else if (type == "edge") {
        
      if (action != "delete") {
        // let isConnectedWithDiverse=nodes.find(k=>k.id==)
        let tempEdges = edges.map(edge => {
          if (edge.id == data.id) {
            return data;
          } else {
            return edge;
          }
        })
        setEdges(tempEdges);
      } else {
        setEdgeDeleteConfirm(data);
        // let tempEdges = edges.filter(edge => edge.id != data.id)
        // setEdges(tempEdges);
      }
    } else if (type == "flow") {
        
      let diverseNodes = nodes.filter(n=>n.type=="diverse").map(n=>n.id);
      let validNodes=[];
      let filteredNodes = nodes.filter(n=>{
        if(n.type=="custom" || (diverseNodes.includes(n.id) && edges.filter(e=>e.source==n.id || e.target==n.id)?.length>=2)){
          validNodes.push(n.id);
          return n;
        }
      });
      let filteredEdges= edges.filter(e=>validNodes.includes(e.source) && validNodes.includes(e.target));
      handleSave(action, data,filteredNodes,filteredEdges);
    } else if (type == "edit flow") {
      if (data.Nodes) {
        setNodes([...data.Nodes])
      }
      if (data.Edges) {
        setEdges([...data.Edges])
      }
      onSelectedData({
        type: "",
        action: "",
        data: ""
      })
    } else if (type == "view flow") {
      if (data.Nodes) {
        setNodes([...data.Nodes])
      }
      if (data.Edges) {
        setEdges([...data.Edges])
      }
      onSelectedData({
        type: "",
        action: "",
        data: ""
      })
    }
  }
  useEffect(e => {
    updateEditedData(selectedData)
  }, [selectedData])
  useEffect(e => {
    if (addNodeButton == "add") {
      let temp = [...nodes]
      let tempNode = { ...newNodeTemplate }
      tempNode["id"] = getRandomNumberExcluding(nodes.map(n => n.id));
      temp.push(tempNode);
      setNodes(temp);
      resetAddNodeButton(null);
    }else if(addNodeButton?.direction){
      let temp = [...nodes]
      let tempNode = { ...newDiverseNodeTemplate }
      tempNode["id"] = getRandomNumberExcluding(nodes.map(n => n.id));
      tempNode["data"]["Direction"]=addNodeButton?.direction
      temp.push(tempNode);
      setNodes(temp);
      resetAddNodeButton(null);
    }
  }, [addNodeButton])
  // const checkNodeValidations=()=>{
  //   let valid=true;
  //   nodes.forEach(n=>{

  //   })
  // }
  const handleSave = async (action, data,Nodes,Edges) => {
    if(Edges?.find(k=>!k.label && Nodes.find(n=>n.id==k.source)?.type!="diverse")){
      setErrorPopConfirm("Some transitions are missing names. Please label all transitions before proceeding.");
      onSelectedData({
        type: "",
        data: "",
        action: "",
      });
      return;
    }
    if(Nodes.some(n=>(n.data?.state!="Close" && n.data?.state!="Start") && n.type=='custom' && Edges.filter(e=>e.source==n.id || e.target==n.id)?.length<2)){
      setErrorPopConfirm("Some statuses are missing required transitions.");
      onSelectedData({
        type: "",
        data: "",
        action: "",
      });
      return;
    }
    if (action == "create") {
      let temp = {
        "CREATEDBY": localStorage.getItem("ZUID"),
        "Name": data.name,
        "Edges": Edges,
        "LockFlow": data.lock,
        "InputFields":data.inputFields,
        "Description": data.description,
        "Nodes": Nodes
      }
      await AwsServerService.createTaskFlow(temp).then(res => {
        setNodes(nodesDummy)
        setEdges([])
        saveCallback(res)
      }).catch(err => {
        console.log(err);
      })
    } else if (action == "edit" && editFlow?.ROWID) {
      let temp = {
        "ROWID": editFlow.ROWID,
        "MODIFIEDBY": localStorage.getItem("ZUID"),
        "Name": data.name,
        "LockFlow": data.lock,
        "Edges": Edges,
        "InputFields":data.inputFields,
        "Description": data.description,
        "Nodes": Nodes
      }
      await AwsServerService.updateTaskFlow(temp).then(res => {
        let ob={
          TaskFlowObject:temp,
        }
        if(updateActions && updateActions.Old && updateActions.New){
            ob["StatusChange"] = {
              Old:updateActions.Old.id,
              New:updateActions.New.id
            }
        }
        AwsServerService.modifyTasksByFlowChange(ob);
        setNodes(nodesDummy)
        setEdges([])
        saveCallback(res)
      }).catch(err => {
        console.log(err);
      })
    }
  }
  function handleMeargeEdges(ee){
    let arr=[];
    let finalArr=[];
    let kk=ee.source+"-"+ee.target+"-"+ee.sourceHandle.split("_")[0]+"_"+ee.targetHandle.split("_")[0];
    edges.forEach(async edg=>{
      let k=edg.source+"-"+edg.target+"-"+edg.sourceHandle.split("_")[0]+"_"+edg.targetHandle.split("_")[0];
      if(!arr.includes(k) && k==kk){
        arr.push(k);
        finalArr.push(ee);
      }else if(k!=kk){
        finalArr.push(edg);
      }
    })
    if(edges.length!=finalArr.length){
      setEdges(finalArr);
    }
  }
  return (<>
    <NewCustomePopover
      width={30}
      open={edgeDeleteConfirm}
      title={"Warning!"}
      okText={"OK"}
      cancelText={"Cancel"}
      onCancel={e=>{
        setEdgeDeleteConfirm(null);
      }}
      onOk={e=>{
        let tempEdges = edges.filter(edge => edge.id != edgeDeleteConfirm?.id)
        onSelectedData({
          type: "",
          data: "",
          action: "",
        });
        setEdgeDeleteConfirm(null);
        setEdges(tempEdges);
      }}
    >
      <div className='Msg-Block'>
        Are you sure you want to delete this Edge?
      </div>
    </NewCustomePopover>
    <NewCustomePopover
      width={30}
      open={errorPopConfirm}
      title={"Warning!"}
      okText={"OK"}
      cancelText={"Cancel"}
      onCancel={e => {
        setErrorPopConfirm(null)
      }}
      onOk={e => {
        setErrorPopConfirm(null)
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className='Msg-Block'>
        {errorPopConfirm}
      </div>
    </NewCustomePopover>
    <NewCustomePopover
      width={30}
      open={edgeConflictObj}
      disableCloseIcon={true}
      title={"Duplicate Edge. Action Required!"}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={e=>{
        setEdgeConflictObj(null);
      }}
      onOk={e=>{
        
      }}
    >
      {/* <div className='Msg-Block'>
        Are you sure you want to delete this status?
      </div> */}
      <Row className="Conflict-Row" sm={12}>
        <Col sm={2}>First Edge</Col>
        <Col sm={6}>
        <div style={{display:Flex,flexDirection:"row"}}>
          <span>Name:</span>
          <span className="ml-2"><FontAwesomeIcon className="mr-1" color={edgeConflictObj?.first?.data?.color} icon={faCircle} />{edgeConflictObj?.first?.label?edgeConflictObj?.first?.label:"No Name"}</span>
        </div>
        <div style={{display:Flex,flexDirection:"row"}}>
          <span>Criteria Count:</span>
          <span  className="ml-2">{edgeConflictObj?.first?.Criteria?edgeConflictObj?.first?.Criteria?.length:"No Criteria"}</span>
        </div>
        </Col>
        <Col className="J-end" sm={4}><Button onClick={e=>{
          let obj={...edgeConflictObj?.first};
          let temp=[]
          if(edgeConflictObj?.first?.Criteria){
            temp=[...edgeConflictObj?.first?.Criteria]
          }
          if(edgeConflictObj?.second?.Criteria){
            temp=[...temp,...edgeConflictObj?.second?.Criteria]
          }
          obj["Criteria"]=temp;
          handleMeargeEdges(obj)
          setEdgeConflictObj(null)
        }}>Merge And Keep First</Button></Col>
      </Row>
      <Row className="Conflict-Row" sm={12}>
        <Col sm={2}>Second Edge</Col>
        <Col sm={6}>
        <div style={{display:Flex,flexDirection:"row"}}>
          <span>Name:</span>
          <span  className="ml-2"><FontAwesomeIcon className="mr-1" color={edgeConflictObj?.second?.data?.color} icon={faCircle} />{edgeConflictObj?.second?.label?edgeConflictObj?.second?.label:"No Name"}</span>
        </div>
        <div style={{display:Flex,flexDirection:"row"}}>
          <span>Criteria Count:</span>
          <span  className="ml-2">{edgeConflictObj?.second?.Criteria?edgeConflictObj?.second?.Criteria?.length:"No Criteria"}</span>
        </div>
        </Col>
        <Col className="J-end" sm={4}><Button onClick={e=>{
          let obj={...edgeConflictObj?.second};
          let temp=[]
          if(edgeConflictObj?.first?.Criteria){
            temp=[...edgeConflictObj?.first?.Criteria]
          }
          if(edgeConflictObj?.second?.Criteria){
            temp=[...temp,...edgeConflictObj?.second?.Criteria]
          }
          obj["Criteria"]=temp;
          handleMeargeEdges(obj)
          setEdgeConflictObj(null)
        }}>Merge And Keep Second</Button></Col>
      </Row>
    </NewCustomePopover>
    <DeleteNodeConformPopup  
    Nodes={nodes}
    edgeConflictObj={edgeConflictObj}
    Edges={edges}
    bluePrintData={bluePrintData} 
    onOk={(nodeTodelete,targetNode,dependancies,statusdependancies)=>{
        
      if(nodeTodelete && targetNode && (statusdependancies?.length>0 || dependancies?.Task || dependancies?.SubTask) && view!="create"){
       setUpdateActions({
          Old:nodeTodelete,
          New:targetNode
        });
      }
      let temp = nodes.filter(k=>k.id!=nodeTodelete.id);
      setNodes(temp);
      onSelectedData({
        type: "",
        data: "",
        action: "",
      });
      setDeletePopover(null);
    }}
    setOpen={e=>setDeletePopover(e)}  
    open={deletePopover} />
  
    <ReactFlow
      className="Blueprint-flow-wrapper"
      nodes={nodes}
      edgesUpdatable
      onNodeClick={(event, node) => {
        if (node.hasOwnProperty('NonSelectable')) {
          return
        }
        onNodeSelect({ ...node });
      }}
      onEdgeClick={(event, edge) => {
        dispatch(OnClickEdge(edge));
      }}
      edges={edges}
      onNodesChange={(params) => {
        onNodesChange(params)
      }}
      onEdgesChange={(params) => {
        onEdgesChange(params);
      }}

      onConnect={(params) => {
        //block the diverse edges from getting duplicate
        let sourceNode=nodes.find(k=>k.id==params.source);
        const existingConnections = edges.find(
          (edge) => (edge.source === params.source && edge.target === params.target) && sourceNode?.type=="diverse"
        );
        if (existingConnections) {
          return false; // Prevent additional connections
        }
        let ed={
          ...params,
          type: "custom-edge",
          data:{color:"#6495ED"},
          markerEnd: {
            type: MarkerType.ArrowClosed
          }
        }
        let data;
        if(ed.data){
          data={...ed.data}
        }
        if(sourceNode.type=="diverse" && sourceNode?.data?.color){
          data["color"]=sourceNode.data.color
        }
        ed["data"]=data;
        onConnect(ed)
      }}
      onEdgeUpdate={onEdgeUpdate}
      onInit={onInit}
      fitView
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      attributionPosition="top-right"
    >
      {disableMiniMap!=true && <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === "input") return "#0041d0";
          if (n.type === "output") return "#ff0072";
          if (n.type === "default") return "#1a192b";

          return "#eee";
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return "#fff";
        }}
        nodeBorderRadius={2}
      />}
      <Controls />
      <Background color="black" gap={16} />
    </ReactFlow> 
    </>
  );
}