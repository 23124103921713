import React, { useState, useEffect, useRef } from 'react';
import { Button, Popconfirm, Popover } from 'antd';
import { invertColor } from '../../../components/HR/TimeClock/Util';
import { QuestionCircleOutlined } from '@ant-design/icons';
import SingleTransition from './SingleTransition';

export const ButtonOverflow = ({ 
  buttons,
  activeSidebarObject,
  onClickButton,
}) => {
  const containerRef = useRef(null);
  const [visibleButtons, setVisibleButtons] = useState(buttons);
  const [overflowButtons, setOverflowButtons] = useState([]);
  const [openButtons, setOpenButtons] = useState(false);

  useEffect(() => {
    const calculateOverflow = () => {
      const container = containerRef?.current;
      const parent = container?.closest('.Button-Wrapper');;
      const containerWidth = parent?.offsetWidth;
      let totalWidth = 0;
      let visible = [];
      let overflow = [];

      for (let i = 0; i < buttons.length; i++) {
        // const buttonWidth = container?.children[i]?.offsetWidth || 0;
        totalWidth+=200;

        if (totalWidth <= containerWidth) {
          visible.push(buttons[i]);
        } else {
          overflow.push(buttons[i]);
        }
      }

      setVisibleButtons(visible);
      setOverflowButtons(overflow);
      // console.log("v---",visible);
      // console.log("iv---",overflow);
      
    };

    calculateOverflow();
    window.addEventListener('resize', calculateOverflow);

    return () => {
      window.removeEventListener('resize', calculateOverflow);
    };
  
  }, [buttons,activeSidebarObject]);

  // const handleCancel = () => {
  //   setConfirmVisible(false);  // Hide the Popconfirm without opening the dropdown
  // };
  // const handleConfirm = () => {
  //   setConfirmVisible(false); // Hide the Popconfirm
  // };

  const getStyleObj=(ob)=>{
    // let temp=mainStatusOptions?.find(k=>k.label==status);
    let sty={
      backgroundColor:"white",
      color:"black",
    //   border:"1px solid white"
    }
    if(ob && ob.color){
      sty["backgroundColor"]=ob.color;
    //   sty["border"]= `1px solid ${ob.color}`
      sty["color"]= invertColor(ob.color,true)
    }
    return sty;
  }
  const popoverContent = (
    <div>
      {overflowButtons.map((button,index) => (
        <SingleTransition
          customeClass={`Overflow_Btn_${index}`}
          disabled={button.disable}
          button={button}
          getStyleObj={getStyleObj}
          onClickButton={onClickButton}
          onCloseCallback={(e)=>{setOpenButtons(false)}}
        />
      ))}
    </div>
  );
 
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap' }} ref={containerRef}>
      {visibleButtons.map((button,index) => (
        <SingleTransition 
          customeClass={`Visible_Btn_${index}`}
          disabled={button.disable}
          button={button}
          getStyleObj={getStyleObj}
          onClickButton={onClickButton}
          onCloseCallback={(e)=>{setOpenButtons(false)}}
        />
      ))}
      {overflowButtons.length > 0 && (
        <Popover content={popoverContent} trigger="click" open={openButtons} onOpenChange={(e)=>{
          setOpenButtons(e);
        }}>
          <Button>+{overflowButtons.length}</Button>
        </Popover>
      )}
    </div>
  );
};