import { Button, Checkbox, Input, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import './common.css';
import { Col, Row } from 'react-bootstrap'; 
import AwsServerService from "../../../common/util/AwsServerService";
import TextArea from 'antd/es/input/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export const Checklist = ({taskData,selectedTable,activeTab}) => {
    const [isPopupOpen,setIsPopupOpen]=useState(false);
    const [loader,setloader]=useState(false);
    const [checklistItem,setChecklistItem]=useState({Name:'',Description:''});
    const userInfo = JSON.parse(localStorage.getItem('userinfo'));

    const [data,setData]=useState([]);

    useEffect(()=>{
        if(activeTab=="2"){
        setloader(true);
        let taskType='';
        if(selectedTable=='tasks'){
            taskType=taskData.hasOwnProperty('SubTaskROWID')?'SubTask':'Task'
        }else{
            taskType=taskData.hasOwnProperty('SubTaskROWID')?'SubTaskTemplate':'TaskTemplate'
        }
        let taskId=taskType=='Task'?taskData.TaskROWID:taskData.SubtTaskROWID; 
        AwsServerService.getChecklistByTaskIDandType(taskId,taskType).then(res=>{
            let data=res.data;
            setData(data);
            setloader(false);
        }).catch(er=>{
            setloader(false);
        })
    }
    },[activeTab])
    const resetChecklistData = ()=>{
        setChecklistItem({Name:'',Description:''})
        setIsPopupOpen(false);
    }
    async function handleSaveChecklist(data) { 
        setloader(true);
        if(selectedTable=='tasks'){
        let type=taskData.hasOwnProperty('SubTaskROWID')?'SubTask':'Task'
        let TaskROWID=type=='Task'?taskData.TaskROWID:taskData.SubtTaskROWID; 
        let payload={ 
        Name:data.Name,
        Description:data.Description,
        CREATEDBY:userInfo.zuid,
        MODIFIEDBY:userInfo.zuid,
        TaskROWID:TaskROWID,
        TaskType:type
        }
        await AwsServerService.createTaskChecklist(payload).then(res=>{  
            setloader(false);
            payload['ROWID']=res.data[0].insertId; 
            setData(previous=>[...previous,payload])
        }).catch(er=>{setloader(false)})
    }else{
        let type=taskData.hasOwnProperty('SubTaskROWID')?'SubTaskTemplate':'TaskTemplate'
        let TaskROWID=type=='Task'?taskData.TaskROWID:taskData.SubtTaskROWID;
        let payload={ 
            Name:data.Name,
            Description:data.Description,
            CREATEDBY:userInfo.zuid,
            MODIFIEDBY:userInfo.zuid,
            TaskROWID:TaskROWID,
            TaskType:type
            }
            await AwsServerService.createTaskChecklist(payload).then(res=>{  
                setloader(false);
                payload['ROWID']=res.data[0].insertId; 
                setData(previous=>[...previous,payload])
            }).catch(er=>{setloader(true)})
    }
    resetChecklistData()
    }
    async function updateCheckedStatus(ROWID,isChecked) { 
        let checked=isChecked?1:0;
        await AwsServerService.updateTaskChecklist({ROWID:ROWID,isChecked:checked}).then(res=>{
            setData(previous=>previous.map(e=>{  
                if(e.ROWID==ROWID){
                    return {
                        ...e,isChecked:checked
                    }
                }else{
                    return e;
                }
            }))
            resetChecklistData()
        })
    }
    async function handleDeleteChecklist(ROWID) { 
        setloader(true);
        await AwsServerService.deleteTaskChecklist(ROWID).then(res=>{
            setloader(false);
            setData(previous=>previous.filter(e=>e.ROWID!=ROWID))
        }).catch(er=>{setloader(false)})
    }
    async function updateTaskIsMandatory(isChecked) {   
        let checked=isChecked?1:0;
        setloader(true);
        if(selectedTable=='tasks'){ 
        let type=taskData.hasOwnProperty('SubTaskROWID')?'SubTask':'Task'
        let ROWID=type=='Task'?taskData.TaskROWID:taskData.SubtTaskROWID; 
        if(type=='Task'){   
            await AwsServerService.updateTask({ChecklistMandatory:checked,ROWID:ROWID}).then(res=>{
                setloader(false);
            }).catch(er=>{setloader(false)})
        }else{
            await AwsServerService.updateSubtask({ChecklistMandatory:checked,ROWID:ROWID}).then(res=>{
                setloader(false);
            }).catch(er=>{setloader(false)})
        }
        }else{  
        let type=taskData.hasOwnProperty('SubTaskROWID')?'SubTaskTemplate':'TaskTemplate'
        let ROWID=type=='Task'?taskData.TaskROWID:taskData.SubtTaskROWID;    
        if(type=='TaskTemplate'){   
            await AwsServerService.updateTaskInTemplate({ChecklistMandatory:checked,ROWID:ROWID}).then(res=>{
            setloader(false);
            }).catch(er=>{setloader(false)})
        }else{
            await AwsServerService.updateSubtaskInTemplate({ChecklistMandatory:checked,ROWID:ROWID}).then(res=>{
            setloader(false);
            }).catch(er=>{setloader(false)})
        } 
        }
        setloader(false);
    }
    const popupForTimeTrackRecords=()=>{
        return <>
        <div className='checklistPopoverDiv'>
        <Row>
        <span className='checklistPopoverSpan'>Name</span>
        <Input value={checklistItem.Name}
        style={{textIndent:'6px'}}
        onChange={e=>setChecklistItem({...checklistItem,Name:e.currentTarget.value})}/>
        </Row>
        <Row>
        <span className='checklistPopoverSpan'>Description</span>
        <TextArea value={checklistItem.Description}
        style={{textIndent:'6px'}}
        onChange={e=>setChecklistItem({...checklistItem,Description:e.currentTarget.value})}/>
        </Row>
        </div>
        <div className='footer'>
        <Button  style={{marginRight:'.5rem'}} onClick={e=>resetChecklistData()}>Cancel</Button>
        <Button  onClick={e=>handleSaveChecklist(checklistItem)}>Save</Button>
        </div>
        </>
    }
    return (
        <div className='checklistTab'>
            <div className='headerChecklist'><Row><Col sm={10} style={{display: 'flex',alignItems: 'center'}}>
            <Checkbox 
            //checked={taskData?.ChecklistMandatory}
             disabled={data.length==0}  value={'IsMandatory'} onChange={e=>updateTaskIsMandatory(e.target.checked)} />
            <div style={{marginLeft:'5px',fontWeight: '500',color: '#64759d'}}>Mark All Mandatory</div>
            </Col><Col sm={2}>
            <Popover 
            placement='top'
            open={isPopupOpen}
            overlayClassName='testClass'
            trigger={"click"}
            onOpenChange={e => {
              setIsPopupOpen(e)
            }}
            content={popupForTimeTrackRecords}
             >
            <Button style={{marginLeft:'2rem'}} onClick={e=>setIsPopupOpen(true)}>Add +</Button>
            </Popover>
            </Col></Row>
            </div>  
            {data.length==0?<div className='checklistWrapper'> {loader?<Spin/>:''} </div>:loader?<Spin/>:''}
            {data.map(element=>(
            <div className='checklistRow'>
            <Row>
            <Col sm={10} style={{display: 'flex'}}>
            <Checkbox checked={element.isChecked}  value={element.ROWID} onChange={e=>updateCheckedStatus(e.target.value,e.target.checked)} />
            <div style={{marginLeft:'5px'}} className='Elipsis-Class' title={element.Name}>{element.Name}</div>
            </Col>
            <Col sm={2}>
            <span className='checklistDeleteIcon'>
            <FontAwesomeIcon icon={faTrash}
            onClick={e=>handleDeleteChecklist(element.ROWID)}
            />
            </span>
            </Col> 
            </Row>
            </div>
        ))
            } 
        </div> 
    )
}
