import { faEdit, faPlayCircle, faStopCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'  
import { Popover, Button, Tabs, DatePicker as AntdDatepicker, Collapse, Input } from 'antd';
import {Row, Col} from 'reactstrap'
import TimePicker from 'react-time-picker';  
import { faSlidersH, faPen, faEye, faTrashAlt, faAlignLeft, faEyeSlash, } from '@fortawesome/free-solid-svg-icons';
import { CustomeCollapse } from '../../tabs/Task/AddTaskTabs/CustomeCollapse';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';  
import './TimeTracker.css'
import { convertToUSTime, formateNoInDecimalString, getWorkingHoursAndMinutes, getWorkingHoursMinutesAndSeconds, invertColor, modifyTimezoneString } from '../../components/HR/TimeClock/Util'
import AwsServerService from '../util/AwsServerService'
import moment from 'moment';
import { CaretDownOutlined, CaretRightOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { CCollapse } from '@coreui/react';
import swal from 'sweetalert'
import { number } from 'mathjs';
import { AppDataRequired } from '../../App';
import JoditTextEditor from '../JoditEditor/JoditTextEditor';
import DOMPurify from 'dompurify';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const TimeTracker = ({checkRecordIfAvailable,
  setTimer,setStartTimer,setTimer2,timer3,formatTime,timer2,timer,clockInterval,startTimer,typeOfTask,setTimer3,lastEntry,setLastEntry,userList,setUserList,description,setDescription,
  setLoader,anotherSessionActiveFlag,setAnotherSessionActiveFlag,viewTaskData,allTasksAndSubtasks, viewTaskFunction, handleHistoryAdd,users,calculateTimeDifference,transitionChangeState, 
  setTransitionChangeState,taskModalState}) => {

  const [FromRange, setFromRange] = useState(null); 
  const [key, setKey] = useState('timer');  
  const [timeLogDate, setTimeLogDate] = useState('');
  const [ToRange, setToRange] = useState(null); 
  const [timetrackedPopover, settimetrackedPopover] = useState(false);
  const availableRecord=useRef(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [editClicked, setEditClicked] = useState([]);
  const [comment, setComment] = useState('');
  const [activePopover, setActivePopover] = useState(null);
  const [commentPopover, setCommentPopover] = useState(null);

  useEffect(()=>{
    if(transitionChangeState){
      startStopClock(false);
      setStartTimer(false);
      setTransitionChangeState(!transitionChangeState);
    }
  },[transitionChangeState]) 
  const formatTimeFromSeconds = (sec) => {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec % 3600) / 60);
    let remainingSeconds = Math.floor(sec % 60); 
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    remainingSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds; 
    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  const handleSave = () => {
    setStartTimer(!startTimer)
    startStopClock(false) 
    setActivePopover(null);
  };

  const handleCancel = () => {
    setComment('')
    setActivePopover(null); // Close the popover
  };

  const handleIconClick = (buttonType) => {
    setActivePopover(buttonType);
  };

const handlePopoverOpenChange = (buttonType, visible) => {
  setActivePopover(visible ? buttonType : null);
};
const handlePopoverToggle = (index) => {
  setCommentPopover(commentPopover === index ? null : index); 
};

const commentForStopClock = (buttonType) => {
  const popoverContent = (
    <div>
      <JoditTextEditor 
        id={"transition"}
        setEmailContent={setComment}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: "10px" }}>
        <Button onClick={() => handleCancel(buttonType)}>Cancel</Button>
        <Button type="primary" onClick={() => handleSave(buttonType)}>Save</Button>
      </div>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      title="Add Comment"
      rootClassName="Timer-Comment"
      trigger="click"
      open={activePopover === buttonType}
      onOpenChange={(visible) => handlePopoverOpenChange(buttonType, visible)}
    >
      <FontAwesomeIcon
        icon={faStopCircle}
        onClick={() => handleIconClick(buttonType)}
        className="play-stop-button"
        style={{ color: 'rgb(204, 45, 45)', cursor: 'pointer' }}
      />
    </Popover>
  );
};

  const startStopClock = async (isActive) => {
    if (!isActive) {
      let name = users.find(el=>el.value==viewTaskData.Assignee);
      availableRecord.current=null;
      let stopTime =  convertToUSTime(new Date()); 
      let startTime= lastEntry.StartTime;
      // let timeObj= getWorkingHoursMinutesAndSeconds(new Date(startTime), new Date(stopTime));
      // let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes)+":"+formateNoInDecimalString(timeObj.seconds);
      let seconds = calculateTimeDifference(startTime, stopTime); 
      let TotalHours=formatTimeFromSeconds(seconds);
      console.log("stoptting",seconds); 
      let payload={ 
        StopTime:stopTime,
        TotalTime:TotalHours,
        ROWID:lastEntry.ROWID,
        Description:description,
        comments:comment
      } 
      await AwsServerService.updateTimeEntry(payload).then((result) => {
        setComment('');
        setAnotherSessionActiveFlag({Active:false,Record:{}})
        let type=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
        let id=type=='Tasks'?viewTaskData.TaskROWID:viewTaskData.SubTaskROWID
        checkRecordIfAvailable(id,type);
          // payload['CreatedBy']=lastEntry.CreatedBy;
          // payload['StartTime']=lastEntry.StartTime;    
          // let arr=[...userList]; 
          // let flag=true; 
          //     let hasArray=arr.filter(e=>e.userId==viewTaskData.Assignee);
          //      
          //     if(hasArray.length>0){ 
          //       arr.forEach((element,index)=>{
          //       if(element.userId==viewTaskData.Assignee){
          //         let TotalTime=0;
          //         Object.keys(arr[element.Entrys]).forEach(rec=>{  
          //           rec.map(record=>{ 
          //           if (record.StopTime == '-') {
          //             let seconds=0;
          //             const StopTime = stopTime
          //             const { StartTime } = record;
          //             record.StopTime=StopTime;
          //             TotalTime += calculateTimeDifference(StartTime, StopTime); 
          //             seconds = calculateTimeDifference(StartTime, StopTime); 
          //             record.TotalTime=formatTimeFromSeconds(seconds);
          //             flag=false;
          //           }else{
          //             const {StopTime} = record;
          //             const { StartTime } = record;
          //             TotalTime += calculateTimeDifference(StartTime, StopTime); 
          //           }
          //         })
          //         }) 
          //         if(flag){
          //           TotalTime+=calculateTimeDifference(payload.StartTime,modifyTimezoneString(stopTime,"MM/DD/YYYY HH:mm:ss"));
          //           let dummyPayload={...payload};
          //           dummyPayload.StopTime=modifyTimezoneString(stopTime,"MM/DD/YYYY HH:mm:ss")
          //           arr[index] = {...element,Entrys: [...element.Entrys, dummyPayload]};
          //         }else{
          //           arr[index] = {...element,Entrys: [...element.Entrys]};
          //         } 
          //       }
          //       })
          //       setUserList(arr);
          //     }else{   
          //       arr.push({      
          //         Entrys :[payload], 
          //         userId:name?.value,
          //         userName :name?.label,
          //         TotalTime:"00:00:00 Hrs"
          //       }) 
          //       setUserList(arr);
          //     } 
          setTimer3(0);
          setLastEntry(null);     
          setDescription('');
          setIsSaveClicked(false);
        })
        .catch((err) => { 
          console.log(err);
        });
    } else {
      let startTime =  convertToUSTime(new Date());
      let name = users.find(el=>el.value==viewTaskData.Assignee).label;
      let payload={
        StartTime:startTime,
        StopTime:'-',
        TaskROWID:typeOfTask.ROWID,
        TaskType:typeOfTask.Type, 
        CreatedBy:viewTaskData.Assignee,
        CreatedByName:name,
        TotalTime:'-',
        Description:description
      }
      await AwsServerService.createTimeEntry(payload).then(async (result) => {  
        if(result.data[0].insertId){
            let RowId=result.data[0].insertId;
            await AwsServerService.getTimeEntryById(RowId,typeOfTask.ROWID,typeOfTask.Type).then(res=>{
              let data=res.data[0];
              let obj = {
                StartTime: startTime,
                StopTime:  "-",
                TaskType:data[0].TaskType,
                TaskROWID:data[0].TaskROWID,
                CreatedBy:data[0].CreatedBy,
                ROWID:data[0].ROWID
              }; 
              setAnotherSessionActiveFlag({Active:true,Record:{...viewTaskData}})
              setLastEntry(obj); 
              setIsSaveClicked(true)
            })
          } else { 
            setLastEntry(null);
          }  
        })
        .catch((err) => { 
          console.log(err);
        });
    }
  };
  useEffect(() => { 
    if (lastEntry ) { 
      // if(availableRecord.current=='paused'){
      //   return;
      // }
      let seconds = 1; 
      seconds = Math.round(seconds / 1000);
      setTimer((timer) => timer + seconds); 
      setTimer2((timer2) => timer2 + seconds); 
      setTimer3((timer3) => timer3 + seconds); 
      clockInterval.current = setInterval(() => {
        setTimer((timer) => timer + 1);
        setTimer3((timer3)=> timer3 +1);
        setTimer2((timer2)=> timer2 +1);
      }, 1000);
    } else {
      clearInterval(clockInterval.current);
      setTimer2(0);
      // setTimer(0); 
    }
  }, [lastEntry]);
 
  function addSecondsToTime(timeStr, secondsToAdd) {
    const [time] = timeStr.split(' ');
    const [hours, minutes, seconds] = time.split(':').map(Number); 
    const currentTime = moment().hour(hours).minute(minutes).second(seconds); 
    const newTime = currentTime.add(secondsToAdd, 'seconds'); 
    return `${newTime.format('HH:mm:ss')} Hrs`;
  }
  const getDatePickerValue = (date) => {
    try {
      let d = date
      if (d) {
        return dayjs(new Date(d));
      } else {
        return dayjs(new Date()).subtract(1,"day");
      }
    } catch (error) {}
  };
  const [editDescription, setEditDescription] = useState({});
  const [expandedEntry, setExpandedEntry] = useState([]); 
  function handleDeleteEntry(data){
    AwsServerService.deleteTimeTrackRecord(data.ROWID).then(result=>{
      let arr=[...userList];
      arr.forEach((element,index)=>{
       if(element.userId==data.CreatedBy){
        let entrys= element['Entrys'].filter(el=>el.ROWID!=data.ROWID); 
        arr[index]['Entrys']=entrys;
       }
     })
      setUserList(arr);
      swal('Success','Record deleted successfully','success')
    })
  }

  const toggleEntry = (entry) => {
    if (expandedEntry.some(e => e.ROWID === entry.ROWID)) {
      // If the entry is already expanded, remove it
      setExpandedEntry(expandedEntry.filter(e => e.ROWID !== entry.ROWID));
    } else {
      // Otherwise, add it to the expanded entries
      setExpandedEntry([...expandedEntry, entry]);
    }
  };
  const toggleEdit = (entry) => {
    console.log(entry); 
    if (editClicked.some(e => e.ROWID === entry.ROWID)) { 
      setEditClicked(editClicked.filter(e => e.ROWID !== entry.ROWID));
    } else { 
      setEditClicked([...expandedEntry, entry]);
    }
  };

  const handleSaveDescription = async (el) => { 
    setLoader(true);
    await AwsServerService.updateTimeEntry({ROWID:el.ROWID,Description:editDescription[el.ROWID]||el.Description}).then(r=>{
     toggleEdit(el);
     setLoader(false);
    }).catch(err=>{
     toggleEdit(el)
     setLoader(false);
     swal('Error','Error saving the description','warning')
    })
    const updatedUserList = userList.map((user) => {
      if (user.userId === el.CreatedBy) {
        return {
          ...user,
          Entrys: user.Entrys.map((entry) => {
            if (entry.ROWID === el.ROWID) {
              return {
                ...entry,
                Description: editDescription[el.ROWID] || entry.Description, 
              };
            }
            return entry;
          }), }; }
      return user;
    }); 
    setUserList(updatedUserList);  
  } 
  const handleDescriptionChange = (ROWID, value) => {
    setEditDescription((prevState) => ({
      ...prevState,
      [ROWID]: value,  
    }));
  };

  const getTimeTrackPopUp = () => {
    const popoverContent = (
      <div>
        <Collapse rootClassName='Time-Track-Collapse' expandIconPosition='end' expandIcon={({ isActive }) =>
          isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
        }>
        {userList.map((user, index) => (
          <Panel
            header={
              <div className="timetracklist">
                <div className="timetracklist2">
                <img src={ "https://contacts.zoho.com/file?ID=" + user.userId + "&fs=thumb"} alt={'s Profile'} style={{height: '2rem', borderRadius: '1rem'}}/>
                <div>{user.userName}</div>
                <div>{viewTaskData && viewTaskData?.Assignee==userList[index].userId?addSecondsToTime(user.TotalTime,timer3):user.TotalTime}</div>
                </div>
              </div>
            }
            key={user.userId}
            className='time-track-panel'
            // showArrow={false}
          >
            <div className="time_track_scroll">
            <CustomeCollapse
             defaultOpen={[]}
             items={
              Object.keys(user['Entrys']).map((el, i) => {
                let [status,color]=el?.split('_');
                return {
                  key: i,
                  label: (
                    <div
                      className="divForTimeTrackCmp"
                      style={{
                        background: color,
                        color: invertColor(color, true),
                      }}
                    >
                      {status}
                    </div>
                  ),
                  children: (
                    <div className="time_track_scroll">
                      {user["Entrys"][el].map((el, i) => (
                        <Row className="no-gutters user-time-track" key={i}>
                          <Col sm={8}>
                            <ul className="list-unstyled">
                              <li>
                                {el.StartTime ? (
                                  <FontAwesomeIcon icon={faPlayCircle} />
                                ) : (
                                  <FontAwesomeIcon icon={faSlidersH} />
                                )}
                              </li>
                              <li>{el.TotalTime}</li>
                              <li>
                                {moment(el.StartTime).format("MM-DD-YYYY")}
                              </li>
                            </ul>
                          </Col>
                          <Col className="text-right" sm={4}>
                            <ul className="list-unstyled">
                              <li>
                                {expandedEntry.some(
                                  (e) => e.ROWID === el.ROWID
                                ) ? (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="view"
                                    style={{ opacity: "0.9" }}
                                    onClick={() => toggleEntry(el)}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="view"
                                    style={{ opacity: "0.9" }}
                                    onClick={() => toggleEntry(el)}
                                  />
                                )}
                              </li>
                              <li>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  style={{ color: "red", opacity: "0.75" }}
                                  className="delete"
                                  onClick={() => {
                                    swal({
                                      title: "Are you sure?",
                                      text: `You want to delete the time track record?`,
                                      className: "Delete-Time-Record",
                                      icon: "warning",
                                      buttons: {
                                        cancel: {
                                          text: "Cancel",
                                          value: null,
                                          visible: true,
                                          className: "swal-button--cancel",
                                          closeModal: true, // Close the SweetAlert modal only
                                        },
                                        confirm: {
                                          text: "OK",
                                          value: true,
                                          visible: true,
                                          className: "swal-button--confirm",
                                          closeModal: false, // Prevent closing the modal until deletion is confirmed
                                        },
                                      },
                                      dangerMode: true,
                                    }).then(async (willDelete) => {
                                      if (willDelete) {
                                        // Handle deletion
                                        await handleDeleteEntry(el);
                                        settimetrackedPopover(false); // Close the popover if deletion is successful
                                      } else {
                                        // Keep the popover open when Cancel is clicked
                                        settimetrackedPopover(true);
                                      }
                                    });
                                  }}
                                />
                              </li>
                            </ul>
                          </Col>
                          {expandedEntry.some((e) => e.ROWID === el.ROWID) && ( // Check if the entry is expanded
                            <CCollapse
                              verticle
                              visible
                              className="View-Time-Collapse"
                            >
                              <div className="View-Time-Ettry">
                                <p>
                                  <span>From:</span>{" "}
                                  {moment(el.StartTime).format(
                                    "MM-DD-YYYY HH:mm:ss A"
                                  )}
                                </p>
                                <p>
                                  <span>To:</span>{" "}
                                  {el.StopTime === "-"
                                    ? "-"
                                    : moment(el.StopTime).format(
                                        "MM-DD-YYYY HH:mm:ss A"
                                      )}
                                </p>
                                {el.Description && (
                                  <p>
                                    <span style={{ fontWeight: "700" }}>
                                      Description:
                                    </span>{" "}
                                    {editClicked.some(
                                      (a) => a.ROWID == el.ROWID
                                    ) ? (
                                      <div
                                        style={{
                                          maxHeight: "5rem",
                                          overflow: "auto",
                                          minWidth: "10.4rem",
                                        }}
                                      >
                                        {el.Description}
                                      </div>
                                    ) : (
                                      <TextArea
                                        // onClick={()=>setIsSaveClicked(false)}
                                        name="TaskDescription"
                                        // style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                                        // placeholder="Write description.."
                                        value={
                                          editDescription[el.ROWID] ||
                                          el.Description
                                        } // Use the local state
                                        onChange={(e) =>
                                          handleDescriptionChange(
                                            el.ROWID,
                                            e.currentTarget.value
                                          )
                                        }
                                        // value={el.Description}
                                        // onChange={(e) => {
                                        //
                                        //   el.Description=e.currentTarget.value
                                        //   setUserList([...userList]);
                                        // }}
                                      />
                                    )}{" "}
                                    <div style={{ marginRight: "-1em" }}>
                                      <EditOutlined
                                        onClick={(event) => {
                                          toggleEdit(el);
                                        }}
                                      />
                                      <Button
                                        disabled={editClicked.some(
                                          (a) => a.ROWID == el.ROWID
                                        )}
                                        style={{
                                          height: "21px",
                                          width: "5em",
                                          color: "#717171",
                                        }}
                                        onClick={() => {
                                          handleSaveDescription(el);
                                        }}
                                      >
                                        {" "}
                                        Save
                                      </Button>
                                      <Button
                                        disabled={editClicked.some(
                                          (a) => a.ROWID == el.ROWID
                                        )}
                                        style={{
                                          height: "21px",
                                          width: "5em",
                                          color: "#717171",
                                        }}
                                        onClick={() => {
                                          let obj = { ...editDescription };
                                          delete obj[el.ROWID];
                                          setEditDescription(obj);
                                          toggleEdit(el);
                                        }}
                                      >
                                        {" "}
                                        Cancel
                                      </Button>
                                    </div>
                                  </p>
                                )}
                                {el.comments && (
                                  <p>
                                    <span style={{ fontWeight: "700" }}>
                                      Comment:
                                    </span>
                                    <div
                                        className="SidebarBtns"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "start",
                                          cursor: "pointer",
                                        }}
                                      >
                                    <Popover
                                      content={ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(el.comments)}} /> }
                                      title=""
                                      trigger="click"
                                      open={commentPopover === i}
                                      onOpenChange={() => handlePopoverToggle(i)}
                                      rootClassName="Transition-Comment-Popover"
                                      placement='topLeft'
                                    > 
                                        <Button className='viewButtonInRecords'> View </Button>
                                    </Popover>
                                      </div>
                                  </p>
                                )}
                              </div>
                            </CCollapse>
                          )}
                        </Row>
                      ))}
                    </div>
                  ),
                };
              })
             }
          
             rootClassName={'Time-Entries-Collapse'}
            />
            {/* {Object.keys(user['Entrys']).map((el, i) => (
            <div className="time_track_scroll">
              <span>{el}</span>
              {user['Entrys'][el].map((el, i) => (
                <Row className="no-gutters user-time-track" key={i}>
                  <Col sm={8}>
                    <ul className="list-unstyled">
                      <li>
                        {el.StartTime ? (
                          <FontAwesomeIcon icon={faPlayCircle} />
                        ) : (
                          <FontAwesomeIcon icon={faSlidersH} />
                        )}
                      </li>
                      <li>{el.TotalTime}</li>
                      <li>{moment(el.StartTime).format('MM-DD-YYYY')}</li>
                    </ul>
                  </Col>
                  <Col className="text-right" sm={4}> 
                    <ul className="list-unstyled">
                    <li>
                      {expandedEntry.some(e => e.ROWID === el.ROWID) ? (
                        <FontAwesomeIcon icon={faEyeSlash} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      )}
                    </li>
                      <li>
                      <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{color: "red", opacity:"0.75"}}
                          className="delete"
                          onClick={() => { 
                            swal({
                              title: "Are you sure?",
                              text: `You want to delete the time track record?`,
                              className: "Delete-Time-Record",
                              icon: "warning",
                              buttons: {
                                cancel: {
                                  text: "Cancel",
                                  value: null,
                                  visible: true,
                                  className: "swal-button--cancel",
                                  closeModal: true, // Close the SweetAlert modal only
                                },
                                confirm: {
                                  text: "OK",
                                  value: true,
                                  visible: true,
                                  className: "swal-button--confirm",
                                  closeModal: false, // Prevent closing the modal until deletion is confirmed
                                },
                              },
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) { 
                                // Handle deletion
                                await handleDeleteEntry(el);
                                settimetrackedPopover(false); // Close the popover if deletion is successful
                              } else {
                                // Keep the popover open when Cancel is clicked
                                settimetrackedPopover(true);
                              }
                            });
                          }}
                        />
                      </li>
                    </ul>
                  </Col>
                  {expandedEntry.some(e => e.ROWID === el.ROWID) && ( // Check if the entry is expanded
                      <CCollapse verticle visible className='View-Time-Collapse'>
                        <div className='View-Time-Ettry'>
                          <p><span>From:</span> {moment(el.StartTime).format('MM-DD-YYYY HH:mm:ss A')}</p>
                          <p><span>To:</span> {el.StopTime === '-' ? '-' : moment(el.StopTime).format('MM-DD-YYYY HH:mm:ss A')}</p>
                          {el.Description && <p>
                          <span style={{fontWeight:'700'}}>Description:</span> {editClicked.some(a=>a.ROWID==el.ROWID) ? <div style={{maxHeight: '5rem', overflow: 'auto',minWidth:'10.4rem'}}>
                            {el.Description}</div> : 
                              <TextArea
                              // onClick={()=>setIsSaveClicked(false)}
                              name="TaskDescription"
                              // style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                              // placeholder="Write description.."
                              value={editDescription[el.ROWID] || el.Description} // Use the local state
                              onChange={(e) => handleDescriptionChange(el.ROWID, e.currentTarget.value)}
                              // value={el.Description}
                              // onChange={(e) => {
                              //     
                              //   el.Description=e.currentTarget.value
                              //   setUserList([...userList]); 
                              // }}
                            />
                          } <div style={{marginRight: '-1em'}}>
                            <EditOutlined onClick={(event)=>{toggleEdit(el) }} />
                            <Button disabled={editClicked.some(a=>a.ROWID==el.ROWID)} style={{height: '21px',width: '5em',color: '#717171'}} onClick={()=>{handleSaveDescription(el)}}> Save</Button>
                            <Button disabled={editClicked.some(a=>a.ROWID==el.ROWID)} style={{height: '21px',width: '5em',color: '#717171'}} onClick={()=>{
                              let obj={...editDescription};
                              delete obj[el.ROWID]
                              setEditDescription(obj);
                              toggleEdit(el)
                              }} > Cancel</Button>
                            </div>
                          </p>
                          }
                        </div>
                      </CCollapse>
                    )}
                </Row>
              ))}
            </div> 
            ))} */}
            </div>
          </Panel>
        ))}
      </Collapse>
        <div className="p-2 border-top">
          <Tabs activeKey={key} onChange={(k) => setKey(k)} className="mb-2 time_tracked_tabs">
            <TabPane tab="Timer" key="timer">
              <Row style={{ marginTop: '20px' }}>
                <Col sm={9}>
                  <div className="d-flex align-items-center" style={{gap:"10px", marginBottom:"0.4rem"}}>
                  {startTimer ? 
                  // <FontAwesomeIcon icon={faStopCircle} 
                  //  onClick={()=>{ 
                  //   setStartTimer(!startTimer)
                  //   startStopClock(false)
                  //  }} 
                  // className="play-stop-button" 
                  // style={{ color: 'rgb(204, 45, 45)' }}/>
                  commentForStopClock("upper")
                  : <FontAwesomeIcon icon={faPlayCircle} 
                  onClick={()=>{  
                        if(anotherSessionActiveFlag.Active){
                          swal({
                            title: "Alert!",
                            // text: `Time tracking is active for the task " ${anotherSessionActiveFlag.Record?.TaskName}. " Want to stop that Tracking Session?`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                            content: {
                              element: "div",
                              attributes: {
                                innerHTML: `
                                  <span>
                              Time tracking is active for the task " <a><strong class='swalDiv' id='taskNameSS'>${anotherSessionActiveFlag.Record?.TaskName}</strong></a> ".  Want to stop that Tracking Session?".
                                  </span>
                                `
                              }
                            }
                          })
                            .then(async (okay) => {
                              if (okay) {
                                let stopTime =  convertToUSTime(new Date()); 
                                let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.Record?.StartTime));
                                let timeObj=getWorkingHoursMinutesAndSeconds(new Date(startTime), new Date(stopTime));
                                let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes)+":"+formateNoInDecimalString(timeObj.seconds);
                                let payload={ 
                                  StopTime:stopTime,
                                  TotalTime:TotalHours,
                                  ROWID:anotherSessionActiveFlag.Record?.ROWID
                                } 
                                await AwsServerService.updateTimeEntry(payload).then((result) => { 
                                    startStopClock(true);
                                    setStartTimer(!startTimer);
                                    setAnotherSessionActiveFlag({Active:false,Record:{}})
                                    console.log(result);
                                  })
                                  .catch((err) => { 
                                    console.log(err);
                                  });
                              }
                            }); 
                          } else{
                            startStopClock(true);
                            setStartTimer(!startTimer);
                          }
                          const taskNameElement = document.getElementById('taskNameSS'); 
                          if (taskNameElement) {
                            taskNameElement.addEventListener('click', (e) => {
                            swal.close();
                            let data2=anotherSessionActiveFlag.Record;
                            let tasksData={...allTasksAndSubtasks}
                            
                            setLoader(true);
                            if(data2.TaskType=='Tasks'){ 
                              AwsServerService.getTaskById(number(data2.TaskROWID)).then(res=>{
                                let k=res[0]?.taskInfo;
                                if(k){
                                  handleHistoryAdd();
                                  viewTaskFunction(k,"TASK");
                                  // setIsSubTaskView(true);
                                  setLoader(false);
                                }
                              }).catch(e=>{
                                setLoader(false);
                              })
                              // const newData=tasksData.Tasks.get(number(data2.TaskROWID));
                              // handleHistoryAdd()
                              // viewTaskFunction(newData,'TASK')
                            }else{
                              AwsServerService.getSubTaskBySubTaskId(number(data2.TaskROWID)).then(res=>{
                                let k=res[0]?.taskInfo;
                                if(k){
                                  handleHistoryAdd();
                                  viewTaskFunction(k,"SUBTASK");
                                  // setIsSubTaskView(true);
                                  setLoader(false);
                                }
                              }).catch(e=>{
                                setLoader(false);
                              })
                              // const newData=tasksData.SubTasks.get(number(data2.TaskROWID));
                              // handleHistoryAdd()
                              // viewTaskFunction(newData,'SUBTASK')
                            } 
                          });
                        }  
                     }}
                   className="play-stop-button" 
                   style={{ color: 'rgb(45, 204, 110)' }}/>
                   }
                    <div>
                      {formatTime(timer2)}
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  {/* <span className="desc_icon">
                    <FontAwesomeIcon icon={faAlignLeft} id="description" />
                  </span> */}
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          // label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          label: "Description",
                          children: (
                            <TextArea
                            onClick={()=>setIsSaveClicked(false)}
                            name="TaskDescription"
                            style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                            placeholder="Write description.."
                            value={description}
                            onChange={(e) => {
                               setDescription(e.currentTarget.value);
                            }}
                          />
                          ),
                        },
                      ]}
                      rootClassName={"Time-Tracker-Collapse-Wrapper"}
                    />
                  </Row>
            </TabPane>
            <TabPane tab="Range" key="range">
              <div className="time_tracked_fields mb-2">
              <AntdDatepicker
                  // disabledDate={disabledDateLocal}
                  allowClear
                  portalId="root"
                  format="MM/DD/YYYY"
                  value={getDatePickerValue(timeLogDate)}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  onChange={(date) => {
                    setTimeLogDate(dayjs(new Date(date)).format("MM/DD/YYYY"));
                  }}
                  // open={showDatePicker}
                  // onOpenChange={open => setShowDatePicker(open)}
                />
              </div>
              <div className="time_tracked_fields mb-2">
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setFromRange(e)}
                  value={FromRange}
                />
                -
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setToRange(e)}
                  value={ToRange}
                />
              </div>
              <Row>
                <Col sm={7}>
                  <p style={{ marginLeft: '14px' }}>When: now</p>
                </Col>
                <Col sm={5}>
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          // label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          label: "Description",
                          children: (
                            <TextArea
                              name="TaskDescription"
                              style={{ border: "none", color:"#5b6c97" }}
                              placeholder="Write description.."
                              value={''}
                              onChange={(e) => {
                                setDescription(e.currentTarget.value);
                             }}
                            />
                          ),
                        },
                      ]}
                      rootClassName={"Time-Tracker-Collapse-Wrapper"}
                    />
              </Row>
            </TabPane>
          </Tabs>
          <div className="text-right mt-2" style={{gap:"10px"}}>
            <Button
              type="default"
              size="middle"
              onClick={() => {
                settimetrackedPopover(false);
                setDescription('');
              }}
              disabled={!description}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                setIsSaveClicked(true);
              }}
              disabled={isSaveClicked || !description}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  
    return (
      <Popover
        content={popoverContent}
        trigger="click"
        open={timetrackedPopover}
        overlayClassName='Time-Track-Popover'
        onOpenChange={(visible) => settimetrackedPopover(visible)}
        placement='bottom'
      >
      <div onClick={() => settimetrackedPopover(true)} style={(taskModalState.action !== "create" && viewTaskData.TaskStatus!=1) ? {cursor:"pointer", marginLeft:'5px'}:{pointerEvents: 'none', cursor:"not-allowed"}}>{timer==0?"00:00:00" + " Hrs":formatTime(timer)}</div>      
      </Popover>
    );
  };

  return (
    <div className='Time-Track-Wrapper'>
      {startTimer ? 
      // <FontAwesomeIcon icon={faStopCircle} 
      // onClick={()=>{ 
      //   setStartTimer(!startTimer)
      //   startStopClock(false)
      // }} 
      // className="play-stop-button" 
      // style={{ color: 'rgb(204, 45, 45)' }}/>
      commentForStopClock("lower")
     : <FontAwesomeIcon icon={faPlayCircle} 
        onClick={()=>{  
            if(anotherSessionActiveFlag.Active){
              swal({
                title: "Alert!",
                // text: `Time tracking is active for the task " ${anotherSessionActiveFlag.Record?.TaskName}. " Want to stop that Tracking Session?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
                content: {
                  element: "div",
                  attributes: {
                    innerHTML: `
                      <span>
                        Time tracking is active for the task " <a><strong class='swalDiv' id='taskNameSS'>${anotherSessionActiveFlag.Record?.TaskName}</strong></a> ".  Want to stop that Tracking Session?".
                      </span>
                    `
                  }
                }
              })
                .then(async (okay) => {
                  if (okay) {
                    let stopTime =  convertToUSTime(new Date()); 
                    let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.Record?.StartTime));
                    let timeObj=getWorkingHoursMinutesAndSeconds(new Date(startTime), new Date(stopTime));
                    let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes)+":"+formateNoInDecimalString(timeObj.seconds);
                    let payload={ 
                      StopTime:stopTime,
                      TotalTime:TotalHours,
                      ROWID:anotherSessionActiveFlag.Record?.ROWID
                    } 
                    await AwsServerService.updateTimeEntry(payload).then((result) => { 
                        startStopClock(true);
                        setStartTimer(!startTimer);
                        setAnotherSessionActiveFlag({Active:false,Record:{}})
                        console.log(result);
                      })
                      .catch((err) => { 
                        console.log(err);
                      });
                  }
                }); 
              } else{
                startStopClock(true);
                setStartTimer(!startTimer);
              }
              const taskNameElement = document.getElementById('taskNameSS'); 
              if (taskNameElement) {
                taskNameElement.addEventListener('click', (e) => {
                swal.close();
                let data2=anotherSessionActiveFlag.Record;
                // let tasksData={...allTasksAndSubtasks}
                setLoader(true);
                if(data2.TaskType=='Tasks'){ 
                  // const newData=tasksData.Tasks.get(number(data2.TaskROWID));
                  AwsServerService.getTaskById(number(data2.TaskROWID)).then(res=>{
                    let k=res[0]?.taskInfo;
                    if(k){
                      handleHistoryAdd()
                      viewTaskFunction(k,"TASK");
                      // setIsSubTaskView(true);
                      setLoader(false);
                    }
                  }).catch(e=>{
                    setLoader(false);
                  }) 
                  // viewTaskFunction(newData,'TASK')
                }else{
                  // const newData=tasksData.SubTasks.get(number(data2.TaskROWID));
                  AwsServerService.getSubTaskBySubTaskId(number(data2.TaskROWID)).then(res=>{
                    let k=res[0]?.taskInfo;
                    if(k){
                      handleHistoryAdd()
                      viewTaskFunction(k,"SUBTASK");
                      // setIsSubTaskView(true);
                      setLoader(false);
                    }
                  }).catch(e=>{
                    setLoader(false);
                  }) 
                  // handleHistoryAdd()
                  // viewTaskFunction(newData,'SUBTASK')
                } 
              });
            } 
          
        }}
       className="play-stop-button" 
       style={(taskModalState.action !== 'create' && viewTaskData.TaskStatus!=1) ? { color: 'rgb(45, 204, 110)' }:{pointerEvents: 'none',color: 'rgb(163 163 163)'}}/>}
       {getTimeTrackPopUp()}
    </div>
  )
}

export default TimeTracker